import { Provider } from "react-redux";
import styled from "styled-components";
import { Button } from "components/ui/Button";
import { useNavigateTo } from "hooks/ui/useNavigateTo";
import { getStore } from "store/dynamic";

const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
`;

type Props = {
  value?: Parameters<typeof useNavigateTo>[0];
};

const OpenButtonInternal = (props: Props) => {
  const { value } = props;
  const { navigateToItem: openItem, supported } = useNavigateTo(value);

  return supported ? (
    <StyledButton ghost bordered size="small" type="primary" onClick={openItem}>
      OPEN
    </StyledButton>
  ) : null;
};

/**
 * This is a wrapper around the OpenButtonInternal component since it's not created in our react context but in code mirrors,
 * we need to wrap it in a provider to make sure it has access to the store
 */
export const OpenButton = (props: Props) => {
  const store = getStore();
  return (
    <Provider store={store}>
      <OpenButtonInternal {...props}></OpenButtonInternal>
    </Provider>
  );
};
