import styled from "styled-components";
import { LinkButton } from "components/ui/Button";
import { useCopyToClipboard } from "hooks/ui";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getStore } from "store/dynamic";
import {
  closeNotification,
  NotificationPosition,
  sendSuccessUINotification,
} from "utils/notification";

const PAGE_CREATED_NOTIFICATION_DURATION = 120;

export const NOTIFICATION_KEY = "pageCreated";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  .text {
    font-weight: normal;
    margin: 0;
  }

  .copy-action {
    span {
      display: inline-block;
      text-align: center;
      width: 80px;
    }
  }

  .edit-action {
    padding-right: 12px;
  }
`;

type RouteCreatedNotificationProps = {
  pageName: string;
  isDynamic: boolean;
  showEdit: boolean;
  viewRouteParams: Record<string, unknown>;
  updatedRoute?: boolean;
  createdRoute?: boolean;
};

function InternalRouteCreatedNotification({
  pageName,
  isDynamic,
  showEdit,
  viewRouteParams,
  updatedRoute = false,
  createdRoute = false,
}: RouteCreatedNotificationProps) {
  const [isCopied, copy] = useCopyToClipboard(3000);

  const goToPage = () => {
    closeNotification(NOTIFICATION_KEY);
    // we dont use the dispatch hook in this function because its rendered outside of the app contexts
    getStore().dispatch({
      type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
      payload: viewRouteParams,
    });
  };

  if (isDynamic && !showEdit) {
    let prefix = (
      <>
        <strong>{pageName}</strong> page created successfully!
      </>
    );
    if (updatedRoute) {
      prefix = <>Route updated successfully!</>;
    } else if (createdRoute) {
      prefix = <>Route created successfully!</>;
    }

    return (
      <Wrapper>
        <p className="text">
          {prefix} Access route parameters using{" "}
          <strong>Global.URL.routeParams</strong>.
        </p>
        <div className="copy-action">
          <LinkButton
            onClick={() => {
              copy("Global.URL.routeParams");
            }}
          >
            {isCopied ? "Copied" : "Copy path"}
          </LinkButton>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <p className="text">
        <strong>{pageName}</strong> page created successfully!
      </p>
      {showEdit && (
        <div className="edit-action">
          <LinkButton onClick={goToPage}>Edit page</LinkButton>
        </div>
      )}
    </Wrapper>
  );
}

export function sendPageRouteSuccessNotification(
  props: RouteCreatedNotificationProps,
) {
  sendSuccessUINotification({
    message: <InternalRouteCreatedNotification {...props} />,
    key: "pageCreated",
    placement: NotificationPosition.top,
    duration: PAGE_CREATED_NOTIFICATION_DURATION,
    isUISystemInitiated: true,
    style: {
      width: "fit-content",
      maxWidth: "550px",
    },
  });
}
