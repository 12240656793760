import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { CUSTOMER_UIBLOCKS_CATEGORY_ID } from "pages/Editors/UiBlocks/InsertUIBlocksModal/constants";
import { Flag } from "store/slices/featureFlags";
import {
  useGetDemoUiBlocksCategoriesQuery,
  useListDemoUiBlocksQuery,
  useListUiBlockCategoriesQuery,
} from "store/slices/reduxApi/uiBlocks";
import {
  UIBlockCategory,
  UIBlockConfigWithCategory,
} from "store/slices/uiBlocks/types";
import { findFirstParent } from "utils/findFirstParent";
import { useFeatureFlag } from "./useFeatureFlag";

export const useUiBlocksWithCategory = () => {
  const showPending = useFeatureFlag(Flag.UI_BLOCKS_ADMINISTRATION);
  const shouldUseServer = useFeatureFlag(Flag.UI_BLOCKS_BACKEND);
  const showCustomerBlocks = useFeatureFlag(Flag.UI_BLOCKS_CUSTOMER_BLOCKS);

  const demoBlocksResponse = useListDemoUiBlocksQuery(
    {
      showPending,
    },
    shouldUseServer ? { skip: true } : undefined,
  );

  const demoCategoriesResponse = useGetDemoUiBlocksCategoriesQuery(
    { showPending },
    shouldUseServer ? { skip: true } : undefined,
  );

  const backendCategoriesResp = useListUiBlockCategoriesQuery(
    { showPending },
    shouldUseServer ? undefined : { skip: true },
  );

  const onRefresh = useCallback(async () => {
    if (shouldUseServer) {
      if (!backendCategoriesResp.isUninitialized) {
        await backendCategoriesResp.refetch();
      }
    } else {
      if (
        !demoBlocksResponse.isUninitialized &&
        !demoCategoriesResponse.isUninitialized
      ) {
        await demoBlocksResponse.refetch();
        await demoCategoriesResponse.refetch();
      }
    }
  }, [
    shouldUseServer,
    backendCategoriesResp,
    demoBlocksResponse,
    demoCategoriesResponse,
  ]);

  return useMemo<{
    uiBlocksWithCategory: UIBlockConfigWithCategory[];
    uiBlocksCategories: UIBlockCategory[];
    isLoadingBlocks: boolean;
    isLoadingCategories: boolean;
    customerUiBlocks: UIBlockConfigWithCategory[];
    customerCategory?: UIBlockCategory;
    error?: unknown;
    onRefresh: () => void;
  }>(() => {
    let uiBlocksWithCategory: UIBlockConfigWithCategory[] = [];
    let uiBlocksCategories: UIBlockCategory[] = [];
    let isLoadingBlocks = false;
    let isLoadingCategories = false;
    let error = null;

    if (shouldUseServer) {
      uiBlocksWithCategory = (backendCategoriesResp.data ?? []).reduce(
        (acc, category) => {
          return acc.concat(
            category.blocks.map((block) => ({
              ...block,
              categoryId: category.id,
            })),
          );
        },
        [] as UIBlockConfigWithCategory[],
      );

      uiBlocksCategories = backendCategoriesResp.data ?? [];
      isLoadingBlocks = backendCategoriesResp.isLoading;
      isLoadingCategories = backendCategoriesResp.isLoading;
      error = backendCategoriesResp.error;
    } else {
      uiBlocksWithCategory =
        demoBlocksResponse.data?.map(
          (block) =>
            ({
              ...block,
              categoryId: block.category,
            }) as UIBlockConfigWithCategory,
        ) ?? [];
      uiBlocksCategories = demoCategoriesResponse.data ?? [];
      isLoadingBlocks = demoBlocksResponse.isLoading;
      isLoadingCategories = demoCategoriesResponse.isLoading;
      error = demoBlocksResponse.error || demoCategoriesResponse.error;
    }

    const customerCategory = uiBlocksCategories.find(
      (category) => category.id === CUSTOMER_UIBLOCKS_CATEGORY_ID,
    );

    // filter customer blocks category
    uiBlocksCategories = uiBlocksCategories.filter(
      (category) => category.id !== CUSTOMER_UIBLOCKS_CATEGORY_ID,
    );

    const { publicUiBlocks, customerUIBlocks } = uiBlocksWithCategory.reduce(
      (acc, block) => {
        if (block.categoryId === CUSTOMER_UIBLOCKS_CATEGORY_ID) {
          acc.customerUIBlocks.push(block);
        } else {
          acc.publicUiBlocks.push(block);
        }
        return acc;
      },
      {
        publicUiBlocks: [] as UIBlockConfigWithCategory[],
        customerUIBlocks: [] as UIBlockConfigWithCategory[],
      },
    );

    return {
      uiBlocksWithCategory: publicUiBlocks,
      isLoadingBlocks,
      isLoadingCategories,
      uiBlocksCategories,
      customerUiBlocks: showCustomerBlocks ? customerUIBlocks : [],
      customerCategory,
      error,
      onRefresh,
    };
  }, [
    shouldUseServer,
    showCustomerBlocks,
    demoBlocksResponse.data,
    demoBlocksResponse.isLoading,
    demoBlocksResponse.error,
    demoCategoriesResponse.data,
    demoCategoriesResponse.isLoading,
    demoCategoriesResponse.error,
    onRefresh,
    backendCategoriesResp.data,
    backendCategoriesResp.isLoading,
    backendCategoriesResp.error,
  ]);
};

export const useShowUIBlockPrompt = (widgetId: undefined | string | null) => {
  const isBlocksEnabled = useFeatureFlag(Flag.UI_BLOCKS_FOR_USERS);
  const widgets = useSelector(getWidgets);
  // Grid widgets will almost never be able to accept a UI block since they don't support nestable widgets
  const isWithinGridWidget = useMemo(
    () =>
      widgetId &&
      findFirstParent(
        widgetId,
        widgets,
        (widget) => widget.type === WidgetTypes.GRID_WIDGET,
      ) != null,
    [widgetId, widgets],
  );
  return isBlocksEnabled && !isWithinGridWidget;
};
