import { TASKS, validateEmail } from "@superblocksteam/shared";
import { Select } from "antd";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { FullWidthSpace } from "components/ui/Space";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import { sendOrgInvitation } from "pages/components/invitation/utils";
import { SelectWrapper } from "./RoundedDropDown";
import { NextButton, QuestionLabel, SecondaryText } from "./SurveyUtilities";

const StyledSelect = styled(Select)`
  font-size: 16px;
  padding: 4px 0;
  border: 1px solid ${(props) => props.theme.colors.GREY_300};
  box-sizing: border-box;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.GREY_500};
  margin-top: 8px;
  width: 400px;
`;

interface SurveyInviteProps {
  organizationId: string;
  onDone: (emails: string[]) => void;
}

export const SurveyInvite = ({ onDone, organizationId }: SurveyInviteProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [markTaskComplete] = useMarkTaskComplete(TASKS.INVITE_TEAMMATE);
  const sendEmails = useCallback(async () => {
    const emailList = emails
      .map((val) => val.trim())
      .filter((val) => !isEmpty(val) && validateEmail(val));
    if (emailList.length) {
      await sendOrgInvitation({
        userEmails: emailList,
        organizationId,
      });
      markTaskComplete();
    }
    onDone(emailList);
  }, [emails, markTaskComplete, onDone, organizationId]);
  return (
    <FullWidthSpace direction="vertical" size={30}>
      <FullWidthSpace direction="vertical" size={18}>
        <QuestionLabel>Invite your team to collaborate</QuestionLabel>
        <SecondaryText>
          Superblocks is better when you build alongside your teammates!
        </SecondaryText>
      </FullWidthSpace>
      <FullWidthSpace direction="vertical" size={60}>
        <SelectWrapper>
          <StyledSelect
            placeholder="Enter emails separated by commas"
            bordered={false}
            onChange={setEmails as any}
            mode="tags"
            // create a tag button for each email every time the user enters a comma or space
            tokenSeparators={[",", " "]}
            // we do not want the dropdown to open because we have nothing to show there
            // we are only using Select for its mode="tags" and are otherwise treating it as an Input
            open={false}
          />
        </SelectWrapper>
        <NextButton onClick={sendEmails}>
          {emails.length > 0 ? "Send invites" : "I'm done"}
        </NextButton>
      </FullWidthSpace>
    </FullWidthSpace>
  );
};
