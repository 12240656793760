import { compact, flatten } from "lodash";
import { ReactNode } from "react";
import {
  WidgetType,
  WidgetTypes,
  Hierarchy,
} from "legacy/constants/WidgetConstants";
import {
  ComponentMenuItem,
  MenuItemType,
} from "legacy/widgets/MenuWidget/types";
import { getLayoutIcon } from "./LayoutIcons";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import type { TabsWidgetProps } from "legacy/widgets/TabsWidget/types";

export type SelectedItemPos = {
  index: number;
  level: number;
};

// TODO: Remove this once we have a better way to handle this
// this is here to handle tabsWidget
type WidgetPropsWithTabs = FlattenedWidgetProps & {
  tabName?: any;
};

export const getWidgetDisplayName = (
  widget: WidgetPropsWithTabs,
  parentWidget?: FlattenedWidgetProps,
  displayName?: string,
) => {
  const isColumn =
    widget.parentId &&
    parentWidget &&
    parentWidget.type === WidgetTypes.SECTION_WIDGET;
  if (isColumn) {
    return `Column${
      (parentWidget.children?.indexOf(widget.widgetId) || 0) + 1
    }`;
  }

  const isTab =
    widget.parentId &&
    parentWidget &&
    parentWidget.type === WidgetTypes.TABS_WIDGET;
  if (isTab) {
    const tabName = (parentWidget as TabsWidgetProps<any>).tabs.find(
      (tab) => tab.widgetId === widget.widgetId,
    )?.label;
    if (tabName) return tabName;
  }

  if (widget.type === WidgetTypes.CANVAS_WIDGET && parentWidget) {
    return parentWidget.widgetName;
  }

  if (displayName) return displayName;
  return widget.widgetName;
};

export const getNestedItemDisplayName = (nestedItem?: unknown): string => {
  if (!nestedItem) {
    return "Deleted menu item";
  }
  const menuItem = nestedItem as ComponentMenuItem;
  if (menuItem.type === MenuItemType.Divider) {
    return "Divider";
  } else {
    return menuItem.label;
  }
};

export const getWidgetTreeByKeyword = (
  widgetId: string,
  widgets: CanvasWidgetsReduxState,
  options: {
    keyword?: string;
    omitTypes?: WidgetType[];
  } = {},
  topLevel = true,
): Hierarchy[] | Hierarchy | undefined => {
  const currentWidget: WidgetPropsWithTabs = widgets[widgetId];

  if (currentWidget) {
    const isCanvas = currentWidget.type === WidgetTypes.CANVAS_WIDGET;
    const isATab =
      isCanvas && "tabName" in currentWidget && currentWidget.tabName;
    const parent = widgets[currentWidget.parentId];
    const parentIsSection =
      currentWidget.parentId &&
      parent &&
      parent.type === WidgetTypes.SECTION_WIDGET;

    const isAColumn = parentIsSection;
    const shouldOmit =
      !topLevel &&
      ((isCanvas && !isAColumn && !isATab) ||
        options.omitTypes?.includes(currentWidget.type));

    const childrenTree: Hierarchy[] = flatten(
      compact(
        currentWidget.children?.map((child: string) =>
          getWidgetTreeByKeyword(child, widgets, options, false),
        ),
      ),
    );

    const widgetName = getWidgetDisplayName(currentWidget, parent);

    let icon: ReactNode | undefined;
    if (currentWidget.type === WidgetTypes.CANVAS_WIDGET) {
      icon = getLayoutIcon(currentWidget.layout);
    }

    if (
      !options.keyword ||
      widgetName.toLowerCase().includes(options.keyword) ||
      childrenTree.length
    ) {
      if (shouldOmit) {
        return childrenTree;
      }
      return {
        widgetName,
        widgetId,
        type: currentWidget.tabName
          ? WidgetTypes.TABS_WIDGET
          : currentWidget.type,
        icon,
        children: childrenTree,
      };
    }
  }
};

export const flattenSectionWidgets = (
  hierarchy: Hierarchy | undefined,
  parentType: Hierarchy["type"] | undefined,
  childIndex = 0,
): Hierarchy | undefined => {
  // Base case: if hierarchy is not present, just return
  if (!hierarchy || !hierarchy.children) return;

  // Check and update for CANVAS_WIDGET with SECTION_WIDGET as parent
  if (
    hierarchy.type === WidgetTypes.CANVAS_WIDGET &&
    parentType === WidgetTypes.SECTION_WIDGET
  ) {
    hierarchy.widgetName = `Column${childIndex + 1}`;
  }

  // Recur for children
  if (hierarchy.children && hierarchy.children.length) {
    hierarchy.children.forEach((child, index) => {
      flattenSectionWidgets(child, hierarchy.type, index);
    });
  }

  return hierarchy;
};

export const sortWidgets = (
  widgets: Hierarchy | undefined,
): Hierarchy | undefined => {
  if (!widgets || !widgets.children) return widgets;

  const children: any = widgets.children
    .sort(
      (a, b) =>
        (a.top?.value || 0) - (b.top?.value || 0) ||
        (a.left?.value || 0) - (b.left?.value || 0),
    )
    .map((w) => sortWidgets(w));

  return { ...widgets, children };
};
