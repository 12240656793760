import { ApplicationScope, RouteDef } from "@superblocksteam/shared";
import {
  DataTree,
  DataTreeEvent,
} from "legacy/entities/DataTree/dataTreeFactory";
import { generateReactKey } from "legacy/utils/generators";
import {
  getResettableProperties,
  getSettableProperties,
} from "legacy/widgets/eventHandlerPanel";
import { AddEventActionValue } from "./types";

const sanitizeEventHandlers = ({
  dataTree,
  routes,
  value,
}: {
  dataTree: DataTree;
  routes: RouteDef[];
  value: AddEventActionValue;
}) => {
  const getWidget = (widgetName: string) => {
    for (const scope in dataTree) {
      const widgets = (dataTree as Record<string, any>)[scope];
      const allWidgets = Object.values(widgets) as {
        widgetId?: string;
        widgetName?: string;
        type?: string;
      }[];
      const widget = allWidgets.find(
        (widget) => widget.widgetName === widgetName,
      );
      if (widget) {
        return {
          name: widgetName,
          id: widget.widgetId,
          type: widget.type,
        };
      }
    }
    return undefined;
  };

  const getItemId = (
    itemName: string,
    scope: string = ApplicationScope.PAGE,
  ) => {
    const items = (dataTree as Record<string, any>)[scope];
    const allItems = Object.values(items) as {
      id?: string;
      name?: string;
    }[];
    const foundItem = allItems.find((item) => item.name === itemName);
    if (foundItem) {
      return foundItem.id;
    }
    return undefined;
  };

  const itemExists = (itemName: string) => {
    const items = (dataTree as Record<string, any>)[ApplicationScope.PAGE];
    return !!items[itemName];
  };

  function parseFloatOrUndefined(val: unknown): number | undefined {
    const parsed = parseFloat(String(val));
    return isNaN(parsed) ? undefined : parsed;
  }

  const getItem = (itemName: string, scope: string) => {
    const items = (dataTree as Record<string, any>)[scope];
    const allItems = Object.values(items || {}) as {
      id?: string;
      name?: string;
    }[];
    const foundItem = allItems.find((item) => item.name === itemName);
    if (foundItem) {
      return foundItem;
    }
    return undefined;
  };

  let newValue = {};

  switch (value.type) {
    case "setComponentProperty":
    case "resetComponent": {
      let property: string | undefined = value.propertyName;
      const widget = getWidget(value.component);
      const availableProperties = !widget?.type
        ? []
        : value.type === "setComponentProperty"
          ? getSettableProperties(widget.type)
          : getResettableProperties(widget.type);
      if (
        !availableProperties ||
        !availableProperties.some((prop) => prop.value === property)
      ) {
        console.warn(
          `Property ${property} is not available for component ${value.component}`,
        );
        property = availableProperties?.[0]?.value;
      }
      newValue = { ...value };
      if (value.type === "setComponentProperty") {
        let propertyValue = value.propertyValue;
        if (typeof propertyValue === "object" && propertyValue) {
          propertyValue = JSON.stringify(propertyValue, null, 2);
        }
        (newValue as any).propertyValue = propertyValue;
      }
      if (!availableProperties || availableProperties?.length === 0) {
        newValue = {
          ...newValue,
          component: undefined,
          propertyName: undefined,
          widget: undefined,
        };
      } else {
        newValue = {
          ...newValue,
          component: undefined,
          widget,
          propertyName: property,
        };
      }
      break;
    }
    case "navigateToRoute":
      newValue = {
        ...value,
        routePathDescriptor: undefined,
        routeId: routes.find(
          (route) => route.path === value.routePathDescriptor,
        )?.id,
      };
      break;
    case "setStateVar": {
      const itemId = getItemId(value.state.name, value.state.scope);
      newValue = {
        ...value,
        state: itemId
          ? {
              ...value.state,
              id: itemId,
            }
          : undefined,
      };
      break;
    }
    case "resetStateVar": {
      const itemId = getItemId(value.state.name, value.state.scope);
      newValue = {
        ...value,
        state: itemId
          ? {
              name: value.state.name,
              scope: value.state.scope,
              id: itemId,
            }
          : undefined,
      };
      break;
    }
    case "controlTimer": {
      const itemId = getItemId(
        value.state.name ?? value.name,
        value.state.scope,
      );
      newValue = {
        ...value,
        state: itemId
          ? {
              name: value.state.name,
              scope: value.state.scope,
              id: itemId,
            }
          : undefined,
      };
      break;
    }
    case "triggerEvent": {
      const event = getItem(
        value.event.name,
        value.event.scope,
      ) as DataTreeEvent;
      let eventPayload = {};
      if (event) {
        eventPayload = Object.entries(value.eventPayload ?? {}).reduce(
          (acc, [argumentName, argumentValue]) => {
            const argumentId = (event.arguments ?? [])?.find(
              (arg: { name: string; id: string }) => arg.name === argumentName,
            )?.id;
            if (argumentId) {
              acc[argumentId] = argumentValue;
            }
            return acc;
          },
          {} as Record<string, any>,
        );
      }
      newValue = {
        ...value,
        event: event
          ? {
              name: value.event.name,
              scope: value.event.scope,
              id: event?.id,
            }
          : undefined,
        eventPayload,
      };
      break;
    }
    case "executeApi":
    case "cancelApi":
      newValue = {
        ...value,
        apiNames: value.apiNames.filter(itemExists),
      };
      break;
    case "showAlert":
      newValue = {
        type: value.type,
        message: value.message,
        style: value.style,
        alertDuration:
          value.alertDuration != null
            ? parseFloatOrUndefined(value.alertDuration)
            : undefined,
        alertPosition: value.alertPosition,
      };
      break;
    default:
      newValue = value;
  }

  return {
    ...newValue,
    id: generateReactKey(),
  };
};

export const getEventHandlers = ({
  prevValue,
  value,
  dataTree,
  routes,
}: {
  prevValue: any;
  value: AddEventActionValue | AddEventActionValue[];
  dataTree: DataTree;
  routes: RouteDef[];
}) => {
  const newEvents = [...((prevValue as Array<any>) ?? [])];
  if (Array.isArray(value)) {
    for (const event of value) {
      newEvents.push(
        sanitizeEventHandlers({
          dataTree,
          routes,
          value: event as AddEventActionValue,
        }),
      );
    }
  } else {
    newEvents.push(
      sanitizeEventHandlers({
        dataTree,
        routes,
        value,
      }),
    );
  }
  return newEvents;
};
