import {
  ApiResponseType,
  ApplicationScope,
  CustomComponentProperty,
  WidgetTypes,
} from "@superblocksteam/shared";
import _, { get, isFunction, merge } from "lodash";
import ecma from "tern/defs/ecmascript.json";
import { convertDef } from "autocomplete/convertPublicDefs";
import {
  AutocompleteConfiguration,
  AutocompleteFunctionsType,
} from "components/app/CodeEditor/EditorConfig";
import {
  ComponentToSettableProperties,
  SettableWidgets,
} from "legacy/constants/EventTriggerPropertiesConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { generateReactKey } from "legacy/utils/generators";
import { ExecutionOutputDto } from "store/slices/apis/types";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { omitGlobal } from "utils/dataTree/globalOmit";
import { resolveScopes } from "utils/dataTree/resolveScopes";
import { ApiScope } from "utils/dataTree/scope";
import { getDottedPathTo } from "utils/dottedPaths";
import omit from "utils/omit";
import recursiveGenerateTypeDef from "./recursiveGenerateTypeDef";
import type { EntityType, TypeDef, TypeInfo } from "./types";
import type {
  DataTree,
  DataTreeAction,
  DataTreeEmbed,
  DataTreeEmbedProp,
  DataTreeEntity,
  DataTreeEvent,
  DataTreeStateVar,
  DataTreeTimer,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";

export enum Origin {
  ENTITY_AC = "entity-complete",
  CUSTOM_DATA_TREE = "customDataTree",
  DATA_TREE = "dataTree",
  APP_DATA_TREE = "appDataTree",
}

export type DataTreeDef = TypeInfo & {
  "!name"?: string;
  Global?: any;
  theme?: any;
  secrets?: any;
  icons?: any;
};

/**
 * We use this function because we want [fdafssda] to take precedence over
 * inline docs for a smaller type def.
 * This is also protects the worker which will fail to correctly parse string objects
 * that also contain other values. It will drop the extra values and cause tern to fail
 * TODO: The Global object needs to be cleaned up as the use
 * of this is due to poor typeDefDesign.
 */
const mergeUnlessString = (a: any, b: any) => {
  const firstIsString = _.isString(a);
  const secondIsString = _.isString(b);
  // if both are strings, return the first one
  if (firstIsString && secondIsString) {
    return a;
  }
  // if one is a string and the other is an object, return the string
  if (firstIsString || secondIsString) {
    return firstIsString ? a : b;
  }
  // if both are objects, merge them
  return merge(a, b);
};

const setPropertyFunction = (
  widgetType: WidgetType,
  functions?: AutocompleteFunctionsType | false,
) => {
  if (functions !== AutocompleteFunctionsType.FRONTEND) {
    return {};
  }
  const castWidgetType = widgetType as (typeof SettableWidgets)[number];
  if (SettableWidgets.includes(castWidgetType)) {
    const settableProperties = ComponentToSettableProperties[
      castWidgetType
    ].map(({ value }) => `"${value}"`);
    return {
      "set()": {
        "!type": "fn(propertyName: string, propertyValue: any)",
        "!doc": `Set component property to specified value. \nProperties available to set are: ${settableProperties.join(
          ", ",
        )}`,
      },
    };
  }
  return {};
};

const isVisible = {
  "!type": "bool",
  "!doc": "Boolean value indicating if the component is in visible state",
};
export const entityDefinitions = {
  ACTION: (
    entity: DataTreeAction,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    let response: undefined | Record<string, any>;
    if (entity.responseType !== ApiResponseType.STREAM) {
      const dataDef = generateTypeDef(entity.response);
      response = {
        "!doc": "The output of the last step of the API",
      };
      if (_.isString(dataDef)) {
        response["!type"] = dataDef;
      } else {
        response = { ...response, ...dataDef };
      }
    }

    return {
      "!doc":
        "Superblocks APIs are used to connect components to data from database queries, API calls and business logic in JS",
      "!url": "",
      ...(response ? { response } : {}),
      error: {
        "!type": "string | null",
        "!doc":
          "The API's error message. If there is no error, this will be null.",
        "!url": "",
      },
      ...(functions === AutocompleteFunctionsType.FRONTEND
        ? {
            "run()": {
              "!type": "fn() -> void",
              "!doc":
                "Function used to call a Superblocks API from a UI Component or API step",
              "!url": "",
            },
            "clearResponse()": {
              "!type": "fn() -> void",
              "!doc":
                "Clear the response from the last call of the API. For example, clear when the input text changes.",
              "!url": "",
            },
            "cancel()": {
              "!type": "fn() -> void",
              "!doc":
                "Function used to cancel a running Superblocks API from a UI Component or API step",
              "!url": "",
            },
          }
        : {}),
    };
  },
  ACTION_STEP: (entity?: ExecutionOutputDto) => {
    const dataDef = generateTypeDef(entity?.output || undefined);

    let outputDef: Record<string, any> = {
      "!doc": "The output of a single step of the API",
    };
    if (_.isString(dataDef)) {
      outputDef["!type"] = dataDef;
    } else {
      outputDef = { ...outputDef, ...dataDef };
    }
    return {
      "!doc": "One of the previous steps of the API",
      output: outputDef,
    };
  },
  STATE_VAR: (
    entity: DataTreeStateVar,
    functions?: AutocompleteFunctionsType | false,
    data?: any,
  ) => {
    return {
      "!doc":
        "Superblocks Variables are used to store & read data in your app.",
      "!url": "",
      value: {
        "!type": generateTypeDef(data?.value ?? {}),
        "!doc": "The value of the state",
        "!url": "",
      },
      ...(functions === AutocompleteFunctionsType.FRONTEND
        ? {
            "set()": {
              "!type": "fn(data: any) -> void",
              "!doc": "sets the value for this variable",
            },
            "setProperty()": {
              "!type": "fn(path: string, data: any) -> void",
              "!doc":
                "assumes that the variable is an object and sets the value for a property path (e.g. name.first) in the variable",
            },
            "resetToDefault()": {
              "!type": "fn() -> void",
              "!doc": "resets the variable to its default value",
            },
          }
        : {}),
    };
  },
  EMBED: (
    entity: DataTreeEmbed,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    return {
      "!doc":
        "Embed object with data passed from host app to Superblocks via iframe",
      "!url": "",
    };
  },
  EMBED_PROP: (
    entity: DataTreeEmbedProp,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    return {
      "!doc": "Embed property passed from host app to Superblocks via iframe",
      "!url": "",
      value: {
        "!type": "any",
        "!doc":
          "The value of the embed property passed from host app to Superblocks app",
        "!url": "",
      },
    };
  },
  CUSTOM_EVENT: (
    entity: DataTreeEvent,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    const argsType =
      entity.arguments && entity.arguments.length > 0
        ? `{${(entity.arguments ?? []).map((a) => `${a.name}: ?`).join(", ")}}`
        : "{}";
    return {
      "!doc":
        "Custom events are used to trigger actions in your app based on user interactions",
      "!url": "",
      ...(functions === AutocompleteFunctionsType.FRONTEND
        ? {
            "trigger()": {
              "!type": `fn(payload: ${argsType}) -> void`,
              "!doc":
                "Trigger this custom event. The payload maps argument names to values.",
            },
          }
        : {}),
    };
  },
  TIMER: (
    entity: DataTreeTimer,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    return {
      "!doc":
        "Superblocks Timers are used to call APIs or widget actions periodically on an interval",
      "!url": "",
      isActive: {
        "!type": "bool",
        "!doc": "Whether or not the timer is running",
        "!url": "",
      },
      ...(functions === AutocompleteFunctionsType.FRONTEND
        ? {
            "start()": {
              "!type": "fn() -> void",
              "!doc": "start this timer",
            },
            "stop()": {
              "!type": "fn() -> void",
              "!doc": "stop this timer",
            },
            "toggle()": {
              "!type": "fn() -> void",
              "!doc": "toggle this timer on or off",
            },
          }
        : {}),
    };
  },
  CONTAINER_WIDGET: {
    "!doc":
      "Containers are a parent component used to group other components together in a box visually",
    "!url": "",
    backgroundColor: {
      "!type": "string",
      "!doc": "The hex background color of the container",
    },
    borderColor: {
      "!type": "string",
      "!doc": "The hex border color of the container",
    },
    isVisible: isVisible,
  },
  INPUT_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Input components capture a users keyboard input such as names, numbers, emails in forms",
    "!url": "",
    value: {
      "!type": "string",
      "!doc": "The string of text in the input",
      "!url": "",
    },
    isValid: {
      "!type": "bool",
      "!doc": "true if input is valid, false if invalid",
    },
    isVisible: isVisible,
    isDisabled: {
      "!type": "bool",
      "!doc": "true if input is disabled, false otherwise.",
    },
    isoCurrencyCode: {
      "!type": "string",
      "!doc":
        "The three letter ISO 4217 currency code of the input. Only relevant for inputs of type currency.",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  TABLE_WIDGET: (
    widget: DataTreeWidget & any /*TableWidgetEvaluatedProps*/,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Table components take in an Array of rows or Object of columns in JSON",
    "!url": "",
    selectedRow: {
      "!type": generateTypeDef(widget.selectedRowSchema),
      "!doc": "The JSON data from the selected row of the table",
    },
    selectedRows: generateTypeDef(widget.selectedRows),

    selectedRowIndex: {
      "!type": "number",
      "!doc": "The numerical index of the selected row in the table",
    },
    selectedRowIndices: {
      "!type": "[number]",
      "!doc": "The numerical indices of the selected rows in the table",
    },
    tableData: {
      "!type": generateTypeDef(widget.tableData),
      "!doc": "The data structure that stores the values in the table",
    },
    filteredTableData: {
      "!type": generateTypeDef(widget.filteredTableData),
      "!doc":
        "The data structure that stores the filtered, sorted, and searched values shown in the table, including custom columns",
    },
    pageNo: {
      "!type": "number",
      "!doc": "The current selected page number of the table",
    },
    //delete - what is this property?
    pageSize: {
      "!type": "number",
      "!doc": "",
    },
    isVisible: isVisible,
    searchText: {
      "!type": "string",
      "!doc": "The string value in the search input in the table",
    },
    filters: {
      "!type": generateTypeDef(widget.filters),
      "!doc": "The object specifying the per column filters used in the table",
    },
    ...setPropertyFunction(widget.type, functions),
    allEdits: {
      "!type": generateTypeDef(widget.allEdits),
      "!doc": "Data structure containing all table data with edits.",
    },
    editedRows: {
      "!type": generateTypeDef(widget.editedRows),
      "!doc":
        "Object containing the old and new values of all modified rows in the table.",
    },
    editedRowIndices: {
      "!type": generateTypeDef(widget.editedRowIndices),
      "!doc": "Array containing the indices of all modified rows in the table.",
    },
    currentEditValue: {
      "!type": "string",
      "!doc": "String containing the cell value of the in-progress edit.",
    },
    currentEditDropdownSearchText: {
      "!type": "string",
      "!doc":
        "The string value in the search input in the dropdown for the currently focused table cell being edited via a dropdown.",
    },
    deletedRows: {
      "!type": "[number]",
      "!doc": "Object containing the deleted rows in the table.",
    },
    ...(functions === AutocompleteFunctionsType.FRONTEND
      ? {
          "updateRows()": {
            "!doc": {
              markdownDocString: `Update table rows. For example, \`\`\`MyTable.updateRows({ 0: { name: "John" } })\`\`\` will update the first row of the table and set the name column to "John".

Supply options, \`\`\`{ absoluteIndices: true }\`\`\` to update rows by their absolute index in the table data, rather than their index in a currently filtered or sorted table view.`,
            },
            "!type": `fn(rows: {}, options?: {}) -> void`,
          },
          "deleteRows()": {
            "!doc": {
              markdownDocString: `Delete table rows. For example, \`\`\`MyTable.deleteRows([0, 1])\`\`\` will delete the first two rows of the table.`,
            },
            "!type": `fn(rows: [number]) -> void`,
          },
          "insertRows()": {
            "!doc": {
              markdownDocString: `Insert table rows. For example, \`\`\`MyTable.insertRows(0, [ { name: "John" } ])\`\`\` will insert a new row at the top of the table and set the name column to "John".`,
            },
            "!type": `fn(startIndex: number, rows: [{}]) -> void`,
          },
        }
      : {}),
  }),
  VIDEO_WIDGET: {
    "!doc":
      "Video components take a URL and pull the video into your application",
    "!url": "",
    playState: "number", //come back
    autoPlay: {
      "!type": "bool",
      "!doc": "true if autoplay enabled, false if disabled",
    },
  },
  DROP_DOWN_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Dropdown components capture the user selected option from an option list",
    "!url": "",
    isVisible: isVisible,
    selectedOptionValue: {
      "!type": "string",
      "!doc": "The string of the selected value in the dropdown",
      "!url": "",
    },
    selectedOptionValues: {
      "!type": "[string]",
      "!doc": "An array of strings selected in a multi select dropdown",
      "!url": "",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if dropdown disabled, false otherwise",
    },
    options: {
      "!type": "[dropdownOption]",
      "!doc": "An Array of available options in the dropdown",
    },
    searchText: {
      "!type": "string",
      "!doc": "The string value in the search input in the dropdown",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  IMAGE_WIDGET: {
    "!doc": "Image components display images using a URL or valid base64",
    "!url": "",
    image: {
      "!type": "string",
      "!doc": "The string URL or Base64 used to reference the image",
    },

    isVisible: isVisible,
  },
  ICON_WIDGET: {
    "!doc": "Icon components display built-in icons or custom icons from URLs",
    "!url": "",
    icon: {
      "!type": "string",
      "!doc": "The selected icon",
    },
    isVisible: isVisible,
  },
  TEXT_WIDGET: {
    "!doc": "‌Text components display headings, labels and paragraphs",
    "!url": "",
    isVisible: isVisible,
    text: {
      "!type": "string",
      "!doc": "The string of text from the text component",
    },
  },
  BUTTON_WIDGET: {
    "!doc":
      "Buttons trigger Superblocks APIs based on user intent (eg. Submit a form)",
    "!url": "",
    isVisible: isVisible,
    text: {
      "!type": "string",
      "!doc": "The string of text on the button",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if button is disabled, false otherwise",
    },
  },
  LINK_WIDGET: {
    "!doc":
      "Links represent hyperlinks that navigate to a different page or URL",
    "!url": "",
    isVisible: isVisible,
    text: {
      "!type": "string",
      "!doc": "The string of text on the button",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if button is disabled, false otherwise",
    },
  },
  DATE_PICKER_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Datepicker components capture the date and time from a user (eg. Date of Birth)",
    "!url": "",
    isVisible: isVisible,
    selectedDate: {
      "!type": "string",
      "!doc": "The string of the selected date in the datepicker",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if datepicker is disabled, false otherwise",
    },
    isValid: {
      "!type": "bool",
      "!doc": "true if date picker value is valid, false if invalid",
    },
    dateFormat: {
      "!type": "string",
      "!doc":
        "The format string of the date in the datepicker in local time zone",
    },
    outputDateLocal: {
      "!type": "string",
      "!doc": "The ISO8601 date string of the selected date in local time zone",
    },
    outputDateUtc: {
      "!type": "string",
      "!doc": "The ISO8601 date string of the selected date in UTC",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  CHECKBOX_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "Checkbox components enable users to select or deselect an option",
    "!url": "",
    isVisible: isVisible,
    isChecked: {
      "!type": "bool",
      "!doc": "true if checked, false if unchecked",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if checkbox is disabled, false otherwise",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  SWITCH_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "Switch components enable users to toggle an option",
    "!url": "",
    isVisible: isVisible,
    label: {
      "!type": "string",
      "!doc": "The string of the label on the switch",
    },
    labelPosition: {
      "!type": "string",
      "!doc": "The position of the label on the switch",
    },
    isToggledOn: {
      "!type": "bool",
      "!doc": "true if toggled on, false if not",
    },
    isRequired: {
      "!type": "bool",
      "!doc": "true if switch is required, false otherwise",
    },
    isDisabled: {
      "!type": "bool",
      "!doc": "true if switch is disabled, false otherwise",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  RADIO_GROUP_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Radio buttons groups lets users choose one option from a predefined set of options",
    "!url": "",
    isVisible: isVisible,
    options: {
      "!type": "[dropdownOption]",
      "!doc": "An Array of the radio button group options",
    },
    selectedOptionValue: {
      "!type": "string",
      "!doc": "The string of the selected radio button option",
    },
    isRequired: "bool",
    ...setPropertyFunction(widget.type, functions),
  }),
  TABS_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc":
      "Tabbed containers are used to allows users to switch between selected tabs of container",
    "!url": "",
    isVisible: isVisible,
    tabs: {
      "!type": "[tabs]",
      "!doc": "An Array of tab name strings",
    },

    selectedTab: {
      "!type": "string",
      "!doc": "The string of the selected tab name",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  MENU_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "A menu widget",
    "!url": "",
    isVisible: isVisible,
    ...setPropertyFunction(widget.type, functions),
  }),
  MODAL_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "Modal components pop up on top of a page",
    "!url": "",
    isVisible: isVisible,
    widthPreset: {
      "!type": "string", // Reference to enum ModalSize
      "!doc": "Modal width when opened",
    },
    heightPreset: {
      "!type": "string", // Reference to enum ModalSize
      "!doc": "Modal height when opened",
    },
    isOpen: {
      "!type": "bool",
      "!doc": "true if modal is open, false if closed",
    },
    ...(functions === AutocompleteFunctionsType.FRONTEND
      ? {
          "open()": {
            "!type": "fn() -> void",
            "!doc": "call to open this modal",
          },
          "close()": {
            "!type": "fn() -> void",
            "!doc": "call to close this modal",
          },
        }
      : {}),
  }),
  SLIDEOUT_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "Slideout components slides out on top of a page",
    "!url": "",
    isVisible: isVisible,
    size: {
      "!type": "string", // Reference to enum SlideoutSize
      "!doc": "Slideout size when opened ",
    },
    isOpen: {
      "!type": "bool",
      "!doc": "true if slideout is open, false if closed",
    },
    ...(functions === AutocompleteFunctionsType.FRONTEND
      ? {
          "open()": {
            "!type": "fn() -> void",
            "!doc": "call to open this slideout",
          },
          "close()": {
            "!type": "fn() -> void",
            "!doc": "call to close this slideout",
          },
        }
      : {}),
  }),
  RICH_TEXT_EDITOR_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "",
    "!url": "",
    isVisible: isVisible,
    isDisabled: "string",
    value: "string",
    isValid: {
      "!type": "bool",
      "!doc": "true if Rich Text Editor value is valid, false if invalid",
    },
    ...setPropertyFunction(widget.type, functions),
  }),
  CHART_WIDGET: {
    //we removed from product for now - 6.13.21
    "!doc": "Chart",
    "!url": "",
    isVisible: isVisible,
    selectedData: "selectedData",
  },
  FORM_WIDGET: (widget: DataTreeWidget & any /*FormWidgetProps*/) => ({
    "!doc":
      "Form is used to capture user input across text input, dropdowns, datepickers and radio buttons",
    "!url": "",
    isVisible: isVisible,
    data: {
      "!type": generateTypeDef(widget.data),
      "!doc": "The JSON data from the form inputs",
    },
    isValid: {
      "!type": "bool",
      "!doc": "true if all fields are valid, false if any field is invalid",
    },
  }),
  FORM_BUTTON_WIDGET: {
    //what is this - delete?
    "!doc":
      "Form button is provided by default to every form. It is used for form submission and resetting form inputs",
    "!url": "",
    isVisible: isVisible,
    text: "string",
    isDisabled: "bool",
  },
  MAP_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    //we removed from product for now - 6.13.21
    isVisible: isVisible,
    mapCenter: "latLong",
    center: "latLong",
    markers: "[mapMarker]",
    selectedMarker: "mapMarker",
    ...setPropertyFunction(widget.type, functions),
  }),
  FILE_PICKER_WIDGET: (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => {
    const fileKey = generateReactKey();
    extraDefs[fileKey] = {
      name: "string",
      extension: "string",
      type: "string",
      size: "number",
      encoding: "string",
      previewUrl: "string",
      ...(functions === AutocompleteFunctionsType.BACKEND
        ? {
            "readContents()": {
              "!doc": "Load the file contents",
              "!type": "fn() -> string",
            },
          }
        : {}),
    };
    return {
      "!doc":
        "Filepicker component is used to allow users to upload files from their local machines to any cloud storage via API. Cloudinary and Amazon S3 have simple APIs for cloud storage uploads",
      "!url": "",
      isVisible: isVisible,
      files: `[${fileKey}]`,
    };
  },
  GRID_WIDGET: (
    widget: DataTreeWidget & any /*GridProps*/,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "Repeatable grid of elements",
    "!url": "",
    selectedCell: generateTypeDef(widget.selectedCellSchema),
    selectedCellIndex: "number",
    cells: generateTypeDef(
      (widget?.cells ?? []).map?.((cell: { [key: string]: DataTreeWidget }) =>
        Object.fromEntries(
          Object.entries(cell).filter(
            ([, value]) => !value.disablePropertyPane,
          ),
        ),
      ),
    ),
    ...setPropertyFunction(widget.type, functions),
  }),
  DIFF_WIDGET: (widget: DataTreeWidget) => ({
    "!doc": `Diff component is used to view the differences between two versions of data`,
    "!url": "",
    diffs: {
      "!doc": `List of change objects, These objects consist of the following fields:
• value: Text content
• added: True if the value was inserted into the new string
• removed: True if the value was removed from the old string`,
      "!type": "[diffChange]",
    },
  }),
  CHAT_WIDGET: (widget: DataTreeWidget & any) => ({
    "!doc": "",
    "!url": "",
    isVisible: isVisible,
    userMessageRichText: {
      "!doc": "A string containing the rich text of the user's message.",
      "!type": "string",
    },
    messageHistory: {
      "!doc": "The list of messages in the chat",
      "!type": generateTypeDef(widget.messageHistory ?? []),
    },
    userMessageText: {
      "!doc": "A string containing the plain text of the user's message.",
      "!type": "string",
    },
    lastMessage: {
      "!doc": "The last message sent or received in the chat history",
      "!type": generateTypeDef(widget.messageHistory?.[0] ?? {}),
    },
    userMessageImages: {
      "!doc": "A list of image URLs attached to the user's message",
      "!type": "[string]",
    },
  }),
  CODE_WIDGET: (
    widget: DataTreeWidget & any /*CodeProps*/,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    "!doc": "",
    "!url": "",
    stringValue: "string",
    parsedValue: generateTypeDef(widget.parsedValue),
    isValid: "bool",
    ...setPropertyFunction(widget.type, functions),
  }),
  IFRAME_WIDGET: (widget: DataTreeWidget) => ({
    "!doc": "",
    "!url": "",
    source: "string",
    srcDoc: "string",
    isVisible: isVisible,
  }),
  CALLOUT_WIDGET: (widget: DataTreeWidget) => ({
    "!doc": "",
    "!url": "",
    isVisible: isVisible,
    isDismissible: {
      "!doc":
        "Controls whether the callout displays an X to allow users to dismiss it",
      "!type": "bool",
    },
    calloutType: {
      "!doc": "The type of callout",
      "!type": "string",
    },
    title: {
      "!doc": "The title of the callout",
      "!type": "string",
    },
    description: {
      "!doc": "The description of the callout",
      "!type": "string",
    },
    ctaText: {
      "!doc":
        "The call to action text displayed on the callout. When clicked, fires the On CTA Click event",
      "!type": "string",
    },
  }),
  KEY_VALUE_WIDGET: (
    widget: DataTreeWidget & any, // KeyValueWidgetProps
  ) => ({
    "!doc": "A key value widget",
    "!url": "",
    isVisible,
    sourceData: {
      "!type": generateTypeDef(widget.sourceData),
      "!doc": "The original data that populates the key value component",
    },
    data: {
      "!type": generateTypeDef(widget.data),
      "!doc":
        "The data structure that includes original data and all computed values",
    },
  }),
};
const GLOBAL_DEFS = {
  dropdownOption: {
    label: "string",
    value: "string",
  },
  tabs: {
    id: "string",
    label: "string",
  },
  chartDataPoint: {
    x: "string",
    y: "string",
  },
  chartData: {
    seriesName: "string",
    data: "[chartDataPoint]",
  },
  latLong: {
    lat: "number",
    long: "number",
  },
  mapMarker: {
    lat: "number",
    long: "number",
    title: "string",
    description: "string",
  },
  diffChange: {
    value: "string",
    added: "bool",
    removed: "bool",
    count: "number",
  },
  Map: convertDef(ecma.Map),
  Set: convertDef(ecma.Set),
};
export const GLOBAL_FUNCTIONS: Record<string, any> = {
  "navigateTo()": {
    "!doc": {
      docString: `Navigate to URL or route in the app. For example: navigateTo("https://google.com", {}, "NEW_WINDOW") or navigateTo("/route", { showFilter: true })`,
    },
    "!type": `fn(urlOrRoute: string, queryParams: {}, target?: string) -> void`,
  },
  "setQueryParams()": {
    "!doc": {
      expanded: {
        markdownDoc:
          'Update the query parameters without refreshing the page or running page load APIs. For example: `setQueryParams({ "search": userInput.value })`',
      },
    },
    "!type": `fn(queryParams: {}, preserveExistingQueryParams?: boolean) -> void`,
  },
  "showAlert()": {
    "!doc": {
      docString: "Show a temporary notification style message to the user",
    },
    "!type":
      "fn(message: string, style: string, durationSeconds?: number, alertPosition?: string) -> void",
  },
  "download()": {
    "!doc": { docString: "Download anything as a file" },
    "!type": "fn(data: any, fileName?: string, fileType?: string) -> void",
  },
  "copyToClipboard()": {
    "!doc": { docString: "Copy text to clipboard" },
    "!type": "fn(data: string, options: object) -> void",
  },
  "resetComponent()": {
    "!doc": { docString: "Reset component to its initial state" },
    "!type": "fn(componentName: string, resetChildren: bool) -> void",
  },
  "logoutIntegrations()": {
    "!doc": {
      docString:
        "Logs out the user from authenticated integrations used in APIs",
    },
    "!type": "fn() -> void",
  },
};
export const entityDefinitionMetadata = {
  TABLE_WIDGET: {
    sortOrder: {
      selectedRow: 1,
      tableData: 2,
      selectedRowIndex: 3,
      selectedRowIndices: 4,
      editedRows: 5,
      allEdits: 6,
      filteredTableData: 7,
      editedRowIndices: 8,
    },
  },
  CHAT_WIDGET: {
    sortOrder: {
      userMessageText: 1,
      messageHistory: 2,
      userMessageRichText: 3,
      userMessageImages: 4,
      lastMessage: 5,
    },
  },
  ACTION: {
    sortOrder: {
      response: 1,
      error: 2,
    },
  },
  TIMER: {},
  CONTAINER_WIDGET: {},
  INPUT_WIDGET: {
    sortOrder: {
      value: 1,
    },
  },
  VIDEO_WIDGET: {
    sortOrder: {
      playState: 1,
      autoPlay: 2,
    },
  },
  DROP_DOWN_WIDGET: {
    sortOrder: {
      selectedOptionValue: 1,
      selectedOptionValues: 2,
      options: 3,
    },
  },
  IMAGE_WIDGET: {
    sortOrder: {
      image: 1,
    },
  },
  ICON_WIDGET: {
    sortOrder: {
      icon: 1,
    },
  },
  TEXT_WIDGET: {
    sortOrder: {
      text: 1,
    },
  },
  BUTTON_WIDGET: {},
  MENU_WIDGET: {},
  DATE_PICKER_WIDGET: {
    sortOrder: {
      selectedDate: 1,
    },
  },
  CHECKBOX_WIDGET: {},
  SWITCH_WIDGET: {},
  RADIO_GROUP_WIDGET: {
    sortOrder: {
      selectedOptionValue: 1,
      options: 3,
    },
  },
  TABS_WIDGET: {
    sortOrder: {
      selectedTab: 1,
      tabs: 2,
    },
  },
  MODAL_WIDGET: {},
  SLIDEOUT_WIDGET: {},
  RICH_TEXT_EDITOR_WIDGET: {},
  CHART_WIDGET: {},
  FORM_WIDGET: {
    sortOrder: {
      data: 1,
    },
  },
  FORM_BUTTON_WIDGET: {},
  MAP_WIDGET: {},
  FILE_PICKER_WIDGET: {},
  GRID_WIDGET: {
    sortOrder: {
      selectedCell: 1,
      cells: 2,
    },
  },
  CODE_WIDGET: {
    sortOrder: {
      parsedValue: 1,
      stringValue: 2,
      isValid: 3,
    },
  },
  KEY_VALUE_WIDGET: {},
  GLOBAL: {
    sortOrder: {
      URL: 1,
    },
  },
  "GLOBAL.URL": {
    sortOrder: {
      routeParams: 1,
      queryParams: 2,
    },
  },
};

let extraDefs: TypeInfo = {};
// Until autocomplete definition loading is made lazy, limit the amount of work we do on a single object
const MAX_KEYS_TO_AUGMENT = 100;

const augmentDefinitionWithEvaluatedValueHelper = (
  entityType: EntityType,
  obj: any,
  definition: any,
  path: string,
): TypeInfo => {
  if (!_.isObject(definition) || _.isArray(definition)) {
    return {
      "!type": definition,
      "!doc": {
        docString: definition["!doc"] ?? "",
        value: _.get(obj, path),
        entityType,
      },
    };
  }
  const augmentedDefinition = {} as TypeInfo;
  let keysAugmented = 0;
  for (const [field, typedef] of Object.entries(definition)) {
    if (field.startsWith("!")) {
      continue;
    }
    if (keysAugmented > MAX_KEYS_TO_AUGMENT) {
      break;
    }
    augmentedDefinition[field] = augmentDefinitionWithEvaluatedValueHelper(
      entityType,
      obj,
      typedef,
      `${path}${
        /^fn\(/.test(typedef["!type"]) ? field : getDottedPathTo(field)
      }`,
    );
    keysAugmented++;
  }

  const doc = _.get(definition, "!doc");
  const ret: TypeInfo = {
    ...augmentedDefinition,
    "!doc": {
      docString:
        "!doc" in definition && typeof doc === "string" ? (doc as string) : "",
      markdownDocString: _.get(doc, "markdownDocString") ?? undefined,
      value: _.get(obj, path),
      entityType,
    },
  };

  const type = _.get(definition, "!type");
  if (_.isString(type)) {
    ret["!type"] = type;
  } else if (type && _.isObject(type) && !_.isArray(type)) {
    // evaluate definitions of dynamic fields (not in definition)
    keysAugmented = 0;
    for (const [field, typedef] of Object.entries(type)) {
      if (field.startsWith("!")) {
        continue;
      }
      if (keysAugmented > MAX_KEYS_TO_AUGMENT) {
        break;
      }
      ret[field] = augmentDefinitionWithEvaluatedValueHelper(
        entityType,
        obj,
        typedef,
        path + "." + field,
      );
      keysAugmented++;
    }
  }
  return ret;
};

const augmentDefinitionAWithEvaluatedValue = (
  entityType: EntityType,
  obj: any,
  definition: any,
): Record<string, unknown> => {
  const ret: Record<string, unknown> = {};
  ret["!doc"] = {
    docString:
      "!doc" in definition
        ? `${_.get(definition, "!doc")}${
            "!type" in definition ? `\n${_.get(definition, "!type")}` : ""
          }`
        : "",
    value: Object.keys(definition).reduce((acc: any, key: string) => {
      if (obj && !key.startsWith("!")) acc[key] = obj[key];
      return acc;
    }, {}),
    topLevel: true,
    entityType,
  };
  for (const [key, value] of Object.entries(definition)) {
    if (key.startsWith("!")) {
      continue;
    }
    ret[key] = augmentDefinitionWithEvaluatedValueHelper(
      entityType,
      obj,
      value,
      key,
    );
  }
  return ret;
};

export const envTypeDefDocString = {
  "!doc": {
    docString: "Environment variables set from SUPERBLOCKS_AGENT_ENV_VARS_JSON",
    entityType: "GLOBAL",
    value: "...",
  },
};

type TypeDefCreatorParams = {
  dataTree: DataTree;
  appScope: ApplicationScope;
  apiScope?: ApiScope;
  configuration?: AutocompleteConfiguration;
  typeDefName?: string;
};

export const dataTreeTypeDefCreator = ({
  dataTree,
  appScope,
  apiScope,
  configuration,
  typeDefName = Origin.DATA_TREE,
}: TypeDefCreatorParams): DataTreeDef => {
  const def: DataTreeDef = {
    "!name": typeDefName,
  };
  const scopes = resolveScopes(appScope);
  scopes.forEach((scope) => {
    generateEntityDefinitions(
      dataTree[scope.scope],
      scope.prefix,
      scope.hidden,
    );
  });

  def["!define"] = {
    ...GLOBAL_DEFS,
    ...extraDefs,
    ...(def["!define"] ?? {}),
  };
  extraDefs = {};
  if (configuration?.functions === AutocompleteFunctionsType.FRONTEND) {
    return { ...def, ...GLOBAL_FUNCTIONS };
  }
  return def;

  function generateEntityDefinitions(
    data: typeof dataTree.APP | typeof dataTree.PAGE,
    prefix?: string,
    hidden?: boolean,
  ) {
    const dataTreeKeys = Object.keys(data);

    if (dataTreeKeys.length === 0) {
      return;
    }

    let localDef = def;
    if (prefix) {
      localDef = _.get(def, prefix, {
        "!doc": {
          hidden,
        },
      }) as DataTreeDef;
      _.set(def, prefix, localDef);
    }

    dataTreeKeys.forEach((entityName) => {
      const entity = data[entityName];
      if (entity && "ENTITY_TYPE" in entity) {
        switch (entity.ENTITY_TYPE) {
          case ENTITY_TYPE.WIDGET: {
            const widgetType = entity.type;
            if (widgetType in entityDefinitions) {
              const definition = _.get(entityDefinitions, widgetType);

              const augmentedDefinition = augmentDefinitionAWithEvaluatedValue(
                widgetType,
                entity,
                _.isFunction(definition)
                  ? definition(entity, configuration?.functions)
                  : definition,
              );

              // TODO(omar): remove this once we ship programmatic table edit
              if (
                widgetType === WidgetTypes.TABLE_WIDGET &&
                !configuration?.isProgrammaticTableEnabled
              ) {
                delete augmentedDefinition["updateRows()"];
                delete augmentedDefinition["deleteRows()"];
                delete augmentedDefinition["insertRows()"];
              }

              localDef[entityName] = augmentedDefinition;
            }
            break;
          }
          case ENTITY_TYPE.ACTION:
            if (
              !apiScope ||
              configuration?.functions !== AutocompleteFunctionsType.BACKEND
            ) {
              localDef[entityName] = augmentDefinitionAWithEvaluatedValue(
                ENTITY_TYPE.ACTION,
                entity,
                entityDefinitions.ACTION(entity, configuration?.functions),
              );
            } else if (apiScope.apiName === entity.name) {
              for (const actionName of apiScope.previousV1ActionNames) {
                const actionOutput = entity.actionOutputs[actionName] ?? {
                  output: null,
                };
                localDef[actionName] = augmentDefinitionAWithEvaluatedValue(
                  ENTITY_TYPE.ACTION,
                  { output: actionOutput.output },
                  entityDefinitions.ACTION_STEP(actionOutput),
                );
              }
              for (const inScopeVar of Object.keys(
                apiScope.v2ComputedScope ?? {},
              )) {
                // inScopeVar could either be a variable or a block name
                const computedScopeInfo =
                  apiScope.v2ComputedScope?.[inScopeVar];
                if (computedScopeInfo) {
                  localDef[inScopeVar] = computedScopeInfo;
                }
              }
            } else if (apiScope.apiName !== entity.name) {
              localDef[entityName] = augmentDefinitionAWithEvaluatedValue(
                ENTITY_TYPE.ACTION,
                entity,
                entityDefinitions.ACTION(entity, false),
              );
            }
            break;
          case ENTITY_TYPE.STATE_VAR:
            localDef[entityName] = augmentDefinitionAWithEvaluatedValue(
              ENTITY_TYPE.STATE_VAR,
              entity,
              entityDefinitions.STATE_VAR(
                entity,
                configuration?.functions,
                data[entityName],
              ),
            );
            break;
          case ENTITY_TYPE.EMBEDDING: {
            let embedPropsDef = {};
            const sanitizedEntity = omit(
              entity,
              "skippedEvaluation",
              "ENTITY_TYPE",
            );
            Object.entries(sanitizedEntity as DataTreeEmbed).forEach(
              ([embedPropName, embedProp]) => {
                if (
                  _.isObject(embedProp) &&
                  (embedProp as DataTreeEmbedProp)?.ENTITY_TYPE !==
                    ENTITY_TYPE.EMBED_PROP
                )
                  return;
                embedPropsDef = {
                  ...embedPropsDef,
                  [embedPropName]: augmentDefinitionAWithEvaluatedValue(
                    ENTITY_TYPE.EMBED_PROP,
                    embedProp,
                    entityDefinitions.EMBED_PROP(
                      embedProp,
                      configuration?.functions,
                    ),
                  ),
                };
              },
            );
            localDef.Embed = {
              "!doc": {
                docString: entityDefinitions.EMBED(entity)["!doc"],
                value: sanitizedEntity,
                entityType: "EMBEDDING",
              },
              ...embedPropsDef,
            };
            break;
          }
          case ENTITY_TYPE.TIMER:
            localDef[entityName] = augmentDefinitionAWithEvaluatedValue(
              ENTITY_TYPE.TIMER,
              entity,
              entityDefinitions.TIMER(entity, configuration?.functions),
            );
            break;
          case ENTITY_TYPE.ICONS:
            if (configuration?.shouldOpenIconSelectorForIconBindings) {
              localDef.icons = {
                "!name": "icons",
                icons: {},
              };
            }
            break;
          case ENTITY_TYPE.THEME: {
            const sanitizedEntity = omit(
              entity,
              "skippedEvaluation",
              "ENTITY_TYPE",
            );
            // theme + Global will be used in the same places
            if (configuration?.global) {
              localDef.theme = {
                // @ts-expect-error: always going to be an obj
                ...generateTypeDef(
                  omit(
                    sanitizedEntity,
                    "bindingPaths" as any,
                    "evaluatedValues" as any,
                    "invalidProps" as any,
                    "validationMessages" as any,
                  ),
                ),
                "!doc": {
                  docString:
                    "The theme holds information about the color palette, font family, and mode.",
                  value: sanitizedEntity,
                  entityType: "THEME",
                },
              };
              localDef.theme.colors = mergeUnlessString(localDef.theme.colors, {
                "!doc": {
                  docString:
                    "An object containing all of the theme-based colors being used by the application.",
                  value: sanitizedEntity.colors,
                },
                "!type": localDef.theme.colors["!type"],
              });
              localDef.theme.mode = mergeUnlessString(localDef.theme.mode, {
                "!doc": {
                  docString:
                    "Set to LIGHT or DARK, depending on what mode the app is in.",
                  value: sanitizedEntity.mode,
                },
                "!type": localDef.theme.mode["!type"],
              });
              localDef.theme.fontFamily = mergeUnlessString(
                localDef.theme.fontFamily,
                {
                  "!doc": {
                    docString:
                      "The string name of the font family being used by the application.",
                    value: sanitizedEntity.fontFamily,
                  },
                  "!type": localDef.theme.fontFamily["!type"],
                },
              );
            }
            break;
          }
          case ENTITY_TYPE.CUSTOM_EVENT: {
            // only add on the frontend
            if (
              configuration?.functions === AutocompleteFunctionsType.FRONTEND
            ) {
              localDef[entityName] = entityDefinitions.CUSTOM_EVENT(
                entity,
                configuration?.functions,
              );
            }
            break;
          }
          case ENTITY_TYPE.GLOBAL: {
            const sanitizedEntity = omit(
              entity,
              "mode",
              "skippedEvaluation",
              "ENTITY_TYPE",
            );
            if (configuration?.global) {
              localDef.Global = {
                // @ts-expect-error: always going to be an obj
                ...generateTypeDef(omitGlobal(sanitizedEntity)),
                "!doc": {
                  docString:
                    "The Global holds metadata for the application like the URL, user information, etc.",
                  value: sanitizedEntity,
                  entityType: "GLOBAL",
                },
              };
              localDef.Global.URL = mergeUnlessString(localDef.Global.URL, {
                "!doc": {
                  docString:
                    "A string representing the URL of the current application page which the end-user is viewing.",
                  value: sanitizedEntity.URL,
                  entityType: "GLOBAL",
                },
                "!type": localDef.Global.URL["!type"],
              });
              localDef.Global.URL.routeParams = mergeUnlessString(
                localDef.Global.URL.routeParams,
                {
                  "!doc": {
                    docString:
                      "Contains any matches for the current route. For example /:userId would contain a userId key if the route was /1234",
                    value: sanitizedEntity.URL.routeParams,
                    entityType: "GLOBAL.URL",
                  },
                  "!type": localDef.Global.URL.routeParams["!type"],
                },
              );
              localDef.Global.URL.queryParams = mergeUnlessString(
                localDef.Global.URL.queryParams,
                {
                  "!doc": {
                    docString:
                      "Contains the current query parameters in the URL. For example, ?userId=1234 would contain a userId key if the query was userId=1234",
                    value: sanitizedEntity.URL.queryParams,
                    entityType: "GLOBAL.URL",
                  },
                  "!type": localDef.Global.URL.queryParams["!type"],
                },
              );
              localDef.Global.user = mergeUnlessString(localDef.Global.user, {
                "!doc": {
                  docString:
                    "An object describing the end-user who is viewing and running the application.",
                  value: sanitizedEntity.user,
                  entityType: "GLOBAL",
                },
                "!type": localDef.Global.user["!type"],
              });
              localDef.Global.user.groups = mergeUnlessString(
                localDef.Global.user.groups,
                {
                  "!doc": {
                    value: sanitizedEntity.global.user.groups,
                    docString:
                      "An array of objects describing user groups the end-user has joined.",
                  },
                  "!type": localDef.Global.user.groups,
                },
              );
              localDef.Global.groups = mergeUnlessString(
                localDef.Global.user.groups,
                {
                  "!doc": {
                    docString:
                      "An array of objects describing all user groups under your organization.",
                    value: sanitizedEntity.global.groups,
                    entityType: "GLOBAL",
                  },
                  "!type": localDef.Global.groups,
                },
              );
              localDef.Global.createdAt = {
                "!doc": {
                  docString:
                    "The application creation time in ISO 8601 string (UTC)",
                  value: sanitizedEntity.createdAt,
                  entityType: "GLOBAL",
                },
                "!type": "string",
              };
              localDef.Global.deployedAt = {
                "!doc": {
                  docString:
                    "The deployment time of the application's latest version in ISO 8601 string (UTC)",
                  value: sanitizedEntity.deployedAt,
                  entityType: "GLOBAL",
                },
                "!type": "string",
              };
              localDef.Global.versions = mergeUnlessString(
                localDef.Global.versions,
                {
                  "!doc": {
                    docString: "An object describing the application versions",
                    value: sanitizedEntity.global.versions,
                    entityType: "GLOBAL",
                  },
                },
              );
              localDef.Global.versions.current = mergeUnlessString(
                localDef.Global.versions.current,
                {
                  "!doc": {
                    docString:
                      "An object describing the current application version",
                    value: sanitizedEntity.global.versions.current,
                  },
                },
              );
              localDef.Global.versions.current.description = mergeUnlessString(
                localDef.Global.versions.current.description,
                {
                  "!doc": {
                    docString: "A string of deployment description",
                    value: sanitizedEntity.global.versions.current.description,
                  },
                },
              );
              localDef.Global.versions.current.tag = mergeUnlessString(
                localDef.Global.versions.current.tag,
                {
                  "!doc": {
                    docString: "A string of version tag",
                    value: sanitizedEntity.global.versions.current.tag,
                  },
                },
              );
              localDef.Global.versions.current.deployedAt = mergeUnlessString(
                localDef.Global.versions.current.deployedAt,
                {
                  "!doc": {
                    docString:
                      "An ISO string describing the application deployment timestamp",
                    value: sanitizedEntity.global.versions.current.deployedAt,
                  },
                },
              );
              localDef.Global.versions.current.deployedBy = mergeUnlessString(
                localDef.Global.versions.current.deployedBy,
                {
                  "!doc": {
                    docString:
                      "An object describing who deployed the application",
                    value: sanitizedEntity.global.versions.current.deployedBy,
                  },
                },
              );
            }

            if (configuration?.env) {
              localDef.Env = envTypeDefDocString;
            }
            if (configuration?.additionalDefs) {
              for (const [key, value] of Object.entries(
                configuration.additionalDefs,
              )) {
                localDef[key] = value;
              }
            }

            break;
          }
        }
      }
    });
  }
};

/**
 * A type def creator that generates definitions for app scope entities with the `App.` prefix, for example `App.AppVar1`
 * while keeping the original definitions for the entities without the prefix for autocomplete purposes, allowing the user
 * to type AppVar without the `App.` prefix.
 */
export const appScopeDataTreeTypeDefCreator = (
  params: Omit<TypeDefCreatorParams, "typeDefName"> & {
    keepOriginalDef?: boolean;
  },
): DataTreeDef => {
  const typeDef = dataTreeTypeDefCreator({
    ...params,
    typeDefName: Origin.APP_DATA_TREE,
  });
  const entityNames = new Set(
    Object.keys(params.dataTree[ApplicationScope.APP]),
  );

  const keepOriginalDefs = params.keepOriginalDef ?? true;
  for (const [entityName, entityDef] of Object.entries(typeDef)) {
    if (entityNames.has(entityName)) {
      typeDef[`App.${entityName}`] = entityDef;
    }

    if (!keepOriginalDefs) {
      delete typeDef[entityName];
    }
  }

  return typeDef;
};

function generateTypeDef(obj: any): TypeDef {
  return recursiveGenerateTypeDef(obj, extraDefs);
}

export function registerCustomComponentAutoCompleteDefinitions(
  widgetType: WidgetType,
  properties: CustomComponentProperty[],
) {
  const readableProperties = properties.filter((property) => {
    return (
      property.isExternallyReadable ||
      typeof property.isExternallyReadable === "undefined"
    );
  });

  (entityDefinitions as any)[widgetType] = (
    widget: DataTreeWidget,
    functions?: AutocompleteFunctionsType | false,
  ) => ({
    isVisible: isVisible,
    ...Object.fromEntries(
      readableProperties.map((property) => [
        property.path,
        {
          "!type": property.dataType,
          "!doc": property.description,
        },
      ]),
    ),
    ...setPropertyFunction(widgetType, functions),
  });
}

/************************************/
/** MAPPING HELPERS
 ** These filter an entity based on the definition
/************************************/

export const accessibleEntityProps = (entity: DataTreeEntity) => {
  if (!("ENTITY_TYPE" in entity)) {
    return;
  }
  return EntityTypeToDefinitionMap[entity.ENTITY_TYPE](entity);
};

const isTypeKeyOfEntityDefinitions = (
  key: string,
): key is keyof typeof entityDefinitions => {
  return key in entityDefinitions;
};

const filterEntityByDefinition = (
  definition: Record<string, unknown> | undefined,
  dataTreeEntity: DataTreeEntity,
) => {
  const ret: Record<string, any> = {};
  if (!definition) {
    return undefined;
  }
  for (const key of Object.keys(definition)) {
    if (key.startsWith("!")) {
      continue;
    }
    ret[key] = get(dataTreeEntity, key);
  }
  return ret;
};

const EntityTypeToDefinitionMap = {
  [ENTITY_TYPE.WIDGET]: (entity_) => {
    const entity = entity_ as DataTreeWidget;
    const type = entity.type;
    const hasDef = isTypeKeyOfEntityDefinitions(type);
    const def = hasDef ? entityDefinitions[type] : undefined;
    return filterEntityByDefinition(
      isFunction(def) ? def(entity) : def,
      entity,
    );
  },
  [ENTITY_TYPE.ACTION]: (entity) =>
    filterEntityByDefinition(entityDefinitions.ACTION(entity as any), entity),
  [ENTITY_TYPE.TIMER]: (entity) =>
    filterEntityByDefinition(entityDefinitions.TIMER(entity as any), entity),
  [ENTITY_TYPE.STATE_VAR]: (entity) =>
    filterEntityByDefinition(
      entityDefinitions.STATE_VAR(entity as any),
      entity,
    ),
  [ENTITY_TYPE.EMBEDDING]: (entity) => {
    const result =
      filterEntityByDefinition(
        entityDefinitions.EMBED(entity as any),
        entity,
      ) ?? {};
    Object.entries(entity).forEach(([subEntityName, subEntity]) => {
      if (subEntity?.ENTITY_TYPE === ENTITY_TYPE.EMBED_PROP) {
        result[subEntityName] = filterEntityByDefinition(
          entityDefinitions.EMBED_PROP(subEntity),
          subEntity,
        );
      }
    });
    return result;
  },
  [ENTITY_TYPE.CUSTOM_EVENT]: (entity) =>
    filterEntityByDefinition(
      entityDefinitions.CUSTOM_EVENT(entity as any),
      entity,
    ),
  [ENTITY_TYPE.EMBED_PROP]: (entity) => {
    return undefined;
  },
  [ENTITY_TYPE.GLOBAL]: (entity) => omitGlobal(entity),
  [ENTITY_TYPE.ICONS]: () => undefined,
  // there is an overlap between theme and global props
  [ENTITY_TYPE.THEME]: (entity) => omitGlobal(entity),
  [ENTITY_TYPE.NESTED_ITEM]: () => undefined,
  [ENTITY_TYPE.API]: () => undefined,
} satisfies Record<ENTITY_TYPE, (e: DataTreeEntity) => object | undefined>;
