import { Dimension } from "@superblocksteam/shared";
import {
  CanvasDefaults,
  GridDefaults,
  MIN_MAX_BUFFER_PX,
} from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { DynamicWidgetsLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import { clampMinMax, isDynamicSize } from "legacy/widgets/base/sizing";
import { clampMinMaxWidth } from "legacy/widgets/base/sizing/canvasWidthUtil";

// Unset means the property is trying to get set, but because it's -1, there is
// no initial value to set it to - so we must compute it based on the current state
// In our property configs on components that have these constraints, we use -1 to indicate
// that the value is unset and must be computed
function isUnsetDimensionValue(value: unknown): boolean {
  const dim = value as Dimension | undefined;
  return dim !== undefined && (dim as any)?.value === -1;
}

function isSetDimensionValue(value: unknown): boolean {
  const dim = value as Dimension | undefined;
  return dim !== undefined && (dim as any)?.value !== -1;
}

export const handleDimensionConstraintUpdate = (params: {
  updates: Record<string, unknown>;
  dynamicWidgetLayout: DynamicWidgetsLayoutState;
  widget: FlattenedWidgetProps;
  widgets: Record<string, FlattenedWidgetProps>;
  parentColumnSpace?: number;
}) => {
  const { updates, dynamicWidgetLayout, widget, widgets } = params;
  const widgetId = widget.widgetId;

  // Check for null values in the updates, which indicates we should clear
  // the property value
  // --------------------------------------
  if (updates.minHeight === null) {
    widgets[widgetId].minHeight = undefined;
  }

  if (updates.maxHeight === null) {
    widgets[widgetId].maxHeight = undefined;
  }

  if (updates.minWidth === null) {
    widgets[widgetId].minWidth = undefined;
  }

  if (updates.maxWidth === null) {
    widgets[widgetId].maxWidth = undefined;
  }

  const currentHeight =
    widget.height.mode === "fillParent"
      ? (dynamicWidgetLayout[widgetId]?.height ?? widget.height)
      : widget.height;

  // Set the minHeight
  // --------------------------------------

  // we check if there is an update for one constraint but not the other, as having an update for both
  // at the same time (like when we update via an AI Accept) is handled in this function below
  if (isUnsetDimensionValue(updates.minHeight)) {
    const maxHeightPx = widget.maxHeight
      ? Dimension.toPx(widget.maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
      : undefined;
    const heightPx = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    let newMinHeight = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    if (maxHeightPx && maxHeightPx.value < heightPx.value) {
      newMinHeight = Dimension.px(
        Math.max(
          heightPx.value - MIN_MAX_BUFFER_PX,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ),
      );
    }

    widgets[widgetId].minHeight = newMinHeight as Dimension<"px">;
  } else if (updates.minHeight && (updates.minHeight as any)?.value !== -1) {
    widgets[widgetId].minHeight = updates.minHeight as Dimension<"px">;
  }

  // Set the maxHeight
  // --------------------------------------
  if (isUnsetDimensionValue(updates.maxHeight)) {
    const minHeightPx = widget.minHeight
      ? (Dimension.toPx(
          widget.minHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ) as Dimension<"px">)
      : Dimension.px(0);

    const heightPx = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ) as Dimension<"px">;

    const newMaxHeightPx =
      Math.max(minHeightPx.value, heightPx.value) + MIN_MAX_BUFFER_PX;
    const newMaxHeight = Dimension.px(newMaxHeightPx);

    widgets[widgetId].maxHeight = newMaxHeight as Dimension<"px">;
  } else if (updates.maxHeight && (updates.maxHeight as any)?.value !== -1) {
    widgets[widgetId].maxHeight = updates.maxHeight as Dimension<"px">;
  }

  // Set the minWidth
  // --------------------------------------

  const currentWidth = isDynamicSize(widget.width.mode)
    ? (dynamicWidgetLayout[widgetId]?.width ?? widget.width)
    : widget.width;
  const parentColumnSpace =
    params.parentColumnSpace ?? GridDefaults.DEFAULT_GRID_COLUMN_WIDTH;

  if (isUnsetDimensionValue(updates.minWidth)) {
    const maxWidthPx = widget.maxWidth ? widget.maxWidth.value : undefined;
    const widthPx = Dimension.toPx(currentWidth, parentColumnSpace);
    let newMinWidth = Dimension.toPx(currentWidth, parentColumnSpace);
    if (maxWidthPx != null && maxWidthPx === widthPx.value) {
      newMinWidth = Dimension.px(
        Math.max(
          widthPx.value - MIN_MAX_BUFFER_PX,
          CanvasDefaults.MIN_GRID_UNIT_WIDTH,
        ),
      );
    }

    widgets[widgetId].minWidth = newMinWidth;
  } else if (
    updates.minWidth &&
    (updates.minWidth as any)?.value !== -1 &&
    widgets[widgetId].maxWidth == null
  ) {
    widgets[widgetId].minWidth = updates.minWidth as Dimension<"px">;
  }

  // Set the maxWidth
  // --------------------------------------

  if (isUnsetDimensionValue(updates.maxWidth)) {
    const minWidthPx = widget.minWidth ? widget.minWidth.value : 0;
    const widthPx = Dimension.toPx(currentWidth, parentColumnSpace);
    const newMaxWidthPx = Math.max(minWidthPx, widthPx.value); // don't add buffer because we can't render the label if its too big
    const newMaxWidth = Dimension.px(newMaxWidthPx);

    widgets[widgetId].maxWidth = newMaxWidth;
  } else if (
    updates.maxWidth &&
    (updates.maxWidth as any)?.value !== -1 &&
    widgets[widgetId].minWidth == null
  ) {
    widgets[widgetId].maxWidth = updates.maxWidth as Dimension<"px">;
  }

  // make sure to make min/max respect each other when they are set
  // --------------------------------------
  if (
    updates.minHeight &&
    (widgets[widgetId].maxHeight != null || updates.maxHeight)
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      ...clampMinMax({
        widget: {
          ...widgets[widgetId],
          ...(updates.maxHeight ? { maxHeight: updates.maxHeight } : {}),
        } as any,
        constraintType: "minHeight",
        newHeight: widgets[widgetId].minHeight as Dimension<"gridUnit" | "px">,
      }),
    };
  } else if (
    updates.minHeight &&
    (updates.minHeight as any)?.value !== -1 &&
    widgets[widgetId].maxHeight == null
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      minHeight: updates.minHeight as Dimension<"px">,
    };
  }

  if (
    updates.maxHeight &&
    (widgets[widgetId].minHeight != null || updates.minHeight)
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      ...clampMinMax({
        widget: {
          ...widgets[widgetId],
          ...(updates.minHeight ? { minHeight: updates.minHeight } : {}),
        } as any,
        constraintType: "maxHeight",
        newHeight: widgets[widgetId].maxHeight as Dimension<"gridUnit" | "px">,
      }),
    };
  } else if (
    updates.maxHeight &&
    (updates.maxHeight as any)?.value !== -1 &&
    widgets[widgetId].minHeight == null
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      maxHeight: updates.maxHeight as Dimension<"px">,
    };
  }

  if (
    isSetDimensionValue(updates.minWidth) &&
    widgets[widgetId].maxWidth != null
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      ...clampMinMaxWidth({
        widget: widgets[widgetId],
        constraintType: "minWidth",
        newWidth: widgets[widgetId].minWidth as Dimension<"px">,
        parentLayout: widgets[widget.parentId]?.layout,
      }),
      minWidth: updates.minWidth as Dimension<"px">,
    };
  }

  if (
    isSetDimensionValue(updates.maxWidth) &&
    widgets[widgetId].minWidth != null
  ) {
    widgets[widgetId] = {
      ...(widgets[widgetId] ?? {}),
      ...clampMinMaxWidth({
        widget: widgets[widgetId],
        constraintType: "maxWidth",
        newWidth: widgets[widgetId].maxWidth as Dimension<"px">,
        parentLayout: widgets[widget.parentId]?.layout,
      }),
      maxWidth: updates.maxWidth as Dimension<"px">,
    };
  }
};
