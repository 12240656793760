// Sanitize the dependency graph to only include tasks that are in the tasks list
export const sanitizeDependencyGraph = (
  tasks: string[],
  dependencyGraph_: Record<string, string[]>,
): Record<string, string[]> => {
  return Object.fromEntries(
    Object.entries(dependencyGraph_)
      .filter(([api]) => tasks.includes(api))
      .map(([api, dependsOn]) => [
        api,
        dependsOn.filter((dep) => tasks.includes(dep)),
      ]),
  );
};

export const calculateLoadBatches = (
  tasks: string[],
  dependencyGraph_: Record<string, string[]>,
): string[][] => {
  const dependencyGraph = sanitizeDependencyGraph(tasks, dependencyGraph_);

  const completedTasks = new Set<string>();
  const batches: string[][] = [];
  const firstApis = tasks
    .filter((api) => (dependencyGraph[api] ?? []).length === 0)
    .sort();
  batches.push(firstApis);
  firstApis.forEach((api) => completedTasks.add(api));

  while (completedTasks.size < tasks.length) {
    const readyTasks: string[] = [];
    Object.entries(dependencyGraph).forEach(([apiName, dependsOn]) => {
      if (!completedTasks.has(apiName)) {
        const canRun = Array.from(dependsOn).every((depName) =>
          completedTasks.has(depName),
        );
        if (canRun) {
          readyTasks.push(apiName);
        }
      }
    });
    if (readyTasks.length) {
      batches.push(readyTasks.sort());
      readyTasks.forEach((api) => completedTasks.add(api));
    } else {
      break;
    }
  }

  return batches;
};
