import { useCallback } from "react";

import { EditorModes } from "components/app/CodeEditor/EditorConfig";

import AiBaseFlow from "./AiAssistantBaseFlow";

import { AiAssistantOptionType, FlowType } from "./constants";
import { generateRequestBodyForTask, getTitleForOption } from "./util";

const AiMockFlow = (props: {
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string,
  ) => void;
  onClose: () => void;
  option: AiAssistantOptionType;
  expectedDataFormat?: string;
}) => {
  const { onConfirm, onClose, option, expectedDataFormat } = props;

  const getRequestBody = useCallback(
    (userInput: string) => {
      return generateRequestBodyForTask({
        userInput,
        syntax: option.syntax,
        shape: expectedDataFormat ?? "unknown",
        flowType: FlowType.MOCK,
      });
    },
    [expectedDataFormat, option.syntax],
  );

  return (
    <AiBaseFlow
      onConfirm={onConfirm}
      onClose={onClose}
      option={option}
      getRequestBody={getRequestBody}
      editorMode={EditorModes.TEXT_WITH_BINDING}
      title={getTitleForOption(option)}
      placeholder={"i.e. generate 10 users"}
      responseViewType="code"
    />
  );
};

export default AiMockFlow;
