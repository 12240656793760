import { Button, Modal, Typography } from "antd";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { FullWidthSpace } from "components/ui/Space";
import {
  PAGE_VIEWER_URL,
  SCHEDULED_JOB_URL,
  WORKFLOW_URL,
} from "legacy/constants/routes";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { useAppSelector } from "store/helpers";
import agentSlice from "store/slices/agents/slice";
import {
  selectControlFlowContextAware,
  selectControlFlowEnabledDynamic,
} from "store/slices/apisShared/selectors";
import { selectCurrentProfileForApi } from "store/slices/apisShared/selectors/selectCurrentProfileForApi";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";

/*
 *  Todo COMPLETELY remove this file once controller is no longer used.
 */
export const ApiProfileReloader = () => {
  const location = useLocation();
  const controlFlowEnabledDynamic = useAppSelector((state) => {
    return selectControlFlowEnabledDynamic(state, location.pathname);
  });

  const controlFlowEnabledActual = useAppSelector((state) => {
    return selectControlFlowContextAware(state, location.pathname);
  });

  const org = useSelector(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(org);
  const agents = useSelector(agentSlice.selector);
  const profileInfo = useAppSelector((state) =>
    selectCurrentProfileForApi(state, location.pathname),
  );
  const [agentStateIsLoaded, setAgentStateLoaded] = useState(false);
  const controlFlowFlagAtApiLoadTime = useRef<undefined | boolean>();

  const [isOpen, setIsOpen] = useState(false);
  const [modalDismissed, setModalDismissed] = useState(false);

  const { workflowMatch, jobMatch, appMatch } = useMemo(() => {
    const pathname = location.pathname;
    const workflowMatch = matchPath(
      { path: WORKFLOW_URL, end: false },
      pathname,
    );
    const jobMatch = matchPath(
      { path: SCHEDULED_JOB_URL, end: false },
      pathname,
    );
    const appMatch = matchPath({ path: PAGE_VIEWER_URL, end: false }, pathname);
    return {
      workflowMatch,
      jobMatch,
      appMatch,
    };
  }, [location.pathname]);

  const locationMatches = useRef({
    workflowMatch,
    jobMatch,
    appMatch,
  });

  const onReload = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKey.CONTROL_FLOW_PROFILE_BASED_OVERRIDE,
      JSON.stringify(controlFlowEnabledActual),
    );
    if (profileInfo?.inAppProfileSwitch) {
      sessionStorage.setItem(
        SessionStorageKey.CONTROL_FLOW_PROFILE_ID_OVERRIDE,
        JSON.stringify(profileInfo?.profileId),
      );
    }

    window.location.reload();
  }, [controlFlowEnabledActual, profileInfo]);

  useEffect(() => {
    const apisLoaded = Object.keys(agents.entities).length > 0;
    if (apisLoaded) {
      if (controlFlowFlagAtApiLoadTime.current === undefined) {
        controlFlowFlagAtApiLoadTime.current = controlFlowEnabledDynamic;
      }
    }

    if (agentStateIsLoaded) {
      if (
        controlFlowFlagAtApiLoadTime.current !== controlFlowEnabledActual &&
        !modalDismissed
      ) {
        // open the modal if we are still on the same page since the user might have unsaved changes
        const isOnSameWorkflow =
          workflowMatch?.params?.apiId != null &&
          workflowMatch?.params?.apiId ===
            locationMatches.current.workflowMatch?.params?.apiId;
        const isOnSameJob =
          jobMatch?.params?.apiId != null &&
          jobMatch?.params?.apiId ===
            locationMatches.current.jobMatch?.params?.apiId;
        const isOnSameApp =
          appMatch?.params?.applicationId != null &&
          appMatch?.params?.applicationId ===
            locationMatches.current.appMatch?.params?.applicationId;
        if (isOnSameWorkflow || isOnSameJob || isOnSameApp) {
          setIsOpen(true);
        } else {
          // otherwise just reload
          onReload();
        }
      }
    }

    locationMatches.current = {
      workflowMatch,
      jobMatch,
      appMatch,
    };

    // don't cause reload unless there is at least 1 agent since it doesn't matter otherwise
    const useAgentState =
      apisLoaded && isOnPremise && profileInfo.appOrApiProfileLoaded;
    if (agentStateIsLoaded === false && useAgentState) {
      setAgentStateLoaded(true);
    }
  }, [
    controlFlowEnabledDynamic,
    isOnPremise,
    agents.entities,
    agentStateIsLoaded,
    profileInfo,
    controlFlowEnabledActual,
    modalDismissed,
    workflowMatch,
    jobMatch,
    appMatch,
    onReload,
  ]);

  const modalContent = useMemo(() => {
    const baseContent = (
      <>
        <p>
          {`Click "Reload Agent" to reload the page with the correct agent selected. This will only affect your browser session.`}
        </p>
      </>
    );
    if (workflowMatch || jobMatch) {
      return (
        <>
          <p>{`The profile for your ${
            workflowMatch ? "workflow" : "job"
          } uses a different agent version than the one currently selected`}</p>
          {baseContent}
        </>
      );
    }
    if (appMatch) {
      return (
        <>
          <p>{`The profile for your application uses a different agent version than the one currently selected.`}</p>
          {baseContent}
        </>
      );
    }
    return (
      <>
        <p>{`The default profile for your organization is different than the one currently in use.`}</p>
        {baseContent}
      </>
    );
  }, [workflowMatch, jobMatch, appMatch]);

  return (
    <Modal
      title={
        <Typography.Text>
          Reload Required: Agent change detected
        </Typography.Text>
      }
      centered
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        setModalDismissed(true);
      }}
      cancelText="Cancel"
      destroyOnClose
      width={520}
      bodyStyle={{
        padding: 26,
      }}
      footer={[
        <Button key="submit" type="primary" onClick={onReload}>
          Reload Agent
        </Button>,
      ]}
    >
      <FullWidthSpace direction="vertical">
        <Typography.Text>{modalContent}</Typography.Text>
      </FullWidthSpace>
    </Modal>
  );
};
