import { getNextEntityName } from "@superblocksteam/shared";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { generateReactKey } from "legacy/utils/generators";

const MIN_TAB_HEIGHT = 36;

export function computeTabHeaderHeightPx(
  lineHeightPx: number | undefined,
  shouldShowTabs: boolean | undefined,
) {
  if (shouldShowTabs === false) {
    return 0;
  }

  const tabHeightGU =
    Math.max((lineHeightPx ?? 0) + 20, MIN_TAB_HEIGHT) /
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT;

  return Math.round(tabHeightGU) * GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
}

export function generateNewTab(
  existingTabs: Array<{
    id: string;
    label: string;
    widgetId: string;
  }>,
) {
  return {
    id: generateReactKey({ prefix: "tab" }),
    label: getNextEntityName(
      "Tab ",
      existingTabs.map((tab: any) => tab.label),
      " ",
    ),
    widgetId: generateReactKey(),
  };
}
