import {
  Agent,
  DatasourceMetadataDto,
  DatasourceMetadataV2Dto,
  Organization,
  extendsRestApiIntegrationPlugin,
  Table,
  GoogleSheetsActionConfiguration,
} from "@superblocksteam/shared";
import { isArray, isEmpty } from "lodash";
import { call, select } from "redux-saga/effects";
import { selectCachedControlFlowById } from "store/slices/apisV2/control-flow/control-flow-selectors";
import { getBlockFromApi } from "store/slices/apisV2/control-flow/getBlockFromApi";
import { StepBlock } from "store/slices/apisV2/control-flow/types";
import { processIntegrationConfig } from "store/slices/apisV2/sagas/execute-block-utils";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { selectFlags } from "store/slices/featureFlags/selectors";
import { fastClone } from "../../../../utils/clone";
import logger from "../../../../utils/logger";
import { callSagas, createSaga } from "../../../utils/saga";
import { getAgentsSaga, selectActiveAgents } from "../../agents";
import * as BackendTypes from "../../apisV2/backend-types";
import { selectOnlyOrganization } from "../../organizations";
import { orgIsOnPremise } from "../../organizations/utils";
import { getMetadata, getMetadataV2Stateless } from "../client";
import { selectDatasourceMetaById } from "../selectors";
import slice from "../slice";
import { metadataSelectorKey } from "../utils";

interface FetchMetadataPayload {
  version?: "v1" | "v2";
  datasourceId: string;
  environment: string;
  apiId?: string;
  actionId?: string;
  searchKeyword?: string; // gsheet: search spreadsheet with given keyword
  getSheets?: boolean; // gsheet: get sheets for a given spreadsheet
}

const convertV2ToV1Metadata = (
  v2Metadata: DatasourceMetadataV2Dto,
): DatasourceMetadataDto => {
  return {
    dbSchema: {
      ...v2Metadata.databaseSchemaMetadata,
      tables: v2Metadata.databaseSchemaMetadata?.tables || [],
      schemas: v2Metadata.databaseSchemaMetadata?.schemas,
    },
    buckets: v2Metadata.bucketsMetadata?.buckets,
    couchbase: v2Metadata.couchbase,
    kafka: v2Metadata.kafka,
    kinesis: v2Metadata.kinesis,
    cosmosdb: v2Metadata.cosmosdb,
    adls: v2Metadata.adls,
    gSheetsNextPageToken: v2Metadata.gSheetsNextPageToken,
  };
};

// set request payload used by gsheet plugin metadata
export const processGSheetIntegrationConfig = (
  block: BackendTypes.Block,
  searchKeyword?: string,
  getSheets?: boolean,
  gSheetsNextPageToken?: string | null | undefined,
) => {
  const processedBlock = fastClone(block);
  if (processedBlock.step?.gsheets) {
    // if search keyword is passed in, don't send next page token
    if (searchKeyword) {
      (processedBlock.step.gsheets as GoogleSheetsActionConfiguration).keyword =
        searchKeyword;
    }
    // do not pass next page token when an option is just selected
    // in this case we are only looking for sheets
    if (!getSheets && gSheetsNextPageToken) {
      (
        processedBlock.step.gsheets as GoogleSheetsActionConfiguration
      ).pageToken = gSheetsNextPageToken;
    }
  }
  return processedBlock;
};

function* getMetadataInternal({
  version = "v1",
  datasourceId,
  environment,
  apiId,
  actionId,
  searchKeyword,
  getSheets,
}: FetchMetadataPayload) {
  const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
  const disabledIntegrations =
    flags[Flag.DISABLE_LOAD_METADATA_FOR_INTEGRATIONS];

  if (
    isArray(disabledIntegrations) &&
    disabledIntegrations.includes(datasourceId)
  ) {
    return {
      loadDisabled: true,
    };
  }
  const organization: Organization = yield select(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(organization);
  const agents: Agent[] = yield select(
    selectActiveAgents(organization.agentType, environment),
  );

  try {
    let result: DatasourceMetadataDto | undefined;

    if (version === "v1") {
      result = yield call(
        getMetadata,
        agents,
        organization,
        datasourceId,
        environment,
        apiId,
        actionId,
      );
    } else {
      const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
        yield select((state) => selectCachedControlFlowById(state, apiId));
      const block =
        controlFlow != null && actionId != null
          ? getBlockFromApi(controlFlow, actionId)
          : undefined;

      const datasourceMeta: ReturnType<typeof selectDatasourceMetaById> =
        yield select((state) => selectDatasourceMetaById(state, datasourceId));

      const processedBlock =
        block && block.step?.gsheets
          ? processGSheetIntegrationConfig(
              block,
              searchKeyword,
              getSheets,
              datasourceMeta?.metadata?.gSheetsNextPageToken,
            )
          : block && controlFlow
            ? processIntegrationConfig(
                block,
                (controlFlow.blocks[block.name] as StepBlock)?.config?.pluginId,
              )
            : undefined;

      let stepConfig = processedBlock?.step;
      if (!stepConfig) {
        stepConfig = {
          stepConfiguration: {
            integrationId: datasourceId,
          },
        };
      }

      result = yield call(
        getMetadataV2Stateless,
        agents,
        organization,
        datasourceId,
        environment,
        stepConfig,
      );
    }

    if (!result) {
      return {};
    }

    // TODO: We need a better to handle this, but right now the components
    // check that the "systemError" property is undefined and that
    // metadata exists. This maintains that pattern for now.
    // If the result is empty (no metadata for this datasource)
    if ((result as Record<string, string>).systemError) {
      logger.error(
        `Error while fetching metadata: ${
          (result as Record<string, string>).systemError
        }`,
      );
      return result;
    }

    // Remap the metadata to the old format for backwards compatibility
    // with the UI components until we remove v1
    if (!isEmpty(result) && version === "v2") {
      return convertV2ToV1Metadata(result);
    }

    return result;
  } catch (err) {
    if (isOnPremise || version !== "v2") {
      yield callSagas([getAgentsSaga.apply({ organization })]);
    }
    logger.error(`Error while fetching metadata: ${err}`);
  }
}

export const getMetadataSaga = createSaga(
  getMetadataInternal,
  "getMetadataSaga",
  {
    sliceName: "datasources",
  },
);

slice.saga(getMetadataSaga, {
  start(state, { payload }) {
    state.loading[metadataSelectorKey(payload.datasourceId)] = true;
    delete state.errors[payload.datasourceId];
  },
  success(state, { payload, meta }) {
    // Do not reload metadata if the integration is or extends the REST API Integration plugin, as
    // it will cause the openApiSpec to be cleared.
    if (
      !extendsRestApiIntegrationPlugin(
        state.entities[meta.args.datasourceId]?.integration?.pluginId,
      )
    )
      if (
        state.meta &&
        state.meta[meta.args.datasourceId]?.metadata?.gSheetsNextPageToken
      ) {
        // This if-clause s currently gsheet specific, since gSheetsNextPageToken
        // is only returned by gsheet plugin. gSheet will conditionally return sheets of a spreadsheets if spreadsheetId is passed
        // in actionConfiguration. GSheets is re-using dbSchema structs and spreadsheets are mapped to tables
        // sheets are mapped to columns Old agent/plugin will not return gSheetsNextPageToken, so payload will override existing state
        // in else-clause
        const existingTables =
          state.meta[meta.args.datasourceId].metadata?.dbSchema?.tables;
        let newTables: Table[] | undefined;

        // selected spreadsheet and it's sheets will be returned as first element
        // it could be in state already, this is trying to update it if so.
        const tableWithColumns = payload?.dbSchema?.tables[0];
        if (tableWithColumns && tableWithColumns.columns) {
          // find and update existing entry's columns filed
          const found = existingTables?.find(
            (table: Table) => table.id === tableWithColumns.id,
          );
          if (found) {
            found.columns = tableWithColumns.columns;
          }

          // add new tables exclude index 0
          newTables = payload?.dbSchema?.tables.slice(1);
        } else {
          // add new tables returned
          newTables = payload?.dbSchema?.tables;
        }

        state.meta[meta.args.datasourceId] = {
          metadata: {
            dbSchema: {
              tables: [...(existingTables ?? []), ...(newTables ?? [])],
            },
            gSheetsNextPageToken: payload?.gSheetsNextPageToken,
          },
        };
      } else {
        // override metadata when there is no pagination
        state.meta[meta.args.datasourceId] = { metadata: payload };
      }
    delete state.loading[metadataSelectorKey(meta.args.datasourceId)];
  },
  // only used by gsheet metadata pagination, this will clear metadata.dbSchema tables when user is searching
  cancel(state, { payload }) {
    delete state.meta[payload.datasourceId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.datasourceId] = { error: payload };
    delete state.loading[metadataSelectorKey(meta.args.datasourceId)];
  },
  store(state, { payload }) {
    if (payload?.integrationId)
      state.meta[payload?.integrationId] = { metadata: payload };
  },
});
