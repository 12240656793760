import equal from "@superblocksteam/fast-deep-equal/es6";
import {
  ApplicationScope,
  Dimension,
  NotificationPosition,
} from "@superblocksteam/shared";
import { set, isEmpty, omit, get, reject, memoize, merge } from "lodash";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { deleteApiInfo, updateApiInfo } from "legacy/actions/apiActions";
import {
  DeleteWidgetPropertyPayload,
  SetWidgetDynamicPropertyPayload,
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
  setSingleWidget,
  UpdateCanvasHeightPayload,
  updateWidgetAutoHeight,
  deleteWidgetProperty,
} from "legacy/actions/controlActions";
import {
  resetChildrenMetaProperty,
  resetWidgetMetaProperty,
} from "legacy/actions/metaActions";
import {
  updateLayout,
  updatePartialLayout,
  WidgetAddChild,
  WidgetAddChildIfNotExists,
  WidgetDelete,
  WidgetMove,
  WidgetResize,
  WidgetAddSectionColumn,
  WidgetUpdateChildren,
  WidgetAddSection,
  WidgetResizeSection,
  undoAction,
  Size,
  GridPosition,
  WidgetReparent,
} from "legacy/actions/pageActions";
import {
  showModal,
  showWidgetPropertyPane,
  focusWidget,
  selectWidgets,
  addSectionWidget,
  resizeSectionWidget,
  addSectionWidgetColumn,
  resizeSectionWidgetColumns,
  deleteWidgets,
  pasteWidget,
  cutWidget,
  deleteEntityFromWidgets,
  copyWidget,
  setSelectedWidgetsAncestory,
  groupSelectedWidgets,
  pasteWidgetConfirmation,
  pasteWidgetConfirmationAccepted,
  pasteWidgetConfirmationCancelled,
} from "legacy/actions/widgetActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { ApisMap } from "legacy/constants/ApiConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  GridDefaults,
  PAGE_WIDGET_ID,
  WidgetTypes,
  SLIDEOUT_DEFAULT_COLUMNS,
  SLIDEOUT_DEFAULT_ROWS,
  SectionDefaults,
  CanvasDefaults,
  Breakpoints,
  WidgetHeightModes,
  CanvasLayout,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { getDataEntities } from "legacy/entities/DataTree/DataTreeHelpers";
import {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import {
  ItemKinds,
  PropertyPaneItem,
} from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { extractPartsFromNestedItemId } from "legacy/pages/Editor/PropertyPane/NestedItemsUtils";
import { type FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  getDataTree,
  getDataTreeWidgetsById,
} from "legacy/selectors/dataTreeSelectors";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import {
  getMainContainerWidgetId,
  getFlattenedCanvasWidgets,
  getFlattenedCanvasWidget,
} from "legacy/selectors/editorSelectors";
import { getWidget, getWidgets } from "legacy/selectors/entitiesSelector";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import {
  getIsPropertyPaneVisible,
  getOpenPropertyPanelItem,
} from "legacy/selectors/propertyPaneSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import {
  DynamicWidgetsVisibilityState,
  getDynamicVisibilityWidgets,
} from "legacy/selectors/visibilitySelectors";
import { getOpenModalOrSlideout } from "legacy/selectors/widgetSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import {
  getWidgetDynamicPropertyPathList,
  mergeUpdatesWithBindingsOrTriggers,
  deleteWithBindingsOrTriggers,
  getDynamicBindingPathListWithExistingReferences,
} from "legacy/utils/DynamicBindingUtils";
import {
  moveWidgetFixedLayout,
  moveWidgetStackLayout,
  getSortedWidgetOrder,
  shouldHaveRootAsParent,
  getPositionOffsetDuringGroup,
} from "legacy/utils/MoveWidgetUtils";
import {
  getPasteParentDetails,
  shrinkWidgetsInHstack,
  pasteWidgetRoot,
  applyRefactoredNamesToCopiedWidgets,
  getWidgetsToDeleteAndReplaceFromPasteAction,
} from "legacy/utils/PasteSagaUtils";
import { getRelativeStackedWidgetPositions } from "legacy/utils/StackWidgetUtils";
import { getCopiedWidgets, saveCopiedWidgets } from "legacy/utils/StorageUtils";
import { convertToString } from "legacy/utils/Utils";
import { getSectionColsForParentType } from "legacy/utils/WidgetPropsUtils";
import {
  deleteReferencesFromWidgetTriggers,
  getWidgetChildrenIds,
  deleteReferencesFromApiInfoTriggers,
  getWidgetChildren,
  getSectionGridRowsForParentType,
} from "legacy/utils/WidgetPropsUtils";
import { generateReactKey } from "legacy/utils/generators";
import { getLastPastedSingleWidgetId } from "legacy/utils/lastPastedSingleWidgetId";
import { WidgetFactory } from "legacy/widgets";
import { resetFilePickers } from "legacy/widgets/FilepickerWidget/FilePickerSingleton";
import { SectionWidgetProps } from "legacy/widgets/SectionWidget/SectionWidget";
import { isStackLayout } from "legacy/widgets/StackLayout/utils";
import { clampMinMax, isFixedHeight } from "legacy/widgets/base/sizing";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";
import { isWidget } from "legacy/workers/evaluationUtils";
import { createFakeDataTree } from "store/helpers/refactoring";
import { refactorNameInWidgets } from "store/sagas/renameApplicationEntity";
import { selectAiDataTreeChangesById } from "store/slices/ai/selectors";
import {
  deleteV1ApiSaga,
  selectV1ApiById,
  ApiV1,
} from "store/slices/apisShared";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { deleteV2ApiSaga, selectV2ApiById } from "store/slices/apisV2";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { getApplicationSettings } from "store/slices/application/selectors";
import { deleteEntityFromTimers } from "store/slices/application/timers/timerActions";
import { Flag, selectFlagById, selectFlags } from "store/slices/featureFlags";
import { copyUiBlock } from "store/slices/uiBlocks/actions";
import { queued, takeLatestByKey } from "store/utils/effects";
import { GeneratorReturnType } from "store/utils/types";
import UITracing from "tracing/UITracing";
import { fastClone } from "utils/clone";
import { UIEvent } from "utils/event";
import { getAllowedChildTypesInWidgetAncestry } from "utils/getAllowedChildTypesInWidgetAncestry";
import log from "utils/logger";
import { sendSuccessUINotification } from "utils/notification";
import {
  createCopyDataFromUIBlock,
  renameSourceWidgetsWithNamespace,
} from "utils/paste";
import { getInternalHeightGridUnits } from "utils/size";

import {
  getApiAppInfo,
  getSelectedWidget,
  getSelectedWidgetsIds,
  getWidgetsMeta,
  getIsDraggingForSelection,
  getSelectedWidgets,
  getSectionsOfParent,
  getV2ApiAppInfo,
  getAllEntityNames,
} from "../selectors/sagaSelectors";
import { clearEvalPropertyCacheOfWidget } from "./EvaluationsSaga";
import {
  updateSectionWidgetCanvasHeights,
  updateWidgetWidths,
  evenlyDivideReductionForColumns,
  getAllWidgetsInTree,
  getEntityNames,
  resizeCanvasOnChildDelete,
  buildWidgetIdsExpandList,
  resizeSectionColumnsAfterColumnDelete,
  logNewEventHandler,
  logNewBinding,
  roundDownToSectionColumnMultiple,
  getCreateConfig,
  handleGridWidgetAutoHeight,
  fixWrongSectionWidgetWidth,
  updateWidgetAfterWidthModeChange,
  updateWidgetAfterHeightModeChange,
  getIsStaticResize,
  addChildIfNotExistsToWidgets,
  updateWidgetMetaUpdates,
  generateChildWidgetsUtil,
  GeneratedWidgetPayload,
} from "./WidgetOperationsSagasUtils";
import { reflowWidgets } from "./WidgetResizeSagas";
import {
  getAddChildPayload,
  validateWidgetGrouping,
} from "./widgets/groupWidgets";
import { handleDimensionConstraintUpdate } from "./widgets/updateProperties";
import type { WidgetBlueprint } from "legacy/mockResponses/WidgetConfigResponse";
import type {
  WidgetProps,
  PartialWidgetProps,
  CopiedWidgets,
  WidgetMap,
} from "legacy/widgets";
import type {
  CanvasWidgetsReduxState,
  WidgetActionResponse,
} from "legacy/widgets/Factory";

function widgetProxy(stateWidgets: CanvasWidgetsReduxState) {
  const changes: CanvasWidgetsReduxState = {};
  const handler: ProxyHandler<CanvasWidgetsReduxState> = {
    set(obj, prop, value, r) {
      changes[prop as any] = value;
      obj[prop as any] = value;
      return true;
    },
  };
  const widgets = new Proxy(Object.assign({}, stateWidgets), handler);
  return { widgets, changes };
}

// Widget hooks are called on four actions:
// - creating a widget,
// - deleting a widget,
// - updating a widget property,
// - setting the evaluated tree.
export function* callWidgetHooks(
  widgets: CanvasWidgetsReduxState,
  action: ReduxAction<
    WidgetAddChild | WidgetDelete | UpdateWidgetPropertiesPayload | DataTree
  >,
  originalWidgetValues: PartialWidgetProps = {},
): Generator<
  any,
  {
    changes: CanvasWidgetsReduxState;
    widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"];
  },
  any
> {
  const classes = WidgetFactory.getWidgetClasses();
  const flags = yield select(selectFlags);
  const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
  const theme = yield select(selectGeneratedTheme);
  const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
    yield select(getDynamicLayoutWidgets);
  const widgetsRuntime: CanvasWidgetsReduxState = yield select(
    getFlattenedCanvasWidgets,
  );
  const widgetMetaProps: WidgetMetadata = yield select(getWidgetsMeta);
  const evaluatedWidgets: Record<string, DataTreeWidget> = yield select(
    getDataTreeWidgetsById,
  );
  const previousAiWidgets = yield select(selectAiDataTreeChangesById);

  if (action.type === ReduxActionTypes.TREE_WILL_UPDATE) {
    const changes: CanvasWidgetsReduxState = {};
    let allWidgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];

    const payload = action.payload as DataTree;
    yield all(
      getDataEntities(payload).map(function* ({ entity }) {
        const evaluatedWidget = entity;

        if (
          !isWidget(evaluatedWidget) ||
          evaluatedWidget?.skippedEvaluation === true
        ) {
          return;
        }

        const widgetId = evaluatedWidget.widgetId;

        const fn = classes[evaluatedWidget.type]?.applyActionHook;
        if (fn) {
          try {
            // TREE_WILL_UPDATE is not able to modify the state immediately
            // Most widgets will apply 0 or 1 updates, but complex widgets
            // like the grid can update parent/child relationships
            const {
              widgetUpdates: updates,
              widgetMetaUpdates,
            }: WidgetActionResponse = yield call(fn, {
              widgetId,
              widgets,
              widgetsRuntime,
              action,
              originalWidgetValues,
              flags,
              appMode,
              theme,
              dynamicWidgetLayout,
              widgetMetaProps,
              evaluatedWidgets,
              previousAiWidgets,
            });

            if (updates && !isEmpty(updates)) {
              updates.forEach(({ widgetId, widget }) => {
                changes[widgetId] = widget;
              });
            }
            if (widgetMetaUpdates && !isEmpty(widgetMetaUpdates)) {
              allWidgetMetaUpdates = [
                ...allWidgetMetaUpdates,
                ...widgetMetaUpdates,
              ];
            }
          } catch (e) {
            console.error(e);
            yield put({
              type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
              payload: {
                action: action.type,
                error: e,
              },
            });
          }
        }
      }),
    );

    return {
      changes,
      widgetMetaUpdates: allWidgetMetaUpdates,
    };
  }

  const changes: CanvasWidgetsReduxState = {};
  let allWidgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];

  yield all(
    Object.entries(widgets).map(function* ([widgetId, widget]) {
      const fn = classes[widget.type]?.applyActionHook;
      if (fn) {
        try {
          // Most widgets will apply 0 or 1 updates, but complex widgets
          // like the grid can update parent/child relationships
          const {
            widgetUpdates: updates,
            widgetMetaUpdates,
          }: WidgetActionResponse = yield call(fn, {
            widgetId,
            widgets,
            widgetsRuntime,
            action,
            originalWidgetValues,
            flags,
            appMode,
            theme,
            dynamicWidgetLayout,
            widgetMetaProps,
            evaluatedWidgets,
            previousAiWidgets,
          });
          if (updates && !isEmpty(updates)) {
            updates.forEach(({ widgetId, widget }) => {
              changes[widgetId] = widget;
            });
          }
          if (widgetMetaUpdates && !isEmpty(widgetMetaUpdates)) {
            allWidgetMetaUpdates = [
              ...allWidgetMetaUpdates,
              ...widgetMetaUpdates,
            ];
          }
        } catch (e) {
          yield put({
            type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
            payload: {
              action: action.type,
              error: e,
            },
          });
        }
      }
    }),
  );
  return {
    changes,
    widgetMetaUpdates: allWidgetMetaUpdates,
  };
}
function* generateChildWidgets(
  parent: FlattenedWidgetProps,
  params: WidgetAddChild,
  widgets: CanvasWidgetsReduxState,
  propsBlueprint?: WidgetBlueprint,
): Generator<any, GeneratedWidgetPayload, any> {
  const flags = yield select(selectFlags);
  const theme = yield select(selectGeneratedTheme);
  const entityNames: GeneratorReturnType<typeof getEntityNames> =
    yield call(getEntityNames);
  const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
  const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
    yield select(getDynamicLayoutWidgets);
  const widgetsRuntime: CanvasWidgetsReduxState = yield select(
    getFlattenedCanvasWidgets,
  );
  const widgetMetaProps: WidgetMetadata = yield select(getWidgetsMeta);
  const evaluatedWidgets: Record<string, DataTreeWidget> = yield select(
    getDataTreeWidgetsById,
  );
  const previousAiWidgets = yield select(selectAiDataTreeChangesById);

  const {
    widgetId,
    widgets: updatedWidgets,
    widgetMetaUpdates,
  } = generateChildWidgetsUtil({
    parent,
    params,
    widgets,
    propsBlueprint,
    flags,
    theme,
    entityNames,
    appMode,
    dynamicWidgetLayout,
    widgetMetaProps,
    evaluatedWidgets,
    previousAiWidgets,
    widgetsRuntime,
  });

  return {
    widgetId,
    widgets: updatedWidgets,
    widgetMetaUpdates,
  };
}

function* batchCrudWidgetOperationsSaga(
  action: ReduxAction<{
    actions: Array<
      | ReduxAction<WidgetAddChild>
      | ReturnType<typeof updateWidgetProperties>
      | ReturnType<typeof deleteWidgets>
    >;
  }>,
): Generator<any, any, any> {
  const { actions } = action.payload;

  for (const childAction of actions) {
    // Call the appropriate saga based on the action type
    if (childAction.type === ReduxActionTypes.WIDGET_CREATE) {
      yield* createSaga(childAction as ReduxAction<WidgetAddChild>);
    } else if (childAction.type === updateWidgetProperties.type) {
      yield* updateWidgetPropertiesSaga(
        childAction as ReduxAction<UpdateWidgetPropertiesPayload>,
      );
    } else if (childAction.type === deleteWidgets.type) {
      yield* deleteSaga(childAction as ReturnType<typeof deleteWidgets>);
    } else {
      // Log unknown action type
      console.warn(
        "Unsupported action type in batchCrudWidgetOperationsSaga:",
        childAction.type,
      );
    }
  }
}

function* createSaga(
  addChildAction: ReduxAction<WidgetAddChild>,
): Generator<any, any, any> {
  try {
    const start = performance.now();
    Toaster.clear();
    // Parent widgetId
    const {
      widgetId: _parentWidgetId,
      newChildIndex,
      stackAdjustments,
    } = addChildAction.payload;
    const parentWidgetId = shouldHaveRootAsParent(
      addChildAction.payload.type as WidgetTypes,
    )
      ? PAGE_WIDGET_ID
      : _parentWidgetId;
    const appMode: APP_MODE = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
    const theme = yield select(selectGeneratedTheme);

    // Get the current parent widget whose child will be the new widget.
    const stateParent: FlattenedWidgetProps = yield select(
      getWidget,
      parentWidgetId,
    );
    const stateGrandParent = yield select(getWidget, stateParent.parentId);
    const grandParent: FlattenedWidgetProps = {
      ...stateGrandParent,
    };

    // const parent = Object.assign({}, stateParent);
    // Get all the widgets from the canvasWidgetsReducer
    const stateWidgets = yield select(getWidgets);
    let widgets: CanvasWidgetsReduxState = Object.assign({}, stateWidgets);
    // Generate the full WidgetProps of the widget to be added.
    const childWidgetPayload: GeneratedWidgetPayload =
      yield generateChildWidgets(stateParent, addChildAction.payload, widgets);

    // Update widgets to put back in the canvasWidgetsReducer
    // TODO(abhinav): This won't work if dont already have an empty children: []

    const newChildren = [...(stateParent.children || [])];
    if (newChildIndex != null) {
      // then this is a stack
      newChildren.splice(newChildIndex, 0, childWidgetPayload.widgetId);
      // perform stack adjustment ops
      if (stackAdjustments) {
        for (const childId of newChildren) {
          const child = widgets[childId];
          if (
            child &&
            child.width.mode === "gridUnit" &&
            childWidgetPayload.widgetId !== childId && // we don't need to updateWidgetWidths for the created widget
            stackAdjustments[childId]
          ) {
            const widthDiff =
              stackAdjustments[childId].newWidth.value - child.width.value;

            const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
              getFlattenedCanvasWidgets,
            );

            updateWidgetWidths({
              widgets,
              flattenedWidgets,
              widget: child,
              widthDiffGU: widthDiff,
            });
          }
        }
      }
    } else {
      newChildren.push(childWidgetPayload.widgetId);
    }

    const parent = {
      ...stateParent,
      children: newChildren,
    };

    widgets[parent.widgetId] = parent;
    const latencyMs = performance.now() - start;
    log.debug(`add child computations took ${latencyMs}ms`);
    log.event(UIEvent.CREATED_COMPONENT, {
      widgetType: addChildAction.payload.type,
      latencyMs: latencyMs,
    });

    // some widgets need to update property of parent if the parent have CHILD_OPERATIONS
    // so here we are traversing up the tree till we get to PAGE_WIDGET_ID
    // while traversing, if we find any widget which has CHILD_OPERATION, we will call the fn in it
    // createSaga
    let updatedWidgets: CanvasWidgetsReduxState = {};
    let widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];

    if (addChildAction.payload.skipCreateHooks !== true) {
      const actionHooksResult: GeneratorReturnType<typeof callWidgetHooks> =
        yield call(callWidgetHooks, widgets, addChildAction);

      updatedWidgets = actionHooksResult.changes;
      widgetMetaUpdates = actionHooksResult.widgetMetaUpdates;
    }

    if (addChildAction.payload.type === WidgetTypes.SLIDEOUT_WIDGET) {
      widgets[addChildAction.payload.newWidgetId] = {
        ...widgets[addChildAction.payload.newWidgetId],
        gridColumns: SLIDEOUT_DEFAULT_COLUMNS.value,
        width: SLIDEOUT_DEFAULT_COLUMNS,
        height: SLIDEOUT_DEFAULT_ROWS,
        left: Dimension.gridUnit(0),
        top: Dimension.gridUnit(0),
      };
    }

    if (addChildAction.payload.type === WidgetTypes.MODAL_WIDGET) {
      widgets[addChildAction.payload.newWidgetId] = {
        ...widgets[addChildAction.payload.newWidgetId],
        gridColumns: GridDefaults.DEFAULT_GRID_COLUMNS,
        width: Dimension.gridUnit(GridDefaults.DEFAULT_GRID_COLUMNS),
        // Height dimensions are determined by the child sections of the modal
        height: Dimension.gridUnit(0),
        left: Dimension.gridUnit(0),
        top: Dimension.gridUnit(0),
      };
    }

    widgets = { ...widgets, ...(updatedWidgets ?? {}) };

    if (
      parent.type === WidgetTypes.CANVAS_WIDGET &&
      grandParent.type === WidgetTypes.SECTION_WIDGET
    ) {
      widgets = updateSectionWidgetCanvasHeights(
        widgets,
        theme,
        appMode,
        grandParent,
      );
    }

    // update all meta props with action hooks updates if any
    yield call(updateWidgetMetaUpdates, widgetMetaUpdates);

    yield put(updateLayout(widgets, addChildAction.payload.skipSave));

    if (
      addChildAction.payload.type === WidgetTypes.SLIDEOUT_WIDGET ||
      addChildAction.payload.type === WidgetTypes.MODAL_WIDGET
    ) {
      // TODO(pbardea): Refactor into new widget hooks being added.
      yield put(showModal(addChildAction.payload.newWidgetId));
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.WIDGET_CREATE,
        error,
      },
    });
  }
}

function* addSectionColumnSaga(
  addSectionColumnAction: ReduxAction<WidgetAddSectionColumn>,
): Generator<any, any, any> {
  const { sectionWidgetId, columnProperties } = addSectionColumnAction.payload;
  const stateWidgets: Readonly<CanvasWidgetsReduxState> =
    yield select(getWidgets);
  const { widgets, changes } = widgetProxy(stateWidgets);

  fixWrongSectionWidgetWidth(widgets, widgets[sectionWidgetId]);

  const sectionWidget: WidgetProps = widgets[sectionWidgetId];
  const sectionParentWidget: WidgetProps = widgets[sectionWidget.parentId];

  const sectionWidgetChildren = sectionWidget.children || [];
  const sectionWidgetChildWidgets =
    widgets[sectionWidget.widgetId].children?.map(
      (childId: string) => widgets[childId],
    ) || [];

  const sectionColumns = getSectionColsForParentType(sectionParentWidget.type);

  // If all columns are min width, we can't add any new columns
  // so just return
  if (
    sectionWidgetChildren.every(
      (childId: string) =>
        widgets[childId].gridColumns ===
        SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
    )
  ) {
    Toaster.show({
      text: `Section has max columns. Delete a column to add more.`,
      variant: Variant.info,
    });
    return;
  }

  const childWidgetPayload: GeneratedWidgetPayload = yield generateChildWidgets(
    sectionWidget,
    {
      widgetId: sectionWidgetId,
      newWidgetId: generateReactKey(),
      type: WidgetTypes.CANVAS_WIDGET,
      size: {
        width: Dimension.gridUnit(0),
        height: sectionWidgetChildWidgets[0]?.height,
      },
      position: {
        left: Dimension.gridUnit(0),
        top: Dimension.gridUnit(0),
      },
      props: {
        ...columnProperties,
        spacing: CanvasDefaults.SPACING,
        canExtend: true,
        shouldScrollContents: true,
      },
    },
    widgets,
  );

  const newColumnWidget =
    childWidgetPayload.widgets[childWidgetPayload.widgetId];
  widgets[childWidgetPayload.widgetId] = newColumnWidget;
  widgets[sectionWidget.widgetId] = {
    ...sectionWidget,
    children: [...sectionWidgetChildren, childWidgetPayload.widgetId],
  };

  // What we want to do is have the new column take up an equal amount of space if the existing
  // columns are evenly divided among the sections total snapColumns. If they aren't, it means some
  // columns have been resized so the new column should take up some minimum value and then
  // the other columns are resized proportionally as best we can
  const expectedGridColumnsPerCanvas =
    (sectionWidget.gridColumns || 0) / sectionWidgetChildren.length;
  const isEvenlyDivided = sectionWidgetChildWidgets.every(
    (childCanvas: FlattenedWidgetProps) =>
      childCanvas.gridColumns === expectedGridColumnsPerCanvas,
  );

  let resizeDownBy: number[];
  let childWidgetsToResize: FlattenedWidgetProps[] = [];
  if (isEvenlyDivided) {
    const numColumns = sectionWidgetChildren.length + 1;
    const newGridColumnsPerCanvas = roundDownToSectionColumnMultiple(
      (sectionColumns / numColumns) * SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
    );

    widgets[newColumnWidget.widgetId].gridColumns = newGridColumnsPerCanvas;
    widgets[newColumnWidget.widgetId].width = Dimension.gridUnit(
      newGridColumnsPerCanvas,
    );

    try {
      resizeDownBy = evenlyDivideReductionForColumns(
        newGridColumnsPerCanvas,
        sectionWidgetChildWidgets.map(
          (child: FlattenedWidgetProps) => child.gridColumns || 0,
        ),
        SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
      );
    } catch (e) {
      Toaster.show({
        text: `Section has max columns. Delete a column to add more.`,
        variant: Variant.info,
      });
      return;
    }
    childWidgetsToResize = sectionWidgetChildWidgets;
  } else {
    // Not evenly divided so make the new column the the min column width
    // and then resize down the other columns proportionally
    // unless a column is already at min width, then don't resize it
    let newColumnWidth = 4 * SectionDefaults.MIN_COLUMN_GRID_COLUMNS;

    if (
      sectionWidgetChildWidgets.filter(
        (child: FlattenedWidgetProps) =>
          child.gridColumns === SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
      ).length >=
      sectionWidgetChildWidgets.length - 1
    ) {
      // Check if we must reduce size of the new column to a single section column otherwise
      // it wont fit
      newColumnWidth = SectionDefaults.MIN_COLUMN_GRID_COLUMNS;
    } else if (sectionWidgetChildWidgets.length >= 4) {
      // If we have a lot of columns, reduce the size of the new column a bit so
      // it doesn't force such a big size reduction in the other columns automatically
      newColumnWidth = 4 * SectionDefaults.MIN_COLUMN_GRID_COLUMNS;
    }

    widgets[newColumnWidget.widgetId].gridColumns = newColumnWidth;
    widgets[newColumnWidget.widgetId].width =
      Dimension.gridUnit(newColumnWidth);

    const sectionWidgetChildrenWithoutMinWidth =
      sectionWidgetChildWidgets.filter(
        (childCanvas: FlattenedWidgetProps) =>
          childCanvas.gridColumns !== SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
      );
    childWidgetsToResize = sectionWidgetChildrenWithoutMinWidth;

    resizeDownBy = evenlyDivideReductionForColumns(
      newColumnWidth,
      childWidgetsToResize.map(
        (child: FlattenedWidgetProps) => child.gridColumns || 0,
      ),
      SectionDefaults.MIN_COLUMN_GRID_COLUMNS,
    );
  }

  for (const [index, canvasWidget] of childWidgetsToResize.entries()) {
    const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
      getFlattenedCanvasWidgets,
    );

    updateWidgetWidths({
      widgets,
      flattenedWidgets,
      widget: canvasWidget,
      widthDiffGU: resizeDownBy[index] * -1,
    });
  }
  yield put(updatePartialLayout(changes));
}

function* updateChildrenSaga(
  reorderChildrenAction: ReduxAction<WidgetUpdateChildren>,
): Generator<any, any, any> {
  const { newChildren, widgetId } = reorderChildrenAction.payload;
  const stateWidgets = yield select(getWidgets);

  const updatedWidgets = Object.assign({}, stateWidgets);
  const parentWidget: WidgetProps = updatedWidgets[widgetId];

  updatedWidgets[widgetId] = {
    ...parentWidget,
    children: newChildren,
  };

  yield put(updateLayout(updatedWidgets));
}

function* resizeSectionColumnsSaga(
  resizeSectionColumnsAction: ReturnType<typeof resizeSectionWidgetColumns>,
): Generator<any, any, any> {
  const { sectionWidgetId, newWidths, overrideWidgets } =
    resizeSectionColumnsAction.payload;
  const stateWidgets = overrideWidgets
    ? overrideWidgets
    : yield select(getWidgets);

  const updatedWidgets: CanvasWidgetsReduxState = Object.assign(
    {},
    stateWidgets,
  );
  const sectionWidget: WidgetProps = updatedWidgets[sectionWidgetId];
  const sectionParentWidget: WidgetProps =
    updatedWidgets[sectionWidget.parentId];
  const sectionColumns = getSectionColsForParentType(sectionParentWidget.type);

  const gridColumnsPerSectionColumn =
    (sectionWidget.gridColumns || 0) / sectionColumns;

  if (!sectionWidget?.children) {
    throw new Error("Section widget does not have any children");
  }
  if (newWidths.length !== sectionWidget.children.length) {
    throw new Error("New widths length does not match number of columns");
  }

  const theme: ReturnType<typeof selectGeneratedTheme> =
    yield select(selectGeneratedTheme);

  const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
    getFlattenedCanvasWidgets,
  );

  // Now let's update each column canvases snapCols
  // plus the children size and position
  newWidths.forEach((newColumns, index) => {
    const currentCanvas = updatedWidgets[sectionWidget.children?.[index]];
    const changeInColumns =
      newColumns * gridColumnsPerSectionColumn -
      (currentCanvas.gridColumns ?? 0);

    if (changeInColumns !== 0) {
      const currentFlattenedCanvas = flattenedWidgets[currentCanvas.widgetId];
      const flattenedSection = flattenedWidgets[sectionWidget.widgetId];
      const isStaticResize = getIsStaticResize({
        flattenedChild: currentFlattenedCanvas,
        flattenedParent: flattenedSection,
        theme,
      });

      updateWidgetWidths({
        widgets: updatedWidgets,
        flattenedWidgets,
        widget: updatedWidgets[currentCanvas.widgetId],
        widthDiffGU: changeInColumns,
        deepCallOptions: {
          staticResizeParentId: isStaticResize
            ? currentCanvas.widgetId
            : undefined,
        },
      });
    }
  });

  yield put(updatePartialLayout(updatedWidgets));
}

function* addSectionSaga(
  addSectionAction: ReduxAction<WidgetAddSection>,
): Generator<any, any, any> {
  const { sectionWidgetId, placement } = addSectionAction.payload;
  const stateWidgets = yield select(getWidgets);
  const parentWidget: WidgetProps =
    stateWidgets[stateWidgets[sectionWidgetId].parentId];

  const previousWidgetIndex =
    placement === "above"
      ? parentWidget.children?.indexOf(sectionWidgetId)
      : (parentWidget.children?.indexOf(sectionWidgetId) || 0) + 1;

  const newWidgetId = generateReactKey();
  const initialHeight = Dimension.fitContent(
    getSectionGridRowsForParentType(parentWidget.type),
  );

  let stackColumnsAt: Dimension<"px"> | undefined;
  let backgroundColor: string | undefined;
  switch (parentWidget.type) {
    case WidgetTypes.MODAL_WIDGET:
    case WidgetTypes.SLIDEOUT_WIDGET:
      stackColumnsAt = Breakpoints.NEVER;
      backgroundColor = "transparent";
      break;
    default:
      stackColumnsAt = Breakpoints.MOBILE;
      break;
  }

  yield put({
    type: ReduxActionTypes.WIDGET_CREATE,
    payload: {
      widgetId: parentWidget.widgetId,
      type: WidgetTypes.SECTION_WIDGET,
      newChildIndex: previousWidgetIndex,
      newWidgetId,
      size: {
        height: initialHeight,
        width: Dimension.gridUnit(parentWidget.gridColumns || 0),
      },
      position: {
        top: Dimension.gridUnit(0),
        left: Dimension.gridUnit(0),
      },
      props: {
        stackColumnsAt,
        backgroundColor,
      } satisfies Partial<SectionWidgetProps>,
    },
  } satisfies ReduxAction<WidgetAddChild>);

  // Now select it
  yield put(selectWidgets([newWidgetId]));
}

function* resizeSectionSaga(
  resizeSectionAction: ReduxAction<WidgetResizeSection>,
): Generator<any, any, any> {
  const { sectionWidgetId, constraintType, newHeight } =
    resizeSectionAction.payload;
  const stateWidgets: Readonly<CanvasWidgetsReduxState> =
    yield select(getWidgets);
  const { widgets, changes } = widgetProxy(stateWidgets);

  const theme = yield select(selectGeneratedTheme);
  const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;

  widgets[sectionWidgetId] = {
    ...widgets[sectionWidgetId],
    [constraintType]: newHeight,
  };

  widgets[sectionWidgetId] = clampMinMax({
    constraintType,
    newHeight,
    widget: widgets[sectionWidgetId],
  });

  updateSectionWidgetCanvasHeights(
    widgets,
    theme,
    appMode,
    widgets[sectionWidgetId],
  );

  yield put(updatePartialLayout(changes));
}

// It does not go through the blueprint based creation
// It simply uses the provided widget props to create widgets that don't already exist
// It does not update any existing widgets
// Use this only when we're 100% sure of all the props the children will need
function* addChildIfNotExists(
  addChildrenAction: ReduxAction<WidgetAddChildIfNotExists>,
): Generator<any, any, any> {
  try {
    const { widgetId, children } = addChildrenAction.payload;
    const stateWidgets: Readonly<CanvasWidgetsReduxState> =
      yield select(getWidgets);
    const widgetNames = Object.values(stateWidgets).map(
      (widget) => widget.widgetName,
    );
    const entityNames: GeneratorReturnType<typeof getEntityNames> =
      yield call(getEntityNames);

    const defaultChildConfigs: GeneratorReturnType<typeof getCreateConfig>[] =
      [];
    for (const child of children) {
      const defaultConfig: GeneratorReturnType<typeof getCreateConfig> =
        yield call(getCreateConfig, child.type);

      defaultChildConfigs.push(defaultConfig);
    }

    // Use our new pure function to get the modified widgets
    const modifiedWidgets = addChildIfNotExistsToWidgets({
      widgetId,
      children,
      defaultChildConfigs,
      widgets: stateWidgets,
      widgetNames,
      entityNames,
    });

    // Only dispatch if there were actual modifications
    if (Object.keys(modifiedWidgets).length > 0) {
      yield put(updatePartialLayout(modifiedWidgets));
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.WIDGET_ADD_CHILD_IF_NOT_EXISTS,
        error,
      },
    });
  }
}

function* groupWidgetsSaga(
  action: ReturnType<typeof groupSelectedWidgets>,
): Generator<any, any, any> {
  const selectedWidgetIds: string[] = yield select(getSelectedWidgetsIds);
  const flattenedWidgets = yield select(getFlattenedCanvasWidgets);

  const { canGroup, reason, parentWidgetId } = validateWidgetGrouping({
    selectedWidgetIds,
    flattenedWidgets,
  });

  if (!canGroup || !parentWidgetId) {
    Toaster.show({
      text: reason ?? "Cannot group these widgets",
      variant: Variant.info,
      duration: 1000,
    });
    return;
  }

  const theme = yield select(selectGeneratedTheme);
  const addChildPayload = getAddChildPayload({
    selectedWidgetIds,
    flattenedWidgets,
    parentWidgetId,
    theme,
  });

  if (!addChildPayload) {
    Toaster.show({
      text: "Cannot group these widgets",
      variant: Variant.info,
      duration: 1000,
    });
    return;
  }

  yield call(createSaga, {
    type: ReduxActionTypes.WIDGET_CREATE,
    payload: addChildPayload,
  });

  // now, move the selected widgets into the newly created container

  const createdContainer: ReturnType<typeof getWidget> = yield select(
    getWidget,
    addChildPayload.newWidgetId,
  );
  const createdCanvasId = createdContainer.children?.[0];
  const createdCanvas: ReturnType<typeof getWidget> = yield select(
    getWidget,
    createdCanvasId,
  );
  if (!createdCanvasId || !createdCanvas) {
    throw new Error("Could not find created canvas");
  }

  const widgetId = selectedWidgetIds[0];
  const offset = getPositionOffsetDuringGroup({
    flattenedWidgets,
    layout: createdContainer.layout,
    selectedWidgetIds,
  });

  const movePayload: WidgetMove = {
    widgetId,
    position: {
      top: Dimension.gridUnit(0),
      left: Dimension.gridUnit(0),
    },
    offset,
    newChildIndex: isStackLayout(createdCanvas.layout) ? 0 : undefined,
    parentId: parentWidgetId,
    newParentId: createdCanvasId,
  };

  yield call(moveSaga, {
    type: ReduxActionTypes.WIDGETS_MOVE,
    payload: movePayload,
  });

  yield put(selectWidgets([createdContainer.widgetId]));

  yield call(reflowWidgets, [createdContainer.widgetId]);
}

function* deleteSaga(
  deleteAction: ReturnType<typeof deleteWidgets>,
): Generator<any, any, any> {
  try {
    const controlFlowEnabled: boolean = yield select(
      selectControlFlowEnabledDynamic,
    );
    const { widgetIds, isShortcut } = deleteAction.payload;
    const haveDifferentParents = false;

    const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
    const theme = yield select(selectGeneratedTheme);
    const stateWidgets: ReturnType<typeof getWidgets> =
      yield select(getWidgets);
    let widgets: CanvasWidgetsReduxState = { ...stateWidgets };

    let selectedWidgets: FlattenedWidgetProps[] = [];
    if (widgetIds) {
      for (let i = 0; i < widgetIds.length; i++) {
        selectedWidgets.push(yield select(getWidget, widgetIds[i]));
      }
    } else {
      selectedWidgets = yield select(getSelectedWidgets);
    }

    selectedWidgets = selectedWidgets?.filter(
      (widget: WidgetProps) => widget.isDeletable !== false,
    );

    const removeWidgetFromSelectedWidgets = (
      widgetIdToRemove: string,
      selectedWidgetsToFilter: FlattenedWidgetProps[],
    ) => {
      return selectedWidgetsToFilter.filter(
        (widget: WidgetProps) => widget.widgetId !== widgetIdToRemove,
      );
    };

    const disallowDeletingLastSection = (widgetId: string) => {
      selectedWidgets = removeWidgetFromSelectedWidgets(
        widgetId,
        selectedWidgets,
      );
      Toaster.show({
        text: "Cannot delete the last section",
        variant: Variant.info,
      });
    };

    for (const selectedWidget of selectedWidgets) {
      const parent = widgets[selectedWidget.parentId];

      // Do not allow deleting the final section of a page, modal, or slideout
      if (selectedWidget.type === WidgetTypes.SECTION_WIDGET) {
        const parentSectionWidgets: WidgetProps[] = yield select(
          getSectionsOfParent,
          selectedWidget.widgetId,
        );
        // Check the parent, ensure it's not the last section of that parent
        if ((parentSectionWidgets || []).length === 1) {
          disallowDeletingLastSection(selectedWidget.widgetId);
        }
      }

      // Check the parent, to see if it's the last column of that parent section
      // and delete the section if so
      if (
        selectedWidget.type === WidgetTypes.CANVAS_WIDGET &&
        parent.type === WidgetTypes.SECTION_WIDGET &&
        parent.children?.length === 1
      ) {
        const parentSectionWidgets: WidgetProps[] = yield select(
          getSectionsOfParent,
          selectedWidget.parentId,
        );

        if ((parentSectionWidgets || []).length === 1) {
          disallowDeletingLastSection(selectedWidget.widgetId);
        } else {
          selectedWidgets.push(parent);
        }

        // and remove the column otherwise it shows that we deleted 2
        // components which feels weird
        selectedWidgets = removeWidgetFromSelectedWidgets(
          selectedWidget.widgetId,
          selectedWidgets,
        );
      }

      // Do not allow deleting the final tab of a tabs component
      if (
        selectedWidget.type === WidgetTypes.CANVAS_WIDGET &&
        parent.type === WidgetTypes.TABS_WIDGET
      ) {
        // Check the parent, to see if it's the last tab of that parent
        if ((parent.children || []).length === 1) {
          // filter out the tabs widget from the selectedWidgets
          selectedWidgets = removeWidgetFromSelectedWidgets(
            selectedWidget.widgetId,
            selectedWidgets,
          );
          Toaster.show({
            text: "Cannot delete the last tab of a tabs component",
            variant: Variant.info,
          });
        }
      }
    }

    if (!selectedWidgets || selectedWidgets.length === 0) return;

    const widgetsIdsToDelete: string[] = selectedWidgets.map(
      (widget: WidgetProps) => widget.widgetId,
    );

    const mainContainerWidgetId = yield select(getMainContainerWidgetId);

    let apis;
    if (controlFlowEnabled) {
      apis = yield select(getV2ApiAppInfo);
    } else {
      apis = yield select(getApiAppInfo);
    }
    const stateApis: ApisMap = fastClone(apis);
    let hasApiUpdates = false;

    for (let i = 0; i < widgetsIdsToDelete.length; i++) {
      const widgetId = selectedWidgets[i].widgetId;
      const parentId = selectedWidgets[i].parentId;
      // Apply deletion hook before removing from the tree
      const {
        changes: updatedWidgets,
        widgetMetaUpdates,
      }: GeneratorReturnType<typeof callWidgetHooks> = yield call(
        callWidgetHooks,
        stateWidgets,
        {
          ...deleteAction,
          // since the widgetId is not always set in the action, insert it
          payload: {
            ...deleteAction.payload,
            widgetIds: [widgetsIdsToDelete[i]],
          },
        },
      );

      widgets = { ...widgets, ...updatedWidgets };

      // apply widget meta updates
      yield call(updateWidgetMetaUpdates, widgetMetaUpdates);

      let parent = widgets[parentId];
      const widget = { ...widgets[widgetId] };
      // only focus parent once for the last deleted widget
      if (i === widgetsIdsToDelete.length - 1) {
        if (parentId === mainContainerWidgetId) {
          // unselect all widgets
          yield put(showWidgetPropertyPane(undefined));
          yield put(selectWidgets([]));
        } else {
          // focus on parent
          const parentWidgetToSelect = getParentToOpenIfAny(widgetId, widgets);
          // focus on the parent of last deleted widget if more than 1 widgets is deleted
          if (parentWidgetToSelect) {
            yield put(focusWidget(parentWidgetToSelect.widgetId));
            const selectedWidget: ReturnType<typeof getSelectedWidget> =
              yield select(getSelectedWidget);
            if (selectedWidget?.widgetId !== parentWidgetToSelect.widgetId) {
              yield put(selectWidgets([parentWidgetToSelect.widgetId]));
            }
          }
        }
      }

      const analyticsEvent = isShortcut
        ? "WIDGET_DELETE_VIA_SHORTCUT"
        : "WIDGET_DELETE";

      AnalyticsUtil.logEvent(analyticsEvent, {
        widgetName: widget.widgetName,
        widgetType: widget.type,
      });

      // Remove entry from parent's children
      if (parent.children) {
        parent = {
          ...parent,
          children: parent.children.filter((c: string) => c !== widgetId),
        };
      }

      widgets[parentId] = parent;

      if (parent.type === WidgetTypes.SECTION_WIDGET) {
        // Deleted a column, so we need to update the snapColumns of the remaining columns
        const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
          getFlattenedCanvasWidgets,
        );
        resizeSectionColumnsAfterColumnDelete({
          widgets,
          flattenedWidgets,
          sectionWidgetId: parent.widgetId,
        });
      }

      const otherWidgetsToDelete = getAllWidgetsInTree(widgetId, widgets);

      let widgetName = widget.widgetName;
      // SPECIAL HANDLING FOR TABS IN A TABS WIDGET
      // Here we are naming the children of a Tabs widget; TODO: This should be strongly typed
      if (parent.type === WidgetTypes.TABS_WIDGET && (widget as any).tabName) {
        widgetName = (widget as any).tabName;
      }

      // change to clear cache of widgets
      yield call(clearEvalPropertyCacheOfWidget, widgetName);

      let finalWidgets: CanvasWidgetsReduxState = omit(
        widgets,
        otherWidgetsToDelete.map((widgets) => widgets.widgetId),
      );

      const dynamicVisibility: DynamicWidgetsVisibilityState = yield select(
        getDynamicVisibilityWidgets,
      );
      if (haveDifferentParents) {
        // Note: mutates finalWidgets
        resizeCanvasOnChildDelete(
          finalWidgets,
          theme,
          appMode,
          parentId,
          dynamicVisibility,
        );
      } else {
        // only re-calculate parent canvas once for all deleleted widgets
        if (i === widgetsIdsToDelete.length - 1) {
          resizeCanvasOnChildDelete(
            finalWidgets,
            theme,
            appMode,
            parentId,
            dynamicVisibility,
          );
        }
      }

      // Update canvas heights if needed
      const grandParent = widgets[parent.parentId];
      if (
        parent.type === WidgetTypes.CANVAS_WIDGET &&
        grandParent.type === WidgetTypes.SECTION_WIDGET
      ) {
        finalWidgets = updateSectionWidgetCanvasHeights(
          finalWidgets,
          theme,
          appMode,
          grandParent,
        );
      }

      const updates = deleteReferencesFromWidgetTriggers(
        finalWidgets,
        widget.widgetName,
        true,
      );
      if (updates) {
        updates.forEach((update) => {
          // finalWidgets is immutable
          finalWidgets[update.entityId] = fastClone(
            finalWidgets[update.entityId],
          );
          set(
            finalWidgets[update.entityId],
            update.propertyName,
            update.propertyValue,
          );
        });
      }

      const apiUpdates = deleteReferencesFromApiInfoTriggers(
        stateApis,
        widget.widgetName,
        true,
      );

      if (apiUpdates) {
        hasApiUpdates = true;
        apiUpdates.forEach((update) => {
          set(
            stateApis[update.entityId],
            update.propertyName,
            update.propertyValue,
          );
        });
      }

      // only update layout once for all deleleted widgets
      if (i === widgetsIdsToDelete.length - 1) {
        yield put(
          updateLayout(finalWidgets, deleteAction.payload.skipSave !== true),
        );
        // updateApiInfo after updateLayout to avoid override
        if (hasApiUpdates) {
          yield put(updateApiInfo(stateApis));
        }
      }
      yield put(deleteEntityFromTimers(widget.widgetName, true));
      widgets = finalWidgets;
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: deleteAction.type,
        error,
      },
    });
  }
}

function* deleteEntityFromWidgetsSaga(
  action: ReturnType<typeof deleteEntityFromWidgets>,
): Generator<any, any, any> {
  try {
    const controlFlowEnabled: boolean = yield select(
      selectControlFlowEnabledDynamic,
    );
    const { entityName } = action.payload;

    const stateWidgets: CanvasWidgetsReduxState = fastClone(
      yield select(getWidgets),
    );

    const updates = deleteReferencesFromWidgetTriggers(
      stateWidgets,
      entityName,
      false,
    );
    if (updates) {
      updates.forEach((update) => {
        set(
          stateWidgets[update.entityId],
          update.propertyName,
          update.propertyValue,
        );
      });
      yield put(updateLayout(stateWidgets));
    }

    let apis;
    if (controlFlowEnabled) {
      apis = yield select(getV2ApiAppInfo);
    } else {
      apis = yield select(getApiAppInfo);
    }
    const stateApis: ApisMap = fastClone(apis);

    const apiUpdates = deleteReferencesFromApiInfoTriggers(
      stateApis,
      entityName,
      false,
    );
    if (apiUpdates) {
      apiUpdates.forEach((update) => {
        set(
          stateApis[update.entityId],
          update.propertyName,
          update.propertyValue,
        );
      });
      yield put(updateApiInfo(stateApis));
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: action.type,
        error,
      },
    });
  }
}

function* deleteV1ApiFromWidgets(
  deleteAction: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = deleteAction.payload;

    const api: ApiV1 | undefined = yield select(selectV1ApiById, id);
    if (!api) return;

    yield put(deleteApiInfo({ id, name: api?.actions?.name ?? "" }));
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: deleteAction.type,
        error,
      },
    });
  }
}

function* deleteV2ApiFromWidgets(
  deleteAction: ReduxAction<{
    id: string;
  }>,
): Generator<any, any, any> {
  try {
    const { id } = deleteAction.payload;

    const api: ReturnType<typeof selectV2ApiById> = yield select(
      selectV2ApiById,
      id,
    );
    if (!api) return;

    yield put(deleteApiInfo({ id, name: getV2ApiName(api) }));
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: deleteAction.type,
        error,
      },
    });
  }
}

function* moveSaga(moveAction: ReduxAction<WidgetMove>) {
  try {
    Toaster.clear();
    const {
      position,
      size,
      widgetId, // the widget that the user dragged on
      parentId: oldParentId,
      newParentId,
      newChildIndex,
      stackAdjustments,
    } = moveAction.payload;
    const stateWidget: ReturnType<typeof getWidget> = yield select(
      getWidget,
      widgetId,
    );
    const widget = Object.assign({}, stateWidget);
    const isFixedMove = newChildIndex == null;
    if (isFixedMove) {
      if (
        oldParentId === newParentId && // Has the parent NOT changed
        equal(widget?.left, position.left) && // Has the left NOT changed
        equal(widget?.top, position.top) && // Has the top NOT changed
        (!size || // Has the size NOT changed
          (equal(widget.width, size?.width) && // Has the width NOT changed
            equal(widget.height, size?.height))) // Has the height NOT changed
      ) {
        // No change, so just return
        return;
      }
    } else {
      const parentStateWidget: ReturnType<typeof getWidget> = yield select(
        getWidget,
        newParentId,
      );
      const oldIndex = parentStateWidget.children?.indexOf(widgetId);
      if (oldParentId === newParentId && oldIndex === newChildIndex) {
        return; // no change, just return
      }
    }

    const theme: ReturnType<typeof selectGeneratedTheme> =
      yield select(selectGeneratedTheme);
    const appMode: APP_MODE = yield select(getAppMode) ?? APP_MODE.PUBLISHED;

    const stateWidgets: ReturnType<typeof getWidgets> =
      yield select(getWidgets);
    const { widgets, changes } = widgetProxy(stateWidgets);

    const stateOldParent: ReturnType<typeof getWidget> = yield select(
      getWidget,
      oldParentId,
    );
    const oldParent = {
      ...stateOldParent,
      children: [...(stateOldParent.children || [])],
    };
    const newParent = {
      ...widgets[newParentId],
      children: [...(widgets[newParentId].children || [])],
    };
    const newGrandParent = stateWidgets[newParent.parentId];
    const oldGrandParent = stateWidgets[oldParent.parentId];

    if (newParent.widgetId === widget.widgetId) {
      console.warn(
        "Unexpected case: Trying to move widget and new parent is same widget as widget being moved. Ignoring move.",
      );
      return;
    }
    if (newGrandParent.widgetId === widget.widgetId) {
      console.warn(
        "Unexpected case: Trying to move widget and new grand parent is same widget as widget being moved. Ignoring move.",
      );
      return;
    }

    // Update position of all selected widgets
    const selectedWidgetIds: string[] = yield select(getSelectedWidgetsIds);

    const dynamicWidgetsLayoutState: ReturnType<
      typeof getDynamicLayoutWidgets
    > = yield select(getDynamicLayoutWidgets);

    const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
      getFlattenedCanvasWidgets,
    );

    const flattenedParent = flattenedWidgets[newParentId];

    const stackDragPositions = getRelativeStackedWidgetPositions({
      widgets,
      widgetIds: selectedWidgetIds,
      primaryWidgetId: widgetId,
      dynamicWidgetsLayoutState,
      parentColumnSpace: flattenedParent?.parentColumnSpace,
    });

    (isFixedMove ? moveWidgetFixedLayout : moveWidgetStackLayout)({
      widget,
      widgets,
      oldParent,
      selectedWidgetIds,
      stateWidgets,
      movePayload: moveAction.payload,
      stackDragPositions,
      stackAdjustments,
      flattenedParent,
      flattenedWidgets,
      theme,
    });

    // Always update the height of the oldSection and the new section. Sometimes they are the same
    // So we might need to only update one
    const hasGrandParentChanged =
      oldGrandParent.widgetId !== newGrandParent.widgetId;
    if (hasGrandParentChanged) {
      updateSectionWidgetCanvasHeights(widgets, theme, appMode, oldGrandParent);
    }
    updateSectionWidgetCanvasHeights(widgets, theme, appMode, newGrandParent);

    // widgetId is only the dragged id, must do this for all moved widgets
    for (const widgetId of selectedWidgetIds) {
      yield call(
        handleGridWidgetAutoHeight,
        widgetId,
        newParentId,
        oldParentId,
        widgets,
        stateWidgets,
        stackDragPositions,
      );
    }

    yield put(updatePartialLayout(changes));
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.WIDGETS_MOVE,
        error,
      },
    });
  }
}

// for emancipation of modals and slideouts
function* reparentSaga(
  reparentAction: ReduxAction<WidgetReparent>,
): Generator<any, any, any> {
  // wait
  const { widgetId, newParentId } = reparentAction.payload;
  const stateWidgets: Readonly<CanvasWidgetsReduxState> =
    yield select(getWidgets);
  const { widgets, changes } = widgetProxy(stateWidgets);

  const widgetToMove = widgets[widgetId];
  const oldParent = widgets[widgetToMove.parentId];
  const newParent = widgets[newParentId];

  if (oldParent.widgetId === newParent.widgetId) {
    return;
  }

  widgets[widgetId] = {
    ...widgetToMove,
    parentId: newParentId,
  };

  widgets[oldParent.widgetId] = {
    ...oldParent,
    children: (oldParent.children || []).filter((id) => id !== widgetId),
  };

  widgets[newParent.widgetId] = {
    ...newParent,
    children: [...(newParent.children || []), widgetId],
  };

  Toaster.show({
    text: `Moved ${widgetToMove.widgetName} to the Page level`,
    variant: Variant.success,
    dispatchableAction: undoAction(),
    duration: 10e3, // 10 seconds
  });

  yield put(updatePartialLayout(changes));
}

function* resizeSaga(
  resizeAction: ReduxAction<WidgetResize>,
): Generator<any, any, any> {
  try {
    Toaster.clear();
    const start = performance.now();
    const stateWidgets: CanvasWidgetsReduxState = yield select(getWidgets);

    const { widgetId, size: size_, position: position_ } = resizeAction.payload;

    const size: Size = {
      width: size_.width ?? stateWidgets[widgetId].width,
      height: size_.height ?? stateWidgets[widgetId].height,
    };

    const position: GridPosition = {
      left: position_?.left ?? stateWidgets[widgetId].left,
      top: position_?.top ?? stateWidgets[widgetId].top,
    };

    const { widgets, changes } = widgetProxy(stateWidgets);
    let widget: FlattenedWidgetProps = { ...widgets[widgetId] };
    const widgetParent: FlattenedWidgetProps = { ...widgets[widget.parentId] };
    const widgetGrandParent: FlattenedWidgetProps = {
      ...widgets[widgetParent.parentId],
    };

    const theme: ReturnType<typeof selectGeneratedTheme> =
      yield select(selectGeneratedTheme);

    // Update left, width / gridColumns + children
    const widthTypesToIgnore = ["fitContent", "fillParent"];

    const ignoreWidths =
      widthTypesToIgnore.includes(size.width.mode) ||
      widthTypesToIgnore.includes(widget.width.mode);

    const parentCanvasRunTime: FlattenedWidgetLayoutProps = yield select(
      getFlattenedCanvasWidget,
      widget.parentId,
    );

    const widthDiff = ignoreWidths
      ? undefined
      : Dimension.minus(
          Dimension.toGridUnit(
            size.width,
            parentCanvasRunTime.parentColumnSpace,
          ).raw(),
          Dimension.toGridUnit(
            widget.width,
            parentCanvasRunTime.parentColumnSpace,
          ).raw(),
        );
    const leftDiff = Dimension.minus(position.left, widget.left);
    if (widthDiff) {
      const flattenedWidgets = yield select(getFlattenedCanvasWidgets);
      const flattenedChild: ReturnType<typeof getFlattenedCanvasWidget> =
        flattenedWidgets[widget?.children?.[0] ?? ""];
      const flattenedWidget: ReturnType<typeof getFlattenedCanvasWidget> =
        flattenedWidgets[widget.widgetId];

      const isStaticResize = getIsStaticResize({
        flattenedChild,
        flattenedParent: flattenedWidget,
        theme,
      });

      updateWidgetWidths({
        widgets,
        flattenedWidgets,
        widget,
        widthDiffGU: widthDiff.value,
        leftDiffGU: leftDiff.value,
        deepCallOptions: {
          staticResizeParentId: isStaticResize ? widget.widgetId : undefined,
        },
      });
    }

    widget = widgets[widgetId];

    // Update top, height
    widget = {
      ...widget,
      top: position.top,
      height: size.height,
    };
    widgets[widgetId] = widget;

    // Update height of all children
    const recursiveChildIds: string[] = getWidgetChildrenIds(
      widgetId,
      stateWidgets,
      { includeOrphanedWidgets: false },
    );
    const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
    const flags = yield select(selectFlags);
    for (const id of recursiveChildIds) {
      const child = { ...widgets[id] };
      if (child.type === WidgetTypes.CANVAS_WIDGET) {
        const parent = widgets[child.parentId];
        // This logic needs to match size.ts recalculateWidgetsLayout
        const iHeight = getInternalHeightGridUnits(child, parent, theme, flags);
        if (!parent.shouldScrollContents) {
          child.height = iHeight;
        } else {
          let lowestRow = 0;
          child.children
            ?.map((id) => widgets[id])
            .forEach((grandChild) => {
              const bottom = Dimension.add(
                grandChild.top,
                Dimension.toGridUnit(
                  grandChild.height,
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                ).roundUp(),
              ).asSecond();
              if (bottom.value > lowestRow) {
                lowestRow = bottom.value;
              }
            });
          child.height = Dimension.build(lowestRow, child.height.mode);
        }
      }
      widgets[child.widgetId] = child;
    }

    if (widgetGrandParent.type === WidgetTypes.SECTION_WIDGET) {
      updateSectionWidgetCanvasHeights(
        widgets,
        theme,
        appMode,
        widgetGrandParent,
      );
    }

    console.debug(`resize computations took ${performance.now() - start}ms`);
    yield put(updatePartialLayout(changes));
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.WIDGET_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.WIDGET_RESIZE,
        error,
      },
    });
  }
}

function* setWidgetDynamicPropertySaga(
  action: ReduxAction<SetWidgetDynamicPropertyPayload>,
) {
  const { isDynamic, propertyPath, widgetId } = action.payload;
  const widget: WidgetProps = yield select(getWidget, widgetId);
  const propertyValue = get(widget, propertyPath);
  let dynamicPropertyPathList = getWidgetDynamicPropertyPathList(widget);
  const propertyUpdates: Record<string, unknown> = {};
  if (isDynamic) {
    dynamicPropertyPathList.push({
      key: propertyPath,
    });
    propertyUpdates[propertyPath] = convertToString(propertyValue);
  } else {
    dynamicPropertyPathList = reject(dynamicPropertyPathList, {
      key: propertyPath,
    });

    const widgetConfig: GeneratorReturnType<typeof getCreateConfig> =
      yield call(getCreateConfig, widget.type);
    propertyUpdates[propertyPath] =
      widgetConfig[propertyPath as keyof typeof widgetConfig];
  }
  propertyUpdates.dynamicPropertyPathList = dynamicPropertyPathList;

  yield* updateWidgetPropertiesSaga(
    updateWidgetProperties(widgetId, propertyUpdates),
  );
}

function* updateWidgetPropertiesSaga(
  action: ReduxAction<UpdateWidgetPropertiesPayload>,
): Generator<any, any, any> {
  const { updates, widgetId } = action.payload;

  if (!widgetId) {
    // Handling the case where sometimes widget id is not passed through here
    return;
  }

  /*
    IMPORTANT NOTE:
    We use the widgetsProxy here so that we can track all the changes made to
    the widgets and then call updatePartialLayout with *only* the changes rather than the
    entire set of widgets

    So it's important that in the code below, we only update the widgetsProxy (do not directly alter the changes object)

    And in order to ensure the changes are tracked in the proxy, you must call set at the top level.

    Ex: this change is tracked: widgets[widgetId] = someUpdatedWidget;

    Ex: This change is not tracked: widgetsProxy[widgetId].someProperty = newValue;

    You can still make updates to intermediary widget objects, but you must eventually
    set them on the widgetsProxy object so that the changes are tracked.

    Note that many of the util functions below make direct edits to the widgetsProxy that's passeed in
  */
  const { widgets: widgetsProxy, changes } = widgetProxy(
    fastClone(yield select(getWidgets)),
  );

  const widget = widgetsProxy[widgetId];
  if (!widget) {
    return;
  }
  const parent = widgetsProxy[widget.parentId];

  const originalWidget = fastClone(widget);
  const originalHeightMode = originalWidget.height.mode;
  const originalWidthMode = originalWidget.width.mode;
  const originalWidthValue = originalWidget.width.value;
  const originalLayout = originalWidget.layout;

  const deepBindingsFeatureFlagIsEnabled: ReturnType<typeof selectFlagById> =
    yield select(selectFlagById, Flag.ENABLE_DEEP_BINDINGS_PATHS);

  const propertyUpdates = mergeUpdatesWithBindingsOrTriggers(
    widget,
    getItemPropertyPaneConfig(widget.type),
    updates,
    deepBindingsFeatureFlagIsEnabled,
  );

  const theme = yield select(selectGeneratedTheme);
  const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
  const originalWidgetValues: PartialWidgetProps = {};

  // We loop over all updates
  Object.entries(propertyUpdates).forEach(([propertyPath, propertyValue]) => {
    originalWidgetValues[propertyPath as keyof WidgetProps] = get(
      widgetsProxy[widgetId],
      propertyPath,
    );
    logNewBinding(widget, propertyPath, propertyValue, widgetsProxy, widgetId);
    logNewEventHandler(
      widget,
      propertyPath,
      propertyValue,
      widgetsProxy,
      widgetId,
    );

    // since property paths could be nested, we use lodash set method
    // potentially also updates `dynamicBindingPathList`
    set(widgetsProxy[widgetId], propertyPath, propertyValue);

    // eslint-disable-next-line no-self-assign
    widgetsProxy[widgetId] = widgetsProxy[widgetId];
  });

  // Clean up dynamic bindings that no longer reference an existing property in the widget
  if (deepBindingsFeatureFlagIsEnabled) {
    const dynamicBindingPathList =
      getDynamicBindingPathListWithExistingReferences(widgetsProxy[widgetId]);

    set(
      widgetsProxy[widgetId],
      "dynamicBindingPathList",
      dynamicBindingPathList,
    );

    // eslint-disable-next-line no-self-assign
    widgetsProxy[widgetId] = widgetsProxy[widgetId];
  }

  const {
    changes: hookUpdatesByWidgetId,
    widgetMetaUpdates,
  }: GeneratorReturnType<typeof callWidgetHooks> = yield call(
    callWidgetHooks,
    widgetsProxy,
    action,
    originalWidgetValues,
  );
  if (hookUpdatesByWidgetId) {
    Object.entries(hookUpdatesByWidgetId).forEach(([widgetId, updates]) => {
      Object.entries(updates).forEach(([propertyPath, propertyValue]) => {
        const updatesObj = {};
        set(updatesObj, propertyPath, propertyValue);
        widgetsProxy[widgetId] = merge(widgetsProxy[widgetId], updatesObj);
      });
    });
  }

  // apply widget meta updates
  yield call(updateWidgetMetaUpdates, widgetMetaUpdates);

  const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
    yield select(getDynamicLayoutWidgets);

  if (widget.height.mode === "fillParent" && widgetsProxy[widgetId]) {
    widgetsProxy[widgetId].height = Dimension.build(
      GridDefaults.FILL_PARENT_DEFAULT_MIN_HEIGHT,
      widgetsProxy[widgetId].height.mode,
    );
  } else if (
    widgetsProxy[widgetId] &&
    widget.height.mode !== "fillParent" &&
    originalHeightMode === "fillParent"
  ) {
    const dynamicHeight = dynamicWidgetLayout[widgetId]?.height;

    if (dynamicHeight) {
      const newHeight: Dimension<WidgetHeightModes> = Dimension.toGridUnit(
        dynamicHeight,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw();
      widgetsProxy[widgetId].height = Dimension.build(
        Math.max(newHeight.value, 1),
        widget.height.mode,
      );
    }
  }

  const flattenedWidgets = yield select(getFlattenedCanvasWidgets);
  const flattenedWidget = flattenedWidgets[widgetId];

  // we set min and max heights here because the switch control doesn't have access to the dynamic height in some cases
  handleDimensionConstraintUpdate({
    updates,
    widget,
    widgets: widgetsProxy,
    dynamicWidgetLayout,
    parentColumnSpace: flattenedWidget?.parentColumnSpace,
  });

  if (
    widget.height.mode !== originalHeightMode &&
    !(
      // if both are a combination of px and gridUnit, then its not really a mode change
      (isFixedHeight(widget.height.mode) && isFixedHeight(originalHeightMode))
    )
  ) {
    updateWidgetAfterHeightModeChange({
      newWidget: widget,
      widgets: widgetsProxy,
      flattenedWidgets,
    });

    // without this, switching height mode to/from fit-content and fill viewport can cause
    // incorrect column canvas heights
    const grandParent = widgetsProxy[parent.parentId];
    const self = widgetsProxy[widgetId];
    const sectionToUpdate =
      grandParent?.type === WidgetTypes.SECTION_WIDGET
        ? grandParent
        : self.type === WidgetTypes.SECTION_WIDGET
          ? self
          : undefined;

    if (sectionToUpdate) {
      updateSectionWidgetCanvasHeights(
        widgetsProxy,
        theme,
        appMode,
        sectionToUpdate,
      );
    }
  }

  // This doesn't go in applyActionHook because we call updateWidgetWidths. It would be a tricky refactor to make it work
  if (widget.width.mode !== originalWidthMode) {
    const flattenedWidgets = yield select(getFlattenedCanvasWidgets);

    updateWidgetAfterWidthModeChange({
      newWidget: widget,
      flattenedWidgets,
      widgets: widgetsProxy,
    });
  } else if (
    originalWidthMode === "px" &&
    widget.width.value !== originalWidthValue
  ) {
    const flattenedWidgets = yield select(getFlattenedCanvasWidgets);
    const parentCanvasRunTime = flattenedWidgets[widget.parentId];
    if (parentCanvasRunTime && parentCanvasRunTime.parentColumnSpace) {
      const pxChange = widget.width.value - originalWidthValue;
      const guChangeRaw = pxChange / parentCanvasRunTime.parentColumnSpace;
      // reset the px value back to original so we can run updateWidgetWidths on it
      const newWidthValue = widget.width.value;
      widgetsProxy[widgetId].width = Dimension.px(originalWidthValue);
      updateWidgetWidths({
        widgets: widgetsProxy,
        flattenedWidgets,
        widget,
        widthDiffGU: guChangeRaw,
        leftDiffGU: 0,
      });
      // now set it back
      widgetsProxy[widgetId].width = Dimension.px(newWidthValue);
    }
  }

  if (
    widget.layout !== originalLayout &&
    widget.layout === CanvasLayout.HSTACK
  ) {
    const widgetsRuntime: FlattenedWidgetLayoutMap = yield select(
      getFlattenedCanvasWidgets,
    );
    // when switching to an HStack, compress the children (if possible) so that the HStack does not scroll
    shrinkWidgetsInHstack({
      parentWidget: widget,
      parentWidgetRunTime: widgetsRuntime[widget.widgetId],
      widgets: widgetsProxy,
      flattenedWidgets: widgetsRuntime,
      childrenIds: widget.children ?? [],
      useFullWidth: true,
    });
  }

  yield put(
    updatePartialLayout(
      changes,
      !action.payload.skipSave,
      action.payload.clearReplayStack,
    ),
  );

  const heightChanged =
    originalWidgetValues.height !== undefined &&
    !equal(widget.height, originalWidgetValues.height);
  if (heightChanged && widget.height.mode !== "fillParent") {
    // call reflow with the new height
    yield put(
      updateWidgetAutoHeight(
        widgetId,
        Dimension.toPx(widget.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
          .value -
          WIDGET_PADDING * 2,
      ),
    );
  }
}

function* deleteWidgetPropertySaga(
  action: ReduxAction<DeleteWidgetPropertyPayload>,
) {
  const { widgetId, propertyPaths } = action.payload;
  if (!widgetId) {
    // Handling the case where sometimes widget id is not passed through here
    return;
  }
  const stateWidget: WidgetProps = yield select(getWidget, widgetId);
  const widget = deleteWithBindingsOrTriggers(stateWidget, propertyPaths);
  yield put(setSingleWidget(widgetId, widget, true, true));
}

function* resetChildrenMetaSaga(
  action: ReturnType<typeof resetChildrenMetaProperty>,
) {
  const parentWidgetId = action.payload.widgetId;
  const widgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const children = getWidgetChildren(parentWidgetId, widgets, {
    includeOrphanedWidgets: false,
  });

  for (const childIndex in children) {
    const childId = children[childIndex].widgetId;
    yield put(resetWidgetMetaProperty(childId));
    UITracing.addEvent(
      action.payload.spanId,
      `All properties of ${children[childIndex].widgetName} reset.`,
    );
  }
}

function* updateCanvasSize(
  action: ReduxAction<UpdateCanvasHeightPayload>,
): Generator<any, any, any> {
  const { canvasWidgetId, gridRows } = action.payload;
  const canvasWidget: ReturnType<typeof getWidget> = yield select(
    getWidget,
    canvasWidgetId,
  );
  const parentWidget: FlattenedWidgetProps | undefined = yield select(
    getWidget,
    canvasWidget.parentId,
  );

  const originalGridRows = canvasWidget.height.value;
  const newGridRows = Math.round(gridRows);

  // Update the canvas's rows only if it has changed since the last render
  // and if the layouts flag is enabled and this canvas is section column
  // we handle the update slightly differently
  const parentIsSection = parentWidget?.type === WidgetTypes.SECTION_WIDGET;
  const parentIsFitContentHeight = parentWidget?.height.mode === "fitContent";

  if (!parentIsSection) {
    if (originalGridRows !== newGridRows) {
      yield put(
        updateWidgetProperties(canvasWidgetId, {
          height: Dimension.build(newGridRows, canvasWidget.height.mode),
        }),
      );
    }
    // If the parent is a section, and it's either fit content or it's extending (not contracting), then
    // update the section and chld canvas heights
  } else if (
    parentIsSection &&
    (parentIsFitContentHeight || newGridRows > parentWidget?.height.value)
  ) {
    const stateWidgets: Readonly<CanvasWidgetsReduxState> =
      yield select(getWidgets);
    const { widgets, changes } = widgetProxy(stateWidgets);

    const theme = yield select(selectGeneratedTheme);
    const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;

    updateSectionWidgetCanvasHeights(widgets, theme, appMode, parentWidget);
    yield put(updatePartialLayout(changes));
  }
}

function* createWidgetCopy(
  selectedWidgets: WidgetProps[],
): Generator<any, any, any> {
  if (!selectedWidgets) return;

  const widgets = yield select(getWidgets);

  // Sort the selected widgets by their position
  const parentWidget = widgets[selectedWidgets[0].parentId];
  const sortedSelectedWidgetIds = getSortedWidgetOrder(
    selectedWidgets.map((widget) => widget.widgetId),
    widgets,
    parentWidget.layout,
    parentWidget.layout,
  );
  const sortedSelectedWidgets = sortedSelectedWidgetIds.map(
    (widgetId) => widgets[widgetId],
  );

  const widgetsToCopy: Array<{
    widgetId: string;
    list: WidgetProps[];
  }> = sortedSelectedWidgets.map((selectedWidget: FlattenedWidgetProps) => {
    const widgetsToStore = getAllWidgetsInTree(
      selectedWidget.widgetId,
      widgets,
    );
    const parentWidget = widgets[selectedWidget.parentId];
    return {
      widgetId: selectedWidget.widgetId,
      type: selectedWidget.type,
      list: widgetsToStore,
      parentType: parentWidget.type,
    };
  });

  return yield saveCopiedWidgets(JSON.stringify(widgetsToCopy));
}

function* copyUiBlockSaga(
  action: ReturnType<typeof copyUiBlock>,
): Generator<any, any, any> {
  const { uiBlock } = action.payload;

  // TODO only support 1 for now since modals and slideouts need to be figured out
  const copyData = createCopyDataFromUIBlock(uiBlock);

  yield call(saveCopiedWidgets, JSON.stringify(copyData));

  sendSuccessUINotification({
    key: "copy-ui-block",
    message: `Copied ${uiBlock.name}. You can now paste it on the canvas`,
    duration: 3,
    placement: NotificationPosition.top,
  });
}

function* copyWidgetSaga(
  action: ReturnType<typeof copyWidget>,
): Generator<any, any, any> {
  let selectedWidgets = yield select(getSelectedWidgets);
  if (!selectedWidgets || selectedWidgets.length === 0) {
    Toaster.show({
      text: `Please select a widget to copy`,
      variant: Variant.info,
    });
    return;
  }
  selectedWidgets = selectedWidgets.filter(
    (selectedWidget: WidgetProps & { disallowCopy: boolean }) => {
      if (selectedWidget.disallowCopy === true) {
        Toaster.show({
          text: `Copying is not allowed on this widget: ${selectedWidget.widgetName}`,
          variant: Variant.info,
        });
        return false;
      }
      return true;
    },
  );
  yield call(createWidgetCopy, selectedWidgets);

  const eventName = action.payload.isShortcut
    ? "WIDGET_COPY_VIA_SHORTCUT"
    : "WIDGET_COPY";
  selectedWidgets.forEach((selectedWidget: WidgetProps) => {
    AnalyticsUtil.logEvent(eventName, {
      widgetName: selectedWidget.widgetName,
      widgetType: selectedWidget.type,
    });
  });
}

function* pasteWidgetSaga(
  action: ReduxAction<{
    pasteTargetId: string;
    forcePasteIntoContainer?: boolean;
    mousePosition?: {
      x: number;
      y: number;
    };
    pasteAtCursor?: boolean;
    stackInsertionPosition?: number;
    sectionInsertionPosition?: number;
    columnInsertionPosition?: number;
    copiedWidgetsOverride?: CopiedWidgets;
    skipReplaceConfirmation?: boolean;
  }>,
): Generator<any, any, any> {
  const sourceWidgets: CopiedWidgets =
    action.payload.copiedWidgetsOverride ?? (yield getCopiedWidgets());

  // Don't try to paste if there is no copied widget
  if (!sourceWidgets || !sourceWidgets.length) return;

  // apply namespace and fix name conflicts
  const entityNames: string[] = yield select(getAllEntityNames);

  const renames = renameSourceWidgetsWithNamespace({
    entityNames,
    sourceWidgets,
  });

  const refactoredSourceWidgets: Awaited<
    ReturnType<typeof applyRefactoredNamesToCopiedWidgets>
  > = yield applyRefactoredNamesToCopiedWidgets({
    renames,
    sourceWidgets,
  });

  if (!action.payload.skipReplaceConfirmation) {
    // unless skipped render paste confirmation component that will check if the paste will
    // replace any existing component and ask for confirmation before proceeding with the paste action
    yield put(
      pasteWidgetConfirmation({
        copiedWidgets: refactoredSourceWidgets,
        pasteTargetId: action.payload.pasteTargetId,
      }),
    );
    const { cancel } = yield race({
      success: take(pasteWidgetConfirmationAccepted.type),
      cancel: take(pasteWidgetConfirmationCancelled.type),
    });

    if (cancel) {
      return;
    }
  }

  // Widgets that are children of a canvas (e.g. Text, Button, Container)
  // should be pasted together because they are part of the same layout
  // others should be grouped based on their own type because the logic for determining their parents might differ
  const toCopyByType: Partial<
    Record<
      | WidgetTypes.SECTION_WIDGET
      | WidgetTypes.CANVAS_WIDGET
      | WidgetTypes.PAGE_WIDGET
      | WidgetTypes.MODAL_WIDGET
      | WidgetTypes.SLIDEOUT_WIDGET
      | "OTHER",
      CopiedWidgets
    >
  > = {};
  refactoredSourceWidgets.forEach((sourceWidget) => {
    switch (sourceWidget.type) {
      case WidgetTypes.SECTION_WIDGET:
      case WidgetTypes.CANVAS_WIDGET:
      case WidgetTypes.PAGE_WIDGET:
      case WidgetTypes.MODAL_WIDGET:
      case WidgetTypes.SLIDEOUT_WIDGET:
        toCopyByType[sourceWidget.type] ??= [];
        toCopyByType[sourceWidget.type]?.push(sourceWidget);
        break;
      default:
        toCopyByType.OTHER ??= [];
        toCopyByType.OTHER.push(sourceWidget);
    }
  });

  const prePasteWidgets = yield select(getWidgets);
  const appSettings = yield select(getApplicationSettings);
  const getAllowedChildTypes = memoize((widgetId: string) => {
    return new Set(
      getAllowedChildTypesInWidgetAncestry({
        widgetId,
        canvasWidgets: prePasteWidgets,
        registeredComponents: appSettings?.registeredComponents,
      }),
    );
  });

  const rejectedPasteTypes: string[] = [];

  function* pasteWidgets(copiedWidgets: CopiedWidgets) {
    // decide the parent in which to paste the copied widgets
    const stateWidgets: ReturnType<typeof getWidgets> =
      yield select(getWidgets);

    const widgetMeta: ReturnType<typeof getWidgetsMeta> =
      yield select(getWidgetsMeta);

    const copiedWidgetsType = copiedWidgets?.[0]?.type;

    const openModalOrSlideout: ReturnType<typeof getOpenModalOrSlideout> =
      yield select(getOpenModalOrSlideout);

    const dataTree: DataTree = yield select(getDataTree);
    const pasteParentWidget = getPasteParentDetails({
      pasteTargetId: action.payload.pasteTargetId,
      widgets: stateWidgets,
      widgetMeta,
      copiedWidgets,
      copiedWidgetsType,
      openModalOrSlideout,
      lastPastedSingleWidgetId: getLastPastedSingleWidgetId(),
      forcePasteIntoContainer: action.payload.forcePasteIntoContainer,
      pageDataTree: dataTree[ApplicationScope.PAGE],
    });

    if (!pasteParentWidget) {
      log.error(
        `Component paste error: Could find correct parent to paste into. pasteTargetId: ${action.payload.pasteTargetId}, copiedWidgetsType: ${copiedWidgetsType}`,
      );
      return;
    }

    const allowedTypes = getAllowedChildTypes(pasteParentWidget.widgetId);

    if (!allowedTypes.has(copiedWidgetsType)) {
      rejectedPasteTypes.push(copiedWidgetsType);
      return [];
    }

    const newWidgetIds: string[] = yield call(pasteWidgetRoot, {
      copiedWidgets,
      pasteParentWidget,
      sectionInsertionPosition: action.payload.sectionInsertionPosition,
      columnInsertionPosition: action.payload.columnInsertionPosition,
      stackInsertionPosition: action.payload.stackInsertionPosition,
      mousePosition: action.payload.mousePosition,
      pasteAtCursor: action.payload.pasteAtCursor,
    });

    return newWidgetIds;
  }

  let newWidgetIds: string[] = [];

  for (const copiedWidgets of Object.values(toCopyByType)) {
    const newIds = yield call(pasteWidgets, copiedWidgets);
    newWidgetIds = [...newWidgetIds, ...newIds];
  }

  // special cases: when pasting into an empty section, if the pasted item is itself a section, replace it.
  const widgets: ReturnType<typeof getWidgets> = yield select(getWidgets);
  const [widgetsToReplace, widgetsToDelete] =
    getWidgetsToDeleteAndReplaceFromPasteAction({
      copiedWidgets: refactoredSourceWidgets,
      pasteTargetId: action.payload.pasteTargetId,
      widgets: widgets,
      newWidgetIds,
    });

  if (widgetsToReplace.length) {
    const dataTree: DataTree = yield select(getDataTree);
    const fakeDataTree: ReturnType<typeof createFakeDataTree> = yield call(
      createFakeDataTree,
      dataTree,
    );

    for (const widgetToReplace of widgetsToReplace) {
      if (widgetToReplace.newWidget) {
        const overrideWidgets: WidgetMap = { ...widgets };
        // do not consider the new copied widget when renaming as it is already renamed
        // This function will temporarily rename the replaced widget with the same value as the new
        // widget in order to update all references correctly. The replaces widget will be deleted later
        // in this saga
        delete overrideWidgets[widgetToReplace.newWidget.widgetId];
        yield refactorNameInWidgets(
          widgetToReplace.oldWidget.widgetName,
          widgetToReplace.newWidget.widgetName,
          fakeDataTree,
          ApplicationScope.PAGE,
          undefined,
          overrideWidgets,
        );
      }
    }
  }
  const widgetsIdsToDelete = [
    ...widgetsToDelete.map(({ widgetId }) => widgetId),
    ...widgetsToReplace.map(({ oldWidget }) => oldWidget.widgetId),
  ];

  yield call(
    deleteSaga,
    deleteWidgets({
      widgetIds: widgetsIdsToDelete,
      disallowUndo: true,
      isShortcut: false,
    }),
  );

  if (rejectedPasteTypes.length) {
    Toaster.show({
      text: `Cannot insert components of type ${rejectedPasteTypes.join(
        ", ",
      )} here.`,
      variant: Variant.warning,
    });
  }
}

function* cutWidgetSaga(): Generator<any, any, any> {
  const selectedWidgets = yield select(getSelectedWidgets);
  if (!selectedWidgets || selectedWidgets.length === 0) {
    Toaster.show({
      text: `Please select a widget to cut`,
      variant: Variant.info,
    });
    return;
  }

  yield createWidgetCopy(selectedWidgets);

  const eventName = "WIDGET_CUT_VIA_SHORTCUT"; // cut only supported through a shortcut
  selectedWidgets.forEach((selectedWidget: WidgetProps) => {
    AnalyticsUtil.logEvent(eventName, {
      widgetName: selectedWidget.widgetName,
      widgetType: selectedWidget.type,
    });
  });

  yield put(
    deleteWidgets({
      widgetIds: selectedWidgets.map(
        (selecteWidget: WidgetProps) => selecteWidget.widgetId,
      ),
      disallowUndo: true,
      isShortcut: true,
    }),
  );
}

// The following is computed to be used in the entity explorer
// Every time a widget is selected, we need to expand widget entities
// in the entity explorer so that the selected widget is visible
function* selectedWidgetsAncestrySaga(
  action: ReturnType<typeof selectWidgets>,
): Generator<any, any, any> {
  try {
    const widgetIds = action.payload.widgetIds;

    if (widgetIds.length > 0) {
      const canvasWidgets = yield select(getWidgets);
      const mainWidgetId: string = yield select(getMainContainerWidgetId);

      const entries = widgetIds.map((widgetId) => {
        return {
          widgetId,
          ancestory: buildWidgetIdsExpandList(
            canvasWidgets,
            widgetId,
            mainWidgetId,
          ),
        };
      });

      yield put(setSelectedWidgetsAncestory(entries));
    }
  } catch (error) {
    log.debug("Could not compute selected component's ancestry");
    log.debug(error);
  }
}

const isPropertyPaneShowingWidgetNestedItem = (
  propertyPaneState: PropertyPaneItem,
  selectedWidgetIds: string[],
) => {
  return (
    selectedWidgetIds.length === 1 &&
    propertyPaneState &&
    propertyPaneState.kind === ItemKinds.NESTED_ITEM &&
    extractPartsFromNestedItemId(propertyPaneState.id).widgetId ===
      selectedWidgetIds[0]
  );
};

function* updatePropertyPaneWidgetSaga(
  action: ReturnType<typeof selectWidgets>,
): Generator<any, any, any> {
  const isDragging = yield select(selectIsDragging);
  const isResizing = yield select(selectIsResizing);
  const isPropertyPaneHidden =
    (yield select(getIsPropertyPaneVisible)) === false;

  // If the user is dragging for selection, we don't want to show the property pane
  // until the user has stopped dragging because the property pane will cause a layout shift
  if (isDragging || isResizing) {
    if (isPropertyPaneHidden) {
      yield race({
        drag: take(ReduxActionTypes.SET_WIDGET_DRAGGING),
        size: take(ReduxActionTypes.SET_WIDGET_RESIZING),
      });
      // We need to wait for the next tick to make sure the main canvas has been updated first
      yield delay(1);
    }
  }

  const selectedWidgetIds = yield select(getSelectedWidgetsIds);
  const isDraggingForSelection = yield select(getIsDraggingForSelection);
  const propertyPaneState = yield select(getOpenPropertyPanelItem);

  if (
    !isDraggingForSelection &&
    isPropertyPaneShowingWidgetNestedItem(
      propertyPaneState,
      selectedWidgetIds,
    ) &&
    !action.payload.forceNavigateInPropertyPane
  ) {
    return;
  }

  if (!isDraggingForSelection && selectedWidgetIds.length === 1) {
    const setVisible = !isPropertyPaneHidden; // Make sure we update the property pane item, while keeping the property pane's visible status
    yield put(showWidgetPropertyPane(selectedWidgetIds[0], setVisible));
  }
}

// This redux action replaces the componentDidUpdate function, which means
// that we need both the previous & current states to be available. This is a
// special hook because it can't accept updates, it's more like an event
function* treeWillUpdateSaga(action: ReduxAction<DataTree>) {
  const stateWidgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const { changes, widgetMetaUpdates } = yield call(
    callWidgetHooks,
    stateWidgets,
    action,
  );

  yield call(updateWidgetMetaUpdates, widgetMetaUpdates);

  if (changes && !isEmpty(changes)) {
    yield put(updatePartialLayout(changes, false));
  }
}

function* resetFilePicker() {
  yield call(resetFilePickers);
}

function* saveAllWidgetsSaga() {
  const widgets: ReturnType<typeof getWidgets> = yield select(getWidgets);
  yield put(updateLayout({ ...widgets }));
}

// these should be called for each item in WidgetOperationsWithCompletionListeners
function withCompletionEvent<A>(
  worker: (a: ReduxAction<A>) => Generator<any, any, any>,
) {
  return function* (action: ReduxAction<A>) {
    yield* worker(action);
    yield delay(1);
    yield put({
      type: ReduxActionTypes.WIDGET_OPERATION_COMPLETE,
    });
  };
}

export default function* widgetOperationSagas() {
  yield all([
    takeEvery(ReduxActionTypes.WIDGET_CREATE, withCompletionEvent(createSaga)),
    takeEvery(
      ReduxActionTypes.BATCH_CRUD_WIDGET_OPERATIONS,
      withCompletionEvent(batchCrudWidgetOperationsSaga),
    ),
    takeEvery(addSectionWidget.type, addSectionSaga),
    takeEvery(resizeSectionWidget.type, resizeSectionSaga),
    takeEvery(addSectionWidgetColumn.type, addSectionColumnSaga),
    takeEvery(ReduxActionTypes.WIDGET_UPDATE_CHILDREN, updateChildrenSaga),
    takeLatestByKey(
      resizeSectionWidgetColumns.type,
      resizeSectionColumnsSaga,
      (a) => a.payload.sectionWidgetId,
    ),
    takeEvery(deleteWidgets.type, deleteSaga),
    takeEvery(deleteV1ApiSaga.start.type, deleteV1ApiFromWidgets),
    takeEvery(deleteV2ApiSaga.start.type, deleteV2ApiFromWidgets),
    takeEvery(deleteEntityFromWidgets.type, deleteEntityFromWidgetsSaga),
    takeLatest(ReduxActionTypes.WIDGETS_MOVE, withCompletionEvent(moveSaga)),
    takeLatest(ReduxActionTypes.WIDGET_RESIZE, withCompletionEvent(resizeSaga)),
    takeLatest(
      ReduxActionTypes.WIDGET_REPARENT,
      withCompletionEvent(reparentSaga),
    ),
    takeEvery(
      ReduxActionTypes.SET_WIDGET_DYNAMIC_PROPERTY,
      setWidgetDynamicPropertySaga,
    ),
    takeEvery(resetChildrenMetaProperty.type, resetChildrenMetaSaga),
    takeEvery(deleteWidgetProperty.type, deleteWidgetPropertySaga),
    takeLatest(ReduxActionTypes.UPDATE_CANVAS_SIZE, updateCanvasSize),
    takeEvery(ReduxActionTypes.RESET_WIDGETS, resetFilePicker),
    takeLatest(copyUiBlock.type, copyUiBlockSaga),
    takeLatest(copyWidget.type, copyWidgetSaga),
    takeEvery(pasteWidget.type, pasteWidgetSaga),
    takeEvery(cutWidget.type, cutWidgetSaga),
    takeEvery(
      ReduxActionTypes.WIDGET_ADD_CHILD_IF_NOT_EXISTS,
      addChildIfNotExists,
    ),
    takeEvery(groupSelectedWidgets.type, groupWidgetsSaga),
    takeLatest(selectWidgets.type, selectedWidgetsAncestrySaga),
    takeEvery(ReduxActionTypes.TREE_WILL_UPDATE, treeWillUpdateSaga),
    takeEvery(ReduxActionTypes.SAVE_ALL_WIDGETS, saveAllWidgetsSaga),
    queued(updateWidgetProperties.type, updateWidgetPropertiesSaga),
    queued(selectWidgets.type, updatePropertyPaneWidgetSaga),
  ]);
}
