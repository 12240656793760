import { useSelector } from "react-redux";
import { FullWidthSpace } from "components/ui/Space";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { shortenUsername } from "utils/user";
import { ProgressBarContainer } from "./SurveyProgressBar";
import {
  NextButton,
  ProgressLabelContainer,
  QuestionLabel,
  SecondaryText,
  SurveyContentsWrapper,
} from "./SurveyUtilities";

interface SurveyWelcomeProps {
  onDone: () => void;
}

export const SurveyWelcome = ({ onDone }: SurveyWelcomeProps) => {
  const user = useSelector(getCurrentUser);

  return (
    <SurveyContentsWrapper>
      <ProgressLabelContainer />
      <ProgressBarContainer />
      <FullWidthSpace direction="vertical" size={30}>
        <QuestionLabel>
          {user?.name
            ? `Welcome to Superblocks, ${shortenUsername(user?.name)}!`
            : "Welcome to Superblocks!"}
        </QuestionLabel>
        <SecondaryText>
          Let’s take a minute to personalize your Superblocks experience 🙌
        </SecondaryText>
        <NextButton onClick={onDone}>Let’s go!</NextButton>
      </FullWidthSpace>
    </SurveyContentsWrapper>
  );
};
