import { DatasourceMetadataDto } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";

import { useParams } from "react-router";
import { SyntaxType } from "code-formatting/constants";
import { Props } from "components/app/CodeEditor/types";
import { useFeatureFlag } from "hooks/ui";
import { AiContextMode } from "legacy/constants/EditorPreferencesConstants";
import { getDataTreeAsCode } from "legacy/pages/Editor/Codeium/context_utils";
import { getUserAccessibleDataTree } from "legacy/selectors/dataTreeSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { getDeveloperPreferences } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { selectCachedControlFlowById } from "store/slices/apisV2";
import { Flag } from "store/slices/featureFlags";
import { AppState } from "store/types";
import AiBaseFlow from "./AiAssistantBaseFlow";

import { AiAssistantOptionType, FlowType } from "./constants";
import {
  generateRequestBodyForTask,
  getEditorModeForSyntax,
  getTitleForOption,
} from "./util";

const PRESET_OPTIONS = [
  {
    label: "Optimize performance",
    value: "Optimize performance",
  },
  {
    label: "Add comments",
    value: "Add comments",
  },
  {
    label: "Format code",
    value: "Format code",
  },
];

const AiEditFlow = (props: {
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string,
  ) => void;
  onClose: () => void;
  option: AiAssistantOptionType;
  allCode: string;
  integrationId?: string;
  codeToEdit: string;
  firstLineNumber: number;
  configurationId?: string;
  datasourceMeta?: DatasourceMetadataDto;
}) => {
  const {
    onConfirm,
    onClose,
    option,
    integrationId,
    configurationId,
    allCode,
    codeToEdit,
    firstLineNumber,
    datasourceMeta,
  } = props;

  const editorMode = getEditorModeForSyntax(option.syntax);
  const aiMetadataEnabled = useFeatureFlag(Flag.ENABLE_AI_ASSISTANT_METADATA);

  const params = useParams() as Props["params"];

  const getDataTree = useCallback(
    (state: AppState) => {
      const apiId = params.apiId;
      const actionId = params.actionId;
      const dataTree = getUserAccessibleDataTree(state, apiId, actionId);
      const apiDsl = selectCachedControlFlowById(state, apiId);
      const widgets = getWidgets(state);
      const developerPreferences = getDeveloperPreferences(state);
      return { dataTree, apiDsl, widgets, developerPreferences };
    },
    [params],
  );

  const { dataTree, apiDsl, widgets, developerPreferences } =
    useAppSelector(getDataTree);
  const language = useMemo(() => {
    switch (option.syntax) {
      case SyntaxType.JAVASCRIPT:
      case SyntaxType.BINDING:
      case SyntaxType.JSON:
        return "javascript";
      case SyntaxType.PYTHON:
        return "python";
      default:
        return "sql";
    }
  }, [option.syntax]);

  const aiContextEnabled = useFeatureFlag(Flag.UI_AI_ASSISTANT_CONTEXT_ENABLED);
  const aiContextModeOverride = useFeatureFlag(
    Flag.UI_AI_CODE_COMPLETION_CONTEXT_MODE_OVERRIDE,
  );
  const aiContextMode = useMemo(
    () =>
      !isEmpty(aiContextModeOverride)
        ? (aiContextModeOverride as AiContextMode)
        : developerPreferences.shared.aiContextMode,
    [developerPreferences, aiContextModeOverride],
  );
  const { code: prefix } = useMemo(() => {
    if (!aiContextEnabled) {
      return { code: "" };
    }
    return getDataTreeAsCode(
      dataTree,
      language,
      apiDsl,
      widgets,
      aiContextMode,
    );
  }, [dataTree, apiDsl, widgets, language, aiContextEnabled, aiContextMode]);
  const context = useMemo(() => {
    if (!aiContextEnabled) {
      return "";
    }
    return "Variables you have access to:\n" + prefix + "\n";
  }, [prefix, aiContextEnabled]);

  const getRequestBody = useCallback(
    (userInput: string) => {
      return generateRequestBodyForTask({
        userInput: context + userInput,
        syntax: option.syntax,
        flowType: FlowType.EDIT,
        integrationId,
        configurationId,
        allCode,
        selectedCode: codeToEdit,
        datasourceMeta,
        metadataEnabled: aiMetadataEnabled,
      });
    },
    [
      option.syntax,
      datasourceMeta,
      allCode,
      codeToEdit,
      integrationId,
      configurationId,
      aiMetadataEnabled,
      context,
    ],
  );

  const title = useMemo(() => `${getTitleForOption(option)}`, [option]);
  const placeholder = useMemo(() => {
    switch (option.syntax) {
      case SyntaxType.PYTHON:
      case SyntaxType.JAVASCRIPT:
      case SyntaxType.BINDING:
        return "i.e. add error handling";
      default:
        return "i.e. sort by date";
    }
  }, [option]);

  const diffViewerProps = useMemo(
    () => ({
      originalCode: codeToEdit || allCode,
      firstLineNumber,
    }),
    [codeToEdit, allCode, firstLineNumber],
  );

  return (
    <AiBaseFlow
      onConfirm={onConfirm}
      onClose={onClose}
      option={option}
      getRequestBody={getRequestBody}
      editorMode={editorMode}
      title={title}
      placeholder={placeholder}
      diffViewerProps={diffViewerProps}
      responseViewType="diff"
      presetOptions={PRESET_OPTIONS}
    />
  );
};

export default AiEditFlow;
