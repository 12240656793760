import { get } from "lodash";
import React, { useCallback, useMemo } from "react";
import CodeEditorWithAiAssistant from "components/app/CodeEditor/CodeEditorWithAiAssistant";
import {
  AutocompleteConfiguration,
  AutocompleteFunctionsType,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import ExpandCodeEditorBtn from "components/app/CodeEditor/ExpandCodeEditorBtn";
import {
  EDITOR_LINE_HEIGHT,
  EDITOR_PADDING_Y,
} from "components/app/CodeEditor/editorStyles";
import { ItemKindToEditorTabType } from "legacy/constants/EditorPreferencesConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { useV2PropertyPaneEnabled } from "legacy/pages/Editor/PropertyPane/useV2PropertyPaneEnabled";
import {
  isArrayPropertyPath,
  isCodeProperty,
} from "legacy/utils/BottomPanelTabUtils";
import { getParentPath } from "utils/dottedPaths";
import { type InputControlProps } from "./InputTextControl";
import { StyledDynamicInput } from "./StyledControls";

export function CodeEditorInput(
  props: Partial<InputControlProps> & {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement> | string) => void;
    validationError?: string;
    evaluatedValue?: any;
    expected?: string;
    exampleData?: string;
    docLink?: string;
    additionalDynamicData?: Record<string, Record<string, unknown>>;
    getDynamicProperties?: (
      controlProps: any,
    ) => undefined | Partial<InputControlProps>;
    codeEditorMode: EditorModes;
    showJsExprModeIndicator: boolean;
    itemKind?: ItemKinds;
  },
) {
  const {
    validationError,
    apiErrors,
    expected,
    exampleData,
    docLink,
    value,
    onChange,
    placeholderText,
    dataTreePath,
    isTriggerProperty,
    onFocus,
    onBlur,
    dataTest,
    widgetProperties,
    propertyName,
    getDynamicProperties,
    codeEditorMode,
    showJsExprModeIndicator,
    canExpandEditor,
    itemKind,
  } = props;

  const input = useMemo(
    () => ({
      value: value,
      onChange: onChange,
    }),
    [onChange, value],
  );

  const autocompleteConfiguration = useMemo(
    (): AutocompleteConfiguration => ({
      functions: isTriggerProperty ? AutocompleteFunctionsType.FRONTEND : false,
      global: true,
      bindingsDisabled: codeEditorMode === EditorModes.JAVASCRIPT,
    }),
    [codeEditorMode, isTriggerProperty],
  );

  const handleEditorFocus = useCallback(
    (cm: CodeMirror.Editor) => {
      onFocus?.();
    },
    [onFocus],
  );

  const dynamicProps = useMemo(() => {
    if (typeof getDynamicProperties === "function") {
      const controlProps = get(
        widgetProperties,
        getParentPath(propertyName ?? "") || "",
        widgetProperties,
      );
      return getDynamicProperties(controlProps) ?? {};
    }
  }, [widgetProperties, propertyName, getDynamicProperties]);

  const editorTabType = itemKind && ItemKindToEditorTabType[itemKind];

  const expandBtn =
    widgetProperties &&
    editorTabType &&
    (!isArrayPropertyPath(props.propertyName || "") ||
      isCodeProperty(props.propertyName || "") ||
      canExpandEditor) ? (
      <ExpandCodeEditorBtn
        properties={widgetProperties}
        propertyPath={propertyName}
        itemKind={itemKind}
      />
    ) : null;

  // TODO - handle this properly once we have new design for evaluated popover positioning
  const isV2Pane = useV2PropertyPaneEnabled(ItemKinds.WIDGET);

  const evaluatedValuePopupProps = useMemo(() => {
    if (isV2Pane) {
      return {
        getWrapperElement: props.getPopupWrapperElem,
      };
    } else {
      return undefined;
    }
  }, [isV2Pane, props.getPopupWrapperElem]);

  return (
    <StyledDynamicInput>
      <CodeEditorWithAiAssistant
        data-test={dataTest}
        input={input}
        expected={expected}
        exampleData={exampleData}
        docLink={docLink}
        dataTreeScope={props.itemScope}
        dataTreePath={dataTreePath}
        validationError={validationError}
        apiErrors={apiErrors}
        monospace={true}
        borderLess={false}
        theme={EditorTheme.LIGHT}
        mode={codeEditorMode}
        showJsExprModeIndicator={showJsExprModeIndicator}
        tabBehaviour={TabBehaviour.INDENT}
        size={EditorSize.EXTENDED}
        enableSearch={false}
        minHeight={
          (props.minLines || 1) * EDITOR_LINE_HEIGHT +
          EDITOR_PADDING_Y * 2 +
          "px"
        }
        maxHeight="300px"
        placeholder={placeholderText}
        autocompleteConfiguration={autocompleteConfiguration}
        additionalDynamicData={props.additionalAutoComplete}
        onEditorFocus={handleEditorFocus}
        onEditorBlur={onBlur}
        expandIntoTabBtn={expandBtn}
        isInPropertyPanel={true}
        isExpression
        disabled={props.isDisabled}
        evaluatedValuePopupProps={evaluatedValuePopupProps}
        {...dynamicProps}
      />
    </StyledDynamicInput>
  );
}
