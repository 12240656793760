import {
  SUPERBLOCKS_AUTHORIZATION_HEADER,
  SUPERBLOCKS_SESSION_ID_HEADER,
} from "@superblocksteam/shared";
import { v4 as uuidv4 } from "uuid";
import TokenProvider from "auth/token";
import { ExtendedRequestInit } from "store/utils/client";
import { ComponentEditAction } from "./types";

type ValidFeedbackAction =
  | "accept"
  | "discard"
  | "exit"
  | "redo"
  | "no_changes";

export type FeedbackAction = {
  action: ValidFeedbackAction;
  type: string;
  modified: boolean;
  actionsRequestId: string;
  userFeedback?: string;
  sessionId: string;
};

export type ComponentEditFeedbackAction = FeedbackAction & {
  changeset?: Array<ComponentEditAction>;
};

export type AiSmartTags = {
  mappings: Array<{
    match: string;
    replaces: string;
  }>;
};

class CodeCompletionFeedbackState {
  lastSubmittedId: string = "";
}

const codeCompletionFeedbackState = new CodeCompletionFeedbackState();

export const makeCodeCompletionFeedbackAction = (
  action: ValidFeedbackAction,
  userFeedback?: string,
): FeedbackAction => ({
  action,
  userFeedback,
  type: "code_completion",
  modified: false,
  actionsRequestId: codeCompletionFeedbackState.lastSubmittedId,
  sessionId: uuidv4(),
});

export const sendCodeCompletionSuggest = async ({
  aiServiceURL,
  input,
  output,
}: {
  aiServiceURL: string;
  input: {
    text: string;
    cursorOffset: number;
    language: string;
  };
  output: string;
}): Promise<void> => {
  const authorizationJwt = TokenProvider.getToken();

  const url = aiServiceURL.includes("creator")
    ? aiServiceURL
    : `${aiServiceURL}/creator`;
  const response = await fetch(`${url}/code-completion/suggest`, {
    method: "POST",
    body: JSON.stringify({
      input,
      output,
    }),
    priority: "low",
    headers: {
      "Content-Type": "application/json",
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  } as ExtendedRequestInit);
  const requestId = response?.headers?.get("x-superblocks-feedback-id");

  codeCompletionFeedbackState.lastSubmittedId = requestId ?? "";
};

export const sendCodeCompletionFeedback = async ({
  action,
  userFeedback,
  aiServiceURL,
  sessionId,
}: FeedbackAction & {
  aiServiceURL: string;
}): Promise<void> => {
  const authorizationJwt = TokenProvider.getToken();

  const url = aiServiceURL.includes("creator")
    ? aiServiceURL
    : `${aiServiceURL}/creator`;
  await fetch(`${url}/feedback`, {
    method: "POST",
    body: JSON.stringify({
      feedbacks: [
        {
          id: codeCompletionFeedbackState.lastSubmittedId,
          userFeedback,
          action,
          modified: false,
          component: "code_completion",
          source: "code_completion",
          subsystem: "code_completion",
        },
      ],
    }),
    priority: "low",
    headers: {
      "Content-Type": "application/json",
      [SUPERBLOCKS_SESSION_ID_HEADER]: sessionId,
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  } as ExtendedRequestInit);
};

export const sendAiWidgetEditActionsFeedback = async ({
  actionsRequestId,
  action,
  modified,
  type,
  changeset,
  aiServiceURL,
  userFeedback,
  sessionId,
}: ComponentEditFeedbackAction & {
  aiServiceURL: string;
}): Promise<void> => {
  const authorizationJwt = TokenProvider.getToken();

  const url = aiServiceURL.includes("creator")
    ? aiServiceURL
    : `${aiServiceURL}/creator`;

  await fetch(`${url}/feedback`, {
    method: "POST",
    body: JSON.stringify({
      feedbacks: [
        {
          id: actionsRequestId,
          action,
          modified,
          component: type,
          changeset,
          userFeedback,
          sessionId,
          subsystem: "comedt",
        },
      ],
    }),
    headers: {
      "Content-Type": "application/json",
      [SUPERBLOCKS_SESSION_ID_HEADER]: sessionId,
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  });
};

export const getAiPromptTags = async ({
  prompt,
  entityNames,
  aiServiceURL,
}: {
  prompt: string;
  entityNames: string[];
  aiServiceURL: string;
}): Promise<AiSmartTags> => {
  const requestBody = {
    request: prompt,
    entities: entityNames.map((entity) => ({ name: entity })),
  };

  const authorizationJwt = TokenProvider.getToken();

  const url = aiServiceURL.includes("creator")
    ? aiServiceURL
    : `${aiServiceURL}/creator`;
  const response = await fetch(`${url}/context/extract`, {
    method: "POST",
    body: JSON.stringify(requestBody),
    priority: "low",
    headers: {
      "Content-Type": "application/json",
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  } as ExtendedRequestInit);

  return response.json();
};
