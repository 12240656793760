import {
  PutApplicationMetadataUpdateRequestBody,
  PutApplicationSettingsUpdateRequestBody,
  PutApplicationUpdateRequestBody,
} from "@superblocksteam/schemas";
import {
  ApplicationSignatureTreeSigned,
  NonVersionedApplicationSettings,
  PostApplicationCreateRequestBody,
  PutApplicationSettingsUpdateResponseBody,
  RequestSource,
  ViewMode,
  FullApplicationV2Dto,
  PostApplicationCreateResponseBody,
  PutApplicationUpdateResponseBody,
  GetAllApplicationsResponseBody,
} from "@superblocksteam/shared";
import { deletePageInit } from "legacy/actions/pageActions";
import { isOnEmbedRoute } from "utils/embed/messages";
import { UIEvent } from "utils/event";
import logger from "utils/logger";
import { getUrlWithBranch } from "utils/urlWithBranch";
import Api from "./Api";
import { ApiResponse } from "./ApiResponses";
import { DeletePageResponse } from "./PageApi";

type FetchSingleApplicationResponse = ApiResponse<FullApplicationV2Dto>;

interface CreateApplicationCommitRequest {
  applicationId: string;
  commitMessage: string;
}

type GetAllApplicationResponse = ApiResponse<GetAllApplicationsResponseBody>;

export type UpdateNonVersionedApplicationSettingsPayload = {
  id: string;
  settings: NonVersionedApplicationSettings;
};
export type UpdateNonVersionedApplicationSettingsRequest =
  PutApplicationSettingsUpdateRequestBody & {
    id: string;
  };

export type UpdateApplicationMetadataRequest =
  PutApplicationMetadataUpdateRequestBody & {
    id: string;
  };

class ApplicationApi extends Api {
  static baseURL = "v2/applications/";
  static baseV3URL = "v3/applications/";

  /** will be deprecated, using reduxApi/applications.ts instead */
  static getAllApplication() {
    return Api.get<GetAllApplicationResponse>(ApplicationApi.baseURL);
  }

  static fetchSingleApplication({
    applicationId,
    viewMode,
    branch,
    commitId,
  }: {
    applicationId: string;
    viewMode: ViewMode;
    branch: string | undefined;
    commitId?: string;
  }) {
    logger.event(UIEvent.FETCHED_APPLICATION, { viewMode, applicationId });
    const queryParams: Record<string, string> = {
      viewMode,
      ...(branch ? { branch } : {}),
      ...(commitId ? { commitId } : {}),
    };
    const baseURL = ApplicationApi.baseV3URL;
    return Api.get<FetchSingleApplicationResponse>(
      getUrlWithBranch(baseURL + applicationId, branch),
      queryParams,
    );
  }

  static fetchDeployedApplicationForViewMode(applicationId: string) {
    const viewMode = ViewMode.DEPLOYED;
    logger.event(UIEvent.FETCHED_APPLICATION, {
      viewMode,
      applicationId,
      source: isOnEmbedRoute()
        ? RequestSource.EMBED
        : RequestSource.SUPERBLOCKS,
    });
    // We're passing through Global Accelerator to access the regional caches
    // as this is the only cached endpoint from the UI currently.
    // We must pass the data domain header to ensure the correct upstream server
    // is hit.
    const queryParams: Record<string, string> = { viewMode };
    const baseURL = ApplicationApi.baseV3URL;
    return Api.get<FetchSingleApplicationResponse>(
      `${baseURL}${applicationId}`,
      queryParams,
    );
  }

  static createCommit(request: CreateApplicationCommitRequest) {
    const { commitMessage, applicationId } = request;
    const endpoint = ApplicationApi.baseURL + applicationId + "/commit";
    return Api.post(endpoint, { commitMessage });
  }

  static createApplication(request: PostApplicationCreateRequestBody) {
    return Api.post<ApiResponse<PostApplicationCreateResponseBody>>(
      ApplicationApi.baseURL,
      request,
    );
  }

  static updateApplication(
    request: PutApplicationUpdateRequestBody & {
      id: string;
      branch: string | undefined;
    },
    signature: ApplicationSignatureTreeSigned | undefined,
    superblocksSupportUpdateEnabled: boolean,
  ) {
    const { id, branch, ...rest } = request;
    const queryParams: Record<string, string> | undefined =
      superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : undefined;
    return Api.put<ApiResponse<{ updated: string }>>(
      getUrlWithBranch(ApplicationApi.baseURL + id, branch),
      { ...rest, signature },
      queryParams,
    );
  }

  static updateNonVersionedApplicationSettings(
    request: UpdateNonVersionedApplicationSettingsRequest,
    superblocksSupportUpdateEnabled: boolean,
  ) {
    const { id, ...rest } = request;
    const queryParams: Record<string, string> | undefined =
      superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : undefined;
    return Api.put<ApiResponse<PutApplicationSettingsUpdateResponseBody>>(
      ApplicationApi.baseURL + id + "/settings",
      rest,
      queryParams,
    );
  }

  static updateApplicationMetadata(
    request: UpdateApplicationMetadataRequest,
    superblocksSupportUpdateEnabled: boolean,
  ) {
    const { id, ...rest } = request;
    const queryParams: Record<string, string> | undefined =
      superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : undefined;
    return Api.put<ApiResponse<PutApplicationUpdateResponseBody>>(
      ApplicationApi.baseURL + id + "/metadata",
      rest,
      queryParams,
    );
  }

  /** will be deprecated, using reduxApi/applications */
  static deleteApplication(request: { applicationId: string }) {
    return Api.delete(ApplicationApi.baseURL + request.applicationId);
  }

  static cloneApplication(request: { applicationId: string }) {
    return Api.post<ApiResponse<PostApplicationCreateResponseBody>>(
      ApplicationApi.baseURL + request.applicationId + "/clone",
    );
  }

  static deletePage(
    request: ReturnType<typeof deletePageInit>["payload"],
    signature: ApplicationSignatureTreeSigned | undefined,
    lastSuccessfulWrite: number,
  ): Promise<DeletePageResponse> {
    if (request.branch) {
      return Api.delete(
        ApplicationApi.baseURL +
          request.applicationId +
          "/pages/" +
          request.pageId +
          "/branches/" +
          request.branch,
        undefined,
        { data: { signature, lastSuccessfulWrite } },
      );
    }
    return Api.delete(
      ApplicationApi.baseURL +
        request.applicationId +
        "/pages/" +
        request.pageId,
      undefined,
      { data: { signature, lastSuccessfulWrite } },
    );
  }
}

export default ApplicationApi;
