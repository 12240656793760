import { RouteDef } from "@superblocksteam/shared";
import { get, set, without } from "lodash";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  getDefaultColumnProperties,
  getTableStyles,
} from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { updateDerivedColumnsHook } from "legacy/widgets/TableWidget/TablePropertyPaneConfig";
import { fastClone } from "utils/clone";
import { getColumnIdFromAi } from "./columnUtils";
import { getEventHandlers } from "./eventHandlers";
import {
  AddEventAction,
  RemoveEventAction,
  RemovePropertyAction,
  ResetPropertyAction,
  SetAction,
  ClarkActionFuntions,
} from "./types";

const PROPERTIES_TO_CHANGE = ["columnOrder"];

const initializeProperties = (changes: Record<string, any>, widget: any) => {
  PROPERTIES_TO_CHANGE.forEach((property) => {
    set(
      changes,
      property,
      fastClone(changes[property] ?? widget[property] ?? {}),
    );
  });
};

export const addColumns = ({
  widget,
  changes,
  numColumns,
  columnName,
  changedKeys,
}: {
  widget: any;
  changes: Record<string, any>;
  numColumns: number;
  columnName: string;
  changedKeys: string[];
}) => {
  // update derived and primary columns
  initializeProperties(changes, widget);

  // Any new column will change the column order
  changedKeys.push("columnOrder");

  const columnProps: ColumnProperties = getDefaultColumnProperties(
    columnName,
    numColumns,
    widget.widgetName,
    true,
  );
  const tableStyles = getTableStyles(widget);
  const column = {
    ...columnProps,
    ...tableStyles,
  };
  const path = `primaryColumns.${column.id}`;
  set(changes, path, column);
  changedKeys.push(path);

  // Also any new column manually added by clark will be derived
  const derivedPath = `derivedColumns.${column.id}`;
  set(changes, derivedPath, column);
  changedKeys.push(derivedPath);

  const additionalUpdates = updateDerivedColumnsHook({
    props: widget,
    propertyPath: path,
    propertyValue: column,
  });
  if (additionalUpdates) {
    additionalUpdates.forEach(
      (update: { propertyPath: string; propertyValue: any }) => {
        set(changes, update.propertyPath, update.propertyValue);
      },
    );
  }
};

export const removeColumns = ({
  widget,
  changes,
  columnName,
  changedKeys,
  deleteComponentPropertiesFn,
}: {
  widget: any;
  changes: Record<string, any>;
  columnName: string;
  changedKeys: string[];
  deleteComponentPropertiesFn: ClarkActionFuntions["deleteComponentPropertiesFn"];
}) => {
  initializeProperties(changes, widget);

  const columnToRemove =
    widget.primaryColumns?.[columnName] ?? changes.primaryColumns?.[columnName];

  if (!columnName || typeof columnName !== "string" || !columnToRemove) {
    return;
  }

  const isDerived = columnToRemove.isDerived;

  if (isDerived) {
    deleteComponentPropertiesFn({
      widgetId: widget.widgetId,
      propertyPaths: [
        `derivedColumns.${columnName}`,
        `primaryColumns.${columnName}`,
      ],
    });

    changedKeys.push(`derivedColumns.${columnName}`);

    changedKeys.push("columnOrder");
  } else {
    // If not derived, we just hide the column as we can't delete it
    // changes[`primaryColumns.${columnName}.isVisible`] = false;
    set(changes, `primaryColumns.${columnName}.isVisible`, false);
    changedKeys.push(`primaryColumns.${columnName}.isVisible`);
  }
};

export const updateColumns = ({
  action,
  widget,
  changes,
  changedKeys,
  dataTree,
  routes,
  columnName,
}: {
  action:
    | SetAction
    | AddEventAction
    | RemoveEventAction
    | ResetPropertyAction
    | RemovePropertyAction;
  widget: any;
  changes: Record<string, any>;
  dataTree: DataTree;
  routes: RouteDef[];
  columnName: string;
  changedKeys: string[];
}) => {
  initializeProperties(changes, widget);
  const updates: { path: string; value: any }[] = [];

  const addUpdate = (
    property: string,
    column: string,
    value: any,
    isDerived: boolean,
  ) => {
    updates.push({
      path: `primaryColumns.${column}.${property}`,
      value,
    });
    changedKeys.push(`primaryColumns.${column}.${property}`);

    if (isDerived) {
      updates.push({
        path: `derivedColumns.${column}.${property}`,
        value,
      });
      changedKeys.push(`derivedColumns.${column}.${property}`);
    }
  };

  const columnExists =
    widget.primaryColumns?.[columnName] ??
    get(changes, `primaryColumns.${columnName}`);
  if (!columnName || typeof columnName !== "string" || !columnExists) {
    return;
  }
  const isDerived =
    (widget.derivedColumns?.[columnName] ??
      get(changes, `derivedColumns.${columnName}`)) != null;

  switch (action.action) {
    case "set": {
      addUpdate(action.property, columnName, action.value, isDerived);
      break;
    }
    case "add": {
      const propertyName = `primaryColumns.${columnName}.${action.property}`;
      const prevValue = get(changes, propertyName) || get(widget, propertyName);
      const newValue = getEventHandlers({
        prevValue,
        value: action.value,
        dataTree,
        routes,
      });
      addUpdate(action.property, columnName, newValue, isDerived);
      break;
    }
    case "remove": {
      if ("value" in action) {
        const propertyName = `primaryColumns.${columnName}.${action.property}`;
        const prevValue =
          get(changes, propertyName) || get(widget, propertyName);
        const newValue = Array.isArray(prevValue)
          ? prevValue.filter(
              (item) =>
                item.id !== (action.value as RemoveEventAction["value"]).id,
            )
          : prevValue;
        addUpdate(action.property, columnName, newValue, isDerived);
      } else {
        // remove is a reset
        addUpdate(action.property, columnName, undefined, isDerived);
      }
      break;
    }
    case "reset": {
      addUpdate(action.property, columnName, undefined, isDerived);
      break;
    }
  }

  updates.forEach((update) => {
    changes[update.path] = update.value;
  });
};

export const updateColumnOrder = ({
  orderFromAi,
  existingOrder,
  originalItems,
}: {
  orderFromAi: string[];
  existingOrder: string[];
  originalItems: Record<string, any>;
}) => {
  const mappedFromAi = orderFromAi.map((column) =>
    getColumnIdFromAi(column, originalItems),
  );
  const missingColumns = without(existingOrder, ...mappedFromAi);
  const newColumnOrder = [...mappedFromAi, ...missingColumns];
  return newColumnOrder;
};
