import { PlaceholderInfo } from "@superblocksteam/shared";
import styled from "styled-components";
import HighlightedCode from "legacy/components/editorComponents/HighlightedCode";
import { CopyButton } from "./CopyButton";

interface Props {
  text: string;
  sqlParams?: PlaceholderInfo[];
  dataTest?: string;
  outline?: boolean;
}

const CopyButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Container = styled.div<{ outline: boolean }>`
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.GREY_25};
  padding: 26px 10px;
  border-radius: 4px;
  border: ${(props) => (props.outline ? "1px" : "0")} solid
    ${(props) => props.theme.colors.GREY_50};
  position: relative;
  max-width: 100%;
`;

export const CodeBlock = ({ text, sqlParams, dataTest, outline }: Props) => {
  return (
    <Container
      className="code-block-container"
      data-test={dataTest}
      outline={outline ?? true}
    >
      <HighlightedCode codeText={text} sqlParams={sqlParams} />
      <CopyButtonWrapper>
        <CopyButton dataTest="code-block-copy" textToCopy={text} />
      </CopyButtonWrapper>
    </Container>
  );
};

const SimpleCodeBlockContainer = styled.div`
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.GREY_25};
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.GREY_50};
  position: relative;
  max-width: 100%;
`;

const StyledCodeBlock = styled.pre`
  margin: 0px;
  white-space: pre-wrap;
`;

export const SimpleCodeBlock = ({ text }: { text: string }) => {
  return (
    <SimpleCodeBlockContainer className="code-block-container">
      <StyledCodeBlock>{text}</StyledCodeBlock>
      <CopyButtonWrapper>
        <CopyButton dataTest="code-block-copy" textToCopy={text} />
      </CopyButtonWrapper>
    </SimpleCodeBlockContainer>
  );
};
