import { SurveyToShow } from "@superblocksteam/shared";
import _ from "lodash";
import { useCallback, useState } from "react";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { User } from "legacy/constants/userConstants";
import { useAppDispatch } from "store/helpers";
import { submitSurvey } from "store/slices/user/client";
import { sendErrorUINotification } from "utils/notification";
import { SurveyEnd } from "./SurveyEnd";
import { FormAnswers, FormElement } from "./SurveyForm";
import { SurveyInvite } from "./SurveyInvite";
import { Question, SurveyItem, SurveyToggleSelectOption } from "./SurveyItem";
import { SurveyItemSlide } from "./SurveyUtilities";
import { SurveyWelcome } from "./SurveyWelcome";
import { TransitionSlides } from "./TransitionSlides";

const TRANSITION_DURATION_MS = 400;

interface SurveyProps {
  surveyShown: SurveyToShow;
  formDefinition: FormElement[];
  user: User | undefined;
}

export const Survey = ({ surveyShown, formDefinition, user }: SurveyProps) => {
  const dispatch = useAppDispatch();

  // stages:
  // 0: welcome screen
  // 1: questions/invite
  // 2: ending screen
  const [stage, setStage] = useState(0);

  // currentElementIdx is only relevant when stage === 1
  const [currentElementIdx, setCurrentElementIndex] = useState(0);
  const [formAnswers, setFormAnswers] = useState<FormAnswers>({
    version: "1.0",
    survey: surveyShown,
    answers: [],
  });

  const handleGoBack = useCallback(() => {
    if (currentElementIdx > 0) setCurrentElementIndex(currentElementIdx - 1);
  }, [currentElementIdx]);
  const handleItemCompleted = useCallback(
    (
      selectedOptions: SurveyToggleSelectOption[],
      additionalAnswers: Record<string, string>,
    ) => {
      const newFormAnswers: FormAnswers = {
        ...formAnswers,
        answers: [...formAnswers.answers],
      };
      newFormAnswers.answers[currentElementIdx] = {
        questionId: formDefinition[currentElementIdx].questionId,
        selectedOptions: selectedOptions.map((opt) => opt.value),
        // do not include additionalAnswers if it is an empty object
        ...(_.isEmpty(additionalAnswers) ? {} : { additionalAnswers }),
      };
      setFormAnswers(newFormAnswers);
      if (currentElementIdx < formDefinition.length) {
        setCurrentElementIndex(currentElementIdx + 1);
      }
    },
    [currentElementIdx, formAnswers, formDefinition],
  );

  const handleInvitesDone = useCallback(
    (emails: string[]) => {
      const newFormAnswers: FormAnswers = {
        ...formAnswers,
        answers: [...formAnswers.answers],
      };
      newFormAnswers.answers[currentElementIdx] = {
        questionId: "invite",
        selectedOptions: emails,
      };
      setFormAnswers(newFormAnswers);
      setStage(stage + 1);
    },
    [currentElementIdx, formAnswers, stage],
  );

  const goToNextStage = useCallback(() => {
    setStage(stage + 1);
  }, [stage]);

  const handleSubmit = useCallback(() => {
    if (!user?.email) {
      return;
    }
    submitSurvey(user.email, formAnswers)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        sendErrorUINotification({
          message: `Survey submission failed. ${error?.message}`,
        });
      })
      .finally(() => {
        dispatch({
          type: ReduxActionTypes.SUBMIT_SURVEY,
          payload: { surveyToShow: SurveyToShow.NO_SURVEY },
        });
      });
  }, [dispatch, formAnswers, user]);

  return (
    <SurveyItemSlide>
      <TransitionSlides
        selectedSlideIdx={stage}
        transitionDurationMs={TRANSITION_DURATION_MS}
      >
        <SurveyWelcome onDone={goToNextStage} />
        <SurveyItem
          currentElementIdx={currentElementIdx}
          maxElementIdx={formDefinition.length + 1}
          hasPreviousItem={currentElementIdx > 0}
          transitionDurationMs={TRANSITION_DURATION_MS}
          onGoBack={handleGoBack}
        >
          <TransitionSlides
            selectedSlideIdx={currentElementIdx}
            transitionDurationMs={TRANSITION_DURATION_MS}
          >
            {formDefinition.map((formElement, idx) => (
              <Question
                key={idx}
                formElement={formElement}
                user={user}
                formAnswers={formAnswers}
                onCompleted={handleItemCompleted}
              />
            ))}
            <SurveyInvite
              onDone={handleInvitesDone}
              organizationId={user?.currentOrganizationId ?? ""}
            />
          </TransitionSlides>
        </SurveyItem>
        <SurveyEnd onDone={handleSubmit} runTransitions={stage === 2} />;
      </TransitionSlides>
    </SurveyItemSlide>
  );
};
