import { SldcMessages } from "./types";

export const getCurrentSdlcMessages = (
  sdlcMessagesHistory: Array<SldcMessages>,
) => {
  // Never undefined. Guaranteed by initialState and no way to remove messages
  return sdlcMessagesHistory.at(-1) as SldcMessages;
};

export const getLastSdlcMessage = (
  sdlcMessagesHistory: Array<SldcMessages>,
) => {
  return sdlcMessagesHistory.at(-1)?.at(-1);
};

export const isLastSdlcMessageAVerifiedTest = (
  sdlcMessagesHistory: Array<SldcMessages>,
) => {
  const lastMessage = getLastSdlcMessage(sdlcMessagesHistory);

  return (
    lastMessage?.type === "test" && lastMessage.status === "noIssuesReported"
  );
};
