import {
  ApplicationScope,
  NotificationPosition,
  TriggerStepType,
} from "@superblocksteam/shared";
import { difference, isEqual, uniq } from "lodash";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { getExpandableNotification } from "components/ui/NotificationExpandable";
import { persistBottomDrawerSize } from "components/ui/PropertiesPanelSplitPane";
import {
  pageLoadActionsComplete,
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  updateCachedData,
  updateCurrentRoute,
} from "legacy/actions/pageActions";
import { runEventHandlers, selectWidgets } from "legacy/actions/widgetActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { EventType } from "legacy/constants/ActionConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getPageCachedData } from "legacy/selectors/sagaSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import { getStore } from "store/dynamic";
import {
  markPageLoadV2Apis,
  selectPageLoadApis,
  selectV2ApiNameToIdMap,
  selectV2ApisByIds,
} from "store/slices/apisV2";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { SagaReturnValue } from "store/utils/saga";
import { GeneratorReturnType } from "store/utils/types";
import {
  calculateLoadBatches,
  sanitizeDependencyGraph,
} from "utils/calculateLoadBatches";
import { NOOP } from "utils/function";
import logger from "utils/logger";
import { sendInfoUINotification } from "utils/notification";
import {
  apiProps,
  apiToApiDep,
  getAllApiBindings,
} from "./EvaluationsSagaHelpers";
import { runEventHandlersSaga } from "./TriggerExecutionSaga";
import { setLoadingEntitiesFromApis } from "./WidgetLoadingSaga";

// TODO: This function operates on groups of actions instead of a tree structure
// This could be problematic if some of the pageload actions take a long time
// the user impact would be that the data would be in a loading state for longer

function* sequentialPageLoad(
  apis: ApiDtoWithPb[],
  loadingGraph: Record<string, string[]>,
): Generator<any, void, any> {
  const completedApiNames = new Set<string>();
  try {
    const apiNames = apis.map((api) => getV2ApiName(api));
    // This needs to fire first because as soon as the iframe received the STARTED_PAGE_LOAD_APIS action
    // it will render some components without loading state.
    yield call(setLoadingEntitiesFromApis, apiNames);
    yield put({ type: ReduxActionTypes.STARTED_PAGE_LOAD_APIS });

    const batches = calculateLoadBatches(apiNames, loadingGraph);
    for (const batch of batches) {
      yield all(
        batch.map(function* (name): Generator<any, void, any> {
          const callbackId = addNewPromise(NOOP);
          yield call(
            runEventHandlersSaga,
            runEventHandlers(
              createRunEventHandlersPayload({
                steps: [
                  {
                    id: "0",
                    type: TriggerStepType.RUN_APIS,
                    apiNames: [name],
                  },
                ],
                type: EventType.ON_PAGE_LOAD,
                entityName: "Page",
                currentScope: ApplicationScope.PAGE,
                triggerLabel: `onPageLoad`,
                callbackId,
              }),
            ),
          );
          completedApiNames.add(name);
        }),
      );
    }
  } catch (e) {
    logger.error(e);

    Toaster.show({
      text: "Failed to load onPageLoad actions",
      variant: Variant.danger,
    });
  }
}

export function* optimizedPageLoad(
  apis: ApiDtoWithPb[],
  loadingGraph: Record<string, string[]>,
): Generator<any, void, any> {
  const apiNames = apis.map((api) => getV2ApiName(api));
  const pendingApiNames = new Set<string>(apiNames);

  const dependencyGraph = sanitizeDependencyGraph(apiNames, loadingGraph);
  const remainingDeps = Object.fromEntries(
    apiNames.map((api) => [
      api,
      dependencyGraph[api] ? dependencyGraph[api].length : 0,
    ]),
  );

  const firstApis = apiNames.filter((api) => remainingDeps[api] === 0).sort();

  function* executeApi(apiName: string): Generator<any, void, any> {
    const callbackId = addNewPromise(NOOP);
    yield call(
      runEventHandlersSaga,
      runEventHandlers(
        createRunEventHandlersPayload({
          steps: [
            {
              id: "0",
              type: TriggerStepType.RUN_APIS,
              apiNames: [apiName],
            },
          ],
          type: EventType.ON_PAGE_LOAD,
          entityName: "Page",
          currentScope: ApplicationScope.PAGE,
          triggerLabel: `onPageLoad`,
          callbackId,
        }),
      ),
    );
    yield markApiAsCompletedAndRunDependantApis(apiName);
  }

  function* markApiAsCompletedAndRunDependantApis(
    apiName: string,
  ): Generator<any, void, any> {
    pendingApiNames.delete(apiName);

    const nextApisToRun = [];
    for (const pendingApi of pendingApiNames) {
      if (dependencyGraph[pendingApi].includes(apiName)) {
        remainingDeps[pendingApi]--;

        if (remainingDeps[pendingApi] === 0) {
          nextApisToRun.push(pendingApi);
        }
      }
    }

    yield all(nextApisToRun.map(executeApi));
  }

  try {
    // This needs to fire first because as soon as the iframe received the STARTED_PAGE_LOAD_APIS action
    // it will render some components without loading state.
    yield call(setLoadingEntitiesFromApis, apiNames);
    yield put({ type: ReduxActionTypes.STARTED_PAGE_LOAD_APIS });

    yield all(firstApis.map(executeApi));
  } catch (e) {
    logger.error(e);

    Toaster.show({
      text: "Failed to load onPageLoad actions",
      variant: Variant.danger,
    });
  }
}

/**
 * updates (if necessary) the cached data in the store
 * @returns true if the cached data was updated, false otherwise
 */
export function* cachePageLoadActionsInfo() {
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode && appMode !== APP_MODE.EDIT) {
    return false;
  }
  const pageLoadActionsInfo: SagaReturnValue<typeof getPageLoadActionsInfo> =
    yield call(getPageLoadActionsInfo);

  const pageLoadActions = {
    apiNames: pageLoadActionsInfo.loadApis.map(
      (api) => getV2ApiName(api) ?? "",
    ),
    apiDeps: pageLoadActionsInfo.apiToApiDependencies,
  };
  const cachedData: ReturnType<typeof getPageCachedData> =
    yield select(getPageCachedData);
  if (
    isEqual(
      pageLoadActions.apiNames,
      cachedData?.pageLoadActions?.apiNames ?? [],
    ) &&
    isEqual(pageLoadActions.apiDeps, cachedData?.pageLoadActions?.apiDeps ?? {})
  ) {
    // no changes, no need to update the cached data
    return false;
  }

  const nameToId: ReturnType<typeof selectV2ApiNameToIdMap> = yield select(
    selectV2ApiNameToIdMap,
  );

  // if cached data has an API that doesnt have an ID associated with it, this means one of two things:
  // 1. this is a post-rename evaluation.
  // 2. this is a post API deletion evaluation.
  // do not create  notifications in these cases.
  if (
    !(cachedData?.pageLoadActions?.apiNames ?? []).find(
      (name) => !nameToId[name],
    )
  ) {
    const addedPageLoadApis = difference(
      pageLoadActions.apiNames,
      cachedData?.pageLoadActions?.apiNames ?? [],
    );
    const removedPageLoadApis = difference(
      cachedData?.pageLoadActions?.apiNames ?? [],
      pageLoadActions.apiNames,
    );

    const enableCustomPageLoadActions: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_CUSTOM_PAGE_LOAD_ACTIONS,
    );

    if (enableCustomPageLoadActions) {
      const titleParts = [];
      if (addedPageLoadApis.length > 1) {
        titleParts.push(
          `**${addedPageLoadApis[0]} +${
            addedPageLoadApis.length - 1
          } more,** added to onPageLoad event handler`,
        );
      } else if (addedPageLoadApis.length === 1) {
        titleParts.push(
          `**${addedPageLoadApis[0]}** added to onPageLoad event handler`,
        );
      }

      if (removedPageLoadApis.length > 1) {
        titleParts.push(
          `**${removedPageLoadApis[0]} +${
            removedPageLoadApis.length - 1
          } more,** removed from onPageLoad event handler`,
        );
      } else if (removedPageLoadApis.length === 1) {
        titleParts.push(
          `**${removedPageLoadApis[0]}** removed from onPageLoad event handler`,
        );
      }

      const descriptionParts = [];
      if (addedPageLoadApis.length > 1) {
        descriptionParts.push(
          `**${addedPageLoadApis.join(", ")}** now run on page load`,
        );
      }
      if (removedPageLoadApis.length > 1) {
        descriptionParts.push(
          `**${removedPageLoadApis.join(", ")}** no longer run on page load`,
        );
      }

      if (addedPageLoadApis.length || removedPageLoadApis.length) {
        sendInfoUINotification({
          message: getExpandableNotification({
            mainText: titleParts.join(" "),
            secondaryText: descriptionParts.join("\n"),
            buttonText: "Edit page load events",
            onClick: () => {
              persistBottomDrawerSize("auto");
              getStore().dispatch(selectWidgets([PAGE_WIDGET_ID]));
            },
          }),
          placement: NotificationPosition.top,
        });
      }
    }
  }

  yield put(updateCachedData({ pageLoadActions }));
  return true;
}

function* getPageLoadGraph(): Generator<any, Record<string, string[]>, any> {
  // This map includes _all_ dependencies, but not all dependencies need to be part of the pageLoad
  // We are automatically running any API that is displayed in the UI, unless the user disables this
  // try {
  const loadApiBindings = yield* getAllApiBindings();
  const { allApiProps, loadApiProps } = yield* apiProps();

  const apiToApiDependencies = yield* apiToApiDep(
    loadApiBindings,
    allApiProps,
    loadApiProps,
  );
  const apiToApiDependenciesArr = Object.fromEntries(
    Object.entries(apiToApiDependencies).map(([name, deps]) => [
      name,
      Array.from(deps),
    ]),
  );
  return apiToApiDependenciesArr;
}

function* getPageLoadActionsInfo() {
  const apiToApiDependencies: Record<string, string[]> =
    yield call(getPageLoadGraph);
  // Remove any APIs that are not part of the pageLoad graph (I.e. they have circular dependencies)
  const pageLoadApis: ReturnType<typeof selectPageLoadApis> =
    yield select(selectPageLoadApis);
  const loadApis = pageLoadApis.filter(
    (api) => apiToApiDependencies[getV2ApiName(api) ?? ""],
  );

  return { loadApis, apiToApiDependencies };
}

export function* getPageLoadInfo() {
  let loadApis: ApiDtoWithPb[];
  let apiToApiDependencies: Record<string, string[]>;
  const cachedData: ReturnType<typeof getPageCachedData> =
    yield select(getPageCachedData);

  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode && appMode !== APP_MODE.EDIT && cachedData?.pageLoadActions) {
    // We are not in edit mode, and we have cached data about the page load actions. Use that.
    const apiNameMap: Record<string, string> = yield select(
      selectV2ApiNameToIdMap,
    );
    const apiIds = cachedData.pageLoadActions.apiNames.map(
      (apiName) => apiNameMap[apiName],
    );
    loadApis = yield select((state) =>
      selectV2ApisByIds(state, apiIds).filter(Boolean),
    );
    apiToApiDependencies = cachedData.pageLoadActions.apiDeps;
  } else {
    // We are in edit mode, or we don't have cached data about the page load actions. Extract that data from
    // the static analysis.
    const pageLoadActionsInfo: SagaReturnValue<typeof getPageLoadActionsInfo> =
      yield call(getPageLoadActionsInfo);
    loadApis = pageLoadActionsInfo.loadApis;
    apiToApiDependencies = pageLoadActionsInfo.apiToApiDependencies;
  }
  const pageActionIds = uniq(loadApis.map((api) => getV2ApiId(api)));

  if (pageActionIds.length) {
    // Make sure all apis are in a loading state
    yield put(markPageLoadV2Apis.create(Array.from(pageActionIds)));
  }

  return { loadApis, apiToApiDependencies };
}

export function* executePageLoadActions({
  loadApis,
  apiToApiDependencies,
}: {
  loadApis: ApiDtoWithPb[];
  apiToApiDependencies: Record<string, string[]>;
}) {
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
    { numActions: loadApis.length },
  );

  const isOptimizedPageLoadEnabled: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_OPTIMIZED_PAGE_LOAD,
  );

  if (loadApis.length) {
    // Make sure all apis are in a loading state
    yield race({
      completed: call(
        // TODO: Keep optimized page load when killing the Flag.ENABLE_OPTIMIZED_PAGE_LOAD flag
        isOptimizedPageLoadEnabled ? optimizedPageLoad : sequentialPageLoad,
        loadApis,
        apiToApiDependencies,
      ),
      leftApp: take(stopEvaluation.type),
      leftPage: take(restartEvaluation.type),
      changedPath: take(updateCurrentRoute.type),
    });
  } else {
    // Mark as completed
    yield put({ type: ReduxActionTypes.STARTED_PAGE_LOAD_APIS });
  }

  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
  );
}

function* markAndRunPageLoadActions() {
  const pageLoadInfo: GeneratorReturnType<typeof getPageLoadInfo> =
    yield call(getPageLoadInfo);
  yield call(executePageLoadActions, pageLoadInfo);
  yield put(pageLoadActionsComplete());
}

export default function* pageLoadSagas() {
  yield all([
    takeEvery(
      ReduxActionTypes.MARK_AND_RUN_PAGE_LOAD_APIS,
      markAndRunPageLoadActions,
    ),
  ]);
}
