import {
  Agent,
  AuthConfig,
  AuthId,
  AuthType,
  DatasourceAuthState,
  DatasourceOneTimeState,
  ENVIRONMENT_PRODUCTION,
  getAuthId,
  getAuthIdFromConfig,
  IntegrationAuthType,
  isAuthenticatedDatasourceConfig,
  isDatasourceAuthState,
  isExchangeCodeResponse,
  Organization,
  Profile,
  SupersetIntegrationDto,
  ViewMode,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Search } from "react-router";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { getAppViewMode } from "legacy/selectors/applicationSelectors";
import localStorage from "legacy/utils/localStorage";
import { selectActiveAgents } from "store/slices/agents";
import {
  exchangeCodeOnServer,
  exchangeCodeV3,
  loginDatasourceV3,
} from "store/slices/apisShared/client-auth";
import {
  getConfigFromIntegrationWithProfileId,
  getConfigIdFromIntegrationWithProfileId,
} from "store/slices/datasources/utils";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import logger from "utils/logger";
import { callServer, HttpMethod } from "../../store/utils/client";
import {
  LS_FULL_STATE,
  LS_OAUTH_DATASOURCE_KEY,
  LS_OAUTH_ENV_KEY,
  LS_OAUTH_ERROR_KEY,
  LS_OAUTH_ONE_TIME_CODE,
  LS_OAUTH_REDIRECT_FINISHED,
} from "./OAuthRedirectLoginModal";

// This component is used to handle the OAuth callback from the OAuth provider(for new/existing Superblocks-managed OAuth clients and for new bring your own OAuth clients).
// For bring your own OAuth clients:
// - It will exchange the code for an access token and then close the window. The same can't be done for Superblocks-managed OAuth clients because we don't want to expose the client secret to the browser.
// For Superblocks-managed OAuth clients:
// - It will just close the window and update the local storage with status and error if any.
const OAuthCallback = () => {
  // State
  const location = useLocation();
  const hash = location.hash;
  const fragments = new URLSearchParams(hash.slice(1));

  // Either the token or the access code will be provided depending on the code
  // that was requested.
  const token = fragments.get("access_token");
  const expiresIn = fragments.get("expires_in");
  let expiryTimestamp: number | undefined;
  if (expiresIn) {
    expiryTimestamp = new Date().getTime() + parseInt(expiresIn) * 1000;
  }

  const accessCode = new URLSearchParams(location.search).get("code");
  const paramError = new URLSearchParams(location.search).get("error");
  const authState = getStateFromLocalOrParam(location.search);
  const scope = new URLSearchParams(location.search).get("scope");
  const organization = useSelector(selectOnlyOrganization);
  // TODO DURING REVIEW: Why does this always use production agents?
  const datasourceToAuth = authState.integrationId;
  const environment =
    localStorage.getItem(LS_OAUTH_ENV_KEY) ?? ENVIRONMENT_PRODUCTION;
  const agents: Agent[] = useSelector(
    selectActiveAgents(organization?.agentType, environment),
  );

  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profile = organization?.profiles?.find((p) => p.key === environment);
  const profileId = useMemo(() => {
    return profile?.id ?? "";
  }, [profile?.id]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  // To prevent multiple requests from being sent in case the main useEffect is triggered multiple times.
  const sentRequest = useRef(false);
  const mode = useSelector(getAppViewMode);

  const agentsLoading = useMemo(() => {
    const needsAgents = () => {
      // A helper to determine if we need agent URLs.
      // If control flow is disabled, we make agent requests to a known
      // address. No discovery needed.
      return orgIsOnPremise(organization);
    };

    // Agents are loading if we need them and they're not there.
    return needsAgents() && isEmpty(agents);
  }, [agents, organization]);

  // finish sets all of the variables to communicate to the other tab that it's
  // done.
  const finish = useCallback(
    (error?: string) => {
      setIsLoading(false);
      localStorage.setItem(LS_OAUTH_REDIRECT_FINISHED, "true");
      localStorage.removeItem(LS_FULL_STATE);
      // NOTE that this just closes the window when the window has been
      // automatically opened.  If a user copies the OAuth URL and manually opens
      // it in a new tab, this will not close the window.
      if (localStorage.getItem("sb_debug_stop_auto_close")) {
        return;
      }

      // do not close the window if integration is not using local storage and there was an error
      //since there's no way to propagate that error back to the tab that initiated this auth flow
      if (authState.useLocalStorage || !error) {
        window.close();
      }
    },
    [authState.useLocalStorage],
  );

  const errorCaseAndFinish = useCallback(
    (error: any) => {
      logger.warn(`Login Error: ${error}`);
      setErrorMessage(error);
      localStorage.setItem(
        LS_OAUTH_ERROR_KEY,
        `Authentication failed: ${error}`,
      );
      // Done, there was an error
      finish(error);
    },
    [finish],
  );

  // Clear the error message (from previous attempts perhaps) on mount.
  useEffect(() => {
    localStorage.removeItem(LS_OAUTH_ERROR_KEY);
  }, []);

  // Load the relevant datasource auth config async.
  const [datasource, setDatasource] = useState<
    SupersetIntegrationDto | undefined
  >();
  const dsConfig = useMemo(() => {
    return getDatasourceConfiguration(datasource, profileId, authState);
  }, [datasource, profileId, authState]);

  useEffect(() => {
    const loadDS = async () => {
      const datasource = await callServer<SupersetIntegrationDto>({
        method: HttpMethod.Get,
        url: `v1/integrations/${datasourceToAuth}`,
        query: profile?.key ? { profile: profile.key } : {},
      });
      setDatasource(datasource);
    };
    loadDS();
  }, [datasourceToAuth, organization?.id, profileId, authState, profile?.key]);

  const loginWithTokenAndFinish = useCallback(
    async (dsConfig: DatasourceConfig) => {
      // Login with token.
      const authId = getAuthIdFromConfig(datasourceToAuth, dsConfig);
      await loginDatasourceV3(
        profile && datasourceToAuth // fallback to controller if orch reqs arent met
          ? {
              orchestrator: true,
              integrationId: datasourceToAuth,
              profile,
              token: token ?? undefined,
              expiryTimestamp,
              agents,
              organization,
            }
          : {
              orchestrator: false,
              agents,
              organization,
              body: {
                authType: IntegrationAuthType.OAUTH2_IMPLICIT,
                authId,
                token,
                expiryTimestamp,
              },
            },
      );
      finish();
    },
    [
      agents,
      datasourceToAuth,
      expiryTimestamp,
      finish,
      organization,
      profile,
      token,
    ],
  );

  const loginWithAuthCodeAndFinish = useCallback(
    async (dsConfig: DatasourceConfig) => {
      // Login with access code.
      try {
        if (validateOneTimeCodeIfApplicable(authState, dsConfig.authConfig)) {
          const redirectInitiatedByEmbedUser = authState.externalUser;
          const useServerEndpoint =
            dsConfig.authConfig?.refreshTokenFromServer ||
            redirectInitiatedByEmbedUser;
          const authId = getAuthId(
            dsConfig.authType,
            dsConfig.authConfig,
            dsConfig.integrationId,
            dsConfig.configurationId,
          );
          if (
            authState.useLocalStorage &&
            localStorage.getItem(LS_OAUTH_REDIRECT_FINISHED) === "true"
          ) {
            // Request has already been made.
            return;
          }
          const integrationId = redirectInitiatedByEmbedUser
            ? dsConfig.integrationId
            : undefined;
          const configurationId = redirectInitiatedByEmbedUser
            ? dsConfig.configurationId
            : undefined;
          const exchangeCodeResp = useServerEndpoint
            ? await doExchangeCodeOnServer(
                authId,
                dsConfig.authType,
                dsConfig.authConfig,
                accessCode as string,
                dsConfig.pluginId,
                scope,
                integrationId,
                configurationId,
              )
            : await doExchangeCodeOnAgent(
                dsConfig.integrationId,
                enableProfiles,
                profile,
                accessCode as string,
                agents,
                organization,
                datasource,
                environment,
                dsConfig,
                mode,
              );
          if (
            (isExchangeCodeResponse(exchangeCodeResp) &&
              !exchangeCodeResp.successful) ||
            (!isExchangeCodeResponse(exchangeCodeResp) &&
              !exchangeCodeResp.success)
          ) {
            localStorage.setItem(
              LS_OAUTH_ERROR_KEY,
              `Failed to exchange an authorization code for an access token: ${exchangeCodeResp.error}.`,
            );
          }
        } else {
          localStorage.setItem(
            LS_OAUTH_ERROR_KEY,
            `Failed to exchange an authorization code for an access token. One time code in state parameter did not match.`,
          );
        }
      } catch (err) {
        localStorage.setItem(
          LS_OAUTH_ERROR_KEY,
          `Failed to exchange an authorization code for an access token: ${err}.`,
        );
      }
      finish();
    },
    [
      accessCode,
      agents,
      datasource,
      enableProfiles,
      environment,
      finish,
      mode,
      organization,
      profile,
      scope,
      authState,
    ],
  );

  // THIS IS THE MAIN ENTRY POINT
  useEffect(() => {
    (async () => {
      if (paramError) {
        // Check for error cases that need to be propagated from earlier in the
        // flow.
        return errorCaseAndFinish(paramError);
      }

      if (authState.useLocalStorage && !localStorage.isSupported()) {
        return errorCaseAndFinish(
          `This integration requires local storage to be enabled. Please enable local storage and try again.`,
        );
      }

      // Check that all of the dependencies are loaded. Return will wait for
      // them to load (async).
      if (agentsLoading) {
        logger.info("No agents found.");
        return;
      } else if (!dsConfig) {
        logger.info("Datasource not found");
        return;
      }

      // Attempt to send the request but another request already sent or the redirect has already finished, no need to send another request.
      if (
        sentRequest.current ||
        (authState.useLocalStorage &&
          localStorage.getItem(LS_OAUTH_REDIRECT_FINISHED) === "true")
      ) {
        return;
      }

      sentRequest.current = true;
      if (!isEmpty(token)) {
        await loginWithTokenAndFinish(dsConfig);
      } else if (!isEmpty(accessCode)) {
        await loginWithAuthCodeAndFinish(dsConfig);
      } else {
        errorCaseAndFinish("Expected either an access token or code.");
      }
    })();
  }, [
    token,
    accessCode,
    authState,
    paramError,
    agentsLoading,
    dsConfig,
    errorCaseAndFinish,
    loginWithTokenAndFinish,
    loginWithAuthCodeAndFinish,
  ]);

  // If there is an error to show, we don't close this screen so we prioritize
  // showing that.
  return (
    <>
      {errorMessage ??
        (isLoading
          ? "Logging in..."
          : "Login complete. This window can be closed.")}
    </>
  );
};

type DatasourceConfig = {
  authConfig: AuthConfig;
  authType: AuthType;
  pluginId: string;
  integrationId: string;
  configurationId: string;
  // orchestrator is able to fetch authConfig and authType from server when exchanging code
  configSource: "server" | "client";
};

function getDatasourceConfiguration(
  datasource: SupersetIntegrationDto | undefined,
  profileId: string,
  authState: DatasourceAuthState | DatasourceOneTimeState,
): DatasourceConfig | undefined {
  if (authState) {
    try {
      if (isDatasourceAuthState(authState)) {
        return {
          authConfig: authState.authConfig,
          authType: authState.authType as AuthType,
          pluginId: authState.pluginId,
          integrationId: authState.integrationId,
          configurationId: authState.configurationId,
          configSource: "client",
        };
      }
    } catch (e) {
      // Failed to parse state as JSON. Ignore.
    }
  }
  const existingConfig = getConfigFromIntegrationWithProfileId(
    datasource,
    profileId,
  );
  if (
    datasource &&
    existingConfig &&
    isAuthenticatedDatasourceConfig(existingConfig)
  ) {
    return {
      authConfig: existingConfig.authConfig as AuthConfig,
      authType: existingConfig.authType as AuthType,
      pluginId: datasource.pluginId,
      integrationId: datasource.id,
      configurationId: getConfigIdFromIntegrationWithProfileId(
        datasource,
        profileId,
      ) as string,
      configSource: "server",
    };
  }
  return undefined;
}

async function doExchangeCodeOnServer(
  authId: AuthId,
  authType: AuthType,
  authConfig: AuthConfig,
  accessCode: string,
  pluginId: string,
  scope: string | null,
  integrationId: string | undefined,
  configurationId: string | undefined,
) {
  return await exchangeCodeOnServer({
    authId: authId,
    authType: authType,
    authConfig: authConfig,
    accessCode: accessCode,
    pluginId: pluginId,
    origin: window.location.origin,
    grantedScope: scope,
    integrationId,
    configurationId,
  });
}

async function doExchangeCodeOnAgent(
  integrationId: string,
  enableProfiles: boolean,
  profile: Profile | undefined,
  accessCode: string,
  agents: Agent[],
  organization: Organization,
  datasource: any,
  environment: string,
  dsConfig: DatasourceConfig,
  mode: ViewMode | undefined,
) {
  return await exchangeCodeV3(
    integrationId && enableProfiles && profile && accessCode
      ? {
          orchestrator: true,
          agents,
          organization,
          integrationId:
            dsConfig.configSource === "server" ? datasource?.id : undefined,
          configurationId: dsConfig.configurationId,
          accessCode,
          profile,
          authType: dsConfig.authType,
          authConfig: dsConfig.authConfig,
        }
      : {
          orchestrator: false,
          agents,
          organization,
          body: {
            accessCode,
            datasourceId: datasource?.id,
            environment: environment,
            authType: dsConfig.authType,
            authConfig: dsConfig.authConfig,
          },
          mode,
          ...(enableProfiles ? { profile } : {}),
        },
  );
}

function validateOneTimeCodeIfApplicable(
  state: DatasourceOneTimeState,
  authConfig: AuthConfig,
): boolean {
  if (!authConfig.sendOAuthState) {
    return true;
  }
  if (!state) {
    return false;
  }
  if (!state.useLocalStorage) {
    //TODO: find another way to validate one time code
    return true;
  }
  return state.oneTimeCode === localStorage.getItem(LS_OAUTH_ONE_TIME_CODE);
}

function getStateFromLocalOrParam(
  search: Search,
): DatasourceOneTimeState | DatasourceAuthState {
  const fullStateFromLocalStorage = localStorage.getItem(LS_FULL_STATE);
  const stateValue =
    fullStateFromLocalStorage ?? new URLSearchParams(search).get("state");
  if (stateValue) {
    try {
      const authState: DatasourceOneTimeState = JSON.parse(atob(stateValue));
      return authState;
    } catch {
      logger.warn(`Failed to parse state string`);
    }
  }
  // fallback to local storage
  return {
    oneTimeCode: localStorage.getItem(LS_OAUTH_ONE_TIME_CODE) ?? "",
    useLocalStorage: true,
    integrationId: localStorage.getItem(LS_OAUTH_DATASOURCE_KEY) ?? "",
    externalUser: false,
  };
}

export default OAuthCallback;
