import { ItemKinds } from "./ItemKindConstants";

const ENABLED_KINDS = new Set([
  ItemKinds.WIDGET,
  ItemKinds.TIMER,
  ItemKinds.STATE_VAR,
  ItemKinds.EMBED_PROP,
  ItemKinds.CUSTOM_EVENT,
  ItemKinds.ROUTE,
  ItemKinds.NESTED_ITEM,
  ItemKinds.API_V2,
  ItemKinds.STATE_VAR,
  ItemKinds.AI_EDITS_HISTORY,
  ItemKinds.AI_EDIT,
]);

export function useV2PropertyPaneEnabled(itemKind: ItemKinds) {
  return ENABLED_KINDS.has(itemKind);
}
