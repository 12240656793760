import { getNextEntityName } from "@superblocksteam/shared";
import AddIconButton from "components/ui/IconButtons/AddIconButton";
import {
  KeyValueProperty,
  PropertyType,
} from "legacy/widgets/KeyValueWidget/types";
import { getDottedPathTo, getParentPath } from "utils/dottedPaths";
import BaseControl, { ControlProps } from "./BaseControl";

export const getDefaultKeyValueProperty = (
  existingPropertyIds: string[],
  propertyName?: string,
) => {
  const newPropertyName =
    propertyName ?? getNextEntityName("customKey", existingPropertyIds);
  return {
    id: newPropertyName,
    key: newPropertyName,
    type: PropertyType.TEXT,
    isVisible: true,
    isDerived: true,
    computedValue: "",
  };
};

class AddKeyValuePropertyControl extends BaseControl<ControlProps> {
  render() {
    return (
      <AddIconButton
        onClick={this.addNewProperty}
        data-test="add-keyvalue-property"
        title="Add property"
        disabled={this.props.isDisabled}
      />
    );
  }

  addNewProperty = () => {
    const properties: Record<string, KeyValueProperty> =
      this.props.propertyValue || {};
    const propertyIds = Object.keys(properties);
    const property: KeyValueProperty = getDefaultKeyValueProperty(propertyIds);

    const parentPath = getParentPath(this.props.propertyName);

    this.updateProperties({
      [`${this.props.propertyName}${getDottedPathTo(property.id)}`]: property,
      [`${parentPath ? `${parentPath}.` : ""}propertiesOrder`]: [
        ...(this.props.widgetProperties.propertiesOrder || []),
        property.id,
      ],
    });
  };

  static getControlType() {
    return "ADD_KEYVALUE_PROPERTY";
  }
}

export default AddKeyValuePropertyControl;
