import { WidgetTypes, Dimension } from "@superblocksteam/shared";
import { set } from "lodash";
import { dimensionToGridRows } from "legacy/utils/WidgetPropsUtils";
import { generateReactKey } from "legacy/utils/generators";
import { WidgetProps } from "legacy/widgets";
import { EditMetadata, ClarkActionFuntions } from "./types";

export const generateSectionColumn = ({
  parentWidget,
}: {
  parentWidget: any;
}) => {
  const widgetId = generateReactKey();
  return {
    type: WidgetTypes.CANVAS_WIDGET,
    left: Dimension.gridUnit(0),
    top: Dimension.gridUnit(0),
    height: Dimension.minus(
      dimensionToGridRows(parentWidget.height),
      Dimension.gridUnit(3),
    ).asFirst(), // tabs header takes 3 rows
    width: parentWidget.width,
    widgetId: widgetId,
    parentId: parentWidget.widgetId,
    canExtend: false,
    detachFromLayout: true,
    children: [],
  };
};

export const initializeSectionColumnMetadata = (
  widget: Partial<WidgetProps>,
): EditMetadata => {
  if (!widget.children) {
    return {
      idsToIndices: {},
    };
  }
  const idsToIndices: Record<string, number> = {};
  for (let i = 0; i < widget.children.length; i++) {
    idsToIndices[widget.children[i]] = i;
  }
  return {
    idsToIndices,
  };
};

export const addSectionColumn = ({
  property,
  value,
  changesByWidgetId,
  widget,
  changedKeysByWidgetId,
  addSectionColumnFn,
}: {
  property: string;
  value: any;
  widget: Partial<WidgetProps>;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  addSectionColumnFn: ClarkActionFuntions["addSectionColumnFn"];
}) => {
  if (!widget.widgetId) {
    throw new Error("Widget ID is required");
  }
  const defaultProperties = {};
  if (property) {
    set(defaultProperties, property, value);
  }

  // create the new column widget
  addSectionColumnFn({
    sectionWidgetId: widget.widgetId,
    columnProperties: defaultProperties,
  });
  if (!changedKeysByWidgetId[widget.widgetId]) {
    changedKeysByWidgetId[widget.widgetId] = [];
  }
  changedKeysByWidgetId[widget.widgetId].push("children");
};

export const updateSectionColumn = ({
  sectionColumnId,
  property,
  value,
  changesByWidgetId,
  changedKeysByWidgetId,
  widget,
}: {
  sectionColumnId: string;
  property: string;
  value: any;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  widget: Partial<WidgetProps>;
}) => {
  const widgetId = widget.widgetId;
  if (!widgetId) {
    throw new Error("Widget ID is required");
  }

  // update the child widget
  changesByWidgetId[sectionColumnId] = {
    ...changesByWidgetId[sectionColumnId],
    [property]: value,
  };
  if (!changedKeysByWidgetId[sectionColumnId]) {
    changedKeysByWidgetId[sectionColumnId] = [];
  }

  changedKeysByWidgetId[sectionColumnId].push(property);
};

export const removeSectionColumn = ({
  sectionColumnId,
  changesByWidgetId,
  changedKeysByWidgetId,
  widget,
  deleteComponentFn,
}: {
  sectionColumnId: string;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  widget: Partial<WidgetProps>;
  deleteComponentFn: ClarkActionFuntions["deleteComponentFn"];
}) => {
  if (!widget.widgetId) {
    throw new Error("Widget ID is required");
  }
  deleteComponentFn({ widgetId: sectionColumnId });
  changesByWidgetId[sectionColumnId] = null;
  const childrenWithoutTab = widget.children?.filter(
    (child) => child !== sectionColumnId,
  );

  set(changesByWidgetId, `${widget.widgetId}.children`, childrenWithoutTab);
  if (!changedKeysByWidgetId[widget.widgetId]) {
    changedKeysByWidgetId[widget.widgetId] = [];
  }
  changedKeysByWidgetId[widget.widgetId].push("children");
};
