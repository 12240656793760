import { GridDefaults } from "legacy/constants/WidgetConstants";
import {
  DATA_TREE_KEYWORDS,
  JAVASCRIPT_GLOBALS,
  JAVASCRIPT_KEYWORDS,
  PYTHON_GLOBALS,
  PYTHON_KEYWORDS,
} from "legacy/constants/WidgetValidation";
import { getNormalizedName } from "pages/Editors/ApiEditor/utils";
import { GLOBAL_FUNCTIONS } from "../../autocomplete/dataTreeTypeDefCreator";
import { MouseCoord } from "../../hooks/ui/mouseCoord";

export const getScrollByPixels = function (
  elem: Element | undefined,
  scrollParent: Element,
  mouseCoord: MouseCoord,
  canvasScaleFactor: number,
): number {
  const scrollParentBounds = scrollParent.getBoundingClientRect();
  const gridHeight =
    GridDefaults.CANVAS_EXTENSION_OFFSET *
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT *
    canvasScaleFactor;
  // set the threshold larger to start auto scrolling earlier
  const SCROLL_THESHOLD = 2 * GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
  if ((!mouseCoord.x || !mouseCoord.y) && elem) {
    const bounding = elem.getBoundingClientRect();
    const scrollParentBounds = scrollParent.getBoundingClientRect();

    if (
      bounding.top > 0 &&
      bounding.top - scrollParentBounds.top < SCROLL_THESHOLD
    )
      return -gridHeight;
    if (scrollParentBounds.bottom - bounding.bottom < SCROLL_THESHOLD)
      return gridHeight;
    return 0;
  }
  if (mouseCoord.y && mouseCoord.y < scrollParentBounds.top + SCROLL_THESHOLD) {
    return -gridHeight - scrollParentBounds.top + mouseCoord.y;
  }
  if (
    mouseCoord.y &&
    mouseCoord.y > scrollParentBounds.bottom - SCROLL_THESHOLD
  ) {
    return gridHeight + mouseCoord.y - scrollParentBounds.bottom;
  }
  return 0;
};

export const scrollElementIntoParentCanvasView = (
  el: Element | null,
  parent: Element | null,
  mouseCoord: MouseCoord,
  canvasScaleFactor: number,
) => {
  if (el) {
    const scrollParent = parent;
    if (scrollParent) {
      const scrollBy: number = getScrollByPixels(
        el,
        scrollParent,
        mouseCoord,
        canvasScaleFactor,
      );
      if (scrollBy < 0 && scrollParent.scrollTop > 0) {
        scrollParent.scrollBy({ top: scrollBy, behavior: "smooth" });
      }
      if (scrollBy > 0) {
        scrollParent.scrollBy({ top: scrollBy, behavior: "smooth" });
      }
    }
  }
};

export const removeSpecialChars = (
  value: string,
  options?: {
    limit?: number;
    allowSpaces?: boolean;
  },
) => {
  return getNormalizedName(value, options?.allowSpaces).slice(
    0,
    options?.limit || 30,
  );
};

// Function to convert CamelCase to dash-case
export const toDashedId = (str: string) =>
  str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();

const NAME_REGEX = /^[a-zA-Z_][0-9a-zA-Z_]*$/;

/**
 * checks if the name is conflicting with
 * 1. API names,
 * 2. Queries name
 * 3. JavaScript reserved names
 * 4. Few internal function names that are in the evaluation tree
 *
 * return false if name does not conflicts with anything from the above list
 *
 * @param name
 * @param invalidNames
 */
export const validateName = (
  name: string | undefined,
  invalidNames: string[],
  skipKeywordCheck?: boolean,
  checkCaseInsensitive = false,
) => {
  if (!name || !name.length) {
    return "Name can't be empty";
  }

  if (!NAME_REGEX.test(name)) {
    return "Name should only contain letters, numbers and underscores and shouldn't start with a number";
  }

  if (invalidNames.includes(name)) {
    return "Name is already taken";
  }

  // check case insensitively
  if (
    checkCaseInsensitive &&
    invalidNames
      .map((value) => value.toLowerCase())
      .includes(name.toLowerCase())
  ) {
    return "Name is already taken. Name must be unique regardless of case.";
  }

  if (skipKeywordCheck) {
    return false;
  }

  if (JAVASCRIPT_KEYWORDS.includes(name)) {
    return "Name can't be a JavaScript keyword";
  }

  if (JAVASCRIPT_GLOBALS.includes(name)) {
    return "Name can't be a JavaScript global name";
  }

  if (PYTHON_KEYWORDS.includes(name)) {
    return "Name can't be a Python keyword";
  }

  if (PYTHON_GLOBALS.includes(name)) {
    return "Name can't be a Python global name";
  }

  if (DATA_TREE_KEYWORDS.includes(name)) {
    return "Name can't be a Superblocks data tree keyword";
  }

  if (name in GLOBAL_FUNCTIONS) {
    return "Name can't be a Superblocks global function";
  }

  return false;
};

export const toSanitizedDomId = (str: string) =>
  str.replace(/^[^a-z]+|[^\w]+/g, "");

/**
 * Type helper that more accurately narrows types when filtering for nullable values.
 */
export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}
