import { WidgetTypes, Dimension } from "@superblocksteam/shared";
import { set } from "lodash";
import { dimensionToGridRows } from "legacy/utils/WidgetPropsUtils";
import { WidgetProps } from "legacy/widgets";
import { generateNewTab } from "legacy/widgets/TabsWidget/utils";
import { EditMetadata, ClarkActionFuntions } from "./types";

export const generateTab = ({ widget }: { widget: any }) => {
  const newTab = generateNewTab(widget.tabs);

  // Create the new tab child widget
  const newChildWidget = {
    type: WidgetTypes.CANVAS_WIDGET,
    left: Dimension.gridUnit(0),
    top: Dimension.gridUnit(0),
    height: Dimension.minus(
      dimensionToGridRows(widget.height),
      Dimension.gridUnit(3),
    ).asFirst(), // tabs header takes 3 rows
    width: widget.width,
    widgetId: newTab.widgetId,
    parentId: widget.widgetId,
    tabId: newTab.id,
    tabName: newTab.label,
    canExtend: false,
    detachFromLayout: true,
    children: [],
  };

  return {
    tab: newTab,
    childWidget: newChildWidget,
  };
};

export const initializeTabMetadata = (
  widget: Partial<WidgetProps>,
): EditMetadata => {
  if (!widget.children) {
    return {
      idsToIndices: {},
    };
  }
  const idsToIndices: Record<string, number> = {};
  for (let i = 0; i < widget.children.length; i++) {
    idsToIndices[widget.children[i]] = i;
  }
  return {
    idsToIndices,
  };
};

export const addTab = ({
  property,
  value,
  changesByWidgetId,
  widget,
  changedKeysByWidgetId,
  createComponentFn,
}: {
  property: string;
  value: any;
  widget: Partial<WidgetProps>;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  createComponentFn: ClarkActionFuntions["createComponentFn"];
}) => {
  if (!widget.widgetId) {
    throw new Error("Widget ID is required");
  }
  const { tab: newTab, childWidget: newChildWidget } = generateTab({
    widget: {
      ...widget,
      tabs: (widget as any).tabs,
      children: widget.children,
    },
  });
  const index = widget.children?.length ?? 0;

  // apply the changes to the new tab and/or child widget
  if (property === "label") {
    newTab.label = value;
    newChildWidget.tabName = value;
    if (!changedKeysByWidgetId[widget.widgetId]) {
      changedKeysByWidgetId[widget.widgetId] = [];
    }
    changedKeysByWidgetId[widget.widgetId].push(`tabs[${index}].label`);
  } else if (property !== "id") {
    set(newChildWidget, property, value);
    if (!changedKeysByWidgetId[newChildWidget.widgetId]) {
      changedKeysByWidgetId[newChildWidget.widgetId] = [];
    }
    changedKeysByWidgetId[newChildWidget.widgetId].push(property);
  }

  // update changesByWidgetId and changedKeysByWidgetId
  changesByWidgetId[newChildWidget.widgetId] = newChildWidget;
  set(changesByWidgetId, `${widget.widgetId}.tabs[${index}]`, newTab);
  set(
    changesByWidgetId,
    `${widget.widgetId}.children[${index}]`,
    newTab.widgetId,
  );
  if (!changedKeysByWidgetId[widget.widgetId]) {
    changedKeysByWidgetId[widget.widgetId] = [];
  }
  changedKeysByWidgetId[widget.widgetId].push(
    `tabs[${index}]`,
    `children[${index}]`,
  );
  // create the new tab widget
  createComponentFn({
    parentWidgetId: widget.widgetId,
    widgetType: WidgetTypes.CANVAS_WIDGET,
    newWidgetId: newChildWidget.widgetId,
    initialProps: newChildWidget,
  });

  return newTab.widgetId;
};

export const TAB_CHILD_COMPONENT_PREFIX = "TAB_CHILD_COMPONENT[";
export const updateTab = ({
  tabId,
  property,
  value,
  changesByWidgetId,
  changedKeysByWidgetId,
  widget,
  index,
}: {
  tabId: string;
  property: string;
  value: any;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  widget: Partial<WidgetProps>;
  index?: number;
}) => {
  const tabIndex =
    index ?? widget.children?.findIndex((child) => child === tabId);
  const widgetId = widget.widgetId;
  if (!widgetId) {
    throw new Error("Widget ID is required");
  }
  // everything except label is actually set on the child widget
  if (property === "label") {
    set(changesByWidgetId, `${widgetId}.tabs[${tabIndex}].label`, value);
    if (!changedKeysByWidgetId[widgetId]) {
      changedKeysByWidgetId[widgetId] = [];
    }
    changedKeysByWidgetId[widgetId].push(`tabs[${tabIndex}].label`);
  } else if (tabId) {
    // update the child widget
    changesByWidgetId[tabId] = {
      ...changesByWidgetId[tabId],
      [property]: value,
    };
    if (!changedKeysByWidgetId[tabId]) {
      changedKeysByWidgetId[tabId] = [];
    }

    changedKeysByWidgetId[tabId].push(property);
  }
};

export const removeTab = ({
  tabId,
  changesByWidgetId,
  changedKeysByWidgetId,
  widget,
  deleteComponentFn,
}: {
  tabId: string;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeysByWidgetId: Record<string, string[]>;
  widget: Partial<WidgetProps>;
  deleteComponentFn: ClarkActionFuntions["deleteComponentFn"];
}) => {
  if (!widget.widgetId) {
    throw new Error("Widget ID is required");
  }
  deleteComponentFn({ widgetId: tabId });
  changesByWidgetId[tabId] = null;
  const childrenWithoutTab = widget.children?.filter(
    (child) => child !== tabId,
  );
  const tabsWithoutTab = (widget as any).tabs?.filter(
    (tab: { widgetId: string }) => tab.widgetId !== tabId,
  );
  set(changesByWidgetId, `${widget.widgetId}.children`, childrenWithoutTab);
  set(changesByWidgetId, `${widget.widgetId}.tabs`, tabsWithoutTab);
  if (!changedKeysByWidgetId[widget.widgetId]) {
    changedKeysByWidgetId[widget.widgetId] = [];
  }
  changedKeysByWidgetId[widget.widgetId].push("children", "tabs");
};
