import { call, put, select } from "redux-saga/effects";
import { worker } from "legacy/sagas/evaluationLoader";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { EVAL_WORKER_ACTIONS } from "legacy/utils/DynamicBindingUtils";
import apiDSLReplayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { selectAllV2Apis } from "store/slices/apisV2/selectors";
import {
  setUndoStackIndexAtRequestStart,
  setStateAtRequestStart,
} from "../slice";
import type { WidgetMap } from "legacy/widgets";

export function* doSetupBeforeClarkRequestSaga(action: {
  payload: { isRedo?: boolean };
}) {
  const isRedo = action.payload.isRedo ?? false;

  // Right now we only need to do this for the first request, not redo requests
  if (isRedo) {
    return;
  }

  const widgetsUndoStackLength: number = yield call(
    worker.request,
    EVAL_WORKER_ACTIONS.GET_UNDO_STACK_LENGTH,
    {},
  );
  const widgetsAtRequestStart: WidgetMap = yield select(getWidgets);

  const apisUndoStackLengths = Object.keys(
    apiDSLReplayManager.replayManagersByApiId,
  ).reduce(
    (acc, apiId) => {
      const replayManager =
        apiDSLReplayManager.replayManagersByApiId.get(apiId);
      if (replayManager) {
        acc[apiId] = replayManager.getUndoStackLength();
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  // undo stacks may be undefined for a given api, so loop through all apis and set to 0 if undefined
  const currentAPIs: ReturnType<typeof selectAllV2Apis> =
    yield select(selectAllV2Apis);

  Object.keys(currentAPIs).forEach((apiId) => {
    if (apisUndoStackLengths[apiId] === undefined) {
      apisUndoStackLengths[apiId] = 0;
    }
  });

  yield put(
    setUndoStackIndexAtRequestStart({
      widgetsUndoStackLength,
      apisUndoStackLengths,
    }),
  );

  // TODO: Ai team: We can likely just store the widgets and apis that we operating on
  // and not the entire state for widgets and apis
  yield put(
    setStateAtRequestStart({
      widgetsAtRequestStart: widgetsAtRequestStart,
      apisAtRequestStart: currentAPIs,
    }),
  );
}
