import { takeEvery } from "redux-saga/effects";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import replayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { resolveById } from "store/utils/resolveIdSingleton";
import { callSagas } from "store/utils/saga";
import { fastClone } from "utils/clone";
import logger from "utils/logger";
import { updateV2ApiSaga } from "./updateV2Api";
import type * as BackendTypes from "../backend-types";

interface ApiUndoRedoPayload {
  operation: "UNDO" | "REDO";
  apiId: string;
  callbackId?: string;

  // : (blockName: string) => void; // todo dont pass callback once https://github.com/superblocksteam/superblocks/pull/3930 is merged
}

interface ApiUndoRedoMultiplePayload {
  apiId: string;
  times: number;
  operation: "UNDO" | "REDO";
}

function* undoRedoSaga(action: ReduxAction<ApiUndoRedoPayload>) {
  const { apiId, operation } = action.payload;
  try {
    const response = replayManager.replay(apiId, operation);
    if (!response) return;

    const { logs, replayDSL, replay } = response;
    logs && logs.forEach((evalLog: any) => logger.debug(evalLog));
    yield callSagas([
      updateV2ApiSaga.apply({
        apiPb: fastClone(replayDSL) as BackendTypes.Api,
      }),
    ]);
    if (replay.updatedBlockName) {
      if (action.payload.callbackId) {
        resolveById(action.payload.callbackId, replay.updatedBlockName);
      }
    }
  } catch (e) {
    logger.error(e);
  }
}

function* undoRedoMultipleTimesSaga(
  action: ReduxAction<ApiUndoRedoMultiplePayload>,
) {
  const { apiId, times, operation } = action.payload;
  try {
    let response: ReturnType<typeof replayManager.replay>;
    for (let i = 0; i < times; i++) {
      // make all of the undos before saving
      const nextResponse = replayManager.replay(apiId, operation);
      if (!nextResponse) {
        // break and just use the last response
        break;
      }
      response = nextResponse;
    }
    if (response) {
      yield callSagas([
        updateV2ApiSaga.apply({
          apiPb: fastClone(response.replayDSL) as BackendTypes.Api,
        }),
      ]);
    }
  } catch (e) {
    logger.error(e);
  }
}

export function* apiUndoRedoListenerSaga() {
  yield takeEvery(ReduxActionTypes.API_UNDO_REDO_OPERATION, undoRedoSaga);
  yield takeEvery(
    ReduxActionTypes.API_UNDO_REDO_OPERATION_MULTIPLE,
    undoRedoMultipleTimesSaga,
  );
}
