import { set } from "lodash";
import { createMenuItem } from "legacy/components/propertyControls/AddMenuItemControl";
import { generateReactKey } from "legacy/utils/generators";
import { WidgetProps } from "legacy/widgets";
import { ManualMenuItem, MenuItemType } from "legacy/widgets/MenuWidget/types";
import { EditMetadata } from "./types";

export const getMenuItemId = (menuItem: ManualMenuItem) => {
  if (menuItem.id) {
    return menuItem.id;
  }
  if (menuItem.type === MenuItemType.Divider) {
    return "_divider";
  }
  return `${menuItem.type}-${menuItem.label}`;
};

export const initializeMenuMetadata = (
  widget: Partial<WidgetProps>,
): EditMetadata => {
  const menuWidget = widget as any;
  if (!menuWidget.manualChildren) {
    return {
      idsToIndices: {},
    };
  }
  const idsToIndices: Record<string, number> = {};
  for (let i = 0; i < menuWidget.manualChildren.length; i++) {
    const id = getMenuItemId(menuWidget.manualChildren[i]);
    idsToIndices[id] = i;
  }
  return {
    idsToIndices,
  };
};

export const addMenuItem = ({
  property,
  value,
  changesByWidgetId,
  changedKeys,
  widget,
}: {
  property: string;
  value: any;
  widget: Partial<WidgetProps>;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  changedKeys: Record<string, string[]>;
}) => {
  if (!widget.widgetId) {
    throw new Error("Widget ID is required");
  }
  const widgetId = widget.widgetId;
  const index = (widget as any).manualChildren?.length ?? 0;
  if (!changedKeys[widget.widgetId]) {
    changedKeys[widget.widgetId] = [`manualChildren[${index}]`];
  } else {
    changedKeys[widgetId].push(`manualChildren[${index}]`);
    changedKeys[widgetId].push(`manualChildren[${index}].${property}`);
  }
  const newMenuItemId = generateReactKey();
  set(changesByWidgetId, `${widgetId}.manualChildren[${index}]`, {
    ...createMenuItem(),
    id: newMenuItemId,
    [property]: value,
  });
  return newMenuItemId;
};

export const updateMenuItem = ({
  menuItemId,
  property,
  value,
  widget,
  changedKeys,
  changesByWidgetId,
}: {
  menuItemId: string;
  property: string;
  value: any;
  widget: Partial<WidgetProps>;
  changedKeys: Record<string, string[]>;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
}) => {
  const widgetId = widget.widgetId;
  if (!widgetId) {
    throw new Error("Widget ID is required");
  }
  const menuItemIndex = (widget as any).manualChildren?.findIndex(
    (child: any) => getMenuItemId(child) === menuItemId,
  );
  if (!changedKeys[widgetId]) {
    changedKeys[widgetId] = [`manualChildren[${menuItemIndex}].${property}`];
  } else {
    changedKeys[widgetId].push(`manualChildren[${menuItemIndex}].${property}`);
  }
  set(
    changesByWidgetId,
    `${widgetId}.manualChildren[${menuItemIndex}].${property}`,
    value,
  );
};

export const removeMenuItem = ({
  menuItemId,
  changedKeys,
  widget,
  changesByWidgetId,
}: {
  menuItemId: string;
  changedKeys: Record<string, string[]>;
  changesByWidgetId: Record<string, Record<string, unknown> | null>;
  widget: Partial<WidgetProps>;
}) => {
  const widgetId = widget.widgetId;
  if (!widgetId) {
    throw new Error("Widget ID is required");
  }
  if (!changedKeys[widgetId]) {
    changedKeys[widgetId] = [];
  }
  changedKeys[widgetId].push(`manualChildren`);
  const childrenWithoutMenuITem = (widget as any).manualChildren?.filter(
    (child: any) => getMenuItemId(child) !== menuItemId,
  );
  set(changesByWidgetId, `${widgetId}.manualChildren`, childrenWithoutMenuITem);
};
