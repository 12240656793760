import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension, PerSideBorder } from "@superblocksteam/shared";
import { debounce } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as BorderLeft } from "assets/icons/common/border-left.svg";
import { ColorPicker, PresetOption } from "components/ui/ColorPicker";
import {
  NEUTRAL_COLOR_KEYS,
  THEME_COLOR_KEYS,
  getColorOptions,
  transparentOption,
} from "legacy/components/propertyControls/ColorPickerControl";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { NO_BORDER_OBJECT } from "legacy/themes/constants";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { styleAsClass } from "styles/styleAsClass";
import { AdvancedModeToggle } from "./AdvancedModeToggle";
import { AdvancedSizeInputs } from "./AdvancedSizeInputs";
import { PixelInput } from "./PixelInput";
import {
  AdvancedEditorRow,
  AdvancedEditorValue,
  AdvancedEditorWrapper,
} from "./Shared";
import type { AppState } from "store/types";

export const createPerSideBorder = ({
  color,
  width,
}: {
  color: string;
  width: number;
}): PerSideBorder => {
  const borderSide = {
    color,
    width: Dimension.px(width),
  };

  return {
    top: borderSide,
    right: borderSide,
    bottom: borderSide,
    left: borderSide,
  };
};

const colorPickerStyle = styleAsClass`
    width: 32px;
    height: 32px;
    > div > div {
        height: 32px;
        width: 32px;
        padding: 7px !important;
    }
`;

export const setBorderColor = (
  perSideBorder: PerSideBorder,
  color?: string,
) => {
  return {
    ...perSideBorder,
    left: { ...perSideBorder.left, color },
    right: { ...perSideBorder.right, color },
    top: { ...perSideBorder.top, color },
    bottom: { ...perSideBorder.bottom, color },
  };
};

const getHasDifferentBorderWidths = (propertyValue?: PerSideBorder) => {
  let hasDifferentBorderWidths = false;
  const expectedWidth = propertyValue?.left?.width?.value;
  for (const side of ["left", "right", "top", "bottom"]) {
    if (
      propertyValue?.[side as keyof PerSideBorder]?.width?.value !==
      expectedWidth
    ) {
      hasDifferentBorderWidths = true;
      break;
    }
  }
  return hasDifferentBorderWidths;
};

type Props = {
  overrideDefault?: PerSideBorder;
  propertyValue?: PerSideBorder;
  onPropertyChange?: (propertyValue: PerSideBorder) => void;
  onBlur?: () => void;
  onDropdownVisibleChange?: (visible: boolean) => void;
  isDisabled?: boolean;
  loadingStates?: Record<string, boolean>;
} & ReturnType<typeof mapStateToProps>;

class BorderControl extends Component<
  Props,
  {
    defaultValue: PerSideBorder;
    borderWidthInputValues: { [key in keyof PerSideBorder]: number | null };
    useAdvancedMode: boolean;
    presets: Array<{ title: string; options: PresetOption[] }>;
  }
> {
  isAnyInputFocused = false;
  constructor(props: Props) {
    super(props);
    this.state = this.getStateFromProps();

    this.updateOneBorderWidth = this.updateOneBorderWidth.bind(this);
    this.updateBorderWidths = this.updateBorderWidths.bind(this);
    this.updateBorderColor = this.updateBorderColor.bind(this);
    this.handleToggleAdvancedMode = this.handleToggleAdvancedMode.bind(this);
  }

  getStateFromProps = () => {
    // we need to track that "undefined" is the same as "default"
    const defaultBorderValue = this.props.overrideDefault ?? NO_BORDER_OBJECT;
    // if any of the border widths differ, then set useAdvancedMode to true
    const hasDifferentBorderWidths = getHasDifferentBorderWidths(
      this.props.propertyValue,
    );
    const colors = this.props.generatedTheme.colors;
    const presets = [
      {
        title: "Theme Colors",
        options: getColorOptions(THEME_COLOR_KEYS, colors),
      },
      {
        title: "Neutral Colors",
        options: [
          ...getColorOptions(NEUTRAL_COLOR_KEYS, colors),
          transparentOption,
        ],
      },
    ];

    const borderWidthInputValues = {
      top:
        this.props.propertyValue?.top?.width?.value ??
        defaultBorderValue?.top?.width?.value ??
        null,
      right:
        this.props.propertyValue?.right?.width?.value ??
        defaultBorderValue?.right?.width?.value ??
        null,
      bottom:
        this.props.propertyValue?.bottom?.width?.value ??
        defaultBorderValue?.bottom?.width?.value ??
        null,
      left:
        this.props.propertyValue?.left?.width?.value ??
        defaultBorderValue?.left?.width?.value ??
        null,
    };

    return {
      presets,
      defaultValue: defaultBorderValue,
      useAdvancedMode: hasDifferentBorderWidths,
      borderWidthInputValues,
    };
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.overrideDefault !== prevProps.overrideDefault ||
      !equal(this.props.propertyValue, prevProps.propertyValue) ||
      prevProps.generatedTheme !== this.props.generatedTheme
    ) {
      const newState = this.getStateFromProps();

      // Blur helps determine what we do with advanced mode, so the UI isnt jarring for the user
      this.setState({
        ...newState,
        useAdvancedMode: this.isAnyInputFocused
          ? this.state.useAdvancedMode
          : newState.useAdvancedMode,
      });
    }
  }

  updateProperty = (value: any) => {
    this.props.onPropertyChange?.(value);
  };

  updatePropertyDebounced = debounce(this.updateProperty, 500);

  componentWillUnmount() {
    this.updatePropertyDebounced.flush();
  }

  onBlur = () => {
    this.props.onBlur?.();
    this.onEnter();
    this.onInputBlur();
  };

  onInputBlur = () => {
    this.isAnyInputFocused = false;
  };

  onInputFocus = () => {
    this.isAnyInputFocused = true;
  };

  onEnter = () => {
    this.updatePropertyDebounced.flush();
    this.props.onBlur?.();
    // update any null values in the border width inputs to be 0
    const updatedBorderWidthInputs = { ...this.state.borderWidthInputValues };
    for (const side in updatedBorderWidthInputs) {
      if (updatedBorderWidthInputs[side as keyof PerSideBorder] == null) {
        updatedBorderWidthInputs[side as keyof PerSideBorder] = 0;
      }
    }
    this.setState({ borderWidthInputValues: updatedBorderWidthInputs });
  };

  updateOneBorderWidth(rawValue: number | null, path: string) {
    let value = rawValue;

    if (!Number.isFinite(value)) {
      return;
    }

    if (value != null && value < 0) {
      value = 0;
    }

    this.setState({
      ...this.state,
      borderWidthInputValues: {
        ...this.state.borderWidthInputValues,
        [path]: value,
      },
    });
    const persistedValue: PerSideBorder = {
      ...((this.props.propertyValue as PerSideBorder) ?? {}),
      [path]: {
        ...(this.props.propertyValue?.[path as keyof PerSideBorder] ?? {}),
        width: Dimension.px(value ?? 0),
      },
    };
    this.updatePropertyDebounced(persistedValue);
  }

  updateBorderWidths = (value: number | null) => {
    // update all of the border widths
    const persistedValue: PerSideBorder = {
      top: {
        ...(this.props.propertyValue?.top ?? {}),
        width: value != null ? Dimension.px(value) : undefined,
      },
      right: {
        ...(this.props.propertyValue?.right ?? {}),
        width: value != null ? Dimension.px(value) : undefined,
      },
      bottom: {
        ...(this.props.propertyValue?.bottom ?? {}),
        width: value != null ? Dimension.px(value) : undefined,
      },
      left: {
        ...(this.props.propertyValue?.left ?? {}),
        width: value != null ? Dimension.px(value) : undefined,
      },
    };
    this.updatePropertyDebounced(persistedValue);
    this.setInputValues(persistedValue);
  };

  updateBorderColor(color?: string) {
    this.updateProperty(
      setBorderColor(
        this.props.propertyValue ?? this.state.defaultValue,
        color,
      ),
    );
  }

  handleToggleAdvancedMode() {
    if (this.state.useAdvancedMode) {
      // if we're in advanced mode, we want to switch to simple mode, need to change all border widths to the same value
      let value =
        typeof this.state.borderWidthInputValues.top === "string"
          ? parseInt(this.state.borderWidthInputValues.top, 10)
          : this.state.borderWidthInputValues.top;
      if (value == null || isNaN(value)) {
        value = 0;
      }
      this.updateBorderWidths(value);
      this.setState({ useAdvancedMode: false });
    } else {
      // if we're in simple mode, we want to switch to advanced mode, need to set the input values to the current border widths
      this.setState({ useAdvancedMode: true });
    }
  }

  setInputValues = (value: PerSideBorder) => {
    this.setState({
      borderWidthInputValues: {
        top: value?.top?.width?.value ?? null,
        right: value?.right?.width?.value ?? null,
        bottom: value?.bottom?.width?.value ?? null,
        left: value?.left?.width?.value ?? null,
      },
    });
  };

  getSelectedColor = () => {
    // for now, all border sides have the same color, so we can just use the top color
    const topBorderColor = this.props.propertyValue?.top?.color;
    const selectedOption =
      this.state.presets && topBorderColor
        ? this.state.presets
            .reduce(
              (accum: PresetOption[], preset) => [...accum, ...preset.options],
              [],
            )
            .find((opt) => {
              return opt.value.toUpperCase() === topBorderColor.toUpperCase();
            })
        : topBorderColor;
    return selectedOption ?? topBorderColor;
  };

  getDefaultSelectedColor = () => {
    // for now, all border sides have the same color, so we can just use the top color
    const defaultTopBorderColor = this.state.defaultValue?.top?.color;
    const selectedOption =
      this.state.presets && defaultTopBorderColor
        ? this.state.presets
            .reduce(
              (accum: PresetOption[], preset) => [...accum, ...preset.options],
              [],
            )
            .find((opt) => {
              return (
                opt.value.toUpperCase() === defaultTopBorderColor.toUpperCase()
              );
            })
        : defaultTopBorderColor;
    return selectedOption ?? defaultTopBorderColor;
  };

  render() {
    const borderWidth = this.state.borderWidthInputValues.top;
    const hasMixedWidths = Object.values(
      this.state.borderWidthInputValues,
    ).some((val) => val !== borderWidth);

    return (
      <div className={AdvancedEditorWrapper}>
        <div className={AdvancedEditorRow}>
          <div className={colorPickerStyle}>
            <ColorPicker
              selectedColor={this.getSelectedColor()}
              defaultSelectedColor={this.getDefaultSelectedColor()}
              onColorSelect={this.updateBorderColor}
              popoverPlacement="left"
              showOpacity={true}
              showInputsInPopover={true}
              compact={true}
              disabled={this.props.isDisabled}
              presetColorChoices={this.state.presets}
            />
          </div>
          {!this.state.useAdvancedMode ? (
            <PixelInput
              title="border"
              // if this is shown, all border widths are being kept in sync
              value={borderWidth}
              onChange={this.updateBorderWidths}
              onPressEnter={this.onEnter}
              style={{ flexGrow: 1, minWidth: 0 }}
              disabled={this.props.isDisabled}
              onFocus={this.onInputFocus}
              onBlur={this.onBlur}
            />
          ) : (
            <div className={AdvancedEditorValue}>
              {hasMixedWidths ? "Mixed" : borderWidth}
            </div>
          )}
          <AdvancedModeToggle
            onToggle={this.handleToggleAdvancedMode}
            isAdvancedMode={this.state.useAdvancedMode}
            isDisabled={this.props.isDisabled}
            type="border"
          />
        </div>
        {this.state.useAdvancedMode && (
          <AdvancedSizeInputs
            values={this.state.borderWidthInputValues}
            onValueChange={this.updateOneBorderWidth}
            onEnter={this.onEnter}
            onBlur={this.onInputBlur}
            onFocus={this.onInputFocus}
            LeftIconComponent={BorderLeft}
            tooltipLabel="border"
            mode="side"
            readOnly={this.props.isDisabled}
            loadingStates={this.props.loadingStates}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  generatedTheme: selectGeneratedTheme(state),
  showAdvancedProperties: selectFlagById(
    state,
    Flag.ENABLE_PADDING_ADVANCED_MODE,
  ),
});

export default connect(mapStateToProps)(BorderControl);
