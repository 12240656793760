import { useCallback, useContext, useEffect } from "react";
import { Rnd } from "react-rnd";
import { AiAssitantBg } from "ai/Shared";
import Popper from "components/ui/Popper";
import { JwtContext } from "hooks/ui/JwtProvider";
import { isExpired } from "hooks/ui/useJwt";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { useAppDispatch } from "store/helpers";
import AiAssistant from "./AiAssistant";
import { RESIZE_HANDLES, RESIZE_HANDLE_STYLES } from "./ResizeHandle";
import {
  AiAssistantOptionType,
  AiAssistantProps,
  ScopeType,
} from "./constants";

const AI_ASSISTANT_MIN_WIDTH = 300;
const AI_ASSISTANT_MIN_HEIGHT = 80;

export const AiAssistantPositioner = ({
  x,
  y,
  width,
  isPinned,
  maxHeight,
  canResizeAssisant,
  onDragResizeStop,
  availableOptions,
  onClose,
  onConfirm,
  contextProps,
  onOptionSelected,
  scope,
}: {
  availableOptions: Array<AiAssistantOptionType>;
  x: number;
  y: number;
  width: number;
  isPinned: boolean;
  maxHeight: number;
  canResizeAssisant: boolean;
  onClose: () => void;
  onConfirm: (
    aiOptionType: AiAssistantOptionType,
    code?: null | string,
  ) => void;
  onOptionSelected: () => void;
  contextProps?: Partial<AiAssistantProps>;
  onDragResizeStop?: (data: any) => void;
  scope: ScopeType;
}) => {
  const dispatch = useAppDispatch();
  const setDraggingOrResizing = useCallback(
    (isDragging: boolean) => {
      dispatch({
        type: ReduxActionTypes.SET_AI_ASSISTANT_DRAGGING,
        payload: isDragging,
      });
    },
    [dispatch],
  );

  const handleResizeDragStart = useCallback(() => {
    setDraggingOrResizing(true);
  }, [setDraggingOrResizing]);

  const handleResizeDragStop = useCallback(
    (e: any, data: any) => {
      setDraggingOrResizing(false);
      onDragResizeStop && onDragResizeStop(data);
    },
    [setDraggingOrResizing, onDragResizeStop],
  );
  const { jwt, fetchJwt } = useContext(JwtContext);
  useEffect(() => {
    if (jwt && isExpired(jwt)) {
      // if necessary, refetch the jwt when the ai assistant is opened
      fetchJwt();
    }
  }, [jwt, fetchJwt]);

  return (
    <Popper zIndex={10} isOpen={true} placement="auto">
      <AiAssitantBg>
        <Rnd
          scale={1}
          default={{
            x,
            y,
            width,
            height: "auto",
          }}
          position={
            isPinned
              ? {
                  x,
                  y,
                }
              : undefined
          }
          minHeight={AI_ASSISTANT_MIN_HEIGHT}
          minWidth={AI_ASSISTANT_MIN_WIDTH}
          maxHeight={maxHeight}
          dragAxis={canResizeAssisant ? "both" : "none"}
          enableResizing={
            canResizeAssisant
              ? {
                  top: false,
                  bottom: false,
                  left: false,
                  right: false,
                  topLeft: true,
                  topRight: true,
                  bottomLeft: true,
                  bottomRight: true,
                }
              : false
          }
          bounds="window"
          style={{
            pointerEvents: "all",
          }}
          cancel=".drag-disabled"
          onDragStop={handleResizeDragStop}
          onDragStart={handleResizeDragStart}
          onResizeStart={handleResizeDragStart}
          onResizeStop={handleResizeDragStop}
          resizeHandleComponent={RESIZE_HANDLES}
          resizeHandleStyles={RESIZE_HANDLE_STYLES}
        >
          <AiAssistant
            options={availableOptions}
            onClose={onClose}
            onConfirm={onConfirm}
            onOptionSelected={onOptionSelected}
            maxHeight={maxHeight}
            scope={scope}
            {...contextProps}
          />
        </Rnd>
      </AiAssitantBg>
    </Popper>
  );
};
