import CodeMirror, { Hint, Editor, Hints, Position } from "codemirror";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { WidgetTypes } from "../legacy/constants/WidgetConstants";
import AutocompleteHint, { showAutocompleteHint } from "./AutocompleteHint";
import {
  AUTOCOMPLETE_TOOLTIP_MAX_HEIGHT,
  AUTOCOMPLETE_TOOLTIP_WIDTH,
} from "./constants";
import {
  DataTreeDef,
  entityDefinitionMetadata,
} from "./dataTreeTypeDefCreator";
import { DataType } from "./getDataType";
import type { TypeInfoDocType } from "./types";
export const AUTOCOMPLETE_CLASS = "CodeMirror-Tern-";

export interface WrappedTooltip {
  tooltip: HTMLElement;
  remove: () => void;
}

export type Completion = Hint & {
  origin: string;
  type: DataType;
  data: DataTreeDef;
};

export function renderHint(
  cm: CodeMirror.Editor,
  params: {
    node: HTMLElement;
    "!doc"?: TypeInfoDocType;
    "!type"?: string;
  },
) {
  const { node, "!doc": doc, "!type": type } = params;

  if (showAutocompleteHint(params)) {
    const [tooltipX, tooltipY] = calculateTooltipCoords(node);
    const divContainer = document.createElement("div");
    const tooltip = makeTooltip(tooltipX, tooltipY, divContainer);
    tooltip.classList.add(AUTOCOMPLETE_CLASS + "hint-doc");
    tooltip.classList.add("visible");

    const root = createRoot(divContainer);
    root.render(<AutocompleteHint doc={doc} type={type} />);

    return {
      tooltip,
      remove: () => {
        tooltip.remove();
        root.unmount();
      },
    };
  }
}

export function makeTooltip(
  x: number,
  y: number,
  content: HTMLElement | string,
) {
  const node = elt("div", AUTOCOMPLETE_CLASS + "tooltip", content);
  node.style.left = x + "px";
  node.style.top = y + "px";
  document.body.appendChild(node);
  return node;
}

function calculateTooltipCoords(node: HTMLElement): [number, number] {
  let x = 0;
  let y = 0;
  const xOffset = 2; // offset between autocompelete and tooltip
  const yOffset = 3; // offset to align autocomplete and tooltip height
  const tooltipWidth = AUTOCOMPLETE_TOOLTIP_WIDTH;
  const parentBoundingRect = (
    node.parentNode as HTMLElement
  ).getBoundingClientRect();
  if (window.innerWidth - parentBoundingRect.right > tooltipWidth) {
    // display on right side of autocomplete
    x = parentBoundingRect.right + window.pageXOffset + xOffset;
    y = parentBoundingRect.top + window.pageYOffset + yOffset;
  } else {
    // display on left side of autocomplete
    x = parentBoundingRect.left - window.pageXOffset - xOffset - tooltipWidth;
    y = parentBoundingRect.top + window.pageYOffset + yOffset;
  }
  return [x, y];
}

export function calculateInlineTooltipCoords(node: HTMLElement): {
  x: number;
  y: number;
  transform?: string;
} {
  let x = 0;
  let y = 0;
  const yOffset = 6; // offset to align autocomplete and tooltip height
  // assume that we are using the largest possible height
  const tooltipHeight = AUTOCOMPLETE_TOOLTIP_MAX_HEIGHT;
  const tooltipWidth = AUTOCOMPLETE_TOOLTIP_WIDTH;
  const boundingRect = node.getBoundingClientRect();
  let transform: string | undefined;
  if (window.innerWidth - boundingRect.right > tooltipWidth) {
    // display on right side of token
    x = boundingRect.left + window.pageXOffset;
  } else {
    // display on left side of token
    x = boundingRect.right - window.pageXOffset - tooltipWidth;
  }
  const fitsBelow = window.innerHeight - boundingRect.bottom >= tooltipHeight;
  const fitsAbove = boundingRect.top >= tooltipHeight + yOffset;
  if (fitsBelow || !fitsAbove) {
    // display below the target
    y = boundingRect.bottom + window.pageYOffset + yOffset;
  } else {
    // display above the target
    y = boundingRect.top + window.pageYOffset;
    transform = "translateY(-100%)";
  }
  return { x, y, transform };
}

export function elt(
  tagName: string,
  cls: string | null,
  content: string | HTMLElement,
): HTMLElement {
  const e = document.createElement(tagName);
  if (cls) e.className = cls;
  if (content) {
    const eltNode =
      typeof content === "string" ? document.createTextNode(content) : content;
    e.appendChild(eltNode);
  }
  return e;
}

export function completionComparator(
  a: Completion,
  b: Completion,
  docProperty: "doc" | "!doc", // TODO: remove "doc", why is there two?
) {
  if (a.type === "FUNCTION" && b.type !== "FUNCTION") {
    return 1;
  } else if (a.type !== "FUNCTION" && b.type === "FUNCTION") {
    return -1;
  }
  const sortOrderA =
    _.get(
      entityDefinitionMetadata,
      (a.data?.[docProperty] as TypeInfoDocType | undefined)?.entityType ?? "",
    )?.sortOrder?.[a.displayText ?? a.text] ?? 0;
  const sortOrderB =
    _.get(
      entityDefinitionMetadata,
      (b.data?.[docProperty] as TypeInfoDocType | undefined)?.entityType ?? "",
    )?.sortOrder?.[b.displayText ?? b.text] ?? 0;
  const sortOrder = sortOrderA - sortOrderB;
  if (sortOrder === 0) {
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
  }
  if (sortOrderA === 0 || sortOrderB === 0) {
    return -sortOrder;
  }
  return sortOrder;
}

const widgetValues = Object.values<string>(WidgetTypes);
export function typeToIcon(type: string, entityType?: string): string {
  let suffix;
  if (type === "number" || type === "string" || type === "bool") suffix = type;
  else if (/^fn\(/.test(type)) suffix = "fn";
  else if (/^\[/.test(type)) suffix = "array";
  else if (/\./.test(type)) suffix = "object";
  else if (entityType && widgetValues.includes(entityType)) suffix = entityType;
  else if (entityType === ENTITY_TYPE.TIMER) suffix = "timer";
  else if (entityType === ENTITY_TYPE.STATE_VAR) suffix = "state";
  else if (type === "?" || (type === undefined && entityType === undefined))
    suffix = "unknown";
  else suffix = "object";

  return (
    AUTOCOMPLETE_CLASS +
    "completion " +
    AUTOCOMPLETE_CLASS +
    "completions-" +
    suffix
  );
}

export function makeDecrementedHintCompletion(
  text: string,
  decrementCursor: number,
  from?: Position,
) {
  return (cm: Editor, data: Hints, cur: Hint) => {
    cm.replaceRange(text, from || data.from, data.to, "complete");
    const { ch, line } = cm.getCursor();
    cm.setCursor({
      ch: ch - decrementCursor,
      line,
    });
  };
}
