import { RouteDef } from "@superblocksteam/shared";
import { get, without } from "lodash";
import { getDefaultKeyValueProperty } from "legacy/components/propertyControls/AddKeyValuePropertyControl";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";

import { KeyValueProperty } from "legacy/widgets/KeyValueWidget/types";
import { getDottedPathTo } from "utils/dottedPaths";
import { getKvPropertyIdFromAi } from "./columnUtils";
import { getEventHandlers } from "./eventHandlers";
import { KvAction, RemoveEventAction, ClarkActionFuntions } from "./types";

const getPropertiesOrder = (widget: any, changes: Record<string, any>) => {
  let existingPropertiesOrder = changes.propertiesOrder;
  if (!existingPropertiesOrder) {
    existingPropertiesOrder = widget.propertiesOrder
      ? [...widget.propertiesOrder]
      : [];
  }
  return existingPropertiesOrder;
};

export const addKvItem = (props: {
  action: KvAction;
  widget: any;
  changes: Record<string, any>;
  dataTree: DataTree;
  routes: RouteDef[];
  kvProperty: string;
  updateComponentFn: ClarkActionFuntions["updateComponentFn"];
  changedKeys: Record<string, string[]>;
}) => {
  const { widget, changes, kvProperty, changedKeys } = props;
  const propertyProps: KeyValueProperty = getDefaultKeyValueProperty(
    [],
    kvProperty,
  );
  changedKeys[widget.widgetId].push(`properties.${propertyProps.id}`);
  const path = `properties${getDottedPathTo(propertyProps.id)}`;

  changes[path] = propertyProps;
  changes[`propertiesOrder`] = [
    ...getPropertiesOrder(widget, changes),
    propertyProps.id,
  ];
  updateKvItem(props);
};

export const removeKvItem = ({
  widget,
  changes,
  kvProperty,
  deleteComponentPropertiesFn,
  changedKeys,
}: {
  widget: any;
  changes: Record<string, any>;
  kvProperty: string;
  deleteComponentPropertiesFn: ClarkActionFuntions["deleteComponentPropertiesFn"];
  changedKeys: Record<string, string[]>;
}) => {
  const propertyToRemove =
    widget.properties?.[kvProperty] ?? changes.properties?.[kvProperty];

  if (!kvProperty || typeof kvProperty !== "string" || !propertyToRemove) {
    return;
  }

  if (propertyToRemove.isDerived) {
    changes[`properties.${kvProperty}`] = null;
    changes.propertiesOrder = getPropertiesOrder(widget, changes).filter(
      (column: string) => column !== kvProperty,
    );
    changedKeys[widget.widgetId].push(`properties.${kvProperty}`);
    changedKeys[widget.widgetId].push(`propertiesOrder`);
    const indexOfProperty = getPropertiesOrder(widget, changes).indexOf(
      kvProperty,
    );

    deleteComponentPropertiesFn({
      widgetId: widget.widgetId,
      propertyPaths: [
        `properties.${kvProperty}`,
        `propertiesOrder.${indexOfProperty}`,
      ],
    });
  } else {
    // If not derived, we just hide the property as we can't delete it
    changes[`properties.${kvProperty}.isVisible`] = false;
    changedKeys[widget.widgetId].push(`properties.${kvProperty}.isVisible`);
  }
};

export const updateKvItem = ({
  action,
  widget,
  changes,
  dataTree,
  routes,
  kvProperty,
  updateComponentFn,
  changedKeys,
}: {
  action: KvAction;
  widget: any;
  changes: Record<string, any>;
  dataTree: DataTree;
  routes: RouteDef[];
  kvProperty: string;
  updateComponentFn: ClarkActionFuntions["updateComponentFn"];
  changedKeys: Record<string, string[]>;
}) => {
  const updates: Record<string, any> = {};

  const addUpdate = (kvProperty: string, propertyName: string, value: any) => {
    updates[`properties${getDottedPathTo(kvProperty)}.${propertyName}`] = value;
  };

  switch (action.action) {
    case "set": {
      addUpdate(kvProperty, action.property, action.value);
      break;
    }
    case "add": {
      const path = `properties${getDottedPathTo(kvProperty)}.${action.property}`;
      const prevValue = get(changes, path) || get(widget, path);
      const newValue = getEventHandlers({
        prevValue,
        value: action.value,
        dataTree,
        routes,
      });
      addUpdate(kvProperty, action.property, newValue);
      break;
    }
    case "remove": {
      if ("value" in action) {
        const path = `properties${getDottedPathTo(kvProperty)}.${action.property}`;
        const prevValue = get(changes, path) || get(widget, path);
        const newValue = Array.isArray(prevValue)
          ? prevValue.filter(
              (item) =>
                item.id !== (action.value as RemoveEventAction["value"]).id,
            )
          : prevValue;
        addUpdate(kvProperty, action.property, newValue);
      } else {
        // remove is a reset
        addUpdate(kvProperty, action.property, undefined);
      }
      break;
    }
    case "reset": {
      addUpdate(kvProperty, action.property, undefined);
      break;
    }
  }

  Object.entries(updates).forEach(([path, value]) => {
    changes[path] = value;
    changedKeys[widget.widgetId].push(path);
  });

  updateComponentFn({
    widgetId: widget.widgetId,
    widgetUpdates: updates,
  });
};

export const updatePropertiesOrder = ({
  orderFromAi,
  existingOrder,
  originalItems,
}: {
  orderFromAi: string[];
  existingOrder: string[];
  originalItems: Record<string, KeyValueProperty>;
}) => {
  const mappedFromAi = orderFromAi.map((property) =>
    getKvPropertyIdFromAi(property, originalItems),
  );
  const missingProperties = without(existingOrder, ...mappedFromAi);
  const newPropertyOrder = [...mappedFromAi, ...missingProperties];
  return newPropertyOrder;
};
