import * as Sentry from "@sentry/react";
import log from "loglevel";
import { put, select } from "redux-saga/effects";

import {
  showWidgetPropertyPane,
  selectWidgets,
} from "legacy/actions/widgetActions";
import {
  ReduxActionTypes,
  ReplayReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  flashElementsById,
  scrollWidgetIntoView,
} from "legacy/pages/Editor/visibilityUtil";
import { getMainContainerWidgetId } from "legacy/selectors/editorSelectors";
import logger from "utils/logger";
import { isUserOnMac } from "utils/navigator";
import { sendSuccessUINotification } from "utils/notification";
import {
  getCurrentWidgetId,
  getPropertyPaneHasContents,
} from "../selectors/propertyPaneSelectors";

export type UndoRedoPayload = {
  operation: ReplayReduxActionTypes;
};

export type UndoRedoMultiplePayload = {
  operation: ReplayReduxActionTypes;
  times: number;
};

export function* openPropertyPaneSaga(replay: any) {
  try {
    const replayWidgetId = Object.keys(replay.widgets)[0];
    if (!replayWidgetId || !replay.widgets[replayWidgetId].propertyUpdates) {
      return;
    }

    scrollWidgetIntoView(replayWidgetId);
    const isPropertyPaneVisible: boolean = yield select(
      getPropertyPaneHasContents,
    );
    const selectedWidgetId: string = yield select(getCurrentWidgetId);
    const mainContainerWidgetId: string = yield select(
      getMainContainerWidgetId,
    );

    // if property pane is not visible, select the widget and force open property pane
    const isTopWidget = mainContainerWidgetId === replayWidgetId;
    const alreadySelected = selectedWidgetId !== replayWidgetId;
    if (!isTopWidget) {
      if (!alreadySelected) yield put(selectWidgets([replayWidgetId]));
      if (!isPropertyPaneVisible)
        yield put(showWidgetPropertyPane(replayWidgetId));
    }

    flashElementsById(
      btoa(
        replay.widgets[replayWidgetId].propertyUpdates.slice(0, 2).join("."),
      ),
    );
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
  }
}

/**
 * For widget addition/deletion/removal
 */
export function* postUndoRedoSaga(replay: any) {
  try {
    if (replay.toasts && replay.toasts.length > 0) {
      sendSuccessUINotification({
        message: replay.toasts[0].isCreated
          ? "Component added back!"
          : `Component removed! (${
              isUserOnMac() ? "⌘" : "Ctrl"
            }+Shift+Z to Redo)`,
      });
    }
    const combinedWidgets = {
      ...replay.widgets,
      ...replay.widgetMeta,
    };
    // select widget and scroll it into view
    const widgetIds = Object.keys(combinedWidgets ?? []);
    if (!combinedWidgets || widgetIds.length <= 0) {
      yield put({ type: ReduxActionTypes.HIDE_PROPERTY_PANE });
      return;
    }
    yield put(selectWidgets([widgetIds[0]]));
    scrollWidgetIntoView(widgetIds[0]);
  } catch (e) {
    logger.error(e);
  }
}
