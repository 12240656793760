import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiDtoWithPb } from "../apisV2/slice";
import {
  ComponentEditFeedbackAction,
  sendAiWidgetEditActionsFeedback,
} from "./client";
import { getCurrentSdlcMessages, getLastSdlcMessage } from "./sdlcUtils";
import type {
  EditMetadata,
  DiscardedEdit,
  ResultHistoryItem,
  ClarkAction,
  SldcMessages,
  SldcMessage,
  ClarkWebSocketConnectionClosedReason,
} from "./types";
import type { WidgetMap } from "legacy/widgets";

const initialState: {
  isLoading: boolean;
  selectedWidgetId?: string;
  viewMode: "chat" | "modal";
  initialPosition?: { x: number; y: number };
  actions?: Array<ClarkAction>;
  changedKeys?: Record<string, string[]>;
  // todo: rename this: this is dataTreeChanges for widgets only, and keyed off widgetId not entityName
  dataTreeChanges?: Record<string, Record<string, unknown> | null>;
  apiChanges?: Record<string, ApiDtoWithPb>;
  createdApiNamesToIds?: Record<string, string>;
  initialDataTreeChanges?: Record<string, Record<string, unknown> | null>;
  propertiesToChange?: Record<string, string[]>;
  renamesByWidgetId?: Record<string, string>;
  error?: string;
  actionsRequestId?: string;
  discardedEdits?: Record<string, DiscardedEdit[]>;
  noResultsResponse?: {
    summary: string;
    suggestion?: string;
  };
  metadataByWidgetId?: Record<string, EditMetadata | undefined>;
  promptHistoryByWidgetId?: Record<string, string[]>;
  resultsHistory: Array<ResultHistoryItem>;
  sdlcMessagesHistory: Array<SldcMessages>;
  apiStatuses?: Record<string, "STARTED" | "COMPLETED">;
  widgetsAtRequestStart?: WidgetMap;
  apisAtRequestStart?: Record<string, ApiDtoWithPb>;
  widgetsUndoStackLengthAtRequestStart?: number;
  apisUndoStackLengthsAtRequestStart?: Record<string, number>;
  connectionClosedReason?: ClarkWebSocketConnectionClosedReason;
} = {
  isLoading: false,
  viewMode: "chat",
  resultsHistory: [],
  sdlcMessagesHistory: [[]],
};

type NoResultsResponse = {
  summary: string;
  suggestion?: string;
};

export const processAiActions = createAction<{
  clarkRequestId: string;
  actions: ClarkAction[];
  existingWidgetId: string;
}>("ai/processAiActions");

export const updateAiChanges = createAction<
  | {
      updates: Record<string, unknown>;
      properties: Record<string, unknown>;
    }
  | {
      rename: string;
      widgetId: string;
    }
>("ai/updateAiChanges");

export const removeChildInAiChanges = createAction<{
  widgetId: string;
}>("ai/removeChildInAiChanges");

export const restoreDeletedChildInAiChanges = createAction<{
  childId: string;
}>("ai/restoreDeletedChildInAiChanges");

export const processAiProperties = createAction<{
  widgetId: string;
  properties: string[];
  widgetType: string;
  noResultsResponse?: NoResultsResponse;
}>("ai/processAiProperties");

export const updateAiDynamicProperties = createAction<{
  itemId: string;
  propertyName: string;
  isDynamicProperty: boolean;
}>("ai/updateAiDynamicProperties");

export const clearAiChanges = createAction<{
  isAccept?: boolean;
  shouldClose: boolean;
  isRedo?: boolean;
}>("ai/clearAiChanges");

export const sendWidgetEditActionsFeedback = createAsyncThunk<
  void,
  ComponentEditFeedbackAction & {
    aiServiceURL: string;
  }
>("ai/sendAiWidgetEditActionsFeedback", async (payload, thunkAPI) => {
  sendAiWidgetEditActionsFeedback(payload);
});

export const doSetupBeforeClarkRequest = createAction<{
  isRedo?: boolean;
}>("ai/doSetupBeforeClarkRequest");

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    setSelectedWidgetId: (state, action: { payload: string | null }) => {
      state.selectedWidgetId = action.payload ?? undefined;
      state.viewMode = "chat";
    },
    resetAiState: (
      state,
      action: { payload: { shouldClose: boolean; isRedo?: boolean } },
    ) => {
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.apiChanges = undefined;
      state.createdApiNamesToIds = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = action.payload.isRedo ? true : false;
      state.actions = undefined;
      state.renamesByWidgetId = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.discardedEdits = undefined;
      state.metadataByWidgetId = undefined;
      state.noResultsResponse = undefined;
      state.apiStatuses = undefined;
      state.connectionClosedReason = undefined;

      if (!action.payload.isRedo) {
        state.widgetsUndoStackLengthAtRequestStart = undefined;
        state.apisUndoStackLengthsAtRequestStart = undefined;
        state.widgetsAtRequestStart = undefined;
        state.apisAtRequestStart = undefined;
      }

      if (state.viewMode === "chat" && !action.payload.isRedo) {
        state.selectedWidgetId = undefined;
      }

      if (action.payload.shouldClose) {
        state.selectedWidgetId = undefined;
        state.initialPosition = undefined;
        state.viewMode = "chat";
      }
    },
    setAiChanges: (
      state,
      action: {
        payload: {
          changedKeys?: Record<string, string[]>;
          dataTreeChanges?: Record<string, Record<string, unknown> | null>;
          renamesByWidgetId?: Record<string, string>;
          discardedEdits?: Record<string, DiscardedEdit[]>;
          metadataByWidgetId?: Record<string, EditMetadata | undefined>;
          apiChanges?: Record<string, ApiDtoWithPb>;
          createdApiNamesToIds?: Record<string, string>;
          apiStatuses?: Record<string, "STARTED" | "COMPLETED">;
        };
      },
    ) => {
      const {
        changedKeys,
        dataTreeChanges,
        renamesByWidgetId,
        discardedEdits,
        metadataByWidgetId,
        apiChanges,
        createdApiNamesToIds,
        apiStatuses,
      } = action.payload;
      state.changedKeys = changedKeys;
      state.dataTreeChanges = dataTreeChanges;
      state.initialDataTreeChanges = dataTreeChanges;
      state.renamesByWidgetId = renamesByWidgetId;
      state.discardedEdits = discardedEdits;
      state.metadataByWidgetId = metadataByWidgetId ?? state.metadataByWidgetId;
      state.apiChanges = apiChanges;
      state.createdApiNamesToIds = createdApiNamesToIds;
      state.apiStatuses = apiStatuses ?? state.apiStatuses;
    },
    openAiModal: (
      state,
      action: {
        payload: { widgetId: string; position: { x: number; y: number } };
      },
    ) => {
      state.selectedWidgetId = action.payload.widgetId;
      state.initialPosition = action.payload.position;
      state.viewMode = "modal";
      // clear all the previous change
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.renamesByWidgetId = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.actionsRequestId = undefined;
      state.discardedEdits = undefined;
      state.metadataByWidgetId = undefined;
      state.noResultsResponse = undefined;
      state.apiStatuses = undefined;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
      if (action.payload) {
        state.error = undefined;
      }
    },
    setError: (state, action: { payload: { error: string } }) => {
      state.error = action.payload.error;
    },
    setPropertiesToChange: (
      state,
      action: { payload: Record<string, string[]> },
    ) => {
      state.propertiesToChange = action.payload;
    },
    setNoResultsResponse: (state, action: { payload: NoResultsResponse }) => {
      state.noResultsResponse = action.payload;
    },
    addAction: (state, action: { payload: ClarkAction }) => {
      state.actions = [...(state.actions ?? []), action.payload];
    },
    updateSdlcActiveTest: (
      state,
      action: {
        payload: Omit<
          Extract<SldcMessage, { type: "test" }>,
          "type" | "message"
        >;
      },
    ) => {
      const lastMessage = getLastSdlcMessage(state.sdlcMessagesHistory);
      const isTest =
        lastMessage &&
        lastMessage.type === "test" &&
        lastMessage.id === action.payload.id;

      if (!isTest) {
        throw new Error("Clark: No active or matching SDLC test to update");
      }

      lastMessage.status = action.payload.status;
      lastMessage.answer = action.payload.answer;
    },

    initSdlcMessagesForNextPrompt: (state) => {
      state.sdlcMessagesHistory.push([]);
    },
    pushSdlcMessage: (
      state,
      action: {
        payload: SldcMessage;
      },
    ) => {
      const lastSdlcMessages = getCurrentSdlcMessages(
        state.sdlcMessagesHistory,
      );
      if (!lastSdlcMessages) {
        throw new Error("Clark: No active SDLC messages structure");
      }
      lastSdlcMessages.push(action.payload);
    },
    setActionsRequestId: (state, action: { payload: string | undefined }) => {
      state.actionsRequestId = action.payload;
    },
    setApiStatus: (
      state,
      action: { payload: { apiName: string; status: "STARTED" | "COMPLETED" } },
    ) => {
      state.apiStatuses = {
        ...(state.apiStatuses ?? {}),
        [action.payload.apiName]: action.payload.status,
      };
    },
    updatePromptHistoryByWidgetId: (
      state,
      action: { payload: { widgetId: string; prompt: string } },
    ) => {
      state.promptHistoryByWidgetId = {
        ...state.promptHistoryByWidgetId,
        [action.payload.widgetId]: [
          action.payload.prompt,
          ...(state.promptHistoryByWidgetId?.[action.payload.widgetId] ?? []),
        ],
      };
    },
    addHistoryItem: (state, action: { payload: ResultHistoryItem }) => {
      state.resultsHistory = [...state.resultsHistory, action.payload];
    },
    setUndoStackIndexAtRequestStart: (
      state,
      action: {
        payload: {
          widgetsUndoStackLength: number;
          apisUndoStackLengths: Record<string, number>;
        };
      },
    ) => {
      state.widgetsUndoStackLengthAtRequestStart =
        action.payload.widgetsUndoStackLength;
      state.apisUndoStackLengthsAtRequestStart =
        action.payload.apisUndoStackLengths;
    },
    setStateAtRequestStart: (
      state,
      action: {
        payload: {
          widgetsAtRequestStart: WidgetMap | undefined;
          apisAtRequestStart: Record<string, ApiDtoWithPb> | undefined;
        };
      },
    ) => {
      state.widgetsAtRequestStart = action.payload.widgetsAtRequestStart;
      state.apisAtRequestStart = action.payload.apisAtRequestStart;
    },
    setConnectionClosedReason: (
      state,
      action: {
        payload: ClarkWebSocketConnectionClosedReason;
      },
    ) => {
      state.connectionClosedReason = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("FETCH_APPLICATION_INIT", (state) => {
      return initialState;
    });
  },
});

export const {
  resetAiState,
  openAiModal,
  setSelectedWidgetId,
  setAiChanges,
  setIsLoading,
  setPropertiesToChange,
  setNoResultsResponse,
  addHistoryItem,
  addAction,
  initSdlcMessagesForNextPrompt,
  pushSdlcMessage,
  updateSdlcActiveTest,
  setError,
  updatePromptHistoryByWidgetId,
  setActionsRequestId,
  setUndoStackIndexAtRequestStart,
  setStateAtRequestStart,
  setApiStatus,
  setConnectionClosedReason,
} = aiSlice.actions;

export const AI_ACTIONS_FOR_IFRAME = [
  setAiChanges.type,
  clearAiChanges.type,
  updateAiChanges.type,
  removeChildInAiChanges.type,
  resetAiState.type,
  restoreDeletedChildInAiChanges.type,
];

export const AI_ACTIONS_FROM_IFRAME = [
  openAiModal.type,
  clearAiChanges.type,
  updateAiChanges.type,
  resetAiState.type,
];

export const AI_ACTIONS_CAUSING_EVALUATION = [
  setAiChanges.type,
  updateAiChanges.type,
  clearAiChanges.type,
  resetAiState.type,
  removeChildInAiChanges.type,
];
