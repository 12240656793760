import { ApplicationScope } from "@superblocksteam/shared";
import { get } from "lodash";
import {
  DataTree,
  DataTreeAction,
} from "legacy/entities/DataTree/dataTreeFactory";
import { isPresent } from "legacy/utils/helpers";
import { resolveScopes } from "utils/dataTree/resolveScopes";
import { ApiScope } from "utils/dataTree/scope";

type Data = {
  data: DataTree;
  additionalData: Record<string, any>;
  apiScope?: ApiScope;
  appScope: ApplicationScope;
};

export function getValue(
  tokens: string[],
  { data, additionalData, apiScope, appScope }: Data,
) {
  if (!tokens.length) return;

  // TODO(CF): Remove this when we fully migrate to v2 control flow
  const actionOutputs = Object.assign(
    {},
    (get(data, apiScope?.apiName ?? "") as DataTreeAction)?.actionOutputs ?? {},
  );

  if (apiScope && actionOutputs) {
    for (const actionName of apiScope.previousV1ActionNames) {
      if (!actionOutputs[actionName]) {
        actionOutputs[actionName] = {
          output: null,
          log: [],
          executionTime: -1,
          request: "",
        };
      }
    }
  }

  const path = tokens.join("");
  const [firstToken, ...restTokens] = tokens;

  // First check additional data
  if (additionalData && additionalData[firstToken]) {
    return get(additionalData, path);
  }

  // Then check actions/apis
  if (actionOutputs && actionOutputs[firstToken]) {
    return get(actionOutputs, path);
  }

  if (apiScope?.v2ComputedScope && apiScope.v2ComputedScope[firstToken]) {
    return get(apiScope.v2ComputedScope, path);
  }

  // Finally check data tree
  const scopes = resolveScopes(appScope);
  const dataTreeValue = scopes
    .map(({ scope, prefix }) => {
      if (prefix) {
        const startsWithPrefix = firstToken === prefix;
        if (!startsWithPrefix) return null;
        return get(data, `${scope}${restTokens.join("")}`);
      }
      return get(data[scope], path);
    })
    .filter(isPresent)[0];

  return dataTreeValue;
}
