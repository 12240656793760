import {
  default as AntdButton,
  ButtonProps as AntButtonProps,
} from "antd/lib/button";
import { clsx } from "clsx";
import React, { ComponentPropsWithRef, forwardRef, memo } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

interface StyledButtonProps {
  $width?: string;
  $bordered?: boolean;
}

const StyledButton = styled(AntdButton)<StyledButtonProps>`
  color: ${(props) => props.color};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $bordered }) => (!$bordered ? "border: 0px;" : "")};
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    cursor: default;
  }
`;

interface ButtonProps extends AntButtonProps {
  width?: string;
  bordered?: boolean;
}

export const Button = memo(
  forwardRef<HTMLElement, ButtonProps>(
    ({ bordered = true, width, children, ...buttonProps }, ref) => {
      return (
        <StyledButton
          $bordered={bordered}
          $width={width}
          {...buttonProps}
          ref={ref}
        >
          {children}
        </StyledButton>
      );
    },
  ),
);

Button.displayName = "Button";
// https://github.com/ant-design/ant-design/issues/9581
// Some antd functionality breaks (e.g. tooltips) with
// wrapped buttons unless this is set
(Button as any).__ANT_BUTTON = true;

const StyledLink = styled.span`
  color: ${colors.ACCENT_BLUE_500};
  cursor: pointer;
`;

export function LinkButton(props: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <StyledLink {...props} role="link" tabIndex={0}>
      {props.children}
    </StyledLink>
  );
}

export const baseButtonCssString = `
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.333;
  border-radius: 4px;
  cursor: pointer;
`;

export const PrimaryButtonCssString = `
  ${baseButtonCssString}
  background: ${colors.ACCENT_BLUE_500};
  color: white;
  border: none;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    background: ${colors.ACCENT_BLUE_500};
    color: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    // when hover or keyboard navigte using tab
    background: ${colors.ACCENT_BLUE_600};
    color: white;
  }
  &:active {
    background: ${colors.ACCENT_BLUE_700};
    color: white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${colors.GREY_300};
    background: ${colors.GREY_50};
    border-color: ${colors.GREY_50};
  }
`;

export const SecondaryButtonCssString = `
  ${baseButtonCssString}
  border-color: ${colors.GREY_100};
  color: ${colors.GREY_700};
  background: white;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    border-color: ${colors.GREY_100};
    color: ${colors.GREY_700};
    background: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    border-color: ${colors.GREY_100};
    background: ${colors.GREY_25};
    color: ${colors.GREY_700};
  }
  &:active {
    border-color: ${colors.GREY_100};
    background: ${colors.GREY_50};
    color: ${colors.GREY_700};
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${colors.GREY_300};
    background: ${colors.GREY_50};
    border-color: ${colors.GREY_50};
  }
`;

export const DangerButtonCssString = `
  ${baseButtonCssString}
  background: ${colors.DANGER};
  color: white;
  border: none;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    background: ${colors.DANGER};
    color: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    background: ${colors.RED_600};
    color: white;
  }
  &:active {
    background: ${colors.RED_700};
    color: white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${colors.GREY_300};
    background: ${colors.GREY_50};
    border-color: ${colors.GREY_50};
  }
`;

export const SecondaryDangerButtonCssString = `
  ${baseButtonCssString}
  background-color: white;
  border: 1px solid ${colors.DANGER};
  color: ${colors.DANGER};
  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover,
  &:focus:not(:hover),
  &:focus-visible,
  &:active {
    border-color: ${colors.DANGER};
    background-color: ${colors.DANGER};
    color: white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${colors.GREY_300};
    background: ${colors.WHITE};
    border-color: ${colors.GREY_50};
  }
`;

export const PrimaryButtonClass = styleAsClass`
  &.ant-btn {
    ${PrimaryButtonCssString}
  }
`;

export const SecondaryButtonClass = styleAsClass`
  &.ant-btn {
    ${SecondaryButtonCssString}
  }
`;

export const DangerButtonClass = styleAsClass`
  &.ant-btn {
    ${DangerButtonCssString}
  }
`;

export const SecondaryDangerButtonClass = styleAsClass`
  &.ant-btn {
    ${SecondaryDangerButtonCssString}
  }
`;

interface ButtonProps extends ComponentPropsWithRef<typeof AntdButton> {
  variant?: "primary" | "secondary" | "danger" | "secondary-danger";
}

export const VariantButton = forwardRef<HTMLElement, ButtonProps>(
  function Button(props, ref) {
    const { variant, ...rest } = props;
    return (
      <AntdButton
        {...rest}
        className={clsx({
          [PrimaryButtonClass]: variant === "primary",
          [SecondaryButtonClass]: variant === "secondary",
          [DangerButtonClass]: variant === "danger",
          [SecondaryDangerButtonClass]: variant === "secondary-danger",
        })}
        ref={ref}
      />
    );
  },
);

// @ts-expect-error - this allows VariantButton to be used with AntD tooltips.
VariantButton.__ANT_BUTTON = true;

export const PrimaryButton = styled(AntdButton)`
  ${PrimaryButtonCssString}
`;

export const SecondaryButton = styled(AntdButton)`
  ${SecondaryButtonCssString}
`;

export const DangerButton = styled(AntdButton)`
  ${DangerButtonCssString}
`;
