/**
 * See https://github.com/angelozerr/CodeMirror-Extension
 * Highly modified version of this hover example
 */
import { ApplicationScope } from "@superblocksteam/shared";
import { Typography } from "antd";
import CodeMirror from "codemirror";
import { debounce } from "lodash";
import { createRoot } from "react-dom/client";
import styled from "styled-components";
import AutocompleteHint, {
  showAutocompleteHint,
} from "autocomplete/AutocompleteHint";
import {
  AUTOCOMPLETE_CLASS,
  calculateInlineTooltipCoords,
} from "autocomplete/util";
import JsonView from "components/ui/JsonView";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ApiScope } from "utils/dataTree/scope";
import { EditorModes } from "./EditorConfig";
import {
  BindingErrorHover,
  NullMarkerHover,
  FunctionScopeErrorMarkerError,
  AppScopeErrorHoverMarker,
} from "./MarkerHovers";
import { OpenButton } from "./OpenButton";
import { getValue } from "./datatree/getValue";
import { MarkerType } from "./markHelpers";
import {
  isHoveringBindingError,
  getMarkers,
  getTokenData,
  getTokens,
  Marker,
} from "./mouse/mouseHelper";

const { Text } = Typography;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

declare module "codemirror" {
  export interface EditorConfiguration {
    textHover?: Options | undefined;
    noNewlines?: boolean;
    styleActiveLine?: { nonEmpty: boolean };
  }
}

interface Options {
  data: () => DataTree;
  additionalData: () => Record<string, unknown>;
  apiScope: () => ApiScope | undefined;
  appScope: () => ApplicationScope;
  visible?: boolean;
}

function getValueFrom(instance: CodeMirror.Editor, tokens: string[]) {
  const data = instance.state.textHover.data();
  const additionalData = instance.state.textHover.additionalData();
  const apiScope = instance.state.textHover.apiScope();
  const appScope = instance.state.textHover.appScope();

  return getValue(tokens, {
    data,
    additionalData,
    apiScope,
    appScope,
  });
}

function handleMouseOver(instance: CodeMirror.Editor, e: MouseEvent) {
  // Disabled is controlled internally, while visible is controlled externally
  if (instance.state.textHover.disabled || !instance.state.textHover.visible) {
    return;
  }

  const node = e.target ?? e.srcElement;

  if (!node) return;
  if (!(node instanceof HTMLElement)) return;
  if (/ai-assistant-marker/.test(node.className)) return;

  const tokenData = getTokenData(instance, e);
  const hasBindingError = isHoveringBindingError(instance, e);

  let tokens: string[] = [];
  let markers: Marker[] = [];
  let value: any = undefined;
  if (tokenData) {
    tokens = getTokens(instance, tokenData);
    markers = getMarkers(instance, tokenData);
    value = getValueFrom(instance, tokens);
  }

  const isValid = typeof value !== "undefined" && typeof value !== "function";
  const isTernDoc =
    typeof value === "object" && "!doc" in value && showAutocompleteHint(value);

  if (!isValid && !markers.length && !hasBindingError) return;

  instance.state.textHover.hide?.();

  const render = () => {
    const {
      x: tooltipX,
      y: tooltipY,
      transform,
    } = calculateInlineTooltipCoords(node);

    const tooltip = document.createElement("div");
    tooltip.className = `${AUTOCOMPLETE_CLASS}tooltip ${AUTOCOMPLETE_CLASS}hint-doc visible`;
    tooltip.style.left = `${tooltipX}px`;
    tooltip.style.top = `${tooltipY}px`;
    if (transform) {
      tooltip.style.transform = transform;
    }
    document.body.appendChild(tooltip);

    const root = createRoot(tooltip);
    root.render(
      <>
        {markers.map((marker) => {
          if (
            marker.type === MarkerType.FunctionScopeError &&
            marker.methodName
          ) {
            return (
              <FunctionScopeErrorMarkerError
                key={marker.content}
                methodName={marker.methodName}
                editorMode={instance.getMode().name as EditorModes}
              />
            );
          }

          if (marker.type === MarkerType.AppScopeError && marker.entityName) {
            return (
              <AppScopeErrorHoverMarker
                key={marker.content}
                entityName={marker.entityName}
              />
            );
          }
          return (
            <NullMarkerHover key={marker.content} message={marker.content} />
          );
        })}
        {hasBindingError && <BindingErrorHover />}
        {isTernDoc && isValid && (
          <AutocompleteHint doc={value["!doc"]} type={value["!type"]} />
        )}
        {!isTernDoc && isValid && tokens.length > 0 && (
          <>
            <StyledHeader>
              <Text strong>{tokens.join("")}</Text>
              <OpenButton value={value} />
            </StyledHeader>
            <JsonView
              data={value}
              maxStringLength={20}
              maxBufferLength={20}
              maxArrayLength={100}
              width={230}
              maxHeight={260}
            />
          </>
        )}
      </>,
    );

    const handleHide = () => {
      root.unmount();
      tooltip.remove();

      CodeMirror.off(node, "click", handleHide);
      CodeMirror.off(node, "mouseleave", instance.state.textHover.onMouseLeave);

      instance.off("inputRead", instance.state.textHover.inputRead);
      instance.removeKeyMap(instance.state.textHover.keyMap);

      document.removeEventListener(
        "mousemove",
        instance.state.textHover.onMouseMove,
      );

      document.removeEventListener("scroll", instance.state.textHover.onScroll);

      delete instance.state.textHover.hide;
      delete instance.state.textHover.inputRead;
      delete instance.state.textHover.onMouseMove;
      delete instance.state.textHover.onMouseLeave;
      delete instance.state.textHover.onScroll;
    };

    // hide the tooltip when the mouse leaves the node or the tooltip
    const handleMouseMove = debounce((event: MouseEvent) => {
      const isOnNode =
        node === event.target || node.contains(event.target as Node);
      const isInTooltip =
        tooltip === event.target || tooltip.contains(event.target as Node);

      if (isOnNode || isInTooltip) return;

      handleHide();
    }, 16); // check at 60fps, this is only attached when the tooltip is visible

    instance.state.textHover.onMouseMove = handleMouseMove;

    document.addEventListener(
      "mousemove",
      instance.state.textHover.onMouseMove,
      { passive: true },
    );

    // hide the tooltip when scrolling happens
    const handleScroll = () => handleHide();
    instance.state.textHover.onScroll = handleScroll;
    document.addEventListener("scroll", instance.state.textHover.onScroll, {
      passive: true,
    });

    // hide the tooltip when the node is clicked
    CodeMirror.on(node, "click", handleHide);

    // hide the tooltip when the CodeMirror 'inputRead' event is triggered
    instance.state.textHover.inputRead = () => {
      handleHide();
      instance.state.textHover.disabled = true;
    };
    instance.on("inputRead", instance.state.textHover.inputRead);

    // hide the tooltip when Esc is pressed
    instance.state.textHover.keyMap = { Esc: handleHide };
    instance.addKeyMap(instance.state.textHover.keyMap);

    instance.state.textHover.hide = handleHide;
  };

  // call render 600ms after we mouseover
  const controller = new AbortController();

  instance.state.textHover.showTooltipTimeout = setTimeout(() => {
    // detach the cancelRender event listeners that were attached to the node
    controller.abort();
    render();
  }, 600);

  const cancelRender = () => {
    if (instance.state.textHover.showTooltipTimeout) {
      clearTimeout(instance.state.textHover.showTooltipTimeout);
    }
  };

  const listenerOptions = {
    once: true,
    passive: true,
    signal: controller.signal,
  };

  // if we mouseleave or click the node before render is called, clear the timeout
  node.addEventListener("mouseleave", cancelRender, listenerOptions);
  node.addEventListener("click", cancelRender, listenerOptions);
}

function handleMouseMove(instance: CodeMirror.Editor) {
  instance.state.textHover.disabled = false;
}

function optionHandler(
  instance: CodeMirror.Editor,
  current: Options,
  old: Options | CodeMirror.Init,
) {
  const wrapper = instance.getWrapperElement();

  if (old && old !== CodeMirror.Init) {
    CodeMirror.off(
      wrapper,
      "mouseover",
      instance.state.textHover.handleMouseOver,
    );
    CodeMirror.off(
      wrapper,
      "mousemove",
      instance.state.textHover.handleMouseMove,
    );

    delete instance.state.textHover;
  }

  if (current) {
    instance.state.textHover = {
      ...current,
      visible: true,
      disabled: false,
      keyMap: {},
      handleMouseOver(e: MouseEvent) {
        handleMouseOver(instance, e);
      },
      handleMouseMove(e: MouseEvent) {
        handleMouseMove(instance);
      },
    };

    CodeMirror.on(
      wrapper,
      "mouseover",
      instance.state.textHover.handleMouseOver,
    );
    CodeMirror.on(
      wrapper,
      "mousemove",
      instance.state.textHover.handleMouseMove,
    );
  }
}

CodeMirror.defineOption("textHover", false, optionHandler);
