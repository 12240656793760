import { KeyValueProperty } from "legacy/widgets/KeyValueWidget/types";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";

export const getColumnIdForAi = (
  columnId: string,
  columnConfig: ColumnProperties,
) => {
  if (!columnConfig?.isDerived) {
    return columnId;
  }
  // use the label or buton label
  let label = columnConfig.label;
  if (label == null || label === "" || typeof label !== "string") {
    label = columnId;
  }
  // replace spaces and non underscore/letter/numbers with underscores
  const cleanedLabel = label
    .replace(/\s+/g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "_");
  return cleanedLabel;
};

export const getColumnIdFromAi = (
  columnId: string,
  columns: Record<string, ColumnProperties>,
) => {
  // wasn't translated, just return the columnId
  if (columns[columnId]) {
    return columnId;
  }
  const matchedId = Object.keys(columns).find((key) => {
    const translatedId = getColumnIdForAi(key, columns[key]);
    return translatedId === columnId;
  });
  return matchedId ?? columnId;
};

export const getKvPropertyIdFromAi = (
  propertyId: string,
  existingProperties: Record<string, KeyValueProperty>,
) => {
  const matchedId = Object.entries(existingProperties).find(
    ([key, property]) => {
      return property.key === propertyId;
    },
  )?.[0];
  return matchedId ?? propertyId;
};

export const getKvPropertyIdForAi = (
  propertyId: string,
  existingProperties: Record<string, KeyValueProperty>,
) => {
  return existingProperties[propertyId]?.key ?? propertyId;
};
