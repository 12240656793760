import {
  ApiTriggerType,
  ApplicationScope,
  DatasourceDto,
  Plugin,
  ScheduleConfig,
  BaseBlock,
} from "@superblocksteam/shared";
import { useCallback, useContext, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { useSaga } from "hooks/store";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";
import { LayoutContext } from "legacy/pages/Editor/LayoutContext";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import { getCurrentRoutePathWithParams } from "legacy/selectors/routeSelectors";
import { ApiUnionTriggerType } from "store/slices/apisShared";
import { createV2ApiSaga, V2ApiPayloadCreators } from "store/slices/apisV2";
import {
  AddAPIBlockOption,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import {
  getV2ApiId,
  getV2ApiName,
} from "store/slices/apisV2/utils/getApiIdAndName";
import { selectOnlyOrganization } from "store/slices/organizations";
import { AppState } from "store/types";

export type CreatedApiInfo = {
  name: string;
  id: string;
  blockName?: string;
};

type Params =
  | {
      triggerType: ApiTriggerType.UI;
      // app scope is not yet implemented
      scope: ApplicationScope;
    }
  | {
      triggerType: ApiTriggerType.WORKFLOW;
    }
  | {
      triggerType: ApiTriggerType.SCHEDULE;
    };

export enum CreateApiSuccessOptions {
  OPEN_IN_NEW_TAB = "OPEN_IN_NEW_TAB",
  OPEN_IN_SAME_TAB = "OPEN_IN_SAME_TAB",
  NONE = "NONE",
}

type CreateApiOptions = {
  datasource?: DatasourceDto | undefined;
  plugin?: Plugin | undefined;
  v2ApiBlockOption?: AddAPIBlockOption;
  initialBlocks?: BaseBlock[];
  apiName?: string;
  folder?: string;
  schedule?: ScheduleConfig;
};

export default function useCreateNewApi(
  params: Params,
  onSuccessOption: CreateApiSuccessOptions,
) {
  const navigate = useNavigate();
  const location = useLocation();
  const [createV2Api] = useSaga(createV2ApiSaga, {
    resolveAfterUnmounted: true,
  });
  const organization = useSelector(selectOnlyOrganization);
  const { expandBottomPanel } = useContext(LayoutContext);
  const getEditorPath = useGetEditorPath();
  const store = useStore<AppState>();

  const creatingApi = useRef(false);

  const onSuccess = useCallback(
    (apiId: string, blockName?: string) => {
      const state = store.getState();
      const applicationId = getCurrentApplicationId(state);
      const currentRoute = getCurrentRoutePathWithParams(store.getState());
      if (onSuccessOption !== CreateApiSuccessOptions.NONE) {
        let successUrl = "";
        switch (params.triggerType) {
          case ApiTriggerType.UI:
            successUrl = getEditorPath(EditorRoute.EditApiAction, {
              applicationId,
              apiId,
              actionId: blockName,
              currentRoute,
            });
            break;
          case ApiTriggerType.SCHEDULE:
            successUrl = SCHEDULED_JOB_CONFIGURE_URL(apiId);
            break;
          case ApiTriggerType.WORKFLOW:
            successUrl = WORKFLOW_CONFIGURE_URL(apiId);
            break;
        }
        if (onSuccessOption === CreateApiSuccessOptions.OPEN_IN_NEW_TAB) {
          window.open(successUrl, "_blank", "noreferrer");
        } else {
          navigate({
            pathname: successUrl,
            search: location.search,
          });
          if (onSuccessOption === CreateApiSuccessOptions.OPEN_IN_SAME_TAB) {
            expandBottomPanel();
          }
        }
      }
    },
    [
      store,
      onSuccessOption,
      params.triggerType,
      getEditorPath,
      navigate,
      location.search,
      expandBottomPanel,
    ],
  );

  const createApi = useCallback(
    async ({
      datasource,
      plugin,
      v2ApiBlockOption,
      apiName,
      folder,
      schedule,
      initialBlocks,
    }: CreateApiOptions): Promise<void | CreatedApiInfo> => {
      let blockName;
      let payload:
        | ReturnType<(typeof V2ApiPayloadCreators)[ApiTriggerType.UI]>
        | ReturnType<(typeof V2ApiPayloadCreators)[ApiTriggerType.WORKFLOW]>
        | ReturnType<(typeof V2ApiPayloadCreators)[ApiTriggerType.SCHEDULE]>;
      let createInPageId: string | undefined = undefined;
      switch (params.triggerType) {
        case ApiTriggerType.UI: {
          blockName = "Step 1";
          const state = store.getState();
          const applicationId = getCurrentApplicationId(state);
          if (!applicationId) {
            throw new Error("ApplicationId is required for UI");
          }
          const currentPageId = getCurrentPageId(state);
          if (params.scope === ApplicationScope.PAGE && !currentPageId) {
            throw new Error("PageId is required for PAGE scope");
          }

          createInPageId =
            params.scope === ApplicationScope.PAGE ? currentPageId : undefined;

          if (initialBlocks) {
            payload = V2ApiPayloadCreators[ApiUnionTriggerType.UI]({
              name: apiName ?? undefined,
              initialBlocks,
              organizationId: organization.id,
              applicationId,
              pageId: createInPageId,
            });
          } else if (v2ApiBlockOption) {
            payload = V2ApiPayloadCreators[ApiUnionTriggerType.UI]({
              name: apiName ?? undefined,
              blockName: v2ApiBlockOption.optionName,
              option: v2ApiBlockOption,
              organizationId: organization.id,
              applicationId,
              pageId: createInPageId,
            });
          } else {
            payload = V2ApiPayloadCreators[ApiUnionTriggerType.UI]({
              name: apiName ?? undefined,
              blockName: "Step",
              option: {
                id: datasource?.id || "",
                type: BlockType.STEP,
                optionName: datasource?.name || "",
                plugin,
                datasource,
              },
              organizationId: organization.id,
              applicationId,
              pageId: createInPageId,
            });
          }
          break;
        }
        case ApiTriggerType.SCHEDULE: {
          blockName = datasource && plugin ? "Step 1" : "";
          payload = V2ApiPayloadCreators[ApiUnionTriggerType.SCHEDULE]({
            organizationId: organization.id,
            name: apiName ?? "",
            folder: folder,
            datasource,
            plugin,
            schedule,
          });
          break;
        }
        case ApiTriggerType.WORKFLOW: {
          payload = V2ApiPayloadCreators[ApiUnionTriggerType.WORKFLOW]({
            organizationId: organization.id,
            name: apiName ?? "",
            folder: folder,
          });
          break;
        }
      }

      const ret = await createV2Api({
        payload,
        pageId: createInPageId,
      });
      onSuccess(getV2ApiId(ret.result), payload?.blocks?.[0]?.name);
      return {
        name: getV2ApiName(ret.result),
        id: getV2ApiId(ret.result),
        blockName,
      };
    },
    [createV2Api, organization.id, params, onSuccess, store],
  );

  return useCallback(
    async (options: CreateApiOptions) => {
      if (creatingApi.current) {
        return;
      }
      creatingApi.current = true;

      try {
        return await createApi(options);
      } finally {
        creatingApi.current = false;
      }
    },
    [createApi],
  );
}
