// This was copied from react-vtree so that we can pass a new property
// (expandToHeight). This allows the JSON View to dynamically calculate its
// height based on the number of visible entries.
import { ReactNode } from "react";
import Tree, {
  createTreeComputer,
  NodeData,
  NodePublicState,
  TreeProps,
  TreeState,
} from "react-vtree/dist/es/Tree";
import { createBasicRecord, getIdByIndex } from "react-vtree/dist/es/utils";
import { FixedSizeList, FixedSizeListProps } from "react-window";

type FixedSizeNodeData = NodeData;

type FixedSizeNodePublicState<TData extends FixedSizeNodeData> =
  NodePublicState<TData>;

type FixedSizeTreeProps<TData extends FixedSizeNodeData> = TreeProps<
  TData,
  FixedSizeNodePublicState<TData>,
  FixedSizeList
> &
  Readonly<Pick<FixedSizeListProps, "itemSize">> & {
    expandToHeight: boolean;
    maxHeight?: number;
  };

type FixedSizeTreeState<TData extends FixedSizeNodeData> = TreeState<
  TData,
  FixedSizeNodePublicState<TData>,
  FixedSizeList
>;

const computeTree = createTreeComputer<
  FixedSizeNodeData,
  FixedSizeNodePublicState<FixedSizeNodeData>,
  FixedSizeTreeProps<FixedSizeNodeData>,
  FixedSizeTreeState<FixedSizeNodeData>
>({
  createRecord: (data, { recomputeTree }, parent, previousRecord) =>
    createBasicRecord(
      {
        data,
        isOpen: previousRecord
          ? previousRecord.public.isOpen
          : data.isOpenByDefault,
        setOpen: (state): Promise<void> =>
          recomputeTree({
            [data.id]: state,
          }),
      },
      parent,
    ),
});

export class FixedSizeTree<
  TData extends FixedSizeNodeData = FixedSizeNodeData,
> extends Tree<
  TData,
  FixedSizeNodePublicState<TData>,
  FixedSizeTreeProps<TData>,
  FixedSizeTreeState<TData>,
  FixedSizeList
> {
  public constructor(props: FixedSizeTreeProps<TData>, context: any) {
    super(props, context);

    this.state = {
      ...this.state,
      computeTree,
    };
  }

  public render(): ReactNode {
    const {
      children,
      listRef,
      placeholder,
      treeWalker,
      rowComponent,
      ...rest
    } = this.props;

    const { attachRefs, order } = this.state;

    if (!rowComponent) {
      return;
    }

    let height = rest.expandToHeight
      ? (order?.length ?? 0) * rest.itemSize
      : (rest.height ?? 300);

    // account for height possibly being a string like "50%"", because we can't clamp that
    if (rest.maxHeight && typeof height === "number") {
      height = Math.min(height, rest.maxHeight);
    }

    return placeholder && order?.length === 0 ? (
      placeholder
    ) : (
      <FixedSizeList
        {...rest}
        itemCount={order?.length ?? 0}
        itemData={this.getItemData()}
        itemKey={getIdByIndex}
        ref={attachRefs}
        height={height}
      >
        {rowComponent}
      </FixedSizeList>
    );
  }
}
