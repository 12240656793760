import { RouteDef } from "@superblocksteam/shared";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { GeneratedTheme } from "legacy/themes";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { AppState } from "store/types";
import { AllFlags } from "../featureFlags";
import { ClarkProcessor } from "./ClarkProcessor";
import {
  ClarkAction,
  EditMetadata,
  DiscardedEdit,
  ClarkActionFuntions,
} from "./types";

export type ProcessActionsIntoChangesResult = {
  changedKeys?: Record<string, string[]>;
  dataTreeChanges: Record<string, Record<string, unknown> | null>;
  widgetRenamesByWidgetId?: Record<string, string>;
  discardedEdits: Record<string, DiscardedEdit[]>;
  metadataByWidgetId?: Record<string, EditMetadata | undefined>;
  apiChanges?: Record<string, ApiDtoWithPb>;
  createdApiNamesToIds?: Record<string, string>;
};

let clarkProcessorInstance: ClarkProcessor | null = null;

export const processActionsIntoChanges = ({
  clarkRequestId,
  actions,
  dataTree,
  routes,
  theme,
  nameValidator,
  featureFlags,
  widgets,
  apisV2,
  getState,
  selectedWidgetId,
  appMode,
  dynamicWidgetLayout,
  widgetsRuntime,
  widgetMetaProps,
  evaluatedWidgets,
  previousAiWidgets,
  organizationId,
  applicationId,
  pageId,
  createComponentFn,
  updateComponentFn,
  deleteComponentFn,
  deleteComponentPropertiesFn,
  renameEntityFn,
  createApiFn,
  updateApiFn,
  addSectionColumnFn,
}: {
  clarkRequestId: string;
  actions: ClarkAction[];
  dataTree: DataTree;
  routes: RouteDef[];
  theme: GeneratedTheme;
  appMode: APP_MODE;
  dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets>;
  widgetsRuntime: CanvasWidgetsReduxState;
  widgetMetaProps: WidgetMetadata;
  evaluatedWidgets: Record<string, DataTreeWidget>;
  previousAiWidgets: Record<string, DataTreeWidget>;
  nameValidator: ReturnType<typeof createNameValidator>;
  featureFlags: Partial<AllFlags>;
  widgets: Record<string, Partial<WidgetProps>>;
  apisV2: Record<string, ApiDtoWithPb>;
  getState: () => AppState;
  selectedWidgetId: string;
  organizationId: string;
  applicationId: string;
  pageId: string;
  createComponentFn: ClarkActionFuntions["createComponentFn"];
  updateComponentFn: ClarkActionFuntions["updateComponentFn"];
  deleteComponentFn: ClarkActionFuntions["deleteComponentFn"];
  deleteComponentPropertiesFn: ClarkActionFuntions["deleteComponentPropertiesFn"];
  renameEntityFn: ClarkActionFuntions["renameEntityFn"];
  createApiFn: ClarkActionFuntions["createApiFn"];
  updateApiFn: ClarkActionFuntions["updateApiFn"];
  addSectionColumnFn: ClarkActionFuntions["addSectionColumnFn"];
}): ProcessActionsIntoChangesResult => {
  if (
    !clarkProcessorInstance ||
    clarkProcessorInstance.clarkRequestId !== clarkRequestId
  ) {
    clarkProcessorInstance = new ClarkProcessor(
      {
        clarkRequestId,
        dataTree,
        routes,
        nameValidator,
        theme,
        featureFlags,
        canvasWidgets: widgets,
        apisV2,
        selectedWidgetId,
        appMode,
        dynamicWidgetLayout,
        widgetsRuntime,
        widgetMetaProps,
        evaluatedWidgets,
        previousAiWidgets,
        organizationId,
        applicationId,
        pageId,
      },
      getState,
      {
        createComponentFn,
        updateComponentFn,
        deleteComponentFn,
        deleteComponentPropertiesFn,
        renameEntityFn,
        createApiFn,
        updateApiFn,
        addSectionColumnFn,
      },
    );
  }

  if (!clarkProcessorInstance) {
    throw new Error("No current Clark processor");
  }

  const currentClarkProcessor = clarkProcessorInstance;

  const defaultComponentName = widgets[selectedWidgetId]?.widgetName ?? "";

  actions.forEach((action) => {
    currentClarkProcessor.addAction(action, defaultComponentName);
  });

  if (!currentClarkProcessor.hasResults()) {
    return {
      changedKeys: {},
      dataTreeChanges: {},
      widgetRenamesByWidgetId: {},
      discardedEdits: {},
      metadataByWidgetId: {},
      apiChanges: {},
      createdApiNamesToIds: {},
    };
  }

  currentClarkProcessor.createDataTreeChanges();

  return currentClarkProcessor.getResult();
};
