import { AccessMode } from "@superblocksteam/shared";
import { values, merge, keyBy } from "lodash";
import { call, select } from "redux-saga/effects";
import { getAccessMode } from "../../../../legacy/selectors/usersSelectors";
import { createSaga } from "../../../utils/saga";
import { getAnalytics } from "../client";
import slice from "../slice";

type TaskResult = Awaited<ReturnType<typeof getAnalytics>>;

function* getUserAnalyticsInternal() {
  const accessMode: ReturnType<typeof getAccessMode> =
    yield select(getAccessMode);
  if (
    accessMode === AccessMode.EXTERNAL_USER ||
    accessMode === AccessMode.VISITOR
  ) {
    return;
  }
  const result: TaskResult = yield call(getAnalytics);
  return result;
}

export const getUserAnalyticsSaga = createSaga(
  getUserAnalyticsInternal,
  "getUsersAnalyticsSaga",
  { sliceName: "user" },
);

slice.saga(getUserAnalyticsSaga, {
  start(state) {
    state.loading.checklistTasks = true;
    state.loading.recommendedDatasources = true;
  },
  success(state, { payload }) {
    const tasks = values(
      merge(
        keyBy(state.entity.checklistTasks, "id"),
        keyBy(payload?.checklist?.tasks, "id"),
      ),
    );
    state.entity.checklistTasks = tasks;
    state.entity.recommendedDatasources = payload?.recommendedDatasources || [];

    state.loading.checklistTasks = false;
    state.loading.recommendedDatasources = false;
  },
  error(state, { payload }) {
    state.loading.checklistTasks = false;
    state.loading.recommendedDatasources = false;
    state.error.checklistTasks = payload;
    state.error.recommendedDatasources = payload;
  },
});
