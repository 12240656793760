import {
  ApplicationScope,
  OBS_TAG_ENTITY_ID,
  OBS_TAG_ENTITY_NAME,
  OBS_TAG_ENTITY_TYPE,
  TriggerStepType,
  getNextEntityName,
} from "@superblocksteam/shared";
import { isEqual } from "lodash";
import { all, takeEvery } from "redux-saga/effects";
import { put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  UpdateEmbedPropMetaPropertiesPayload,
  editEmbedPropPropertyPane,
} from "legacy/actions/embeddingActions";
import {
  pageLoadSuccess,
  updatePartialLayout,
} from "legacy/actions/pageActions";
import {
  deleteEntityFromWidgets,
  runEventHandlers,
  selectWidgets,
} from "legacy/actions/widgetActions";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  EMBED_PATH_PREFIX,
  EmbedProperty,
  EmbedPropertyMap,
} from "legacy/constants/EmbeddingConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { ReduxPageType } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { getEmbedEntityNames } from "legacy/selectors/dataTreeSelectors";
import { getMainContainer } from "legacy/selectors/entitiesSelector";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import {
  getAllEmbedPropertiesAsArray,
  getInitialEmbedValues,
  getEmbedPropMetaIdValueMap,
  getEmbedProperties,
  getTriggerableEmbedEvents,
} from "legacy/selectors/sagaSelectors";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import { getEventByName } from "store/slices/application/events/selectors";
import { deleteEntityFromTimers } from "store/slices/application/timers/timerActions";
import { fastClone } from "utils/clone";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { EmbedEventType } from "utils/embed/messages";
import logger from "utils/logger";

function* updateEmbedPropertySaga(
  action: ReduxAction<ReduxPageType["embedding"]>,
): Generator<any, any, any> {
  try {
    const updates = action.payload;
    const mainContainerWidget: ReturnType<typeof getMainContainer> =
      yield select(getMainContainer);

    // No embed prop, just return
    if (!mainContainerWidget?.embedding) return;

    const newEmbedPropertyMap: Record<EmbedProperty["id"], EmbedProperty> =
      fastClone(mainContainerWidget.embedding.propertyMap);

    Object.entries(updates).forEach(([embedPropId, update]) => {
      newEmbedPropertyMap[embedPropId] = {
        ...newEmbedPropertyMap[embedPropId],
        ...update,
      };
    });

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        embedding: {
          ...mainContainerWidget.embedding,
          propertyMap: newEmbedPropertyMap,
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));
  } catch (error) {
    logger.error(
      `EMBED_PROP_OPERATION_ERROR action type: ${
        ReduxActionTypes.UPDATE_EMBED_PROPERTY
      } error: ${(error as any)?.message}, payload: ${JSON.stringify(
        action.payload,
      )}`,
    );
    yield put({
      type: ReduxActionErrorTypes.EMBED_PROP_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.UPDATE_EMBED_PROPERTY,
        error,
      },
    });
  }
}

// createEmbedPropertySaga can be passed with an ID and Name if these are controlled by
// the caller E.g. when created from a form-based trigger.
function* createEmbedPropertySaga(
  action: ReduxAction<{
    id?: string;
    name?: string;
    open?: boolean;
  }>,
): Generator<any, any, any> {
  try {
    const id = action.payload.id ?? uuidv4();

    let name = action.payload.name;
    if (!name) {
      const entityNames = yield select(getEmbedEntityNames);
      name = getNextEntityName("EmbedProp", [...entityNames]);
    }
    if (!name) {
      // Ensure that names are unique.
      throw new Error("Failed to generate name");
    }

    // Get all the widgets from the canvasWidgetsReducer
    const stateWidgets = yield select(getWidgets);

    // Parent widgetId of the timer is always the main container right now
    const mainContainerWidget = { ...stateWidgets[PAGE_WIDGET_ID] };

    if (!mainContainerWidget.embedding) {
      mainContainerWidget.embedding = {
        propertyMap: {},
      };
    }

    const newEmbedProp = {
      id: id,
      name: name,
      defaultValue: "",
      createdAt: new Date().getTime(),
    };

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        embedding: {
          ...mainContainerWidget.embedding,
          propertyMap: {
            ...mainContainerWidget.embedding.propertyMap,
            [newEmbedProp.id]: newEmbedProp,
          },
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));

    if (action.payload.open) {
      yield put(editEmbedPropPropertyPane(newEmbedProp.id));
      yield put(selectWidgets([]));
    }
  } catch (error) {
    logger.error(
      `EMBED_PROP_OPERATION_ERROR action type: ${
        ReduxActionTypes.CREATE_EMBED_PROPERTY
      } error: ${(error as any)?.message}, payload: ${JSON.stringify(
        action.payload,
      )}`,
    );
    yield put({
      type: ReduxActionErrorTypes.EMBED_PROP_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.CREATE_EMBED_PROPERTY,
        error,
      },
    });
  }
}

function* deleteEmbedPropSaga(
  action: ReduxAction<{ id: EmbedProperty["id"] }>,
): Generator<any, any, any> {
  try {
    const { id } = action.payload;
    const mainContainerWidget: ReturnType<typeof getMainContainer> =
      yield select(getMainContainer);

    // No embedProps, just return
    if (!mainContainerWidget?.embedding.propertyMap) return;

    // Clone the embed prop and make the edit
    const newEmbedPropMap = fastClone(
      mainContainerWidget.embedding.propertyMap,
    ) as EmbedPropertyMap;

    const embedProp = newEmbedPropMap[id];
    const embedPropName = embedProp.name;

    delete newEmbedPropMap[id];

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        embedding: {
          ...mainContainerWidget.embedding,
          propertyMap: newEmbedPropMap,
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));

    // TODO(pbardea): These 2 should always be called together - they should be
    // combined.
    yield put(deleteEntityFromWidgets(embedPropName));
    yield put(deleteEntityFromTimers(embedPropName));
  } catch (error) {
    logger.error(
      `EMBED_PROP_OPERATION_ERROR action type: ${
        ReduxActionTypes.DELETE_EMBED_PROPERTY
      } error: ${(error as any)?.message}, payload: ${JSON.stringify(
        action.payload,
      )}`,
    );
    yield put({
      type: ReduxActionErrorTypes.EMBED_PROP_OPERATION_ERROR,
      payload: {
        action: ReduxActionTypes.DELETE_EMBED_PROPERTY,
        error,
      },
    });
  }
}

function* initializeEmbedMetaPropsSaga() {
  const knownEmbedProperties: ReturnType<typeof getAllEmbedPropertiesAsArray> =
    yield select(getAllEmbedPropertiesAsArray);
  if (!knownEmbedProperties) return;
  const initialEmbedPropValues: ReturnType<typeof getInitialEmbedValues> =
    yield select(getInitialEmbedValues);
  const updates: Record<string, { value: any }> = {};
  for (const embedPropConfig of knownEmbedProperties) {
    const { id, name } = embedPropConfig;
    updates[id] = {
      value: initialEmbedPropValues?.[name],
    };
  }
  yield put({
    type: ReduxActionTypes.UPDATE_EMBED_PROPERTY_META,
    payload: { updates },
  });
}

function* updateEmbedMetaPropsSaga(
  action: ReduxAction<UpdateEmbedPropMetaPropertiesPayload>,
) {
  const updates = action.payload.updates;
  // 1. update the embed meta props in redux
  const embedPropertiesMap: ReturnType<typeof getEmbedProperties> =
    yield select(getEmbedProperties);
  const existingEmbedValues: ReturnType<typeof getEmbedPropMetaIdValueMap> =
    yield select(getEmbedPropMetaIdValueMap);
  const changedValueIds = [];
  for (const [id, { value }] of Object.entries(updates)) {
    if (!isEqual(existingEmbedValues[id], value)) {
      changedValueIds.push(id);
    }
  }
  yield put({
    type: ReduxActionTypes.UPDATE_EMBED_PROPERTY_META,
    payload: { updates },
  });

  // 2. run any onChange callbacks for the affected embed props
  for (const id of changedValueIds) {
    const { onChange, name } = embedPropertiesMap[id];
    yield put(
      runEventHandlers(
        createRunEventHandlersPayload({
          steps: onChange as MultiStepDef,
          type: EventType.ON_EMBED_PROP_CHANGE,
          entityName: name,
          // TODO: change when it supports APP
          currentScope: ApplicationScope.PAGE,
          additionalEventAttributes: {
            [OBS_TAG_ENTITY_TYPE]: ENTITY_TYPE.EMBED_PROP,
            [OBS_TAG_ENTITY_ID]: id,
            [OBS_TAG_ENTITY_NAME]: name,
            pathToDisplay: `${EMBED_PATH_PREFIX}.${name}.onChange`,
          },
        }),
      ),
    );
  }
}

function* triggerCustomEventSaga(
  action: ReduxAction<{
    eventName: string;
    eventPayload: Record<string, any>;
  }>,
) {
  const { eventName: fullEventName, eventPayload } = action.payload;

  // events triggered from outside superblocks will not have scoping information on them,
  // we need to get that from the event name i.e. `App.setUser` vs `setUser`
  let scope = ApplicationScope.PAGE;
  let eventName = fullEventName;
  if (eventName.startsWith("App.")) {
    scope = ApplicationScope.APP;
    eventName = eventName.split(".")[1];
  }

  // get the event from the event store
  const event: ReturnType<typeof getEventByName> = yield select(
    getEventByName,
    eventName,
    scope,
  );
  if (!event) {
    console.warn(`Event ${eventName} not found`);
    return;
  }
  // check if the event is triggerable
  const triggerableEvents: ReturnType<typeof getTriggerableEmbedEvents> =
    yield select(getTriggerableEmbedEvents);
  if (!triggerableEvents?.[event.id]) {
    console.warn(`Event ${eventName} is not triggerable`);
    return;
  }
  const { arguments: knownEventArguments } = event;
  const convertedEventPayload = knownEventArguments.reduce(
    (accum, { id, name }) => {
      accum[id] = eventPayload[name];
      return accum;
    },
    {} as Record<string, any>,
  );

  yield put(
    runEventHandlers(
      createRunEventHandlersPayload({
        steps: [
          {
            id: "",
            type: TriggerStepType.TRIGGER_EVENT,
            event: {
              id: event.id,
              name: event.name,
              scope,
            },
            eventPayload: convertedEventPayload,
          },
        ],
        // TODO(APP_SCOPE): change when embedding supports APP
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_EMBED_EVENT,
        entityName: "",
      }),
    ),
  );
}

function* updateEmbedEventsSaga(
  action: ReduxAction<{
    eventsType: EmbedEventType;
    events: Record<string, boolean>;
  }>,
) {
  const { eventsType, events } = action.payload;
  const mainContainerWidget: ReturnType<typeof getMainContainer> =
    yield select(getMainContainer);

  if (!mainContainerWidget) return;
  const eventsToUpdate =
    eventsType === EmbedEventType.EMIT ? "emittedEvents" : "triggerableEvents";
  const updatedWidgetsPartial = {
    [PAGE_WIDGET_ID]: {
      ...mainContainerWidget,
      embedding: {
        ...mainContainerWidget.embedding,
        // make sure property map is always set to avoid schema issues
        propertyMap: mainContainerWidget?.embedding?.propertyMap ?? {},
        [eventsToUpdate]: events,
      },
    },
  };
  yield put(updatePartialLayout(updatedWidgetsPartial));
}

export default function* EmbeddingSagas() {
  yield all([
    takeEvery(ReduxActionTypes.CREATE_EMBED_PROPERTY, createEmbedPropertySaga),
    takeEvery(ReduxActionTypes.UPDATE_EMBED_PROPERTY, updateEmbedPropertySaga),
    takeEvery(ReduxActionTypes.DELETE_EMBED_PROPERTY, deleteEmbedPropSaga),
    takeEvery(pageLoadSuccess.type, initializeEmbedMetaPropsSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_EMBED_PROPERTY_META_ON_CHANGE,
      updateEmbedMetaPropsSaga,
    ),
    takeEvery(ReduxActionTypes.UPDATE_EMBED_EVENTS, updateEmbedEventsSaga),
    takeEvery(ReduxActionTypes.TRIGGER_CUSTOM_EVENT, triggerCustomEventSaga),
  ]);
}
