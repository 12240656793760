import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHomepageApplicationV2Dto } from "@superblocksteam/shared";
import { startEvaluation } from "legacy/actions/evaluationActions";
import { updateApplicationMetadata } from "../application/applicationActions";

const initialState = {
  applications: [] as IHomepageApplicationV2Dto[],
  isCreatingApplication: false,
  isDeletingApplication: false,
  isFetchingApplications: true, // starts in loading state
  isDuplicatingApplication: false,
};

/**
 * delete & getAll will be deprecated here, using reduxApi/applications
 * still keeping create & deplicate since they more complicated (use CreateApplicationSaga  & DuplicateApplicationSaga)
 */
export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    getAllApplicationsSuccess(
      state,
      action: PayloadAction<IHomepageApplicationV2Dto[]>,
    ) {
      state.applications = action.payload;
      state.isFetchingApplications = false;
    },
    createApplicationInit(
      state,
      action: PayloadAction<{
        applicationName: string;
        orgId: string;
        folderId?: string;
        navigateOpts?: {
          replace?: boolean;
        };
      }>,
    ) {
      state.isCreatingApplication = true;
    },
    createApplicationSuccess(
      state,
      action: PayloadAction<IHomepageApplicationV2Dto>,
    ) {
      state.isCreatingApplication = false;
      state.applications.push(action.payload);
    },
    createApplicationError(state) {
      state.isCreatingApplication = false;
    },
    deleteApplicationInit(
      state,
      action: PayloadAction<{ applicationId: string }>,
    ) {
      state.isDeletingApplication = true;
    },
    deleteApplicationSuccess(state, action: PayloadAction<{ id: string }>) {
      state.applications = state.applications.filter(
        (app) => app.id !== action.payload.id,
      );
      state.isDeletingApplication = false;
    },
    deleteApplicationError(state) {
      state.isDeletingApplication = false;
    },
    getAllApplicationsInit(state) {
      state.isFetchingApplications = true;
    },
    fetchAllApplicationsError(state, action: PayloadAction<string>) {
      state.isFetchingApplications = false;
    },
    duplicateApplicationInit(
      state,
      action: PayloadAction<{ applicationId: string }>,
    ) {
      state.isDuplicatingApplication = true;
    },
    duplicateApplicationSuccess(state) {
      state.isDuplicatingApplication = false;
    },
    duplicateApplicationError(state) {
      state.isDuplicatingApplication = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(startEvaluation, () => initialState)
      .addCase(updateApplicationMetadata, (state, action) => {
        state.applications.forEach((app) => {
          if (app.id !== action.payload.id) return;
          // immediately update name and folder
          if (action.payload.name) app.name = action.payload.name;
          if (action.payload.folderId !== undefined)
            app.folderId = action.payload.folderId;
        });
      }),
});

export const {
  getAllApplicationsInit,
  fetchAllApplicationsError,
  getAllApplicationsSuccess,
  createApplicationInit,
  createApplicationSuccess,
  createApplicationError,
  deleteApplicationInit,
  deleteApplicationSuccess,
  deleteApplicationError,
  duplicateApplicationInit,
  duplicateApplicationSuccess,
  duplicateApplicationError,
} = homepageSlice.actions;
