import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowTopLeftIcon } from "assets/icons/home/arrow-top-left.svg";
import useExpandEditor from "components/app/CodeEditor/hooks/useExpandEditor";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { formatPropertyPathWithIds } from "legacy/utils/BottomPanelTabUtils";

const ExpandBtn = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.WHITE};
  box-shadow:
    0px 0px 0px 1px rgba(34, 39, 47, 0.04),
    0px 1px 2px rgba(34, 39, 47, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_50};
  }
`;

type ExpandEditorProps = {
  properties?: any;
  propertyPath?: string;
  itemKind: ItemKinds | undefined;
};

const ExpandCodeEditorBtn = ({
  properties,
  propertyPath,
  itemKind,
}: ExpandEditorProps) => {
  const expandEditor = useExpandEditor();
  const propertyPathWithIds = formatPropertyPathWithIds(
    properties,
    propertyPath || "",
  );

  const itemId = useMemo(() => {
    switch (itemKind) {
      case ItemKinds.WIDGET:
        return properties.widgetId;
      case ItemKinds.STATE_VAR:
      case ItemKinds.CUSTOM_EVENT:
      case ItemKinds.TIMER:
      case ItemKinds.API_V2:
        return properties.id;
      default:
        return null;
    }
  }, [itemKind, properties]);

  const onExpandEditor = useCallback(() => {
    if (itemId && propertyPathWithIds && itemKind) {
      expandEditor({
        entityId: itemId,
        property: propertyPathWithIds,
        itemKind,
      });
    }
  }, [itemId, propertyPathWithIds, itemKind, expandEditor]);

  if (!propertyPath || !itemKind || !itemId) return null;

  return (
    <Tooltip
      placement="left"
      title="Expand editor into bottom tab"
      // Important so that the overaly doesn't block clicking tabs
      overlayStyle={{ pointerEvents: "none" }}
      mouseEnterDelay={0.5}
    >
      <ExpandBtn
        onClick={onExpandEditor}
        className="expand-editor-btn"
        data-test="expand-editor-btn"
      >
        <ArrowTopLeftIcon />
      </ExpandBtn>
    </Tooltip>
  );
};

export default ExpandCodeEditorBtn;
