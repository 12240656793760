import { ApplicationScope, RouteDef } from "@superblocksteam/shared";
import {
  all,
  select,
  call,
  put,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";
import { setMetaProp } from "legacy/actions/metaActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import {
  closeModal,
  focusWidget,
  selectWidgets,
  showModal,
} from "legacy/actions/widgetActions";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getWidget } from "legacy/selectors/entitiesSelector";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import {
  getWidgetByName,
  getWidgetsMeta,
  getWidgetIdsByType,
  getWidgetMetaProps,
} from "legacy/selectors/sagaSelectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { sendWarningUINotification } from "utils/notification";
import type { WidgetProps } from "legacy/widgets";

function* showModalSaga(
  action: ReturnType<typeof showModal>,
): Generator<any, any, any> {
  // First we close the currently open modals (if any)
  yield call(closeModalSaga, closeModal(undefined, action.payload.modalId));

  // if child is selected from nav, we only need to show modal but not select.
  if (!action.payload.doNotSelect) {
    yield put(
      selectWidgets(
        [action.payload.modalId],
        action.payload.isSelectingMultiple,
      ),
    );
    yield put(focusWidget(action.payload.modalId));
  }

  // Warn the user about opening widgets directly in edit mode if they have routes to that modal/slideout
  const appMode = yield select(getAppMode);
  const multipageEnabled = yield select(selectFlagById, Flag.ENABLE_MULTIPAGE);
  if (appMode === APP_MODE.EDIT && multipageEnabled) {
    const routes: RouteDef[] = yield select(getRoutesList);
    const widgetRoutes = routes.filter(
      (route) =>
        "widgetId" in route && route.widgetId === action.payload.modalId,
    );
    if (widgetRoutes.length && !action.payload.isRouteInitiated) {
      const widget: WidgetProps = yield select(
        getWidget,
        action.payload.modalId,
      );
      sendWarningUINotification({
        message: `Please use the Navigate to Page action to open ${widget.widgetName}. Opening it directly will not modify the URL.`,
        isUISystemInitiated: true,
      });
    }
  }

  const metaProps = yield select(getWidgetMetaProps, action.payload.modalId);
  if (!metaProps || !metaProps.isVisible) {
    // Then show the modal we would like to show.
    yield put(setMetaProp(action.payload.modalId, "isVisible", true));
    yield delay(1000);
  }
  if (action.payload.showPropertyPane) {
    yield put(
      showItemPropertyPane({
        kind: ItemKinds.WIDGET,
        id: action.payload.modalId,
        scope: ApplicationScope.PAGE,
      }),
    );
  }
}

function* closeModalSaga(
  action: ReturnType<typeof closeModal>,
): Generator<any, any, any> {
  try {
    const { modalName } = action.payload;
    let widgetIds: string[] = [];
    // If modalName is provided, we just want to close this modal
    if (modalName) {
      const widget = yield select(getWidgetByName, modalName);
      widgetIds = [widget.widgetId];
      yield put(showItemPropertyPane());
    } else {
      // If modalName is not provided, find all open modals
      // Get all meta prop records
      const metaProps: Record<string, any> = yield select(getWidgetsMeta);

      // Get widgetIds of all widgets of type MODAL_WIDGET
      const modalWidgetIds: string[] = yield select(
        getWidgetIdsByType,
        WidgetTypes.MODAL_WIDGET,
      );
      const slideoutWidgetIds: string[] = yield select(
        getWidgetIdsByType,
        WidgetTypes.SLIDEOUT_WIDGET,
      );
      modalWidgetIds.push(...slideoutWidgetIds);

      // Loop through all modal widgetIds
      modalWidgetIds.forEach((widgetId: string) => {
        // Check if modal is open
        if (metaProps[widgetId] && metaProps[widgetId].isVisible) {
          // Add to our list of widgetIds
          widgetIds.push(widgetId);
        }
      });
    }
    widgetIds = action.payload.exclude
      ? widgetIds.filter((id: string) => id !== action.payload.exclude)
      : widgetIds;
    // If we have modals to close, set its isVisible to false to close.
    if (widgetIds) {
      for (const widgetId of widgetIds) {
        yield put(setMetaProp(widgetId, "isClosing", true));
        yield put(setMetaProp(widgetId, "isOpen", false));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* modalSagas() {
  yield all([
    takeEvery(closeModal.type, closeModalSaga),
    takeLatest(showModal.type, showModalSaga),
  ]);
}
