import {
  Dimension,
  getDynamicBindings,
  getNextEntityName,
  Padding,
  WidgetPosition,
} from "@superblocksteam/shared";
import { isString, isArray } from "lodash";
import { XYCoord } from "react-dnd";
import { select, put } from "redux-saga/effects";
import {
  setMetaProps,
  resetWidgetMetaProperty,
} from "legacy/actions/metaActions";
import { undoAction, WidgetAddChild } from "legacy/actions/pageActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  GridDefaults,
  WidgetType,
  WidgetTypes,
  SectionDefaults,
  DETACHED_WIDGETS,
  MODAL_ROWS_PRESETS,
  ModalSize,
  CanvasLayout,
  WidgetWidthModes,
  CANVAS_LAYOUT_WIDTH_MODES,
  CANVAS_LAYOUT_HEIGHT_MODES,
  CanvasDefaults,
  WidgetHeightModes,
  PAGE_WIDGET_ID,
  MODAL_COLUMNS,
  MODAL_LEGACY_COLUMNS,
  SLIDEOUT_DEFAULT_COLUMNS,
  SLIDEOUT_DEFAULT_ROWS,
} from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import WidgetConfigResponse, {
  WidgetConfigProps,
  WidgetBlueprint,
} from "legacy/mockResponses/WidgetConfigResponse";
import { getWidgetBlueprint } from "legacy/mockResponses/selectors";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { DynamicWidgetsLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  DynamicChanges,
  YInfos,
  dynamicHeight,
} from "legacy/sagas/autoHeight/compositeReflowTypes";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import { getWidgetParentIds } from "legacy/selectors/editorSelectors";
import { GeneratedTheme } from "legacy/themes";
import {
  isPathADynamicProperty,
  isPathADynamicTrigger,
} from "legacy/utils/DynamicBindingUtils";
import { getHstackCanvasRemainingWidthPx } from "legacy/utils/StackWidgetUtils";
import { evenlyDivideReduction, evenlyDivide } from "legacy/utils/Utils";
import {
  getSectionColsForParentType,
  getMaxGridColsForParentType,
  getCanvasSnapRows,
} from "legacy/utils/WidgetPropsUtils";
import { buildView } from "legacy/utils/WidgetPropsUtils";
import { WidgetProps, WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import WidgetFactory, { WidgetActionHookSync } from "legacy/widgets/Factory";
import {
  isStackLayout,
  type StackDragPositions,
} from "legacy/widgets/StackLayout/utils";
import {
  findExactFreePosition,
  findNearbyFreePosition,
  getDropZoneOffsets,
} from "legacy/widgets/base/ResizableUtils";
import { CantFit } from "legacy/widgets/base/ResizableUtils/getAvailableRectInDropZone";
import {
  getCanvasMinHeightFlattened,
  getWidgetDefaultPadding,
  isDynamicSize,
} from "legacy/widgets/base/sizing";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";
import { AllFlags } from "store/slices/featureFlags/models/Flags";
import { GeneratorReturnType } from "store/utils/types";
import { getDropTargetResultType } from "utils/drop";
import { UIEvent } from "utils/event";
import log from "utils/logger";
import {
  hasLeftRightProperties,
  scaledXYCoord,
  getCanvasInternalMinWidthPx,
} from "utils/size";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";
import type { WidgetTypeToPropType } from "legacy/widgets";
import type {
  CanvasWidgetsReduxState,
  WidgetActionResponse,
} from "legacy/widgets/Factory";

const getColumnPaddingY = (
  columnWidget: WidgetProps,
  theme: GeneratedTheme,
): Dimension<"gridUnit"> => {
  const columnPadding =
    columnWidget.padding ?? getWidgetDefaultPadding(theme, columnWidget);

  // TODO(Layouts): Round down here not up
  const paddingY = Dimension.toGridUnit(
    Padding.y(columnPadding),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw();

  return Dimension.gridUnit(paddingY.value);
};

// This is a fix for a bug where Modals sometimes get set to width and gridColumns < 96. They should always be 96.
// When this happens the Section and only column also get set to 48, but they should also be 96. This causes problems
// that size.ts cannot fix on its own, so this function fixes the section and column in the DSL
export const fixWrongSectionWidgetWidth = (
  widgets: CanvasWidgetsReduxState,
  sectionWidget: FlattenedWidgetProps,
) => {
  const sectionParent = widgets[sectionWidget.parentId];

  // get the right number of grid columns for the section
  const defaultSectionGridColumns = getMaxGridColsForParentType(
    sectionParent.type,
  );

  if (
    sectionWidget.gridColumns !== defaultSectionGridColumns ||
    sectionWidget.width.value !== defaultSectionGridColumns
  ) {
    widgets[sectionWidget.widgetId] = {
      ...sectionWidget,
      gridColumns: defaultSectionGridColumns,
      width: Dimension.gridUnit(defaultSectionGridColumns),
    };
  }
  const numCols = sectionWidget.children?.length || 0;
  const firstCol = widgets[sectionWidget.children?.[0] || ""];
  if (
    numCols === 1 &&
    firstCol &&
    (firstCol.width.value !== defaultSectionGridColumns ||
      firstCol.gridColumns !== defaultSectionGridColumns)
  ) {
    widgets[firstCol.widgetId] = {
      ...firstCol,
      gridColumns: defaultSectionGridColumns,
      width: Dimension.gridUnit(defaultSectionGridColumns),
    };
  }

  // Populate any wrong widths if gridColumns is available
  // this is to fix any pre-existing main canvases that didn't
  // have a width value properly set
  (widgets[sectionWidget.widgetId].children || []).forEach((childId) => {
    const column = widgets[childId];
    if (
      column.width.value < SectionDefaults.MIN_COLUMN_GRID_COLUMNS &&
      column.gridColumns !== undefined
    ) {
      widgets[childId] = {
        ...widgets[childId],
        width: Dimension.gridUnit(column.gridColumns),
      };
    }
  });
};

/**
 * If the canvas widget being resized is a section column we want to update all
 * columns sizes to match. Their size should all be equal to the highest bottomRow value
 * across the children of ALL the columns. Then the section widget parent itself also needs to be
 * updated to match this same value.
 * If we pass in newGridRows, then we use that value instead of the max bottom row value.
 */
export function updateSectionWidgetCanvasHeights(
  widgets: CanvasWidgetsReduxState,
  theme: GeneratedTheme,
  appMode: APP_MODE,
  sectionWidget: FlattenedWidgetProps,
  changedYs?: YInfos,
  changes?: DynamicChanges,
): CanvasWidgetsReduxState {
  // Do not run this helper unless the widget is a SectionWidget
  if (sectionWidget.type !== WidgetTypes.SECTION_WIDGET) return widgets;

  const latestHeights = getSectionWidgetCanvasHeights(
    widgets,
    theme,
    appMode,
    sectionWidget,
    changes,
  );

  for (const widgetId of Object.keys(latestHeights)) {
    if (latestHeights[widgetId].changed) {
      widgets[widgetId] = {
        ...widgets[widgetId],
        height: latestHeights[widgetId].height,
      };
      if (changedYs) {
        changedYs[widgetId] = {
          top: 0,
          height: Dimension.toPx(
            latestHeights[widgetId].height,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).value,
        };
      }
      if (changes) {
        changes[widgetId] = dynamicHeight(
          Dimension.toPx(
            latestHeights[widgetId].height,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).value,
        );
      }
    }
  }

  return widgets;
}

function getSectionWidgetCanvasHeights(
  widgets: CanvasWidgetsReduxState,
  theme: GeneratedTheme,
  appMode: APP_MODE,
  sectionWidget: FlattenedWidgetProps,
  changes?: DynamicChanges,
): Record<
  FlattenedWidgetProps["widgetId"],
  {
    height: FlattenedWidgetProps["height"];
    changed: boolean;
  }
> {
  const widgetIsHidden = (widgetId: string) => {
    return changes?.[widgetId]?.type === "visibility"
      ? !changes[widgetId].value
      : false;
  };

  const heights: ReturnType<typeof getSectionWidgetCanvasHeights> = {};

  // Do not run this helper unless the widget is a SectionWidget
  if (sectionWidget.type !== WidgetTypes.SECTION_WIDGET) return heights;

  // The section height is fixed:
  // Section height is simply the height of the section that has been set (section.height)
  // Column heights are a MAX of column height to fit content and section fixed height value
  if (sectionWidget.height.mode !== "fitContent") {
    for (const columnWidgetId of sectionWidget.children || []) {
      const columnWidget = widgets[columnWidgetId];

      if (widgetIsHidden(columnWidget.widgetId)) {
        heights[columnWidget.widgetId] = {
          height: Dimension.gridUnit(0),
          changed: true,
        };
        continue;
      }

      // First get the min height to fit the content
      const minHeightGridUnits = Dimension.toGridUnit(
        WidgetFactory.getWidgetMinimumHeight(
          columnWidget,
          widgets,
          theme,
          appMode,
          {}, // dynamic visibility doesnt matter because we already checked above for hidden canvases
        ) ?? Dimension.px(0),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw().value;

      // We need to minus the padding because so that padding + height = section height
      const paddingY = getColumnPaddingY(columnWidget, theme).value;
      const sectionHeightMinusPadding = sectionWidget.height.value - paddingY;

      const newHeight = Math.max(minHeightGridUnits, sectionHeightMinusPadding);

      if (newHeight !== columnWidget.height.value) {
        heights[columnWidget.widgetId] = {
          height: Dimension.fitContent(newHeight),
          changed: true,
        };
      } else {
        heights[columnWidget.widgetId] = {
          height: columnWidget.height,
          changed: false,
        };
      }
    }
  }
  // The section height is fitContent:
  // Section height is a MAX of section height to fit content and min height value,
  // then a MIN of that value and the section's maxHeight
  // Column heights are a MAX of column height to fit content, and min height value
  else {
    const columnMinHeights: Record<WidgetProps["widgetId"], number> = {};
    const columnPaddings: Record<WidgetProps["widgetId"], number> = {};
    const columnTotalMinHeights: Record<WidgetProps["widgetId"], number> = {}; // these include padding
    const collapsedColumns: WidgetProps["widgetId"][] = [];

    for (const columnWidgetId of sectionWidget.children || []) {
      const columnWidget = widgets[columnWidgetId];

      if (widgetIsHidden(columnWidget.widgetId)) {
        heights[columnWidget.widgetId] = {
          height: Dimension.gridUnit(0),
          changed: true,
        };
        collapsedColumns.push(columnWidget.widgetId);
        columnMinHeights[columnWidget.widgetId] = 0;
        columnPaddings[columnWidget.widgetId] = 0;
        continue;
      }

      // First get the min height to fit the content
      const minColHeightGridUnits = Dimension.toGridUnit(
        WidgetFactory.getWidgetMinimumHeight(
          columnWidget,
          widgets,
          theme,
          appMode,
          {}, // dynamic visibility doesnt matter because we already checked above for hidden canvases
        ) ?? Dimension.px(0),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw().value;

      columnMinHeights[columnWidget.widgetId] = minColHeightGridUnits;
      columnPaddings[columnWidget.widgetId] = getColumnPaddingY(
        columnWidget,
        theme,
      ).value;
      columnTotalMinHeights[columnWidget.widgetId] =
        columnMinHeights[columnWidget.widgetId] +
        columnPaddings[columnWidget.widgetId];
    }

    const maxOfColumnTotalMinHeights = Math.max(
      ...Object.values(columnTotalMinHeights),
    );

    const sectionMinHeightGU = Dimension.toGridUnit(
      sectionWidget.minHeight ?? Dimension.gridUnit(0),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).raw().value;

    const sectionMaxHeightGU = Dimension.toGridUnit(
      sectionWidget.maxHeight ?? Dimension.gridUnit(0),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).raw().value;

    for (const columnWidgetId of sectionWidget.children || []) {
      const columnWidget = widgets[columnWidgetId];
      const sectionMinHeightMinusPadding = Math.max(
        sectionMinHeightGU - columnPaddings[columnWidget.widgetId],
        0,
      );
      const sectionMaxHeightMinusPadding = Math.max(
        sectionMaxHeightGU - columnPaddings[columnWidget.widgetId],
        0,
      );

      // Start with column height set to the min needed to fit it's content
      let newColumnHeight = columnMinHeights[columnWidget.widgetId];

      // If it's lower than the section minHeight, increase to match the minHeight
      if (newColumnHeight < sectionMinHeightMinusPadding) {
        newColumnHeight = sectionMinHeightMinusPadding;
      }

      // If there's no section maxHeight, make this column match the tallest column height if it's height is below it
      // but also add any different between this column's total height and the max columns total height
      // to account for padding
      const thisColHeightWithPadding =
        newColumnHeight + columnPaddings[columnWidget.widgetId];
      if (
        !sectionWidget.maxHeight &&
        thisColHeightWithPadding < maxOfColumnTotalMinHeights
      ) {
        const differenceFromTallestColumn =
          maxOfColumnTotalMinHeights - thisColHeightWithPadding;
        newColumnHeight += differenceFromTallestColumn;
      }
      // If there is a section maxHeight, and this column's content is below it, but a *different* column's
      // content is above it, match the section maxheight
      else if (
        sectionWidget.maxHeight &&
        newColumnHeight < sectionMaxHeightMinusPadding &&
        maxOfColumnTotalMinHeights > sectionMaxHeightGU
      ) {
        newColumnHeight = sectionMaxHeightMinusPadding;
      }

      if (newColumnHeight !== columnWidget.height.value) {
        heights[columnWidget.widgetId] = {
          height: Dimension.fitContent(newColumnHeight),
          changed: true,
        };
      } else {
        heights[columnWidget.widgetId] = {
          height: columnWidget.height,
          changed: false,
        };
      }
    }

    // Now update the section height to be the max of min height or tallest column
    const maxColumnHeightPlusPadding = Math.max(
      ...Object.values(columnTotalMinHeights),
    );

    const allColsCollapsed =
      collapsedColumns.length === (sectionWidget.children || []).length;

    let newSectionHeight = allColsCollapsed
      ? 0
      : Math.max(maxColumnHeightPlusPadding, sectionMinHeightGU);

    // Don't grow taller than the maxHeight
    if (sectionWidget.maxHeight) {
      newSectionHeight = Math.min(newSectionHeight, sectionMaxHeightGU);
    }

    if (newSectionHeight !== sectionWidget.height.value) {
      heights[sectionWidget.widgetId] = {
        height: Dimension.fitContent(newSectionHeight),
        changed: true,
      };
    } else {
      heights[sectionWidget.widgetId] = {
        height: sectionWidget.height,
        changed: false,
      };
    }
  }

  return heights;
}

export function updateWidgetAfterWidthModeChange(params: {
  newWidget: FlattenedWidgetProps;
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
}) {
  const { newWidget, flattenedWidgets, widgets } = params;
  const originalFlattenedWidget = flattenedWidgets[newWidget.widgetId]!;
  const widgetId = newWidget.widgetId;

  const originalWidthMode = originalFlattenedWidget.width.mode;

  const widthToUse =
    isDynamicSize(originalWidthMode) &&
    originalFlattenedWidget.dynamicWidgetLayout?.width &&
    originalFlattenedWidget.parentColumnSpace
      ? originalFlattenedWidget.dynamicWidgetLayout.width
      : originalFlattenedWidget.width;

  if (newWidget.width.mode === "gridUnit") {
    const widthInGU = Dimension.gridUnit(
      Dimension.toGridUnit(
        widthToUse,
        originalFlattenedWidget.parentColumnSpace,
      ).raw().value,
    );

    widgets[widgetId] = {
      ...newWidget,
      width: widthInGU,
    };
  }

  if (newWidget.width.mode === "px") {
    const widthInPx = Dimension.toPx(
      widthToUse,
      originalFlattenedWidget.parentColumnSpace,
    );

    widgets[widgetId] = {
      ...newWidget,
      width: widthInPx,
    };
  }

  const parent = widgets[newWidget.parentId];
  const changeToOrFromDynamic =
    isDynamicSize(originalWidthMode) !== isDynamicSize(newWidget.width.mode);

  if (changeToOrFromDynamic && parent) {
    const updatedWidgets = updateWidgetWidths({
      widgets,
      flattenedWidgets,
      widget: parent,
      widthDiffGU: 0,
      rootCallOptions: {
        forceCheckChildren: true,
      },
      deepCallOptions: {
        staticResizeParentId:
          newWidget.width.mode === "fitContent" ? widgetId : undefined,
      },
    });

    return updatedWidgets;
  }
  return widgets;
}

export function updateWidgetAfterHeightModeChange(params: {
  newWidget: FlattenedWidgetProps;
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
}) {
  const { newWidget, widgets, flattenedWidgets } = params;
  const originalFlattenedWidget = flattenedWidgets[newWidget.widgetId]!;
  const widgetId = newWidget.widgetId;

  const heightToUse =
    isDynamicSize(originalFlattenedWidget.height.mode) &&
    originalFlattenedWidget.dynamicWidgetLayout?.height
      ? originalFlattenedWidget.dynamicWidgetLayout?.height
      : originalFlattenedWidget.height;

  if (newWidget.height.mode === "gridUnit") {
    const heightInGU = Dimension.gridUnit(
      Dimension.toGridUnit(
        heightToUse,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).roundUp().value,
    );
    widgets[widgetId] = {
      ...newWidget,
      height: heightInGU,
    };
  }

  if (newWidget.height.mode === "px") {
    const heightInPx = Dimension.toPx(
      heightToUse,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    widgets[widgetId] = {
      ...newWidget,
      height: heightInPx,
    };
  }
  if (newWidget.width.mode === "gridUnit") {
    if (
      originalFlattenedWidget.dynamicWidgetLayout?.width &&
      originalFlattenedWidget.parentColumnSpace
    ) {
      const widthRaw = Dimension.toGridUnit(
        originalFlattenedWidget.dynamicWidgetLayout.width,
        originalFlattenedWidget.parentColumnSpace,
      );
      const widthInGU = Dimension.gridUnit(
        (originalFlattenedWidget.width.mode === "fitContent"
          ? widthRaw.roundUp()
          : widthRaw.roundDown()
        ).value,
      );
      widgets[widgetId] = {
        ...newWidget,
        width: widthInGU,
      };
    }
  }

  const parent = widgets[newWidget.parentId];
  // Do not need to run this for height mode changes on section widgets
  if (parent && parent.widgetId !== PAGE_WIDGET_ID) {
    return updateWidgetWidths({
      widgets,
      widget: parent,
      widthDiffGU: 0,
      flattenedWidgets,
      rootCallOptions: {
        forceCheckChildren: true,
      },
      deepCallOptions: {
        staticResizeParentId:
          newWidget.width.mode === "fitContent" ? widgetId : undefined,
      },
    });
  }

  return widgets;
}

export function updateWidgetWidths({
  widgets,
  flattenedWidgets,
  widget,
  widthDiffGU,
  leftDiffGU = 0,
  rootCallOptions,
  deepCallOptions,
}: {
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
  widget: FlattenedWidgetProps;
  widthDiffGU: number;
  leftDiffGU?: number;
  // rootCallOptions do NOT get passed down during traversal
  rootCallOptions?: {
    // forceCheckChildren is used when the parent width does not change but a child might need
    // adjustment due to a change in width mode
    forceCheckChildren?: boolean;
  };
  deepCallOptions?: {
    // if this is provided, only traverse children of this widget if they are dynamic
    staticResizeParentId?: string;
  };
}): CanvasWidgetsReduxState {
  const parentColumnSpace =
    flattenedWidgets[widget.parentId]?.parentColumnSpace || 1;

  // TODO: Rounding causes shrinks, then grows to not be perfect
  // We could instead round to 1/2 or 1/4 of a grid unit to make it more responsive
  const round = (num: number) => Math.round(num);

  const isCanvas = widget.type === WidgetTypes.CANVAS_WIDGET;
  // Update the widget itself
  const originalWidthGU = isCanvas
    ? (widget.gridColumns ?? 1) // canvas widths are determined by grid cols since they're dependent on parent
    : Dimension.toGridUnit(widget.width, parentColumnSpace).raw().value;

  const originalLeft = widget.left.value;
  const newWidthGU = Math.max(1, originalWidthGU + widthDiffGU);
  const newLeft = Math.max(0, originalLeft + leftDiffGU);
  const isDynamicWidth = isDynamicSize(widget.width.mode);
  if (isDynamicWidth && widget?.children == null) {
    // If the widget is a dynamic primitive widget, we don't want to change the width
    return widgets;
  }

  widgets[widget.widgetId] = {
    ...widgets[widget.widgetId],
    left: Dimension.gridUnit(newLeft),
    // Widgets that can't have children should not have gridColumns set
    gridColumns: Math.ceil(newWidthGU), // this will be at least 1
    width:
      widget.width.mode === "px"
        ? Dimension.px(round(newWidthGU * parentColumnSpace))
        : Dimension.build(Math.ceil(newWidthGU), widget.width.mode),
  };

  // If the width did not change then we don't need to update the children
  if (newWidthGU === originalWidthGU && !rootCallOptions?.forceCheckChildren) {
    return widgets;
  }

  const staticResize =
    deepCallOptions?.staticResizeParentId === widget.widgetId;
  const widgetIsShrinkingHstack =
    widthDiffGU < 0 && widget.layout === CanvasLayout.HSTACK;

  const availableWidth = widgetIsShrinkingHstack
    ? getHstackCanvasRemainingWidthPx(
        // We fall back to the redux widgets (vs. widgets from the size.ts selector) here
        // because in some edge cases, like pasting, we have added new widgets to the widgets object
        // but have not yet persisted them to redux, so the flattenedwidgets are missing the new ones
        // This fallback seems to work as expected, but it really should only be necessary
        // for when users paste widgets
        flattenedWidgets[widget.widgetId]
          ? (flattenedWidgets[widget.widgetId] as any as FlattenedWidgetProps)
          : widgets[widget.widgetId], // todo (layouts): fix types
        widgets,
      )
    : 0;

  const availableWidthGU = Dimension.toGridUnit(
    Dimension.px(availableWidth),
    parentColumnSpace,
  ).raw().value;
  // when shrinking an HStack, if there is enough available space to not have to shrink the children, we should not shrink them
  // if not, we should use as much empty space as possible before shrinking the children
  const dontShrink =
    availableWidthGU > 0 && availableWidthGU > -1 * widthDiffGU;
  const originalWidthWithoutAvailableSpace = originalWidthGU - availableWidthGU;
  // Update the children
  const newSizePercentage =
    dontShrink || originalWidthWithoutAvailableSpace === 0
      ? 1
      : newWidthGU / originalWidthWithoutAvailableSpace;

  if (newSizePercentage === 1 && !rootCallOptions?.forceCheckChildren) {
    return widgets;
  }

  (widget.children ?? [])
    .map((childId) => widgets[childId])
    .filter((child) => {
      if (staticResize && !isDynamicSize(child.width.mode)) {
        return false; // even when we don't want to resize the children of an hstack, we must always update the grid columns for fill parent / fit content children
      }
      if (child.width.mode === "px") {
        // px width children create their own grid system
        return false;
      }
      return !DETACHED_WIDGETS.includes(child.type);
    })
    .forEach((child) => {
      let origChildWidth = getNormalizedChildWidthGU(child, parentColumnSpace);
      if (
        child.type === WidgetTypes.CANVAS_WIDGET &&
        widget.type !== WidgetTypes.SECTION_WIDGET
      ) {
        // edge case: if the canvas child width is smaller than the container parent width, we need to update the child width
        if (origChildWidth < originalWidthGU) {
          origChildWidth = originalWidthGU;
          widgets[child.widgetId] = {
            ...child,
            gridColumns: originalWidthGU,
            width: Dimension.gridUnit(originalWidthGU),
          };
          child = widgets[child.widgetId];
        }
      }

      // left value is not meaningful for vstacks
      const childLeft =
        widget.layout === CanvasLayout.VSTACK ? 0 : child.left.value;

      // This means the child widget is touching the right side of the parent
      // Even when shrinking we want to preserve this relationship
      const widgetAnchoredToRightEdge =
        widget.layout === CanvasLayout.HSTACK
          ? originalWidthGU === childLeft + origChildWidth // in hstacks, children can exceed the right edge
          : originalWidthGU <= childLeft + origChildWidth;

      let left = Math.max(0, round(childLeft * newSizePercentage));
      if (left >= newWidthGU) {
        // If rounding has set the left to be greater than the width of the parent
        // then we need to set the left to be the width of the parent
        left = Math.max(0, newWidthGU - 1);
      }
      let newChildWidth;
      if (
        isStackLayout(widget.layout) &&
        (child.width.mode === "fillParent" || child.width.mode === "fitContent")
      ) {
        newChildWidth = newWidthGU; // despite the name "width", what we care about is grid columns for fill parent children
      } else if (widgetAnchoredToRightEdge) {
        newChildWidth = newWidthGU - left;
      } else {
        // Don't round if we're using pixels as rounding grid units can cause a large jump in size for pixels
        newChildWidth =
          child.width.mode === "px"
            ? origChildWidth * newSizePercentage
            : round(origChildWidth * newSizePercentage);
      }

      const widthDiff = newChildWidth - origChildWidth;
      const leftDiff = left - childLeft;

      updateWidgetWidths({
        widgets,
        flattenedWidgets,
        widget: child,
        widthDiffGU: widthDiff,
        leftDiffGU: leftDiff,
        deepCallOptions,
      });
    });

  return widgets;
}

const getNormalizedChildWidthGU = (
  child: CanvasWidgetsReduxState[string],
  parentColumnSpace: number,
) => {
  // Some canvas widgets have a width of 0 which is incorrect, but do have a correct gridColumns value
  // so use the gridColumns value in those cases
  // Layouts team will be migrating these to have the correct width value in the very near future
  const childWidthGU = Dimension.toGridUnit(
    child.width,
    parentColumnSpace,
  ).raw().value;

  if (
    child.type === WidgetTypes.CANVAS_WIDGET &&
    (childWidthGU === 0 || childWidthGU !== child.gridColumns)
  ) {
    return child.gridColumns ?? 1;
  }
  return childWidthGU;
};

// returns whether or not updateWidgetWidth should be called with staticResize set to true on the child
export function getIsStaticResize(params: {
  flattenedChild: FlattenedWidgetLayoutProps;
  flattenedParent: FlattenedWidgetLayoutProps;
  theme: GeneratedTheme;
}) {
  const { flattenedChild, flattenedParent, theme } = params;
  if (
    flattenedChild &&
    flattenedChild.type === WidgetTypes.CANVAS_WIDGET &&
    flattenedChild.layout === CanvasLayout.HSTACK
  ) {
    const widthIfNoOverflow = getCanvasInternalMinWidthPx(
      flattenedChild,
      flattenedParent,
      theme,
    ).value;
    // we can infer if this condition is true that there is an existing scroll
    if (
      flattenedChild.internalWidth &&
      flattenedChild.internalWidth.value > widthIfNoOverflow
    ) {
      return true;
    }
  }
  return false;
}

const roundDownToMultiple = (num: number, multiple: number): number => {
  return Math.floor(num / multiple) * multiple;
};

// Take a number of grid columns and round it down to the closest multiple of the section column grid columns
export const roundDownToSectionColumnMultiple = (num: number): number => {
  return roundDownToMultiple(num, SectionDefaults.MIN_COLUMN_GRID_COLUMNS);
};

export const evenlyDivideReductionForColumns = (
  value: number,
  gridColsPerCol: number[],
  gridColumnsPerSectionColumn: number,
): number[] => {
  return evenlyDivideReduction(
    value,
    gridColsPerCol,
    gridColumnsPerSectionColumn,
    gridColumnsPerSectionColumn,
  );
};

export const getAllWidgetsInTree = (
  widgetId: string,
  canvasWidgets: CanvasWidgetsReduxState,
) => {
  const widget = canvasWidgets[widgetId];
  const widgetList = [widget];
  if (widget && widget.children) {
    widget.children
      .filter(Boolean)
      .forEach((childWidgetId: string) =>
        widgetList.push(...getAllWidgetsInTree(childWidgetId, canvasWidgets)),
      );
  }
  return widgetList;
};

export function calculateNewWidgetPosition({
  widget,
  parentId,
  canvasWidgets,
  parentRight,
  desiredLeft,
}: {
  widget: Partial<WidgetPosition>;
  parentId: string;
  canvasWidgets: CanvasWidgetsReduxState;
  parentRight?: number;
  desiredLeft?: number;
}): {
  left: Dimension<"gridUnit">;
  top: Dimension<"gridUnit">;
  width: Dimension<WidgetWidthModes>;
  height: Dimension<WidgetHeightModes>;
} {
  if (!hasLeftRightProperties(widget)) throw Error("");

  const parentCanvasWidget = canvasWidgets[parentId];
  if (
    !parentCanvasWidget ||
    parentCanvasWidget.type !== WidgetTypes.CANVAS_WIDGET
  ) {
    throw Error("Parent widget is not a canvas widget");
  }

  let nextAvailableRow = 0;
  if (parentCanvasWidget.children && parentCanvasWidget.children.length > 0) {
    const canvasChildren = parentCanvasWidget.children.map(
      (widgetId) => canvasWidgets[widgetId],
    );
    const maxBottomRow = canvasChildren.reduce(
      (max, child) => Math.max(max, child.top.value + child.height.value),
      0,
    );
    nextAvailableRow = maxBottomRow + 1;
  }

  const widgetLeftFromParent =
    parentRight && parentRight - widget.width.value > 0
      ? parentRight - widget.width.value
      : 0;
  const left =
    desiredLeft && widgetLeftFromParent
      ? Math.min(desiredLeft, widgetLeftFromParent)
      : 0;

  return {
    left: Dimension.gridUnit(left),
    top: Dimension.gridUnit(nextAvailableRow),
    width: widget.width,
    height: widget.height,
  };
}

export function calculateNewWidgetPositionOnMousePosition({
  widget,
  canvasWidgets,
  mousePosition: mousePositionUnscaled,
  canvasScaleFactor,
  occupiedSpaces,
  dropTarget,
  parent,
}: {
  widget: WidgetPropsRuntime & Partial<WidgetConfigProps>;
  canvasWidgets: CanvasWidgetsReduxState;
  mousePosition: XYCoord;
  canvasScaleFactor: number;
  parent: FlattenedWidgetLayoutProps;
  occupiedSpaces: OccupiedSpace[] | undefined;
  dropTarget: getDropTargetResultType;
}) {
  const mousePosition = scaledXYCoord(mousePositionUnscaled, canvasScaleFactor);

  const parentId = parent.widgetId;
  const colWidth = parent.parentColumnSpace ?? 0;
  const rowHeight = parent.parentRowSpace ?? 0;
  const parentLeft = parent.left.value;
  const parentTop = parent.top.value;
  const parentRows = getCanvasSnapRows(parent.height, parent.minHeight);
  const parentCols = parent.gridColumns as number;
  const paddingLeft =
    Dimension.toPx(parent?.padding?.left, parent.parentColumnSpace ?? 0)
      ?.value ?? 0;
  const paddingTop =
    Dimension.toPx(parent?.padding?.top, parent.parentRowSpace ?? 0)?.value ??
    0;

  if (!dropTarget) {
    throw Error("No drop target found");
  }

  const dropTargetOffset = dropTarget.offset;

  dropTargetOffset.x += paddingLeft;
  dropTargetOffset.y += paddingTop;
  dropTargetOffset.x /= canvasScaleFactor;
  dropTargetOffset.y /= canvasScaleFactor;

  // paddingX is used for keeping the canvas padding on the right side.
  // Padding on the left is already accounted for above, and doesn't take up canvas columns.
  // Padding on the right needs to be taken out of the available canvas columns.
  // Similar to snapToGrid but using ceil instead of round
  const paddingX = Math.ceil(paddingLeft / colWidth);

  const bestSpace = findExactFreePosition({
    clientOffset: mousePosition,
    colWidth,
    rowHeight,
    paddingX,
    widget,
    dropTargetOffset,
    occupiedSpaces,
    parentRows,
    parentCols,
    parentLeft,
    parentTop,
  });

  if (bestSpace !== CantFit) {
    return {
      left: Dimension.gridUnit(bestSpace.left),
      top: Dimension.gridUnit(bestSpace.top),
      width: widget.width,
      height: widget.height,
    };
  }

  // Find nearest available position to where the widget would have been placed
  const nearBestSpace = findNearbyFreePosition({
    clientOffset: mousePosition,
    colWidth,
    rowHeight,
    paddingX,
    widget,
    dropTargetOffset,
    occupiedSpaces,
    parentRows,
    parentCols,
    parentLeft,
    parentTop,
  });

  if (nearBestSpace !== CantFit) {
    return {
      left: Dimension.gridUnit(nearBestSpace.left),
      top: Dimension.gridUnit(nearBestSpace.top),
      width: widget.width,
      height: widget.height,
    };
  }

  const [desiredLeft] = getDropZoneOffsets(
    colWidth,
    rowHeight,
    mousePosition,
    dropTargetOffset,
  );

  // It will always find space at the bottom of the canvas
  return calculateNewWidgetPosition({
    widget,
    parentId,
    canvasWidgets,
    parentRight: parentLeft + parentCols - paddingX,
    desiredLeft,
  });
}

export const updateWidgetPosition = (
  widget: WidgetProps,
  updates: {
    left: Dimension<"gridUnit">;
    top: Dimension<"gridUnit">;
    height: Dimension<WidgetHeightModes>;
    width: Dimension<WidgetWidthModes>;
  },
) => {
  if (!hasLeftRightProperties(widget)) throw Error("");
  const { left, top, height, width } = updates;
  return {
    left,
    top,
    width,
    height,
  };
};

export function* getEntityNames() {
  const evalTree: ReturnType<typeof getDataTree> = yield select(getDataTree);
  return Object.keys(evalTree);
}

/**
 * Note: Mutates finalWidgets[parentId].height for CANVAS_WIDGET
 * Update the canvas height to be either the lowest widget
 * or the same height as the parent container if there are no
 * child widgets so that the addition of a new child has the
 * needed space to be added
 * @param finalWidgets
 * @param widgetId The canvas widget to resize
 */
export const resizeCanvasOnChildDelete = (
  finalWidgets: CanvasWidgetsReduxState,
  theme: GeneratedTheme,
  appMode: APP_MODE,
  widgetId: string,
  dynamicVisibility: DynamicWidgetsVisibilityState,
) => {
  const widget = finalWidgets[widgetId];
  const parent = finalWidgets[widget.parentId];

  if (
    !widget ||
    widget.type !== WidgetTypes.CANVAS_WIDGET ||
    !parent ||
    parent.type === WidgetTypes.SECTION_WIDGET
  ) {
    // section columns are resized by other code paths
    return;
  }

  const newHeight = Dimension.toGridUnit(
    WidgetFactory.getWidgetComputedHeight(
      widget,
      finalWidgets,
      theme,
      appMode,
      dynamicVisibility,
    ) ?? Dimension.gridUnit(0),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;

  widget.height = {
    mode: widget.height.mode,
    value: newHeight,
  };
};

// The following is computed to be used in the entity explorer
// Every time a widget is selected, we need to expand widget entities
// in the entity explorer so that the selected widget is visible
export function buildWidgetIdsExpandList(
  canvasWidgets: {
    [widgetId: string]: FlattenedWidgetProps;
  },
  selectedWidgetId: string,
  mainContainerWidgetId: string,
) {
  const widgetIdsExpandList = [];

  // Make sure that the selected widget exists in canvasWidgets
  let widgetId = canvasWidgets[selectedWidgetId]
    ? canvasWidgets[selectedWidgetId].parentId
    : undefined;
  // If there is a parentId for the selectedWidget
  if (widgetId) {
    // Keep including the parent until we reach the main container
    while (widgetId !== mainContainerWidgetId) {
      widgetIdsExpandList.push(widgetId);
      if (canvasWidgets[widgetId] && canvasWidgets[widgetId].parentId)
        widgetId = canvasWidgets[widgetId].parentId;
      else break;
    }
  }
  return widgetIdsExpandList;
}

export function resizeSectionColumnsAfterColumnDelete({
  widgets,
  flattenedWidgets,
  sectionWidgetId,
}: {
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
  sectionWidgetId: WidgetProps["widgetId"];
}): void {
  const sectionWidget: WidgetProps = widgets[sectionWidgetId];
  if (!sectionWidget || !sectionWidget.children) return;
  const sectionParentWidget: WidgetProps = widgets[sectionWidget.parentId];

  const sectionColumns = getSectionColsForParentType(sectionParentWidget.type);
  const gridColumnsPerSectionColumn =
    (sectionWidget.gridColumns || 0) / sectionColumns;

  const sectionCanvasWidgets = sectionWidget.children.map((childId: string) => {
    return widgets[childId];
  });

  const totalSectionColumnsGridCols = sectionCanvasWidgets.reduce(
    (total: number, child: WidgetProps) => {
      return total + (child.gridColumns || 0);
    },
    0,
  );
  const maxGridCols = getMaxGridColsForParentType(sectionParentWidget.type);
  const gridColsToAddBack = maxGridCols - totalSectionColumnsGridCols;

  const gridColsPerColumnCanvas = evenlyDivide(
    gridColsToAddBack,
    sectionCanvasWidgets.length,
    gridColumnsPerSectionColumn,
  );

  // Update the existing canvases
  for (const [index, canvasId] of sectionWidget.children.entries()) {
    updateWidgetWidths({
      widgets,
      flattenedWidgets,
      widget: widgets[canvasId],
      widthDiffGU: gridColsPerColumnCanvas[index],
    });
  }
}

/**
 * Tracks a newly added event handler such as the first onClick, onRowSelected
 * Note: Since this runs on every keypress performance needs to be optimized
 * @param {FlattenedWidgetProps} widget - FlattenedWidgetProps - the widget that was updated
 * @param {string} propertyPath - the path to the property that was changed
 * @param {unknown} propertyValue - the new value of the property
 * @param {CanvasWidgetsReduxState} widgets - CanvasWidgetsReduxState
 * @param {string} widgetId - The id of the widget that was updated
 */
export function logNewEventHandler(
  widget: FlattenedWidgetProps,
  propertyPath: string,
  propertyValue: unknown,
  widgets: CanvasWidgetsReduxState,
  widgetId: string,
) {
  if (isPathADynamicTrigger(widget, propertyPath, true)) {
    try {
      let addedBinding = false;
      if (isString(propertyValue)) {
        const oldValue = widget[
          propertyPath as keyof FlattenedWidgetProps
        ] as string;
        const previousBindings = getDynamicBindings(oldValue);
        const newBindings = getDynamicBindings(propertyValue);

        const hasTriggers =
          previousBindings.jsSnippets.length &&
          previousBindings.jsSnippets[0] !== "";
        const addedTriggers =
          newBindings.jsSnippets.length > 0 && newBindings.jsSnippets[0] !== "";

        addedBinding = !hasTriggers && addedTriggers;
      } else if (isArray(propertyValue)) {
        const oldValue = widget[
          propertyPath as keyof FlattenedWidgetProps
        ] as Array<string>;
        const previousBindings = getDynamicBindings(oldValue[0]);
        const newBindings = getDynamicBindings(propertyValue[0]);

        const hasTriggers =
          previousBindings.jsSnippets.length &&
          previousBindings.jsSnippets[0] !== "";
        const addedTriggers =
          newBindings.jsSnippets.length > 0 && newBindings.jsSnippets[0] !== "";

        addedBinding = !hasTriggers && addedTriggers;
      }

      if (addedBinding) {
        log.event(UIEvent.ADDED_EVENT_HANDLER, {
          widgetProperty: propertyPath,
          widgetType: widgets[widgetId]?.type,
        });
      }
    } catch (err: any) {
      log.warn(
        `failed to send event on user adding event handler, ${err}; stack: ${err?.stack}`,
      );
    }
  }
}

/**
 * Logs an event when a widget property is updated to add data binding
 * NOTE: We are NOT handling data binding events for properties with code toggle nor triggers
 * @param {FlattenedWidgetProps} widget - FlattenedWidgetProps,
 * @param {string} propertyPath - The path to the property that was updated.
 * @param {unknown} propertyValue - the value of the property that is being updated
 * @param {CanvasWidgetsReduxState} widgets - CanvasWidgetsReduxState
 * @param {string} widgetId - The id of the widget that is being updated
 */
export function logNewBinding(
  widget: FlattenedWidgetProps,
  propertyPath: string,
  propertyValue: unknown,
  widgets: CanvasWidgetsReduxState,
  widgetId: string,
) {
  if (
    !isPathADynamicProperty(widget, propertyPath) &&
    !isPathADynamicTrigger(widget, propertyPath) &&
    typeof widget[propertyPath as keyof FlattenedWidgetProps] === "string" &&
    typeof propertyValue === "string"
  ) {
    try {
      const preProp = widget[
        propertyPath as keyof FlattenedWidgetProps
      ] as string;
      const previousValue = getDynamicBindings(preProp);
      const newValueStr = getDynamicBindings(String(propertyValue));
      // check if the property value already has data binding by checking it has non empty jsSnippet
      const hasDataBinding =
        previousValue.jsSnippets.length > 0 &&
        previousValue.jsSnippets[0] !== "";
      // check if this update adds data binding property by checking it has non empty jsSnippet
      const addsDataBinding =
        newValueStr.jsSnippets.length > 0 && newValueStr.jsSnippets[0] !== "";

      // Send property update events async only when data binding is added in this update
      if (!hasDataBinding && addsDataBinding) {
        log.event(UIEvent.ADDED_DATA_BINDING, {
          widgetProperty: propertyPath,
          widgetType: widgets[widgetId]?.type,
        });
      }
    } catch (err) {
      log.warn(`failed to send update widget property event, ${err}`);
    }
  }
}

export const repositionWidgetsFromStackIntoFixedGrid = ({
  widgets,
  dynamicWidgetLayout,
  canvasWidgetId,
  canvasWidgetParentColumnSpace,
  previousLayout,
}: {
  widgets: CanvasWidgetsReduxState | CanvasWidgetsReduxState;
  dynamicWidgetLayout: DynamicWidgetsLayoutState;
  canvasWidgetId: string;
  canvasWidgetParentColumnSpace: number;
  previousLayout: CanvasLayout;
}): CanvasWidgetsReduxState => {
  const canvasWidget = widgets[canvasWidgetId];
  if (!canvasWidget) return widgets;

  switch (previousLayout) {
    case CanvasLayout.HSTACK: {
      const spacing = Dimension.toGridUnit(
        canvasWidget.spacing ?? Dimension.gridUnit(0),
        canvasWidgetParentColumnSpace,
      ).raw();
      const switchedWidgets: string[] = [];

      let nextLeftValue = 0;
      const leftValues = new Map<string, number>();
      const childWidgets: WidgetProps[] = [];

      for (const childWidgetId of canvasWidget.children || []) {
        const childWidget = widgets[childWidgetId];
        const isWidthFilledParent = childWidget.width.mode === "fillParent";
        if (isWidthFilledParent) {
          const dynamicWidth = dynamicWidgetLayout[childWidgetId]?.width;
          if (dynamicWidth) {
            widgets[childWidgetId] = {
              ...widgets[childWidgetId],
              width: Dimension.toGridUnit(
                dynamicWidth,
                canvasWidgetParentColumnSpace,
              ).raw(),
            };
            switchedWidgets.push(childWidget.widgetName);
          }
        }
        // Also swap any dynamic width/height values to gridUnit mode if they are fitContent or fillParent
        // as fixed grid layout does not support these modes
        updateChildSizeModesForParentLayout({
          widgets,
          childWidget,
          parentLayout: CanvasLayout.FIXED, // hard coded to fixed because the update may not have been applied yet, but this function is specifically for when we switch to fixed layout
          dynamicWidgetLayout,
          parentColumnSpace: canvasWidgetParentColumnSpace,
        });
        const updatedWidth = widgets[childWidgetId].width;

        leftValues.set(childWidgetId, nextLeftValue);
        nextLeftValue += updatedWidth.value + spacing.value;
        childWidgets.push(widgets[childWidgetId]);
      }

      const hasConflicts = gridCanvasHasChildConflicts(
        canvasWidget.gridColumns || 0,
        childWidgets,
      );

      if (hasConflicts) {
        for (const childWidget of childWidgets) {
          const nextLeftValue = leftValues.get(childWidget.widgetId) || 0;
          widgets[childWidget.widgetId] = {
            ...widgets[childWidget.widgetId],
            left: Dimension.gridUnit(nextLeftValue),
            top: Dimension.gridUnit(0),
          };
        }
      }
      // Show a toaster notification for switched widgets
      if (switchedWidgets.length > 0) {
        Toaster.show({
          text: `${switchedWidgets.join(", ")} ${
            switchedWidgets.length === 1 ? "component was" : "components were"
          } set to Fixed Width due to grid layout not supporting Fill Parent`,
          variant: Variant.info,
          duration: 10000,
          dispatchableAction: undoAction(),
        });
      }
      break;
    }

    case CanvasLayout.VSTACK:
    default: {
      const spacing = Dimension.toGridUnit(
        canvasWidget.spacing ?? Dimension.px(0),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw();

      const switchedWidgets: string[] = [];

      let nextTopValue = 0;
      const topValues = new Map<string, number>();
      const childWidgets: WidgetProps[] = [];

      for (const childWidgetId of canvasWidget.children || []) {
        const childWidget = widgets[childWidgetId];
        childWidgets.push(childWidget);

        let height = childWidget.height;
        const isHeightFillParent = childWidget.height.mode === "fillParent";
        if (isHeightFillParent) {
          const dynamicHeight =
            dynamicWidgetLayout[childWidget.widgetId]?.height;
          if (dynamicHeight) {
            height = Dimension.toGridUnit(
              dynamicHeight,
              GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
            ).raw();
            switchedWidgets.push(childWidget.widgetName);
          }
        }

        widgets[childWidget.widgetId] = {
          ...widgets[childWidget.widgetId],
          height,
        };

        topValues.set(childWidget.widgetId, nextTopValue);
        nextTopValue += height.value + spacing.value;

        // Also swap any dynamic width/height values to gridUnit mode if they are fitContent or fillParent
        // as fixed grid layout does not support these modes
        updateChildSizeModesForParentLayout({
          widgets,
          childWidget,
          parentLayout: CanvasLayout.FIXED, // hard coded to fixed because the update may not have been applied yet, but this function is specifically for when we switch to fixed layout
          dynamicWidgetLayout,
          parentColumnSpace: canvasWidgetParentColumnSpace,
        });
      }

      const hasConflicts = gridCanvasHasChildConflicts(
        canvasWidget.gridColumns || 0,
        childWidgets,
      );

      if (hasConflicts) {
        for (const childWidget of childWidgets) {
          const nextTopValue = topValues.get(childWidget.widgetId) || 0;
          widgets[childWidget.widgetId] = {
            ...widgets[childWidget.widgetId],
            left: Dimension.gridUnit(0),
            top: Dimension.gridUnit(nextTopValue),
          };
        }
      }

      // Show a toaster notification for switched widgets
      if (switchedWidgets.length > 0) {
        Toaster.show({
          text: `${switchedWidgets.join(", ")} ${
            switchedWidgets.length === 1 ? "component was" : "components were"
          } set to Fixed Height due to grid layout not supporting Fill Parent`,
          variant: Variant.info,
          duration: 10000,
          dispatchableAction: undoAction(),
        });
      }
    }
  }

  return widgets;
};

const updateChildSizeModesForParentLayout = ({
  widgets,
  childWidget,
  parentLayout,
  dynamicWidgetLayout,
  parentColumnSpace = CanvasDefaults.MIN_GRID_UNIT_WIDTH,
}: {
  widgets: CanvasWidgetsReduxState;
  childWidget: FlattenedWidgetProps;
  parentLayout: CanvasLayout;
  parentColumnSpace?: number;
  dynamicWidgetLayout?: DynamicWidgetsLayoutState;
}): CanvasWidgetsReduxState => {
  // Check width
  if (
    !CANVAS_LAYOUT_WIDTH_MODES[parentLayout].includes(childWidget.width.mode)
  ) {
    // this parent layout doesn't support this widget's width mode, so update it
    // to gridUnits which all layout types support
    let width = childWidget.width;

    const dynamicWidth = dynamicWidgetLayout?.[childWidget.widgetId]?.width;
    if (isDynamicSize(childWidget.width.mode) && dynamicWidth) {
      width = dynamicWidth;
    }

    const widthInGridUnits = Dimension.toGridUnit(
      width,
      parentColumnSpace,
    ).roundUp();

    widgets[childWidget.widgetId] = {
      ...widgets[childWidget.widgetId],
      width: Dimension.gridUnit(widthInGridUnits.value),
    };
  }

  // Check height modes
  if (
    !CANVAS_LAYOUT_HEIGHT_MODES[parentLayout].includes(childWidget.height.mode)
  ) {
    let height: Dimension<WidgetHeightModes> = childWidget.height;

    const dynamicHeight = dynamicWidgetLayout?.[childWidget.widgetId]?.height;
    if (isDynamicSize(childWidget.height.mode) && dynamicHeight) {
      height = dynamicHeight;
    }

    height = Dimension.toGridUnit(
      height,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).raw();

    widgets[childWidget.widgetId] = {
      ...widgets[childWidget.widgetId],
      height: Dimension.gridUnit(height.value),
    };
  }

  return widgets;
};

export const updateChildrenSizeModesForParentLayout = ({
  widgets,
  parentLayout,
  children,
  dynamicWidgetLayout,
  parentColumnSpace = CanvasDefaults.MIN_GRID_UNIT_WIDTH,
}: {
  widgets: CanvasWidgetsReduxState;
  parentLayout: CanvasLayout;
  children: string[];
  dynamicWidgetLayout?: DynamicWidgetsLayoutState;
  parentColumnSpace?: number;
}): CanvasWidgetsReduxState => {
  children?.forEach((childId) => {
    const child = widgets[childId];
    updateChildSizeModesForParentLayout({
      widgets,
      childWidget: child,
      parentLayout: parentLayout || CanvasLayout.FIXED,
      dynamicWidgetLayout,
      parentColumnSpace,
    });
  });

  return widgets;
};

export function* getCreateConfig(type: WidgetType) {
  const config: ReturnType<typeof getWidgetBlueprint> = yield select(
    getWidgetBlueprint,
    type as WidgetTypes,
  );
  return config;
}

// No GridWidget support for fitContent height right now (todo: fix this)
// we currently don't support fitContent height for widgets inside the grid
// So in this case, we convert the height to fixed
export function* handleGridWidgetAutoHeight(
  widgetId: string,
  newParentId: string,
  oldParentId: string,
  widgets: CanvasWidgetsReduxState,
  stateWidgets: CanvasWidgetsReduxState,
  stackDragPositions?: StackDragPositions,
) {
  const height = widgets[widgetId].height;
  const parentIsDifferent = newParentId !== oldParentId;

  if (parentIsDifferent) {
    const newParents: ReturnType<typeof getWidgetParentIds> = yield select(
      getWidgetParentIds,
      newParentId,
    );
    const ancestorIsGrid = newParents?.some(
      (id: string) => stateWidgets[id].type === WidgetTypes.GRID_WIDGET,
    );

    const parent = stateWidgets[newParentId];
    const parentIsStack = isStackLayout(parent.layout);

    switch (height.mode) {
      case "fitContent":
        if (ancestorIsGrid) {
          widgets[widgetId] = {
            ...widgets[widgetId],
            height: Dimension.gridUnit(height.value),
          };
        }
        break;
      case "fillParent":
        if (!parentIsStack || ancestorIsGrid) {
          const newHeightD = stackDragPositions?.[widgetId]?.height ?? height;
          const newHeightGridUnits = Dimension.toGridUnit(
            newHeightD,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw();

          widgets[widgetId] = {
            ...widgets[widgetId],
            height: Dimension.gridUnit(newHeightGridUnits.value),
          };
        }
        break;
    }
  }
}

// When the layouts flag is off, we need to update a modal's main section + canvas
// height to be the max of the presetHeight and the height needed to fit the content
export function updateModalMainCanvasHeightWithHeightPreset(
  widgets: CanvasWidgetsReduxState,
  modalWidgetId: string,
  heightPreset: ModalSize,
  theme: GeneratedTheme,
) {
  const widget = widgets[modalWidgetId];
  if (!widget) return widgets;

  const sectionWidget = widgets[
    widget.children?.[0] as string
  ] as FlattenedWidgetProps;

  // FIXME: temp for ai testing
  if (!sectionWidget) {
    console.error(`Section widget not found for modal widget ${modalWidgetId}`);
    return widgets;
  }

  const canvasWidget = widgets[
    sectionWidget.children?.[0] as string
  ] as FlattenedWidgetProps;

  // FIXME: temp for ai testing
  if (!canvasWidget) {
    console.error(`Canvas widget not found for modal widget ${modalWidgetId}`);
    return widgets;
  }

  const presetMinHeight = Dimension.toPx(
    Dimension.gridUnit(MODAL_ROWS_PRESETS[heightPreset as ModalSize]),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  );
  const presetHeight = Dimension.gridUnit(
    MODAL_ROWS_PRESETS[heightPreset as ModalSize],
  );

  // We need to check if the new height based on the preset is larger than the
  // static canvas height. If so, we update the canvas to be taller.
  // Otherwise, we leave the canvas height the same (taller than the modal height
  // so that it will scroll if necessary
  const canvasMinHeight = Dimension.toGridUnit(
    getCanvasMinHeightFlattened(canvasWidget, widgets),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;

  const canvasPaddingY = getColumnPaddingY(canvasWidget, theme);

  const canvasHeight = Dimension.gridUnit(
    Math.max(presetHeight.value, canvasMinHeight),
  );
  widgets[canvasWidget.widgetId] = {
    ...canvasWidget,
    minHeight: presetMinHeight,
    height: canvasHeight,
  };

  // Ensure section is taller than the canvas by the padding amount
  widgets[sectionWidget.widgetId] = {
    ...widgets[sectionWidget.widgetId],
    height: Dimension.build(
      canvasHeight.value + canvasPaddingY.value,
      widgets[sectionWidget.widgetId].height.mode,
    ),
    minHeight: Dimension.gridUnit(canvasHeight.value),
  };

  return widgets;
}

type WidgetPropsWithSizing = Pick<
  WidgetProps,
  "height" | "width" | "top" | "left"
>;

export const gridCanvasHasChildConflicts = (
  containerGridColumns: number,
  widgets: WidgetPropsWithSizing[],
): boolean => {
  // Check if any rectangle is outside the container bounds
  for (const widget of widgets) {
    if (
      widget.left.value < 0 ||
      widget.top.value < 0 ||
      widget.left.value + widget.width.value > containerGridColumns
    ) {
      return true; // A rectangle is partially or fully outside
    }
  }

  // Check for overlaps between rectangles
  for (let i = 0; i < widgets.length; i++) {
    for (let j = i + 1; j < widgets.length; j++) {
      if (widgetsAreOverlapping(widgets[i], widgets[j])) {
        return true; // Found overlapping rectangles
      }
    }
  }

  return false; // No conflicts found
};

const widgetsAreOverlapping = (
  widgetA: WidgetPropsWithSizing,
  widgetB: WidgetPropsWithSizing,
): boolean => {
  if (
    widgetA.left.value + widgetA.width.value <= widgetB.left.value ||
    widgetB.left.value + widgetB.width.value <= widgetA.left.value ||
    widgetA.top.value + widgetA.height.value <= widgetB.top.value ||
    widgetB.top.value + widgetB.height.value <= widgetA.top.value
  ) {
    return false; // No overlap
  }
  return true; // Overlap exists
};

/**
 * Adds child widgets to a parent widget if they don't already exist.
 * Returns the modified widgets object with the new children added.
 */
export function addChildIfNotExistsToWidgets({
  widgetId,
  children,
  defaultChildConfigs,
  widgets,
  widgetNames,
  entityNames,
}: {
  widgetId: string;
  children: Array<{
    widgetId: string;
    type: WidgetType;
    position: { left: Dimension<"gridUnit">; top: Dimension<"gridUnit"> };
    size: {
      width: Dimension<WidgetWidthModes>;
      height: Dimension<WidgetHeightModes>;
    };
    [key: string]: any;
  }>;
  defaultChildConfigs: GeneratorReturnType<typeof getCreateConfig>[];
  widgets: CanvasWidgetsReduxState;
  widgetNames: string[];
  entityNames: string[];
}): CanvasWidgetsReduxState {
  try {
    const modifiedWidgets: CanvasWidgetsReduxState = {};
    const allWidgetNames = [...widgetNames];

    if (!children || children.length === 0) {
      return modifiedWidgets; // Return empty object if no changes
    }

    for (const [index, child] of children.entries()) {
      // Create only if it doesn't already exist
      if (!widgets[child.widgetId]) {
        const { position, size, ...otherChildProps } = child;

        const newWidgetName = getNextEntityName(
          defaultChildConfigs[index].widgetName,
          [...allWidgetNames, ...entityNames],
        );
        // update the list of widget names for the next iteration
        allWidgetNames.push(newWidgetName);
        modifiedWidgets[child.widgetId] = {
          ...otherChildProps,
          parentId: widgetId,
          left: position.left,
          top: position.top,
          width: size.width,
          height: size.height,
          gridColumns: size.width.value,
          widgetName: newWidgetName,
          appMode: APP_MODE.EDIT,
          isLoading: false,
        };

        // Only modify parent widget if we're adding a new child
        const widgetToModify = modifiedWidgets[widgetId] ?? {
          ...widgets[widgetId],
        };
        modifiedWidgets[widgetId] = {
          ...widgetToModify,
          children: [...(widgetToModify.children ?? []), child.widgetId],
        };
      }
    }

    // Return only the modified widgets
    return modifiedWidgets;
  } catch (error) {
    console.error(error);
    // In case of error, return empty object
    return {};
  }
}

export function* updateWidgetMetaUpdates(
  widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"],
) {
  // update all meta props with action hooks updates if any
  for (const { widgetId, updates, reset, resetOptions } of widgetMetaUpdates) {
    if (reset === true) {
      yield put(resetWidgetMetaProperty(widgetId, resetOptions));
    } else if (updates) {
      yield put(setMetaProps(widgetId, updates));
    }
  }
}

export function getChildWidgetProps({
  parent,
  params,
  widgets,
  flags,
  theme,
  entityNames,
}: {
  parent: FlattenedWidgetProps;
  params: WidgetAddChild;
  widgets: CanvasWidgetsReduxState;
  flags: Partial<AllFlags>;
  theme: GeneratedTheme;
  entityNames: string[];
}): WidgetProps {
  const { position, size, newWidgetId, props = {}, type } = params;
  let { widgetName } = params;
  let minHeight = undefined;

  const { blueprint: _unused = undefined, ...restDefaultConfig } =
    WidgetConfigResponse.getCreateConfig(
      type as keyof WidgetTypeToPropType,
      flags,
      theme,
    );

  if (!widgetName) {
    const widgetNames = Object.keys(widgets).map((w) => widgets[w].widgetName);

    widgetName = getNextEntityName(restDefaultConfig.widgetName, [
      ...widgetNames,
      ...entityNames,
    ]);
  }

  let height: Dimension<WidgetHeightModes> = size.height;
  let width = size.width;

  if (type === WidgetTypes.CANVAS_WIDGET) {
    if (!hasLeftRightProperties(parent)) throw Error("");
    width = parent.width;
    height = parent.height;
    minHeight = parent.minHeight;
    if (props) props.children = [];
  }

  if (parent) {
    const defaultCanvasVerticalPadding = Dimension.toGridUnit(
      Padding.y(
        getWidgetDefaultPadding(theme, { type: WidgetTypes.CANVAS_WIDGET }),
      ),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).raw().value;

    const sizes: {
      top: Dimension<"gridUnit">;
      left: Dimension<"gridUnit">;
      width: Dimension<WidgetWidthModes>;
      height: Dimension<WidgetHeightModes>;
      minHeight?: Dimension<"gridUnit" | "px">;
      gridColumns?: number;
    } =
      type === WidgetTypes.CANVAS_WIDGET
        ? {
            // These props are persisted to the DB, we don't need left/right on canvases
            top: Dimension.gridUnit(0),
            left: Dimension.gridUnit(0),
            width,
            height,
            gridColumns: width.value,
          }
        : {
            left: position.left,
            top: position.top,
            width,
            height,
            gridColumns: width.value, // TODO(Layouts): This is not correct
          };

    if (type === WidgetTypes.SLIDEOUT_WIDGET) {
      sizes.gridColumns = SLIDEOUT_DEFAULT_COLUMNS.value;
      sizes.width = Dimension.gridUnit(sizes.gridColumns);
    }

    if (parent.type === WidgetTypes.TABS_WIDGET) {
      sizes.height = params.size.height; // use rows passed from TabsWidget which reduces tablist height
      minHeight = undefined; // minHeight is not defined like the first two tabs
    }

    if (parent.type === WidgetTypes.SLIDEOUT_WIDGET) {
      if (parent.children?.length === 0) {
        sizes.height = Dimension.fitContent(
          SLIDEOUT_DEFAULT_ROWS.value + defaultCanvasVerticalPadding,
        );
      }
      // If this slideout already exists and it's a new section being added
      // as a child, then the sizes will be passed in from the create call

      sizes.gridColumns = SLIDEOUT_DEFAULT_COLUMNS.value;
    }

    if (parent.type === WidgetTypes.MODAL_WIDGET) {
      const modalRows =
        MODAL_ROWS_PRESETS[parent.heightPreset as ModalSize] ||
        MODAL_ROWS_PRESETS[ModalSize.MEDIUM];

      if (parent.children?.length === 0) {
        sizes.height = Dimension.fitContent(
          modalRows + defaultCanvasVerticalPadding,
        );
      }
      // If this modal already exists and it's a new section being added
      // as a child, then the sizes will be passed in from the create call

      const modalColumns = parent.widthPreset
        ? MODAL_COLUMNS
        : MODAL_LEGACY_COLUMNS;
      sizes.gridColumns = modalColumns;
    }

    // Copy properties from the parent section and pass it to the child canvas
    // but take into account padding
    if (parent.type === WidgetTypes.SECTION_WIDGET) {
      sizes.top = parent.top ?? sizes.top;
      sizes.left = parent.left ?? sizes.left;
      sizes.width = parent.width ?? sizes.width;
      sizes.height = parent.height
        ? Dimension.fitContent(
            parent.height.value - defaultCanvasVerticalPadding,
          )
        : sizes.height;
      sizes.gridColumns = parent.gridColumns ?? sizes.gridColumns;
      sizes.minHeight = parent.minHeight;

      // All columns are scroll enabled by default
      // this property on canvas widgets gets ignored when the sections flag is off
      // but is used when the flag is on
      props.shouldScrollContents = true;
    }

    const widget: WidgetProps = {
      ...restDefaultConfig,
      type,
      minHeight,
      appMode: APP_MODE.EDIT, // TODO(Layout): Remove this
      ...props,
      ...sizes,
      widgetId: newWidgetId,
      widgetName,
      isLoading: false, // TODO(Layout): Remove this
      parentId: parent.widgetId,
    };

    return widget;
  } else {
    throw Error("Failed to create component: Parent was not provided ");
  }
}

export type GeneratedWidgetPayload = {
  widgetId: string;
  widgets: CanvasWidgetsReduxState;
  widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"];
};

export function generateChildWidgetsUtil({
  parent,
  params,
  widgets,
  propsBlueprint,
  flags,
  theme,
  entityNames,
  appMode,
  widgetsRuntime,
  widgetMetaProps,
  evaluatedWidgets,
  previousAiWidgets,
  dynamicWidgetLayout,
}: {
  parent: FlattenedWidgetProps;
  params: WidgetAddChild;
  widgets: CanvasWidgetsReduxState;
  propsBlueprint?: WidgetBlueprint;
  flags: Partial<AllFlags>;
  theme: GeneratedTheme;
  entityNames: string[];
  appMode: APP_MODE;
  widgetsRuntime: CanvasWidgetsReduxState;
  dynamicWidgetLayout: DynamicWidgetsLayoutState;
  widgetMetaProps: WidgetMetadata;
  evaluatedWidgets: Record<string, DataTreeWidget>;
  previousAiWidgets: Record<string, DataTreeWidget>;
}): GeneratedWidgetPayload {
  const { skipChildGeneration = false } = params;

  // Get the props for the widget
  const widget: WidgetProps & {
    blueprint?: WidgetBlueprint;
  } = getChildWidgetProps({
    parent,
    params,
    widgets,
    flags,
    theme,
    entityNames,
  });

  // Add the widget to the canvasWidgets
  // We need this in here as widgets will be used to get the current widget
  widgets[widget.widgetId] = widget;

  // Get the default config for the widget from WidgetConfigResponse
  const defaultConfig = WidgetConfigResponse.getCreateConfig(
    widget.type as keyof WidgetTypeToPropType,
    flags,
    theme,
  );

  // If blueprint is provided in the params, use that
  // else use the blueprint available in WidgetConfigResponse
  // else there is no blueprint for this widget
  const blueprint =
    propsBlueprint || { ...defaultConfig.blueprint } || undefined;

  // If there is a blueprint.view
  // We need to generate the children based on the view
  if (blueprint && blueprint.view && !skipChildGeneration) {
    // Get the list of children props in WidgetAddChild format
    const childWidgetList = buildView(blueprint.view, widget.widgetId, widget);

    // For each child props
    const childPropsList: GeneratedWidgetPayload[] = childWidgetList.map(
      (props: WidgetAddChild) => {
        const childOverrideProps = params.childProps ?? {};
        // Generate full widget props
        // Notice that we're passing the blueprint if it exists.
        return generateChildWidgetsUtil({
          parent: widget,
          params: {
            ...props,
            props: {
              ...props.props,
              ...childOverrideProps,
            },
          },
          widgets,
          propsBlueprint: props.props?.blueprint,
          flags,
          theme,
          entityNames,
          appMode,
          widgetsRuntime,
          dynamicWidgetLayout,
          widgetMetaProps,
          evaluatedWidgets,
          previousAiWidgets,
        });
      },
    );

    // Start children array from scratch
    widget.children = [];
    childPropsList.forEach((props: GeneratedWidgetPayload) => {
      // Push the widgetIds of the children generated above into the widget.children array
      widget.children?.push(props.widgetId);
      // Add the list of widgets generated into the canvasWidgets
      widgets = props.widgets;
    });
  }

  // Add the parentId prop to this widget
  widget.parentId = parent.widgetId;

  // Finally, add the widget to the canvasWidgets
  // This is different from above, as this is the final widget props with
  // a fully populated widget.children property
  widgets[widget.widgetId] = widget;

  let widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];

  // we do not need to run the hook if there is no child, createSaga calls hooks on all widgets at the end
  if (blueprint && blueprint.view) {
    // Some widgets need to run a few operations like modifying props or adding an action
    // these operations can be performed on the parent of the widget we're adding
    const classes = WidgetFactory.getWidgetClasses();
    const actionHookFn = classes[widget.type]
      ?.applyActionHook as WidgetActionHookSync;

    if (actionHookFn) {
      const {
        widgetUpdates,
        widgetMetaUpdates: metaUpdates,
      }: WidgetActionResponse = actionHookFn({
        action: {
          type: ReduxActionTypes.WIDGET_CREATE,
          payload: params,
        },
        widgetId: widget.widgetId,
        widgets,
        widgetsRuntime: widgetsRuntime as CanvasWidgetsReduxState,
        originalWidgetValues: {},
        flags,
        appMode,
        theme,
        dynamicWidgetLayout,
        widgetMetaProps,
        evaluatedWidgets,
        previousAiWidgets,
      });

      for (const update of widgetUpdates) {
        widgets[update.widgetId] = {
          ...widgets[update.widgetId],
          ...update,
        };
      }

      widgetMetaUpdates = metaUpdates;
    }
  }
  // Remove the blueprint from the widget (if any)
  // as blueprints are not useful beyond this point.
  delete widget.blueprint;

  return {
    widgetId: widget.widgetId,
    widgets,
    widgetMetaUpdates,
  };
}
