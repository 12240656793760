export interface CreateRepositoryRequest {
  name: string;
  owner: string;
  configFileDirectory: string;
  defaultBranch: string;
  protectDefaultBranch: boolean;
  installationId?: string;
  provider: VcsProvider;
}

export interface FetchAppFlobsRequest {
  path: string;
  branch: string;
  repo: string;
  owner: string;
  installationId?: string; // GitHub only
  provider: VcsProvider;
}

export enum RepositoryEntityType {
  APPLICATION = 'application',
  WORKFLOW = 'workflow',
  SCHEDULED_JOB = 'scheduled_job'
}

export interface UpdateBranchProtectionRequest {
  protected: boolean;
}

export interface RepositoryDto {
  id: string;
  name: string;
  owner: string;
  configFileDirectory: string;
  defaultBranch: string;
  githubInstallationId: string | null;
  provider: VcsProvider;
}

export type GitToken = {
  id: string;
  token: string;
  instanceUrl?: string;
  provider: VcsProvider;
  organizationId: string;
};

export enum VcsProvider {
  GITHUB = 'github',
  GITLAB = 'gitlab'
}

export type GitLabInstanceType = 'cloud' | 'self-hosted';

export interface RepositoryWithEntitiesDto extends RepositoryDto {
  entities: {
    id: string;
    name: string;
    type: RepositoryEntityType;
    lastSyncedTo: Date;
  }[];
}

export const getGitProviderBaseUrl = (provider?: VcsProvider, token?: GitToken) => {
  switch (provider) {
    case VcsProvider.GITLAB: {
      if (token?.instanceUrl) return token.instanceUrl;
      return 'https://gitlab.com';
    }
    case VcsProvider.GITHUB:
    default: {
      return 'https://github.com';
    }
  }
};

export const getRepositoryUrl = (repository: RepositoryDto, token?: GitToken) => {
  const baseUrl = getGitProviderBaseUrl(repository.provider, token);
  return `${baseUrl}/${repository.owner}/${repository.name}`;
};

// This function is used to get the owner and name of a repository from a github url.
// Example: `https://github.com/superblocksteam/monorepo` => { owner: 'superblocksteam', name: 'monorepo' }
export const getOwnerAndNameFromGitHubUrl = (
  remoteUrl: string
): {
  owner: string;
  name: string;
} => {
  const [owner, name] = remoteUrl.replace('https://github.com/', '').split('/');
  return {
    owner,
    name
  };
};
