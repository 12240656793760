import { Tooltip, TooltipProps } from "antd";
import { clsx } from "clsx";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as CheckedIcon } from "assets/icons/common/checkmark.svg";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { Button } from "./Button";

const BaseStyles = styleAsClass`
  && {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const IconWrapper = styleAsClass`
  && {
    padding: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);

    &:hover, &:active {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const CopyIconStyles = styleAsClass`
  color: currentColor;
`;

const CheckIconStyles = styleAsClass`
  color: ${colors.ACCENT_GREEN_600};
`;

interface Props {
  textToCopy: string;
  tooltipPlacement?: TooltipProps["placement"];
  dataTest?: string;
  ddogActionName?: string;
  buttonStyle?: React.CSSProperties;
  variant?: "default" | "simple";
  buttonClassName?: string;
}

export const CopyButton = ({
  textToCopy,
  tooltipPlacement = "left",
  dataTest,
  ddogActionName,
  buttonStyle,
  variant = "default",
  buttonClassName,
}: Props) => {
  const [tokenCopied, setTokenCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      navigator.clipboard.writeText(textToCopy);
      setTokenCopied(true);
      timeoutRef.current = setTimeout(() => {
        setTokenCopied(false);
      }, 2000);
    },
    [textToCopy],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={tokenCopied ? "Copied" : "Copy"}
      mouseEnterDelay={0.5}
    >
      <Button
        htmlType="button"
        type={variant === "simple" ? "ghost" : "default"}
        bordered={variant !== "simple"}
        onClick={handleClick}
        size="small"
        aria-label={
          tokenCopied
            ? `Copied ${textToCopy}`
            : `Copy ${textToCopy} to clipboard`
        }
        data-test={dataTest}
        data-dd-action-name={ddogActionName}
        className={clsx(
          BaseStyles,
          {
            [BaseStyles]: true,
            [IconWrapper]: variant === "simple",
          },
          buttonClassName,
        )}
        style={buttonStyle}
      >
        {!tokenCopied ? (
          <CopyIcon className={CopyIconStyles} />
        ) : (
          <CheckedIcon className={CheckIconStyles} />
        )}
      </Button>
    </Tooltip>
  );
};
