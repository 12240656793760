import { createCachedSelector } from "re-reselect";
import { PAGE_WIDGET_ID, WidgetTypes } from "legacy/constants/WidgetConstants";
import { CanvasWidgetsReduxState } from "../reducers/entityReducers/canvasWidgetsReducer";
import type { WidgetProps, WidgetMap } from "legacy/widgets";
import type { AppState } from "store/types";

export const getAppData = (state: AppState) => state.legacy.entities.app;

export const getAppStoreState = (state: AppState) =>
  state.legacy.entities.app.store;

export const getWidgets = (state: AppState): WidgetMap => {
  return state.legacy.entities.canvasWidgets;
};

export const getWidget = (state: AppState, widgetId: string): WidgetProps => {
  return state.legacy.entities.canvasWidgets[widgetId];
};

export const getIsTab = createCachedSelector(
  getWidgets,
  (_: AppState, entityId: string) => entityId,
  (canvasWidgets, entityId): boolean => {
    if (entityId in canvasWidgets) {
      const widget = canvasWidgets[entityId];

      if (
        widget &&
        widget.parentId &&
        widget.parentId in canvasWidgets &&
        canvasWidgets[widget.parentId].type === WidgetTypes.TABS_WIDGET
      ) {
        return true;
      }
    }

    return false;
  },
)((_state, entityId) => entityId);

export const isColumnUtil = (
  canvasWidgets: CanvasWidgetsReduxState,
  entityId: string,
): boolean => {
  if (entityId in canvasWidgets) {
    const widget = canvasWidgets[entityId];

    if (
      widget &&
      widget.parentId &&
      widget.parentId in canvasWidgets &&
      canvasWidgets[widget.parentId].type === WidgetTypes.SECTION_WIDGET
    ) {
      return true;
    }
  }

  return false;
};

export const getIsColumn = createCachedSelector(
  getWidgets,
  (_: AppState, entityId: string) => entityId,
  isColumnUtil,
)((_state, entityId) => entityId);

export const getMainContainer = (state: AppState) => {
  return state.legacy.entities.canvasWidgets[PAGE_WIDGET_ID];
};
