import {
  ActionTypeEnum,
  ResourceTypeEnum,
  TASKS,
} from "@superblocksteam/shared";
import { ReactNode } from "react";
import {
  CREATE_APPLICATIONS,
  CREATE_INTEGRATION,
  CREATE_JOBS,
  CREATE_WORKFLOWS,
  MANAGE_USERS,
} from "constants/rbac";
import { IconProps } from "legacy/constants/IconConstants";
import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import {
  HomeModalRoutes,
  HOME_MODAL_URL,
} from "./../../../legacy/constants/routes";
import { INTEGRATION_URL } from "./../../routes";
import {
  CreateApplicationLink,
  CreateScheduledJobLink,
  CreateWorkflowLink,
} from "./CreateEntityLink";
import DemoLink from "./DemoLink";

type Task = {
  id: TASKS;
  title: string;
  description: string;
  tooltip: {
    completeMessage: string;
    nextTaskMessage: string;
  };
  icon: (props: IconProps) => JSX.Element;

  url?: string;
  permissionsRequired?: {
    resourceType: ResourceTypeEnum;
    action: ActionTypeEnum;
    preRbacValue?: boolean;
  }[];
  hidden?: boolean;
  Link?: ({ children }: { children: ReactNode }) => JSX.Element;
};

export const tasks: Task[] = [
  {
    id: TASKS.COMPLETE_SURVEY,
    title: "Fill out onboarding survey",
    description: "Tell us more about your company and technology",
    tooltip: {
      completeMessage:
        "We have customized your Superblocks experience based on your answers.",
      nextTaskMessage: "Next, fill out the survey",
    },
    icon: ChecklistIcons.SURVEY_ICON,
  },
  {
    id: TASKS.VIEW_DEMO,
    title: "Explore demo apps",
    description: "Securely read & write with Apps, Workflows, Jobs",
    tooltip: {
      completeMessage:
        "Nice work! Take some time to explore the demo application.",
      nextTaskMessage:
        "Next, check out a demo application to see what you can build in Superblocks. ",
    },
    icon: ChecklistIcons.EXPLORE_DEMO_ICON,
    Link: DemoLink,
  },
  {
    id: TASKS.VIEW_DEMO_VIDEO,
    title: "Explore demo video",
    description: "Securely read & write with Apps, Workflows, Jobs",
    tooltip: {
      completeMessage: "Hope the video was helpful!",
      nextTaskMessage: "Next, view the demo video",
    },
    icon: ChecklistIcons.EXPLORE_DEMO_ICON,
    url: HOME_MODAL_URL(HomeModalRoutes.DEMO_VIDEO),
    hidden: true,
  },
  {
    id: TASKS.CONNECT_INTEGRATION,
    title: "Connect your 1st Integration",
    description: "Read & write to Postgres, S3, Snowflake and more",
    tooltip: {
      completeMessage: "Way to go, you just connected your first integration!",
      nextTaskMessage:
        "Next, let’s connect an integration to start building your own internal tool!",
    },
    icon: ChecklistIcons.CONNECT_INTEGRATION_ICON,
    url: INTEGRATION_URL,
    permissionsRequired: [CREATE_INTEGRATION],
  },
  {
    id: TASKS.DEPLOY_APP,
    title: "Create an App",
    description: "Create an App with drag & drop UI blocks",
    tooltip: {
      completeMessage: "Congratulations on deploying your first Application.",
      nextTaskMessage: "Now try building your own Application!",
    },
    icon: ChecklistIcons.APP_ICON,
    Link: CreateApplicationLink,
    // TODO(rbac): potentially add app:deploy permission, but now backend only return create
    permissionsRequired: [CREATE_APPLICATIONS],
  },
  {
    id: TASKS.INVITE_TEAMMATE,
    title: "Invite a teammate",
    description: "Collaborate with your team to build and use your apps",
    tooltip: {
      completeMessage:
        "Sweet invite, the best applications are built together!",
      nextTaskMessage:
        "Now invite your coworkers to show them what you've built",
    },
    icon: ChecklistIcons.INVITE_ICON,
    url: HOME_MODAL_URL(HomeModalRoutes.INVITE_USER),
    permissionsRequired: [MANAGE_USERS],
  },
  {
    id: TASKS.DEPLOY_WORKFLOW,
    title: "Create a Workflow",
    description:
      "Build REST endpoints that can be called with your code or 3rd party webhook",
    tooltip: {
      completeMessage: "Nice Workflow!",
      nextTaskMessage:
        "Now try creating a Workflow. Workflows are REST endpoints built on Superblocks. You can trigger them with code from anywhere",
    },
    icon: ChecklistIcons.WORKFLOW_ICON,
    Link: CreateWorkflowLink,
    permissionsRequired: [CREATE_WORKFLOWS],
  },
  {
    id: TASKS.DEPLOY_SCHEDULED_JOB,
    title: "Create a Scheduled Job",
    description: "Build custom logic to be run on a schedule",
    tooltip: {
      completeMessage:
        "We will automatically run your Scheduled Job on the interval you picked.",
      nextTaskMessage:
        "Now create a Scheduled Job. Scheduled Jobs let you run custom business logic every minute, hour, day, or week.",
    },
    icon: ChecklistIcons.SCHEDULED_JOB_ICON,
    Link: CreateScheduledJobLink,
    permissionsRequired: [CREATE_JOBS],
  },
];

export const getTaskSchema = (id?: TASKS) => {
  return tasks.find((frontendBlueprint) => frontendBlueprint.id === id);
};

export const getTooltipMessage = (
  completedTask?: TASKS,
  nextTask?: TASKS,
): string => {
  const taskSchema = getTaskSchema(completedTask);
  const nextTaskSchema = getTaskSchema(nextTask);
  const lastTask = !nextTaskSchema;
  return `${
    lastTask
      ? "Congratulations on completing your onboarding checklist!\n "
      : ""
  }${taskSchema?.tooltip.completeMessage ?? ""} ${
    nextTaskSchema?.tooltip.nextTaskMessage ?? ""
  }`;
};
