import { CUSTOM_THEME_TYPOGRAPHY_KEY } from "@superblocksteam/shared";
import { get, noop } from "lodash";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ColorPicker,
  ColorPickerProps,
  PresetOption,
} from "components/ui/ColorPicker";
import { useDebounce } from "hooks/ui/useDebounce";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import BaseControl, { ControlProps } from "./BaseControl";

export const THEME_COLOR_KEYS: Array<keyof GeneratedTheme["colors"]> = [
  "primary500",
  "primary600",
  "primary700",
  "appBackground",
  "danger",
  "warning",
  "success",
  "info",
];

export const NEUTRAL_COLOR_KEYS: Array<keyof GeneratedTheme["colors"]> = [
  "neutral900",
  "neutral700",
  "neutral500",
  "neutral300",
  "neutral100",
  "neutral50",
  "neutral",
  "contrastText",
];

type ColorOption = {
  displayName: string;
  hexValue: string;
  value: string;
  key: string;
};

export const getColorOptions = (
  colorKeys: Array<keyof GeneratedTheme["colors"]>,
  colors: GeneratedTheme["colors"],
): ColorOption[] => {
  return colorKeys.map((colorKey) => ({
    hexValue: colors[colorKey] as string,
    value: `{{ theme.colors.${colorKey} }}`,
    displayName: colorKey,
    key: `colors.${colorKey}`,
  }));
};

type Presets = Array<{
  title: string;
  options: PresetOption[];
}>;

export const transparentOption = {
  displayName: "transparent",
  hexValue: "#00000000",
  value: "transparent",
  key: "transparent",
};

const usePresets = (generatedTheme: GeneratedTheme, themeValue: string) => {
  const [presets, setPresets] = useState<Presets>([]);
  const [themeColor, setThemeColor] = useState<ColorOption | null>(null);

  const getPresets = useCallback(() => {
    const colors = generatedTheme.colors;
    const typographies = generatedTheme.typographies;
    const newPresets = [
      {
        title: "Theme Colors",
        options: getColorOptions(THEME_COLOR_KEYS, colors),
      },
      {
        title: "Neutral Colors",
        options: [
          ...getColorOptions(NEUTRAL_COLOR_KEYS, colors),
          transparentOption,
        ],
      },
    ];
    let themeColor: ColorOption | undefined = [
      ...newPresets[0].options,
      ...newPresets[1].options,
    ].find((opt) => opt.key === themeValue);

    const themeValuePath = themeValue?.split(".");
    if (
      !themeColor &&
      Boolean(themeValue) &&
      themeValuePath?.[0] === "typographies"
    ) {
      // must be a typography color
      const typographyVariantName = themeValuePath[1];
      if (typographyVariantName === SB_CUSTOM_TEXT_STYLE) {
        themeColor = undefined;
      } else if (typographyVariantName === CUSTOM_THEME_TYPOGRAPHY_KEY) {
        const customKey = themeValuePath[2];
        const customVariantName =
          generatedTheme.typographies?.custom?.[customKey ?? ""]?.name;
        if (customVariantName) {
          const accessor = themeValuePath.slice(1).join(".");
          themeColor = {
            displayName: customVariantName ?? typographyVariantName,
            key: themeValue,
            hexValue: get(typographies, accessor),
            value: "",
          };
        }
      } else {
        themeColor = {
          displayName: themeValuePath[1],
          key: themeValue,
          hexValue: get(
            typographies,
            `${typographyVariantName}.textColor.default`,
          ),
          value: "",
        };
      }
    }

    if (!themeColor && themeValue === "dynamic") {
      themeColor = {
        displayName: "dynamic contrast",
        key: "dynamicContrast",
        hexValue: colors.contrastText,
        value: "",
      };
    }

    setPresets(newPresets);
    setThemeColor(themeColor ?? null);
  }, [generatedTheme, themeValue]);

  useEffect(() => {
    getPresets();
  }, [getPresets]);

  return { presets, themeColor };
};

const useUpdateColor = (
  updateProperty: (name: string, value: any) => void,
  propertyName: string,
) => {
  const handleChangeColor = useCallback(
    (color?: string) => {
      updateProperty(propertyName, color);
    },
    [updateProperty, propertyName],
  );

  return useDebounce(handleChangeColor, 400) ?? noop;
};

const useMatchingPresetOption = (presets: Presets) => {
  return useCallback(
    (value: string) => {
      if (presets && value) {
        return presets
          .flatMap((preset) => preset.options)
          .find((opt) => opt.value.toUpperCase() === value.toUpperCase());
      }
    },
    [presets],
  );
};

const useSelectedColor = (
  presets: Presets,
  propertyValue: string,
  defaultValue: string,
  themeValue: string | undefined,
  themeColor: ColorOption | null,
) => {
  const getMatchingPresetOption = useMatchingPresetOption(presets);

  const { selectedOption, defaultOption, fallbackThemeValue } = useMemo(() => {
    let selectedOption: PresetOption | string = propertyValue;
    let defaultOption: PresetOption | string = defaultValue;
    let fallbackThemeValue: PresetOption | string | undefined = undefined;

    if (propertyValue) {
      const presetSelectedOption = getMatchingPresetOption(propertyValue);
      if (presetSelectedOption) {
        selectedOption = presetSelectedOption;
      }
    }

    if (defaultValue) {
      const presetDefaultOption = getMatchingPresetOption(defaultValue);
      if (presetDefaultOption) {
        defaultOption = presetDefaultOption;
      }
    }

    // fallbackThemeValue is for when we use the theme value as an inherited value
    // from a parent component, which currently only happens
    // with table component columns
    if (themeValue && !themeColor) {
      const presetThemeValue = getMatchingPresetOption(themeValue);

      if (presetThemeValue) {
        fallbackThemeValue = presetThemeValue;
      } else if (themeValue) {
        fallbackThemeValue = themeValue;
      }
    }

    return { selectedOption, defaultOption, fallbackThemeValue };
  }, [
    propertyValue,
    defaultValue,
    themeValue,
    themeColor,
    getMatchingPresetOption,
  ]);

  const selectedColor = selectedOption ?? defaultOption ?? fallbackThemeValue;

  return {
    selectedColor,
  };
};

const ColorPickerInternal = (
  props: ColorPickerControlProps &
    Pick<BaseControl<ColorPickerControlProps>, "updateProperty">,
) => {
  const generatedTheme = useSelector(selectGeneratedTheme);
  const { presets, themeColor } = usePresets(generatedTheme, props.themeValue);

  const { selectedColor } = useSelectedColor(
    presets,
    props.propertyValue,
    props.defaultValue,
    props.themeValue,
    themeColor,
  );

  const debouncedHandleChangeColor = useUpdateColor(
    props.updateProperty,
    props.propertyName,
  );

  if (!generatedTheme || !presets.length) return null;

  return (
    <ColorPicker
      selectedColor={selectedColor}
      onColorSelect={debouncedHandleChangeColor}
      presetColorChoices={presets}
      popoverPlacement="left"
      showOpacity={true}
      showInputsInPopover={true}
      isClearable={false}
      defaultSelectedColor={themeColor}
      disabled={props.isDisabled}
      compact={props.compact}
    />
  );
};

type ColorPickerControlProps = ControlProps & Pick<ColorPickerProps, "compact">;

export default class ColorPickerControl extends BaseControl<ColorPickerControlProps> {
  constructor(props: ControlProps) {
    super(props);
    this.updateProperty = this.updateProperty.bind(this);
  }

  render() {
    return (
      <ColorPickerInternal
        {...this.props}
        updateProperty={this.updateProperty}
      />
    );
  }

  static getControlType() {
    return "COLOR_PICKER";
  }
}
