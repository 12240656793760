import {
  CheckCircleFilled,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";
import { PluginResponseType, PluginType } from "@superblocksteam/shared";
import { List, Space, Spin, Table, Tabs, Typography } from "antd";
import { isArray, isString } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import Alert from "components/ui/Alert";
import Badge from "components/ui/Badge";
import { CodeBlock } from "components/ui/CodeBlock";
import { CopyButton } from "components/ui/CopyButton";
import { FullWidthSpace } from "components/ui/Space";
import { StyledSwitch } from "legacy/components/propertyControls/StyledControls";
import { scrollbarLight } from "legacy/constants/DefaultTheme";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import { DotSeparator } from "../common/StyledComponents";
import StyledTabs from "./StyledTabs";
import type { SharedExecutionOutput } from "store/slices/apisShared";

const RESULTS_JSON_VIEW_COLLAPSE_STRING_AFTER_LENGTH = 5000;

export const ACTION_PANE_MIN_SIZE = 66;
export const RESULTS_PANE_MIN_SIZE = 56;
export const DEFAULT_RESULTS_PANE_SIZE = 200;
const BYTES_STRING_MAX_LENGTH = 80;

const Wrapper = styled.div`
  padding: 0 12px 12px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.WHITE};

  ${scrollbarLight}

  .ant-table-wrapper .ant-table-cell {
    font-size: 12px;
    padding: 8px 16px;
    color: ${(props) => props.theme.colors.GREY_900};
  }

  .ant-table:not(.ant-table-empty) thead > tr > th {
    padding: 2px 16px;
  }
`;

const ResultContainer = styled.div`
  height: 100%;
`;

const TabsPane = styled(Tabs.TabPane)`
  overflow: auto;
  max-height: 50%;
`;

const StyledList = styled(List)`
  font-size: 12px;
`;

const ResultPaneHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
`;

const JsonSwitchContainer = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;

  .ant-typography {
    font-weight: 500;
    color: ${(props) => props.theme.colors.GREY_700};
  }
`;

const SuccessCheckMark = styled(CheckCircleFilled)`
  color: ${(props) => props.theme.colors.ACCENT_GREEN_600};
  margin-left: 5px;
  margin-right: 5px;
`;

const LoadingIcon = styled(LoadingOutlined)`
  color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  margin-left: 5px;
  margin-right: 5px;
`;

const ErrorIcon = styled(CloseCircleOutlined)`
  color: ${(props) => props.theme.colors.DANGER};
  margin-left: 5px;
  margin-right: 5px;
`;

const SuccessText = styled(Typography.Text)`
  color: ${(props) => props.theme.colors.ACCENT_GREEN_600};
  font-weight: bold;
`;

const CancelledText = styled(Typography.Text)`
  color: ${(props) => props.theme.colors.ACCENT_ORANGE_600};
  font-weight: bold;
  margin-left: 10px;
`;

const LoadingText = styled(Typography.Text)`
  margin-right: 5px;
  color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  font-weight: bold;
`;

const ErrorText = styled(Typography.Text)`
  color: ${(props) => props.theme.colors.DANGER};
  font-weight: bold;
`;

const ExecutionTimeText = styled(Typography.Text)`
  &.ant-typography {
    color: ${(props) => props.theme.colors.ACCENT_GREEN_600 + "cc"};
  }
`;

const ErrorHelpInfoContainer = styled.div`
  background-color: lightyellow;
  padding: 8px 15px;
`;

const CopyButtonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const JSONContainer = styled.div`
  font-size: 12px;
  background-color: ${(props) => props.theme.palettes.gray[10]};
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palettes.gray[9]};
  position: relative;
  max-width: 100%;
`;

enum SelectedTab {
  RESULT = "Result",
  REQUEST = "Request",
  CONSOLE = "Console",
}

interface ResultPaneProps {
  name?: string;
  jsonParent?: string;
  supportsRawRequest: boolean;
  rawRequestName: string;
  executionOutput?: SharedExecutionOutput;
  dataFormat: PluginResponseType;
  isLoading: boolean;
  isCancelled: boolean;
  showNotRunWarning: boolean;
  errorActionUrl?: string;
  errorActionName?: string;
  pluginType?: PluginType;
  executionError?: string;
  systemError?: string;
  errorHelpInfoMsg?: JSX.Element;
  disableStepTime?: boolean;
}

function formatByteString(value: Uint8Array, maxStrLen: number) {
  const maxLen = Math.floor((maxStrLen - "<bytes>".length) / 3);
  return `<bytes ${[...value.subarray(0, maxLen)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join(" ")}${value.length > maxLen ? "…" : ""}>`;
}

const ResultPane = ({
  name,
  jsonParent = "output",
  supportsRawRequest,
  rawRequestName,
  executionOutput,
  dataFormat,
  isLoading,
  isCancelled,
  showNotRunWarning,
  pluginType,
  executionError,
  systemError,
  errorActionUrl,
  errorActionName,
  errorHelpInfoMsg,
  disableStepTime,
}: ResultPaneProps) => {
  const theme = useTheme();

  const sqlParams = useMemo(
    () =>
      executionOutput?.placeholdersInfo &&
      Object.values(executionOutput.placeholdersInfo),
    [executionOutput?.placeholdersInfo],
  );
  const executionOutputInResultPane =
    executionOutput?.output instanceof Uint8Array
      ? formatByteString(executionOutput?.output, BYTES_STRING_MAX_LENGTH)
      : (executionOutput?.output as string);

  const columns = useMemo(() => {
    const rows =
      (executionOutput?.output as Array<Record<string, unknown>>) ?? [];
    if (
      !Array.isArray(rows) ||
      rows.length <= 0 ||
      dataFormat !== PluginResponseType.TABLE
    ) {
      return [];
    }

    return Object.keys(rows[0]).map((name) => ({
      title: name,
      dataIndex: name,
      key: name,
    }));
  }, [executionOutput, dataFormat]);

  const tableDatasource = useMemo(() => {
    if (
      dataFormat !== PluginResponseType.TABLE ||
      !executionOutput ||
      !isArray(executionOutput.output)
    ) {
      return [];
    }

    const rows =
      (executionOutput?.output as Array<Record<string, unknown>>) ?? [];

    if (!Array.isArray(rows)) {
      return [];
    }

    return rows.map((row, idx) => {
      const ret = {} as Record<string, unknown>;
      for (const [key, value] of Object.entries(row)) {
        ret[key] = isString(value) ? value : JSON.stringify(value);
      }
      ret["key"] = idx + 1;
      return ret;
    });
  }, [executionOutput, dataFormat]);

  const hasError = useMemo(
    () =>
      (executionOutput && "error" in executionOutput) ||
      systemError ||
      executionError,
    [executionError, executionOutput, systemError],
  );

  const hasRawRequest = useMemo(
    () =>
      !(
        executionOutput?.request === undefined ||
        executionOutput?.request === null
      ),
    [executionOutput],
  );

  const [selectedTab, setSelectedTab] = useState(SelectedTab.RESULT);

  const [jsonViewActive, setJsonViewActive] = useState(false);

  useEffect(
    () => setJsonViewActive(dataFormat !== PluginResponseType.TABLE),
    [dataFormat],
  );

  const extraContent = useMemo(
    () => (
      <ResultPaneHeader>
        {selectedTab === SelectedTab.RESULT && (
          <>
            {hasError && !isLoading && (
              <>
                <ErrorIcon />
                <ErrorText data-test="result-error">{"Error"}</ErrorText>
              </>
            )}
            {isLoading && (
              <>
                <LoadingIcon />
                <LoadingText data-test="result-loading">
                  {"Loading"}
                </LoadingText>
              </>
            )}
            {isCancelled && (
              <CancelledText data-test="result-cancelled">
                {"API Run Cancelled"}
              </CancelledText>
            )}
            {executionOutput &&
              !isLoading &&
              !hasError &&
              !isCancelled &&
              !disableStepTime && (
                <>
                  <SuccessCheckMark />
                  <SuccessText data-test="result-success">
                    {"Success"}
                  </SuccessText>
                  <DotSeparator />
                  <ExecutionTimeText type="secondary">
                    {String(executionOutput.executionTime) + " ms"}
                  </ExecutionTimeText>
                </>
              )}
            {!hasError && dataFormat === PluginResponseType.TABLE && (
              <JsonSwitchContainer>
                <StyledSwitch
                  large
                  disabled={!executionOutput}
                  checked={jsonViewActive}
                  onChange={() => {
                    setJsonViewActive(!jsonViewActive);
                  }}
                />
                <Typography.Text>Show JSON</Typography.Text>
              </JsonSwitchContainer>
            )}
          </>
        )}
      </ResultPaneHeader>
    ),
    [
      selectedTab,
      hasError,
      isLoading,
      isCancelled,
      executionOutput,
      disableStepTime,
      dataFormat,
      jsonViewActive,
    ],
  );

  const errorAlert = useMemo(() => {
    const msg =
      systemError ??
      executionError ??
      executionOutput?.error?.fullErrorMessage?.trim?.() ??
      "";

    const notSerializable = msg?.includes("not JSON serializable");
    const mapNotSerializable = msg?.includes(
      "Object of type map is not JSON serializable",
    );

    let serializationErrorHelp;
    if (mapNotSerializable) {
      serializationErrorHelp = (
        <>
          <br></br>
          <a
            target="_blank"
            href={DOCS_PAGE_URL(DocsPage.RETURNING_JSON)}
            rel="noreferrer"
          >
            The return value of your Python code must be JSON serializable
          </a>
          <br></br>
          <b>map()</b> in Python 3 is a generator function, which is not
          serializeable in JSON
          <br></br>
          You can make it serializeable by casting it to a <b>list</b>
        </>
      );
    } else if (notSerializable) {
      serializationErrorHelp = (
        <a
          target="_blank"
          href={DOCS_PAGE_URL(DocsPage.RETURNING_JSON)}
          rel="noreferrer"
        >
          The return value of your Python code must be JSON serializable
        </a>
      );
    }

    return (
      <>
        {errorHelpInfoMsg && (
          <ErrorHelpInfoContainer>{errorHelpInfoMsg}</ErrorHelpInfoContainer>
        )}
        <Alert
          message={
            <code data-test="step-failure-error">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                <ReactMarkdown>{msg}</ReactMarkdown>
              </pre>
              {serializationErrorHelp}
            </code>
          }
          type="error"
        />
      </>
    );
  }, [executionError, executionOutput, errorHelpInfoMsg, systemError]);

  const warningAlert = useMemo(() => {
    return (
      <Alert
        message={
          <code data-test={"not-run-warning"}>
            <pre>
              {`This step was not executed due to the failure in `}
              <Link to={errorActionUrl ?? ""}>{errorActionName}</Link>
            </pre>
          </code>
        }
        type="warning"
      />
    );
  }, [errorActionName, errorActionUrl]);

  const onChange = useCallback(
    (newTabKey: string) => setSelectedTab(newTabKey as SelectedTab),
    [],
  );
  return (
    <Wrapper>
      <StyledTabs
        defaultActiveKey={"1"}
        size="middle"
        type="line"
        tabBarExtraContent={extraContent}
        onChange={onChange}
        destroyInactiveTabPane={true}
      >
        <TabsPane tab="Output" key={SelectedTab.RESULT}>
          <Spin spinning={isLoading} size={"large"}>
            <ResultContainer data-test="result-container">
              <FullWidthSpace direction="vertical" size={10}>
                {showNotRunWarning && warningAlert}
                {!hasError ? (
                  <>
                    {dataFormat === PluginResponseType.TABLE &&
                    !jsonViewActive ? (
                      <Table
                        bordered={true}
                        columns={columns}
                        dataSource={tableDatasource}
                      />
                    ) : (
                      <>
                        <JSONContainer>
                          <>
                            <ReactJson
                              style={{ fontSize: theme.text.sizes.default }}
                              indentWidth={2}
                              collapsed={2}
                              src={{
                                [jsonParent]: executionOutputInResultPane,
                              }}
                              name={name}
                              displayObjectSize={false}
                              collapseStringsAfterLength={
                                RESULTS_JSON_VIEW_COLLAPSE_STRING_AFTER_LENGTH
                              }
                              enableClipboard={false}
                              quotesOnKeys={false}
                              displayDataTypes={false}
                            />
                            {executionOutput?.output && (
                              <CopyButtonWrapper>
                                <CopyButton
                                  dataTest="api-result-copy-json"
                                  textToCopy={JSON.stringify(
                                    executionOutputInResultPane,
                                    null,
                                    2,
                                  )}
                                />
                              </CopyButtonWrapper>
                            )}
                          </>
                        </JSONContainer>
                      </>
                    )}
                  </>
                ) : (
                  <>{errorAlert}</>
                )}
              </FullWidthSpace>
            </ResultContainer>
          </Spin>
        </TabsPane>
        {supportsRawRequest && (
          <TabsPane
            tab={rawRequestName}
            key={SelectedTab.REQUEST}
            disabled={!hasRawRequest}
          >
            <Spin spinning={isLoading} size={"large"}>
              {showNotRunWarning && warningAlert}
              {hasRawRequest ? (
                <CodeBlock
                  text={executionOutput?.request ?? ""}
                  sqlParams={sqlParams}
                />
              ) : executionOutput?.error ? (
                errorAlert
              ) : (
                <StyledList />
              )}
            </Spin>
          </TabsPane>
        )}
        <TabsPane
          tab={
            <Space>
              <Typography.Text>Console</Typography.Text>
              {executionOutput && executionOutput.log.length > 0 && (
                <Badge
                  backgroundColor={theme.palettes.gray[6]}
                  count={executionOutput?.log.length}
                />
              )}
            </Space>
          }
          key={SelectedTab.CONSOLE}
        >
          <Spin spinning={isLoading} size={"large"}>
            <StyledList
              dataSource={executionOutput?.log}
              renderItem={(item, idx) => (
                <List.Item key={idx}>
                  <pre>{item as ReactNode}</pre>
                </List.Item>
              )}
            />
          </Spin>
        </TabsPane>
      </StyledTabs>
    </Wrapper>
  );
};

export default ResultPane;
