import { Alert, AlertProps } from "antd";
import styled from "styled-components";
import { ReactComponent as WarningIcon } from "assets/icons/common/system-danger.svg";
import { ReactComponent as InfoIcon } from "assets/icons/common/system-info.svg";
import { styleAsClass } from "styles/styleAsClass";

export { WarningIcon, InfoIcon };

export const AlertStyles = styleAsClass`
  &.ant-alert {
    padding: 8px;
  }
`;

export type AlertType = "success" | "error" | "info" | "warning" | undefined;

const StyledAlert = styled(Alert)`
  font-size: 12px;
  background: ${({ theme }) => theme.colors.DANGER_SUBTLE};
  border: 0;
  pre {
    margin-bottom: 0;
  }
  .ant-alert-message {
    font-size: 14px;
  }
  .ant-alert-description {
    font-size: 12px;
  }
`;

const InnerAlert = (props: AlertProps) => {
  return <StyledAlert {...props} />;
};

export default InnerAlert;
